import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import * as QRCode from "qrcode";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";
import { ToastrService } from "ngx-toastr";
import { depotDetails } from "@/model/depotDetails/depotDetails";
import Swal from "sweetalert2";

@Component({
  selector: "app-toll-gate-form",
  templateUrl: "./toll-gate-form.component.html",
  styleUrls: ["./toll-gate-form.component.css"]
})
export class TollGateFormComponent implements OnInit {
  choosedTime: any;
  choosedDate: any;
  tollGateTypes: string[] = ["V", "A", "Heavy"];
  Purposes: string[] = ["Loading", "Un Loading"];
  selectedPurpose: string = "Loading";
  todayDate: any;
  date_Model: any;
  currentTime: any;
  currentDate: any;
  currentYear: any;
  currentMonth: any;
  currentDay: any;
  datepipe: any;
  form: FormGroup;
  heavyForm: any;
  vForm: any;
  aForm: any;
  qrCodeGenerate = false;
  vehicleNumber: string;
  currentTollGate: TollGate | undefined;
  qrCodeData: string = "https://aftc-digi.varnik.cloud/#/qrEntry?code=";
  vehicleOption: string[] = [];
  wheelOptions: number[] = [];
  heavy = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedForm: string = "V";
  qrCodeUrl: string;
  allMemberDetails: depotDetails[] = [];
  currentMemberDetails: depotDetails[] = [];
  currentMemberNames: string[] = [];
  commodityDetails: CommodityDetails[] = [];
  commodityNames: string[] = [];
  serviceType = false;
  userType: string;

  dropdownList = [];
  selectedItem = this.dropdownList;
  dropdownSettings = {};

  allStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana",
    "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
    "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh",
    "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep", "Delhi", "Puducherry"
  ];

  constructor(private formBuilder: FormBuilder, private apiService: ApiService,
              datepipe: DatePipe, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {
    this.datepipe = datepipe;
    this.todayDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.initial_data();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data available',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      allowRemoteDataSearch: false,
      itemsShowLimit: 3,
      lazyLoading: false,
      selectAllText: '',
      unSelectAllText: '',
    };
  }

  gateForm = new UntypedFormGroup({
    tollgateSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    memberName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateTypeSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehEntryDateTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehExitDateTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehCost: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterEntryName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weightMt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    bagsUnits: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    consignorSupplierName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    placeDispatchOrigin: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    deliveryWayBill: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    declarationDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    purpose: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    charges: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterAgentName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    securityClerk: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    passPrice: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    reportQr: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    isActive: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    modifiedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateVehicleDetails: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleQr: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    wheelCount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    driverName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    driverPhNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehInfo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehNumberPlate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehState: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    cashDeposit: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  // Save tollgate details
  saveForm() {
    this.spinner.show();
    this.gateForm.controls["charges"].enable();
    const entryDate = this.gateForm.get("vehEntryDateTime").value;
    const epochTimestamp = new Date(entryDate).getTime() / 1000;
    this.gateForm.controls["vehEntryDateTime"].setValue(epochTimestamp);
    const declarationDate = this.gateForm.get("declarationDate").value;
    const epochTimeDeclarationDate = new Date(declarationDate).getTime() / 1000;
    this.gateForm.controls["declarationDate"].setValue(epochTimeDeclarationDate);
    this.gateForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    const tollGateDetails = this.gateForm.value;
    this.gateForm.controls["cashDeposit"].setValue(false);

    // For nested json combined the tollgate & vehicle details
    const combinedData = {
      tollgateSeq: tollGateDetails.tollgateSeq,
      memberName: tollGateDetails.memberName[0].item_text,
      tollgateType: tollGateDetails.tollgateType,
      tollgateTypeSeq: tollGateDetails.tollgateTypeSeq,
      vehEntryDateTime: tollGateDetails.vehEntryDateTime,
      vehExitDateTime: tollGateDetails.vehExitDateTime,
      vehCost: tollGateDetails.vehCost,
      renterSeq: tollGateDetails.renterSeq,
      renterEntryName: tollGateDetails.renterEntryName,
      commodityName: tollGateDetails.commodityName,
      serviceType: tollGateDetails.serviceType,
      weightMt: tollGateDetails.weightMt,
      bagsUnits: tollGateDetails.bagsUnits,
      consignorSupplierName: tollGateDetails.consignorSupplierName,
      placeDispatchOrigin: tollGateDetails.placeDispatchOrigin,
      deliveryWayBill: tollGateDetails.deliveryWayBill,
      declarationDate: tollGateDetails.declarationDate,
      purpose: tollGateDetails.purpose,
      charges: tollGateDetails.charges,
      renterAgentName: tollGateDetails.renterAgentName,
      securityClerk: tollGateDetails.securityClerk,
      passPrice: tollGateDetails.passPrice,
      reportQr: tollGateDetails.reportQr,
      active: tollGateDetails.active,
      createdAt: tollGateDetails.createdAt,
      modifiedAt: tollGateDetails.modifiedAt,
      storageType: tollGateDetails.storageType,
      tollgateVehicleDetails: {
        vehicleSeq: tollGateDetails.vehicleSeq,
        vehicleQr: tollGateDetails.vehicleQr,
        wheelCount: tollGateDetails.wheelCount,
        driverName: tollGateDetails.driverName,
        driverPhNo: tollGateDetails.driverPhNo,
        vehInfo: tollGateDetails.vehInfo,
        vehNumberPlate: tollGateDetails.vehNumberPlate.toUpperCase(),
        vehState: tollGateDetails.vehState,
        vehType: tollGateDetails.vehType
      }
    };
    this.apiService.addTollGate(combinedData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        this.currentTollGate = data;
        this.qrCodeGenerate = true;
        Swal.fire({
          title: "Do you want print?",
          icon: "warning",
          showCancelButton: true,
          showDenyButton: true,
          denyButtonColor: "#3091d6",
          confirmButtonColor: "#30d66a",
          cancelButtonColor: "#d33",
          confirmButtonText: "A5 Print",
          denyButtonText: "Thermal Print",
          cancelButtonText: "No Print"
        }).then((result) => {
          if (result.isConfirmed) {
            sessionStorage.setItem("reportQr", this.currentTollGate.reportQr);
            this.router.navigate(["toll-gate-print"]);
          } else if (result.isDenied) {
            sessionStorage.setItem("reportQr", this.currentTollGate.reportQr);
            this.router.navigate(["tollgate-receipt"]);
          } else {
            Swal.fire({
              title: "Details saved successfully",
              icon: "success"
            }).then((res) => {
              this.spinner.show();
              window.location.reload();
            });
          }
        });
        this.gateForm.reset();
        this.initial_data();
      }, (error: any) => {
        this.spinner.hide();
      });
  }

  ngOnInit(): void {
    this.userType = localStorage.getItem("role");
    this.getMemberDetails();
    this.getCommodityDetails();
    this.initial_data();
    Object.keys(this.gateForm.controls).forEach((controlName) => {
      if (controlName !== "serviceType") {
        this.gateForm.get(controlName).disable();
      }
    });
  }

  // Get all member details
  getMemberDetails() {
    this.apiService.getDepotDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.allMemberDetails = data.filter(item => item.records == "NEW");
      }, (err: any) => {
      });
  }

  // Get all commodity details
  getCommodityDetails() {
    this.apiService.getCommodityDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.commodityDetails = data;
        const set = new Set(this.commodityDetails.map(item => item.commodityName));
        this.commodityNames = Array.from(set).sort((a, b) => a.localeCompare(b));
      }, (err: any) => {
      });
  }

  // Get current Date
  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.gateForm.controls["vehEntryDateTime"].setValue(formattedDateTime);
    this.gateForm.controls["vehState"].setValue("Tamil Nadu");
  }

  // Field conditions based on selection of service type
  selectServiceType(event: any) {
    this.serviceType = true;
    this.vehicleOption = [];
    this.currentMemberDetails = [];
    this.currentMemberNames = [];
    const value = event.target.value;
    if (value == "Warehouse") {
      this.gateForm.enable();
      this.gateForm.controls["charges"].disable();
      this.gateForm.controls["storageType"].enable();
      this.vehicleOption.push("Auto");
      this.vehicleOption.push("Van");
      this.vehicleOption.push("Truck");
    } else if (value == "Depot") {
      this.gateForm.enable();
      this.gateForm.controls["charges"].disable();
      this.gateForm.controls["storageType"].disable();
      this.vehicleOption.push("Auto");
      this.vehicleOption.push("Van");
      this.vehicleOption.push("Truck");
    }
    this.currentMemberDetails = this.allMemberDetails.sort((a, b) => a.depotOccupantName.localeCompare(b.depotOccupantName));
    this.dropdownList = this.currentMemberDetails.map(member => {
      var returnValue = {};
      if (member.depotNo != '') {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / ' + member.depotNo }
      } else {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName }
      }
      return returnValue;
    });
  }

  // Number only acceptable condition
  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowRight", "ArrowLeft"];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  formErrorValidation = {
    memberName: "",
    vehNumberPlate: "",
    vehEntryTime: "",
    vehType: "",
    driverPhNo: "",
    wheelCount: "",
    serviceType: "",
    printType: "",
    purpose: "",
    weightMt: "",
    commodityName: ""
  };

  // Required field validation conditions
  formValidation(form: any) {
    this.formErrorValidation.memberName = "";
    this.formErrorValidation.vehEntryTime = "";
    this.formErrorValidation.vehNumberPlate = "";
    this.formErrorValidation.vehType = "";
    this.formErrorValidation.driverPhNo = "";
    this.formErrorValidation.wheelCount = "";
    this.formErrorValidation.serviceType = "";
    this.formErrorValidation.printType = "";
    this.formErrorValidation.purpose = "";
    this.formErrorValidation.weightMt = "";
    this.formErrorValidation.commodityName = "";
    let hasError = false;

    if (this.gateForm.get("vehType").value == "Truck") {
      if (this.gateForm.get("purpose").invalid) {
        this.formErrorValidation.purpose = "Purpose is Required";
        hasError = true;
      }

      if (this.gateForm.get("purpose").value == "Unloading" && this.gateForm.get("weightMt").invalid) {
        this.formErrorValidation.weightMt = "Weight MT is Required";
        hasError = true;
      }

      if (this.gateForm.get("purpose").value == "Unloading" && this.gateForm.get("commodityName").invalid) {
        this.formErrorValidation.commodityName = "Commodity is Required";
        hasError = true;
      }
    }
    if (this.gateForm.get("memberName").invalid) {
      this.formErrorValidation.memberName = "Member Name is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehNumberPlate").invalid) {
      this.formErrorValidation.vehNumberPlate = "Vehicle Number is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehEntryDateTime").invalid) {
      this.formErrorValidation.vehEntryTime = "Vehicle Entry Time is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehType").invalid) {
      this.formErrorValidation.vehType = "Vehicle Type is Required";
      hasError = true;
    }

    if (this.gateForm.get("wheelCount").invalid) {
      this.formErrorValidation.wheelCount = "Wheel Count is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehType").value == "Truck") {
      if (this.gateForm.get("driverPhNo").value < 10) {
        this.formErrorValidation.driverPhNo = "10 Numbers Required";
        hasError = true;
      }
    }

    if (this.gateForm.get("serviceType").invalid) {
      this.formErrorValidation.serviceType = "Service Type is Required";
      hasError = true;
    }

    if (!hasError) {
      this.saveForm();
    }
  }

  // Wheels count set based on selected vehicle type
  selectVehicleType(event: any) {
    const value = event.target.value;
    this.wheelOptions = [];
    var wheel: number[] = [];
    if (value == "Auto") {
      wheel.push(Number(3));
      this.gateForm.controls["wheelCount"].setValue(Number(3));
      this.gateForm.controls["charges"].setValue(Number(5));
      this.heavy = false;
    } else if (value == "Van") {
      wheel.push(Number(4));
      this.gateForm.controls["wheelCount"].setValue(Number(4));
      this.gateForm.controls["charges"].setValue(Number(10));
      this.heavy = false;
    } else if (value == "Truck") {
      wheel.push(Number(6));
      wheel.push(Number(10));
      wheel.push(Number(12));
      this.gateForm.controls["wheelCount"].setValue("");
      this.heavy = true;
    }

    this.wheelOptions = wheel;
  }

  // When change the wheel again select the purpose field
  wheelCountFunction(event: any) {
    this.gateForm.controls["purpose"].setValue("");
    this.gateForm.controls["charges"].setValue("");
  }

  // Charges calculation based on purpose
  chargeCal(event: any) {
    const value = event.target.value;
    if (value == "Loading") {
      if (this.gateForm.get("wheelCount").value == Number(6)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value > Number(6)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      }
      this.gateForm.controls["weightMt"].setValue("");
      this.gateForm.controls["bagsUnits"].setValue("");
      this.gateForm.controls["commodityName"].setValue("");
      this.gateForm.controls["weightMt"].disable();
      this.gateForm.controls["bagsUnits"].disable();
      this.gateForm.controls["commodityName"].disable();
    } else if (value == "Unloading") {
      const weightValue = this.gateForm.get("weightMt").value;
      const weight = Math.round(weightValue);
      if (this.gateForm.get("vehState").value == "Tamil Nadu") {
        const charge = weight * Number(15);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        }
      } else {
        const charge = weight * Number(30);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        }
      }
      this.gateForm.controls["weightMt"].enable();
      this.gateForm.controls["bagsUnits"].enable();
      this.gateForm.controls["commodityName"].enable();
    }
  }

  // Charges calculation based on state
  changeState(event: any) {
    const value = event.target.value;
    const weight = Math.round(this.gateForm.get("weightMt").value);
    if (this.gateForm.get("purpose").value == "Unloading") {
      if (value == "Tamil Nadu") {
        const charge = weight * Number(15);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        }
      } else {
        const charge = weight * Number(30);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        }
      }
    }
  }

  // Charges calculation based on weight
  calculateCharges(event: any) {
    const value = event.target.value;
    const weight = Math.round(value);
    console.log(weight);
    if (this.gateForm.get("vehState").value == "Tamil Nadu") {
      const charge = weight * Number(15);
      if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
        this.gateForm.controls["charges"].setValue(weight * Number(15));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
        this.gateForm.controls["charges"].setValue(weight * Number(15));
      }
    } else {
      const charge = weight * Number(30);
      if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
        this.gateForm.controls["charges"].setValue(weight * Number(30));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
        this.gateForm.controls["charges"].setValue(weight * Number(30));
      }
    }
  }

  // Close the service choose popup
  close() {
    if (this.gateForm.get("serviceType").invalid) {
      this.toaster.error("Please select service type");
    } else {
      this.serviceType = false;
    }
  }

  // Vehicle number input field format condition
  formatVehicleNumber(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\W/g, "").toUpperCase();

    let positions;
    let hyphenPositions;
    const split = value.split("");

    if (!isNaN(Number(split[5]))) {
      positions = [
        { start: 0, end: 1, regex: /[^A-Z]/g },
        { start: 2, end: 3, regex: /[^\d]/g },
        { start: 4, end: 4, regex: /[^A-Z]/g },
        { start: 5, end: 8, regex: /[^\d]/g }
      ];
      hyphenPositions = [2, 5, 7];
      if (value.length == 10) {
        this.formErrorValidation.vehNumberPlate = "*Enter Valid Register Number";
      } else {
        this.formErrorValidation.vehNumberPlate = "";
      }
    } else if (!isNaN(Number(split[6]))) {
      positions = [
        { start: 0, end: 1, regex: /[^A-Z]/g },
        { start: 2, end: 3, regex: /[^\d]/g },
        { start: 4, end: 5, regex: /[^A-Z]/g },
        { start: 6, end: 9, regex: /[^\d]/g }
      ];
      hyphenPositions = [2, 5, 8];
      if (value.length == 11) {
        this.formErrorValidation.vehNumberPlate = "*Enter Valid Register Number";
      } else {
        this.formErrorValidation.vehNumberPlate = "";
      }
    } else {
      positions = [
        { start: 0, end: 1, regex: /[^A-Z]/g },
        { start: 2, end: 3, regex: /[^\d]/g },
        { start: 4, end: 6, regex: /[^A-Z]/g }
      ];
      hyphenPositions = [2, 5, 9];
    }
    positions.forEach(pos => {
      value = value.replace(pos.regex, (match, offset) =>
        (offset >= pos.start && offset < pos.end) ? "" : match);
    });
    value = value.slice(0, 11);
    hyphenPositions.forEach(pos => {
      if (value.length > pos) {
        value = value.slice(0, pos) + "-" + value.slice(pos);
      }
    });
    this.gateForm.controls["vehNumberPlate"].setValue(value);
  }
}



