import { PrintDataService } from "@/model/labModal/PrintDataService";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { InternalTestDetails } from "@/model/labModal/internalTestDetails";
import { NormalTestDetails } from "@/model/labModal/normalTestDetails";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { TestDetails } from "@/model/labModal/testDetails";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";


@Component({
  selector: "app-aftc-tech-analyst-print",
  templateUrl: "./aftc-tech-analyst-print.component.html",
  styleUrls: ["./aftc-tech-analyst-print.component.scss"],
})

export class AftcTechAnalystComponent implements OnInit {
  TestResult: any[] = []
  currentTestDetails: TestDetails | undefined;
  currentNormalTestDetails: NormalTestDetails | undefined;
  currentInternalTestDetails: InternalTestDetails | undefined;
  public ulrNo: String;
  curretAnalsyt: any
  currentPrint: AllTestDetails | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private dataService: PrintDataService,
    private appService: ApiService,
    private route: ActivatedRoute) {

  }

  ngOnInit() {
    if (this.dataService.entryEdit != undefined) {
      this.currentPrint = this.dataService.entryEdit;
      this.ulrNo = this.route.snapshot.paramMap.get("ulrNo");
      this.getTestDetail();
      this.getNormalTestDetail();
      this.getInternalTestDetail();
      this.getProcedureResults();
    }
  }

  // Function to fetch test details based on ulrNo
  getTestDetail() {
    this.appService
      .getTestDetails(this.currentPrint.aftcl_ulr_no)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
      });
  }

   // Function to fetch normal test details based on ulrNo
  getNormalTestDetail() {
    this.appService
      .getNormalTestDetails(this.currentPrint.aftcl_ulr_no)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
      });
  }

    // Function to fetch internal test details based on ulrNo
  getInternalTestDetail() {
    this.appService
      .getInternalTestDetails(this.currentPrint.aftcl_ulr_no)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
      });
  }

  // Function to fetch procedure results based on ulrNo
  getProcedureResults() {
    this.appService
      .getProcedureResults(this.currentPrint.aftcl_ulr_no)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        var localdatass: ProcedureResults[] = [];

        localdatass = data;
        const uniqueProcedureIds = new Set(
          localdatass.map((item) => item.aftcl_procedure_id)
        );

        const uniqueProcedureResults = Array.from(uniqueProcedureIds).map(
          (procedureId) => {
            const analysts = localdatass
              .filter((item) => item.aftcl_procedure_id === procedureId)
              .map((item) => item.technicalAnalystName);
            return {
              aftcl_procedure_id: procedureId,
              aftcl_test_method: localdatass.find(
                (item) => item.aftcl_procedure_id === procedureId
              ).aftcl_test_method,
              aftcl_test_parameter: localdatass.find(
                (item) => item.aftcl_procedure_id === procedureId
              ).aftcl_test_parameter,
              aftcl_fssai_standard: localdatass.find(
                (item) => item.aftcl_procedure_id === procedureId
              ).aftcl_fssai_standard,
              aftcl_updated_at: localdatass.find(
                (item) => item.aftcl_procedure_id === procedureId
              ).aftcl_updated_at,
              aftcl_ulr_no: localdatass.find(
                (item) => item.aftcl_procedure_id === procedureId
              ).aftcl_ulr_no,
              aftcl_test_unit: localdatass.find(
                (item) => item.aftcl_procedure_id === procedureId
              ).aftcl_test_unit,
              technicalAnalystName: analysts,
            };
          }
        );

        this.TestResult = uniqueProcedureResults;
      });
  }
}
