import { TestDetails } from "@/model/labModal/testDetails";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { NormalTestDetails } from "@/model/labModal/normalTestDetails";
import { InternalTestDetails } from "./internalTestDetails";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { ProcedureDetails } from "./procedureDetails";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { WeighBridgeDetails } from "@components/aftc-digitalization/aftc-digitalization-models/weighBridgeDetails";


export class PrintDataService {
  public allTest: TestDetails | undefined;
  public allNormalTestDetails: NormalTestDetails | undefined;
  public allInternalTestDetails: InternalTestDetails | undefined;
  public allResult: ProcedureResults | undefined;
  public entryEdit: AllTestDetails | undefined;
  public productEdit: ProcedureDetails | undefined;
  public entryView: AllTestDetails | undefined;
  public tollGateDetails: TollGate [] = [];
  public weighBridgeDetails: WeighBridgeDetails [] = [];
}
