<div class="container-fluid">

    <form [formGroup]="aftclProduct" id="aftclProduct">
        <div class="row">
            <div class="col-md-12 mt-3">
                <h5 class="fw-bolder fs-6" *ngIf="isUpdate == false">ADD NEW PRODUCT</h5>
                <h5 class="fw-bolder fs-6" *ngIf="isUpdate == true">EDIT EXIST PRODUCT</h5>
            </div>
        </div>
        <div class="row mt-3">

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_test_group" list="group"
                        id="aftcl_test_group" placeholder="Group Name">
                    <datalist id="group">
                        <option *ngFor="let sample of showGroup">{{sample}}</option>
                    </datalist>
                    <label>Group Name</label>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_test_sub_group" list="subGroup"
                        id="aftcl_test_sub_group" placeholder="Sub Group Name">
                    <datalist id="subGroup">
                        <option *ngFor="let sample of showSubGroup">{{sample}}</option>
                    </datalist>
                    <label>Sub Group Name</label>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" placeholder="Sample Name"
                        formControlName="aftcl_test_sample" list="datalistOptions">
                    <datalist id="datalistOptions">
                        <option *ngFor="let sample of showSample">{{sample}}</option>
                    </datalist>
                    <label>Sample Name</label>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_standard" list="datalistStand"
                        id="aftcl_standard" placeholder="Standard Name">
                    <datalist id="datalistStand">
                        <option *ngFor="let sample of showStandard">{{sample}}</option>
                    </datalist>
                    <label>Standard</label>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_test_analysis" list="datalistAnaly"
                        id="aftcl_test_analysis" placeholder="Analysis">
                    <datalist id="datalistAnaly">
                        <option *ngFor="let sample of showAnalysis">{{sample}}</option>
                    </datalist>
                    <label>Analysis</label>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_test_parameter" list="datalistParam"
                        id="aftcl_test_parameter" placeholder="Parameter">
                    <datalist id="datalistParam">
                        <option *ngFor="let sample of showParameter">{{sample}}</option>
                    </datalist>
                    <label>Parameter</label>
                </div>
            </div>

        </div>

        <div class="row mt-3">
            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_test_method" list="datalistMethod"
                        id="aftcl_test_method" placeholder="Test Method">
                    <datalist id="datalistMethod">
                        <option *ngFor="let sample of showMethod">{{sample}}</option>
                    </datalist>
                    <label>Test Method</label>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_test_unit" list="datalistUnit"
                        id="aftcl_test_unit" placeholder="Unit">
                    <datalist id="datalistUnit">
                        <option *ngFor="let sample of showUnit">{{sample}}</option>
                    </datalist>
                    <label>Unit</label>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="aftcl_fssai_standard" list="datalistNorms"
                        id="aftcl_fssai_standard" placeholder="FSSAI Standard Norms">
                    <datalist id="datalistNorms">
                        <option *ngFor="let sample of showNorms">{{sample}}</option>
                    </datalist>
                    <label>Norms</label>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-12 text-center">
                <button type="button" class="btn btn-success" role="button" data-bs-toggle="modal"
                    data-bs-target="staticBackdrop" (click)="saveNewProduct(); ">
                    {{this.isUpdate == true ? "Update" : "Save"}}
                </button>
            </div>
        </div>

    </form>

    <div class="row mb-5"></div>
</div>