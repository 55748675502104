import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { Subject, takeUntil } from "rxjs";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ProcedureDetails } from "@/model/labModal/procedureDetails";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "@services/api.service";

@Component({
    selector: "app-aftc-add-product",
    templateUrl: "./aftc-add-product.component.html",
    styleUrls: ["./aftc-add-product.component.scss"],
})
export class AFTCAddProductComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    productEdit: ProcedureDetails | undefined;
    procedure: ProcedureDetails[] = [];
    showGroup: String[] = [];
    showSubGroup: String[] = [];
    showSample: String[] = [];
    showStandard: String[] = [];
    showAnalysis: String[] = [];
    showParameter: String[] = [];
    showMethod: String[] = [];
    showNorms: String[] = [];
    showUnit: String[] = [];
    active: string = "Yes";
    delete: string = "No";
    isUpdate = false;

    constructor(
        private appService: ApiService,
        private router: Router,
        private dataService: PrintDataService,
        private spinnerService: NgxSpinnerService,
        private toaster: ToastrService
    ) {
    }

    aftclProduct = new UntypedFormGroup({
        aftcl_test_parameter: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_group: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_sub_group: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_sample: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_analysis: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_fssai_standard: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_unit: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_method: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_standard: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        active_yn: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        delete_yn: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
    });

    ngOnInit(): void {
        if (this.dataService.productEdit != undefined) {
            this.isUpdate = true;
            this.productEdit = this.dataService.productEdit;
            this.aftclProduct.controls["aftcl_test_parameter"].setValue(
                this.productEdit.aftcl_test_parameter
            );
            this.aftclProduct.controls["aftcl_test_group"].setValue(
                this.productEdit.aftcl_test_group
            );
            this.aftclProduct.controls["aftcl_test_sub_group"].setValue(
                this.productEdit.aftcl_test_sub_group
            );
            this.aftclProduct.controls["aftcl_test_sample"].setValue(
                this.productEdit.aftcl_test_sample
            );
            this.aftclProduct.controls["aftcl_test_analysis"].setValue(
                this.productEdit.aftcl_test_analysis
            );
            this.aftclProduct.controls["aftcl_fssai_standard"].setValue(
                this.productEdit.aftcl_fssai_standard
            );
            this.aftclProduct.controls["aftcl_test_unit"].setValue(
                this.productEdit.aftcl_test_unit
            );
            this.aftclProduct.controls["aftcl_test_method"].setValue(
                this.productEdit.aftcl_test_method
            );
            this.aftclProduct.controls["aftcl_standard"].setValue(
                this.productEdit.aftcl_standard
            );
        }
        this.getProduct();
    }
    reload() {
        window.location.reload();
    }
    

// Product Add and update function
saveNewProduct() {
    if (this.isUpdate) {
        // Update existing product
        this.aftclProduct.controls["active_yn"].setValue(this.active);
        this.aftclProduct.controls["delete_yn"].setValue(this.delete);

        this.appService
            .UpdateActive(this.productEdit.aftcl_product_list_id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                // Handle the response if needed
            });

        this.appService
            .saveProcedureDetails(this.aftclProduct.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    this.updateAlertShow();
                },
                (err: any) => {
                    this.toaster.error("Something went wrong, Please try again later....");
                },
                () => console.log("HTTP request completed.")
            );

        setTimeout(() => {
            this.spinnerService.hide();
        }, 2000);

        setTimeout(() => {
            this.router.navigate(["/main/aftcl-Product-Screen"]);
        }, 3000);
    } else {
        // Add new product
        this.aftclProduct.controls["active_yn"].setValue(this.active);
        this.aftclProduct.controls["delete_yn"].setValue(this.delete);

        this.appService
            .saveProcedureDetails(this.aftclProduct.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    this.saveAlertShow();
                },
                (err: any) => {
                    this.toaster.error("Something went wrong, Please try again later....");
                },
                () => console.log("HTTP request completed.")
            );

        setTimeout(() => {
            this.spinnerService.hide();
        }, 2000);

        setTimeout(() => {
            this.router.navigate(["/main/aftcl-Product-Screen"]);
        }, 3000);
    }
}


  // Navigate to the Edit Screen
NavEditScreen() {
    this.router.navigate(["aftcl-Edit-Screen"]);
}

// Navigate to the Report Screen
NavReportScreen() {
    this.router.navigate(["aftcl-Report-Screen"]);
}

// Navigate to the Product Screen
NavProductscreen() {
    this.router.navigate(["aftcl-Product-Screen"]);
}

// Show a success alert when details are saved
saveAlertShow() {
    if (!this.isUpdate) {
        Swal.fire({
            title: "Details Saved Successfully...",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        });
    }
}

// Show a success alert when details are updated
updateAlertShow() {
    if (this.isUpdate) {
        Swal.fire({
            title: "Details Updated Successfully...",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        });
    }
}

// Fetch product details and filter them based on certain criteria
getProduct() {
    this.appService
        .getProduct()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
            this.procedure = data;
            var groupName: String[] = [];
            var subGroupName: String[] = [];
            var sampleList: String[] = [];
            var standardList: String[] = [];
            var analysisList: String[] = [];
            var parameterList: String[] = [];
            var normsList: String[] = [];
            var methodList: String[] = [];
            var unitList: String[] = [];
            for (var pro of this.procedure) {
                if (pro.active_yn == "Yes" && pro.delete_yn == "No") {
                    groupName.push(pro.aftcl_test_group);
                    subGroupName.push(pro.aftcl_test_sub_group);
                    sampleList.push(pro.aftcl_test_sample);
                    standardList.push(pro.aftcl_standard);
                    analysisList.push(pro.aftcl_test_analysis);
                    parameterList.push(pro.aftcl_test_parameter);
                    normsList.push(pro.aftcl_fssai_standard);
                    methodList.push(pro.aftcl_test_method);
                    unitList.push(pro.aftcl_test_unit);
                }
            }
            // Filter the lists to remove duplicates
            this.showGroup = groupName.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showSubGroup = subGroupName.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showSample = sampleList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showStandard = standardList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showAnalysis = analysisList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showParameter = parameterList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showNorms = normsList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showMethod = methodList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.showUnit = unitList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
        });
}

// Navigate to the specified URL if it's not empty
onNavigation(url: string) {
    if (url && url !== "") {
        this.router.navigate([url]);
    }
}
}
