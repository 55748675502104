import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { depotDetails } from "@/model/depotDetails/depotDetails";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-aftc-depot-view",
  templateUrl: "./aftc-depot-view.component.html",
  styleUrls: ["./aftc-depot-view.component.scss"]
})
export class AftcDepotView implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  depotDetails: depotDetails[] = [];
  dataEntry: depotDetails[] = [];
  stackNumber: String[] = [];
  depots: string | null = null;
  commonWarehouse: string | null = null;
  coldStorageUnit: string | null = null;
  packagingUnit: string | null = null;
  cleaningGrading: string | null = null;
  RD: string | null = null;
  solarTunnelDryer: string | null = null;

  searchText;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {

  }

  ngOnInit() {
    this.getStockDetails();
  }

  ngOnDestroy() {
  }

  // Get all stock details
  getStockDetails() {
    this.spinner.show();
    this.apiServices.getDepotDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.depotDetails = data.filter(item => item.records == "NEW");
        this.dataEntry = this.depotDetails.sort((a, b) => a.depotOccupantName.localeCompare(b.depotOccupantName));
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Filter function based on selection
  updateStock(event: any, filterType: string) {
    const value = event.target.value;
    this.dataEntry = [];

    if (filterType === "depots") {
      this.depots = (this.depots === "depots") ? null : "depots";
      if (this.depots) {
        const depotDetails = this.depotDetails.filter(item => item.serviceType === "Depot");
        this.dataEntry = depotDetails.sort((a, b) => {
          const depotNoA = parseInt(a.depotNo.split(":")[1]);
          const depotNoB = parseInt(b.depotNo.split(":")[1]);
          return depotNoA - depotNoB;
        });
      }
      this.commonWarehouse = null;
      this.coldStorageUnit = null;
      this.packagingUnit = null;
      this.cleaningGrading = null;
      this.RD = null;
      this.solarTunnelDryer = null;
    } else if (filterType === "commonWarehouse") {
      this.commonWarehouse = (this.commonWarehouse === "commonWarehouse") ? null : "commonWarehouse";
      if (this.commonWarehouse) {
        const cwDetails = this.depotDetails.filter(item => item.serviceType === "Warehouse");
        this.dataEntry = cwDetails;
      }
      this.depots = null;
      this.coldStorageUnit = null;
      this.packagingUnit = null;
      this.cleaningGrading = null;
      this.RD = null;
      this.solarTunnelDryer = null;
    } else if (filterType === "coldStorageUnit") {
      this.coldStorageUnit = (this.coldStorageUnit === "coldStorageUnit") ? null : "coldStorageUnit";
      if (this.coldStorageUnit) {
        const cswDetails = this.depotDetails.filter(item => item.serviceType === "Cold Storage Unit");
        this.dataEntry = cswDetails;
      }
      this.depots = null;
      this.commonWarehouse = null;
      this.packagingUnit = null;
      this.cleaningGrading = null;
      this.RD = null;
      this.solarTunnelDryer = null;
    } else if (filterType === "packagingUnit") {
      this.packagingUnit = (this.packagingUnit === "packagingUnit") ? null : "packagingUnit";
      if (this.packagingUnit) {
        const pkDetails = this.depotDetails.filter(item => item.serviceType === "Packaging Unit");
        this.dataEntry = pkDetails;
      }
      this.depots = null;
      this.commonWarehouse = null;
      this.coldStorageUnit = null;
      this.cleaningGrading = null;
      this.RD = null;
      this.solarTunnelDryer = null;
    } else if (filterType === "cleaningGrading") {
      this.cleaningGrading = (this.cleaningGrading === "cleaningGrading") ? null : "cleaningGrading";
      if (this.cleaningGrading) {
        const cgDetails = this.depotDetails.filter(item => item.serviceType === "Cleaning & Grading");
        this.dataEntry = cgDetails;
      }
      this.depots = null;
      this.commonWarehouse = null;
      this.coldStorageUnit = null;
      this.packagingUnit = null;
      this.RD = null;
      this.solarTunnelDryer = null;
    } else if (filterType === "RD") {
      this.RD = (this.RD === "RD") ? null : "RD";
      if (this.RD) {
        const rdDetails = this.depotDetails.filter(item => item.serviceType === "R & D");
        this.dataEntry = rdDetails;
      }
      this.depots = null;
      this.commonWarehouse = null;
      this.coldStorageUnit = null;
      this.packagingUnit = null;
      this.cleaningGrading = null;
      this.solarTunnelDryer = null;
    } else if (filterType === "solarTunnelDryer") {
      this.solarTunnelDryer = (this.solarTunnelDryer === "solarTunnelDryer") ? null : "solarTunnelDryer";
      if (this.solarTunnelDryer) {
        const stdDetails = this.depotDetails.filter(item => item.serviceType === "Solar Tunnel Dryer");
        this.dataEntry = stdDetails;
      }
      this.depots = null;
      this.commonWarehouse = null;
      this.coldStorageUnit = null;
      this.packagingUnit = null;
      this.cleaningGrading = null;
      this.RD = null;
    }
  }

  applyFilters() {
    const cwDetails = this.commonWarehouse ? this.depotDetails.filter(item => item.serviceType == "Common Warehouse") : this.depotDetails;
    const csuDetails = this.coldStorageUnit ? this.depotDetails.filter(item => item.serviceType == "Cold Storage Unit") : cwDetails;
    this.dataEntry = csuDetails.sort((a, b) => a.depotOccupantName.localeCompare(b.depotOccupantName));
  }
}

