import { Component, HostBinding, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, takeUntil } from "rxjs";
import { UiState } from "@/store/ui/state";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "@services/api.service";
import { WarehouseStockDeposit } from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";

@Component({
  selector: "app-aftc-edit",
  templateUrl: "./pending-test.component.html",
  styleUrls: ["./pending-test.component.scss"],
})
export class PendingTestComponent implements OnInit {
  searchText;
  @HostBinding("class") class = "wrapper";
  public ui: Observable<UiState>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  pendingTestList: WarehouseStockDeposit[] = [];

  page = 1;
  pageSize = 5;
  premiumData: any[] = [];
  paginateData: any[] = [];

  constructor(
    private appService: ApiService, private router: Router, private toaster: ToastrService) {
  }

  ngOnInit(): void {
    this.getDepositDetails();
  }

  ngOnDestroy(): void {
  }

  getPremiumData() {
    this.paginateData = this.premiumData
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  }

  getDepositDetails() {
    this.appService.getDepositDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.pendingTestList = data;
      }, (err: any) => {
        console.log("something went wrong::", err);
        this.toaster.error("Something went wrong, Please try again later....");
      })
  }
}
