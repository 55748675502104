<div class="container-fluid">

    <div class="row">
        <div class="col-md-4 mt-3">
            <div class="form-floating">
                <select class="form-select" id="aftcl_packing_type" (change)="tableTestSelect($event)">
                    <option selected>Select Test Type</option>
                    <option>NABL Test</option>
                    <option>Non-NABL Test</option>
                    <option>Internal Test</option>
                </select>
                <label>Select Test Type<span class="text-danger">*</span></label>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="form-floating">
                <select class="form-select" id="aftc_sample_no" (change)="sampleno($event)">
                    <option selected value="">Select Sample No</option>
                    <option *ngFor="let parms of this.nablSampleNo" value="{{parms}}">{{parms}}</option>
                </select>
                <label>Select Sample No<span class="text-danger">*</span></label>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" placeholder="Search.." [(ngModel)]="searchText">
                <label>Search</label>
            </div>
        </div>

    </div>

    <div class="container-fluid" *ngIf="normalTableDisable">
        <div class="row mt-3">
            <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">

                <table class="table table-sm table-bordered text-center table-hover mb-0">
                    <thead class="header sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>ULR NO</th>
                            <th>Sample No</th>
                            <th>Customer Name</th>
                            <th>Assigned To</th>
                            <th>Sample Received Date</th>
                            <th>View</th>
                            <th>Edit</th>
                            <th>Print</th>
                        </tr>
                    </thead>
                    <tbody class="editTable" *ngIf="this.sampleTestList.length > 0; else noDataFound">
                        <tr
                            *ngFor="let nablTest of sampleTestList | slice: (page-1) * pageSize : page * pageSize | filter: searchText;let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{nablTest.aftcl_ulr_no}}</td>
                            <td>{{nablTest.aftcl_sample_no}}</td>
                            <td>{{nablTest.aftcl_customer_name}}</td>
                            <td>
                                <ng-container *ngIf="nablTest.procedures && nablTest.procedures.length > 0">
                                    {{ getUniqueTechnicalAnalysts(nablTest.procedures).join(', ') }}
                                </ng-container>
                                <span *ngIf="!nablTest.procedures || nablTest.procedures.length === 0 
                                || nablTest.procedures[0].technicalAnalystName == '' ">No Analyst Assigned</span>
                            </td>
                            <td>{{nablTest.aftcl_date | date:'dd-MM-yyyy'}}</td>
                            <td>
                                <i class="fa fa-eye" type="button" style="font-size:14px" data-toggle="tooltip"
                                    data-placement="top" title="Click to View the Report"
                                    (click)="openModal(nablTest.aftcl_ulr_no);"></i>
                            </td>
                            <td>
                                <button type="button" class="btn-view" (click)="selectUlr(nablTest.aftcl_ulr_no)">Edit
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn-print"
                                    *ngIf="nablTest.qualityManagerStatus == 'Approved'"
                                    (click)="NavPrintScreen(nablTest.aftcl_ulr_no)">Print
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noDataFound>
                        <tr class="text-bold">
                            <td colspan="11">Data Not Found</td>
                        </tr>
                    </ng-template>
                </table>

            </div>
        </div>

        <div class="d-flex justify-content-end w-100">
            <ngb-pagination [(page)]="page" [boundaryLinks]="true" [collectionSize]="sampleTestList.length"
                [maxSize]="5" [pageSize]="pageSize" (pageChange)="getPremiumData()"></ngb-pagination>
        </div>

    </div>
</div>

<!--Technical Manager, Technical Analyst & Quality Manager Table View-->

<div class="container-fluid" *ngIf="analystTableDisable">
    <div class="row mt-3">
        <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">

            <table class="table table-sm table-bordered text-center table-hover mb-0">
                <thead class="header sticky-top">
                    <tr>
                        <th>S.No</th>
                        <th>Sample No</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>View</th>
                        <th>Edit</th>
                        <th>Print</th>
                    </tr>
                </thead>
                <tbody class="editTable" *ngIf="this.sampleTestList.length > 0; else noDataFound">
                    <tr
                        *ngFor="let nablTest of sampleTestList | slice: (page-1) * pageSize : page * pageSize | filter: searchText;let i = index;">
                        <td>{{i + 1}}</td>
                        <td>{{nablTest.aftcl_sample_no}}</td>
                        <td>{{nablTest.aftcl_test_starting_date}}</td>
                        <td>{{nablTest.aftcl_test_complete_date}}</td>
                        <td>
                            <i class="fa fa-eye" style="font-size:24px" data-toggle="tooltip" data-placement="top"
                                title="Click to View the Report" (click)="openModal(nablTest.aftcl_ulr_no);"></i>
                        </td>
                        <td>
                            <button type="button" class="btn-view text-uppercase"
                                (click)="selectUlr(nablTest.aftcl_ulr_no)">Edit
                            </button>
                        </td>
                        <td>
                            <button type="button" class="btn-print text-uppercase"
                                (click)="TestPrintScreen(nablTest.aftcl_ulr_no)">Print
                            </button>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noDataFound>
                    <tr class="text-bold">
                        <td colspan="11">Data Not Found</td>
                    </tr>
                </ng-template>
            </table>

        </div>
    </div>

    <div class="d-flex justify-content-end w-100">
        <ngb-pagination [(page)]="page" [boundaryLinks]="true" [collectionSize]="sampleTestList.length" [maxSize]="5"
            [pageSize]="pageSize" (pageChange)="getPremiumData()"></ngb-pagination>
    </div>

</div>

<div class="row mt-2"></div>
<div class="modal bg-light bg-opacity-50" aria-hidden="true" [style.display]="isModalShow ? 'block' : 'none'">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center text-green fw-bolder fs-4 text-uppercase">test report view screen
                </h5>
                <button type="button" class="close text-danger" data-dismiss="modal" aria-label="Close"
                    (click)="isModalShow = false;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="container-fluid p-3">
                    <div class="container-fluid bg-gradient-green">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-1 mt-2">
                                <img src="../assets/img/NABL logo.png" style="height: 80px; width: 80px">
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-1">
                                <img src="../assets/img/AFTC logo.png" style="height: 80px; width: 80px">
                            </div>
                            <div class="col-md-8 text-right mt-3">
                                <h5 class="fw-bolder">AGRO FOOD TRADE CENTRE</h5>
                                <h6 class="fw-bolder">Accredited by NABL ISO/IEC 17025:2017</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="table-responsive">
                        <table class="table table-bordered " *ngIf="normalTableDisable">
                            <tbody *ngFor="let model of this.modalAllList">
                                <tr>
                                    <th>ULR No:</th>
                                    <td>{{model.aftcl_ulr_no}}</td>
                                </tr>
                                <tr>
                                    <th>
                                        <p>Report No</p>
                                        <p>Report Date</p>
                                    </th>
                                    <td>
                                        <p>{{model.aftcl_report_no}}</p>
                                        <p>{{model.aftcl_sample_received_date | date: "dd-MM-yyyy"}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Customer Name</th>
                                    <td>{{model.aftcl_customer_name}}</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{{model.aftcl_customer_address}}</td>
                                </tr>
                                <tr>
                                    <th>Contact No</th>
                                    <td>{{model.aftcl_phone_number}}</td>
                                </tr>
                                <tr>
                                    <th>Email Id</th>
                                    <td>
                                        <p style="text-align: center;"
                                            *ngIf="model.aftcl_email_id== null  || model.aftcl_email_id == ''">
                                            --</p>
                                        <p *ngIf="model.aftcl_email_id!= null">{{model.aftcl_email_id}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Customer Reference</th>
                                    <td>
                                        <p
                                            *ngIf="model.aftcl_customer_reference== null  || model.aftcl_customer_reference == ''">
                                            --</p>
                                        <p *ngIf="model.aftcl_customer_reference!= null">
                                            {{model.aftcl_customer_reference}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Sample No</th>
                                    <td>{{model.aftcl_sample_no}}</td>
                                </tr>
                                <tr>
                                    <th>Sample Drawn By</th>
                                    <td>
                                        <p
                                            *ngIf="model.aftcl_sample_drawn_by == null ||model.aftcl_sample_drawn_by == ''">
                                            -- </p>
                                        <p *ngIf="model.aftcl_sample_drawn_by != null">{{model.aftcl_sample_drawn_by}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Sample Received Date</th>
                                    <td>{{model.aftcl_date | date: "dd-MM-yyyy"}}</td>
                                </tr>
                                <tr>
                                    <th>Test Starting Date</th>
                                    <td>{{model.aftcl_test_starting_date | date: "dd-MM-yyyy"}}</td>
                                </tr>
                                <tr>
                                    <th>Due Date</th>
                                    <td>{{model.aftcl_test_complete_date | date: "dd-MM-yyyy"}}</td>
                                </tr>
                                <tr>
                                    <th>Sample Qty Received</th>
                                    <td>{{model.aftcl_sample_qty_received}}</td>
                                </tr>
                                <tr>
                                    <th>Packing Condition</th>
                                    <td>{{model.aftcl_packing_condition}}</td>
                                </tr>
                                <tr>
                                    <th>Discipline</th>
                                    <td>{{model.aftcl_discipline}}</td>
                                </tr>
                                <tr>
                                    <th>Group</th>
                                    <td>{{model.aftcl_group}}</td>
                                </tr>
                                <tr>
                                    <th>Product</th>
                                    <td>{{productList}}</td>
                                </tr>
                                <tr>
                                    <th>Sample Name</th>
                                    <td>{{model.sample_name}}</td>
                                </tr>
                                <tr>
                                    <th>Product Description</th>
                                    <td>{{model.aftcl_description}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered text-center">
                            <tr class="text-center">
                                <th>S.NO</th>
                                <th>PARAMETER</th>
                                <th>UNIT</th>
                                <th>RESULT</th>
                                <th>Assign</th>
                            </tr>
                            <tr class="col-md-12" *ngFor="let result of TestResult;let i = index">
                                <td>{{i + 1}}</td>
                                <td>{{result.aftcl_test_parameter}}</td>
                                <td>{{result.aftcl_test_unit}}</td>
                                <td>{{result.aftcl_test_result}}</td>
                                <td>{{result.technicalAnalystName}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
    <div id="page">
        <div id="container">
            <div id="ring"></div>
            <div id="ring"></div>
            <div id="ring"></div>
            <div id="ring"></div>
            <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
        </div>
    </div>
</ngx-spinner>