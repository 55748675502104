import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { DepositorDetails } from "@/model/stockDetails/depositorDetails";
import { StackNumberDetails } from "@/model/stockDetails/stackNumberDetails";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-impact-data-entry",
  templateUrl: "./impact-data-entry.component.html",
  styleUrls: ["./impact-data-entry.component.scss"]
})
export class StockDetails implements OnInit {

  getLocalPassDetails: TollGate[] = [];
  options: string[] = [];
  depositerDetails: DepositorDetails [] = [];
  stackNumberDetails: StackNumberDetails [] = [];
  depositerName: String [] = [];
  stackNumbers: String [] = [];
  getPassDetails: TollGate | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {

  }

  StcokForm = new UntypedFormGroup({
    stockUploadDetailsId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    nameOfDepositor: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stackNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stockInDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stockInWeightParty: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stockInWeightMachine: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stockOutDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stockOutWeightParty: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stockOutWeightMachine: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    difference: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    average: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    activeYn: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  ngOnInit() {
    this.getDepositerName();
    this.getStackNumber();
  }

  ngOnDestroy() {
  }

  // Save stock Details
  saveStockDetails() {
    this.spinner.show();
    this.apiServices.saveStcokDetilas(this.StcokForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert();
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
        this.spinner.hide();
      });
  }

  // Weight calculation
  Weight(event: any) {
    const value = event.target.value;
    const inweight = this.StcokForm.get("stockInWeightMachine").value;
    this.StcokForm.controls["difference"].setValue(value - inweight);
    const differents = value - inweight;
    const average: number = (differents / inweight) * 100;
    const averageFixed: number = parseFloat(average.toFixed(2));
    this.StcokForm.controls["average"].setValue(averageFixed);
  }

  // Get all depositor's details
  getDepositerName() {
    this.apiServices.getDepositorDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.depositerDetails = data;
        const depositer = new Set(this.depositerDetails.map(item => item.depositorName));
        this.depositerName = Array.from(depositer).sort((a, b) => a.localeCompare(b));
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Get all stack numbers
  getStackNumber() {
    this.apiServices.getStackNumber()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.stackNumberDetails = data;
        const stackNumber = new Set(this.stackNumberDetails.map(item => item.stackNumber));
        this.stackNumbers = Array.from(stackNumber);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  saveAlert() {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      window.location.reload();
    });
  }

}
