import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";


@Component({
  selector: "app-Impact-dashboard",
  templateUrl: "./Impact-dashboard.component.html",
  styleUrls: ["./Impact-dashboard.component.scss"]
})
export class ImpactDashboard implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
