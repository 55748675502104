<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <p class="navbar-brand">Vehicle Module Data Entry</p>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" type="button">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/depotDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Vehicle Data Entry</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/depotView" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">AFTC Members Data View</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/adminVehicleDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Admin Vehicle Data View</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/depotVehicleDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Depot Vehicle Data View</p>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fulid py-5">
  <div class="row">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3 ms-2">
      </div>
    </div>
    <div class="col-md-4 justify-content-end">
      <div class="div mb-2 mt-3">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>
  </div>
  <div class="row ms-3">
    <div class="col-lg-1">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'depots')" [checked]="depots === 'depots'">
        <label class="ms-1">Depots</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'commonWarehouse')"
               [checked]="commonWarehouse === 'commonWarehouse'">
        <label class="ms-1">Common Warehouse</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'coldStorageUnit')"
               [checked]="coldStorageUnit === 'coldStorageUnit'">
        <label class="ms-1">Cold Storage Unit</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'packagingUnit')"
               [checked]="packagingUnit === 'packagingUnit'">
        <label class="ms-1">Packaging Unit</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'cleaningGrading')"
               [checked]="cleaningGrading === 'cleaningGrading'">
        <label class="ms-1">Cleaning & Grading</label>
      </div>
    </div>
    <div class="col-lg-1">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'RD')" [checked]="RD === 'RD'">
        <label class="ms-1">R & D</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'solarTunnelDryer')"
               [checked]="solarTunnelDryer === 'solarTunnelDryer'">
        <label class="ms-1">Solar Tunnel Dryer</label>
      </div>
    </div>
  </div>
  <div class="py-2"></div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
        <tr class="sticky-top">
          <th>No</th>
          <th>Depot Number</th>
          <th>Depot Name</th>
          <th>AFTC Membership Type</th>
          <th>Service Type</th>
        </tr>
        </thead>
        <tbody *ngFor="let aftc of this.dataEntry | filter: searchText;let i = index">
        <tr>
          <td>{{i + 1}}</td>
          <td>{{aftc.depotNo}}</td>
          <td>{{aftc.depotOccupantName}}</td>
          <td>{{aftc.aftcMembershipType}}</td>
          <td>{{aftc.serviceType}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
