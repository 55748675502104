import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiService} from "@services/api.service";

import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";



@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  productForm: any;

  onSubmit() {
    let field_check = this.checkObj();
    if (!field_check) {
      return;
    } else {
      console.log(this.productForm.value);
      this.apiService.addProducts(this.productForm.value);

      this.productForm.reset();
      this.initial_data();
    }
  }

  checkObj() {
    return true;
  }

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {}

  ngOnInit() {

    this.initial_data()
  }




  initial_data() {


      this.productForm = this.formBuilder.group({

        productName:"",
        productAltName:"",
        productBioName:""

        })
  }


}
