<div class="col-md-12">
  <h3 class="ml-2">Weigh Bridge Entry</h3>
</div>
<div class="container-fluid">
  <form [formGroup]="weightBridgeForm" (ngSubmit)="formValidation()">
    <div class="row mt-2">
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Transaction No" disabled
                 formControlName="transactionNumber">
          <label>Transaction No</label>
        </div>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" disabled placeholder="Print Date Time"
                 formControlName="printDate">
          <label class="">Print Date Time<span class="text-danger">*</span></label>
        </div>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" disabled placeholder="Vehicle Number" formControlName="vehicleNumber">
          <label class="">Vehicle Number</label>
        </div>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" disabled placeholder="Member Name" formControlName="memberName">
          <label class="">Member Name</label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-3  col-md-3 mt-2">
        <div *ngIf="!commodityShow" class="form-floating">
          <input type="text" class="form-control textCap" disabled placeholder="Commodity"
                 formControlName="commodityCode">
          <label>Commodity</label>
        </div>
        <div *ngIf="commodityShow" class="form-floating">
          <select type="text" class="form-select"
                  formControlName="commodityCode">
            <option value="" selected hidden>Select Commodity Name</option>
            <option *ngFor="let com of commodityNames" value="{{com}}">{{com}}</option>
          </select>
          <label class="form-label">Commodity Name</label>
          <span *ngIf="weighBridgeFormErrors.commodityCode" class="text-danger">{{weighBridgeFormErrors.commodityCode}}</span>
        </div>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select" (change)="chargeValue($event)"
                  formControlName="loadType">
            <option disabled selected hidden value="">Select One</option>
            <option value="Load">Load</option>
            <option value="Empty">Empty</option>
          </select>
          <label>Load/Empty<span class="text-danger">*</span></label>
          <span *ngIf="weighBridgeFormErrors.loadType" class="text-danger">{{weighBridgeFormErrors.loadType}}</span>
        </div>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Shift" formControlName="shift">
          <label class="">Shift<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="weighBridgeFormErrors.shift" class="text-danger">{{weighBridgeFormErrors.shift}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control" placeholder="Inv Date" formControlName="invDate">
          <label class="">Inv Date<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="weighBridgeFormErrors.invDate" class="text-danger">{{weighBridgeFormErrors.invDate}}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" (keypress)="OnlyNumbers($event)" (input)="calculateNetWeight($event, 'gross')" placeholder="Gross Weight" formControlName="grossWeight">
          <label class="">Gross Weight<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="weighBridgeFormErrors.grossWeight" class="text-danger">{{weighBridgeFormErrors.grossWeight}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" (keypress)="OnlyNumbers($event)" (input)="calculateNetWeight($event, 'tare')" placeholder="Tare Weight" formControlName="tareWeight">
          <label>Tare Weight<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="weighBridgeFormErrors.tareWeight" class="text-danger">{{weighBridgeFormErrors.tareWeight}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" disabled placeholder="Net Weight" formControlName="netWeight">
          <label class="">Net Weight</label>
        </div>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" disabled placeholder="Charges" formControlName="weighmentChargesRs">
          <label class="">Charges</label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" readonly class="form-control" placeholder="Date In" formControlName="dateIn">
          <label>Date In</label>
        </div>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" readonly class="form-control" placeholder="Date Out" formControlName="dateOut">
          <label>Date Out</label>
        </div>
      </div>

      <!--<div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" readonly class="form-control" placeholder="Total Amount" formControlName="total">
          <label>Total Amount</label>
        </div>
      </div>-->
    </div>
    <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2">
      <button type="submit" class="btn btn-success col-md-1">{{isUpdate? "Update" : "Save"}}</button>
    </div>
  </form>
</div>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>
