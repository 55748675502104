import { AppState } from "@/store/state";
import { UiState } from "@/store/ui/state";
import { Component, HostBinding, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppService } from "@services/app.service";
import path from "path";
import { Observable } from "rxjs";

const BASE_CLASSES = "main-sidebar elevation-4";

@Component({
  selector: "app-menu-sidebar",
  templateUrl: "./menu-sidebar.component.html",
  styleUrls: ["./menu-sidebar.component.scss"]
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding("class") classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu;
  department: string;
  role: string;

  constructor(
    public appService: AppService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.ui = this.store.select("ui");
    this.department = localStorage.getItem("department");
    this.role = localStorage.getItem("role");
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.user = this.appService.user;

    switch (this.role) {
      case "USER":
        this.departmentMenu();
        break;
      case "ADMIN":
        this.menu = MENU;
        break;
    }
  }

  departmentMenu() {
    switch (this.department) {
      case "GATE KEEPER": {
        this.menu = gateKeeperMenu;
        break;
      }
      case "ACCOUNTANT": {
        this.menu = accountantMenu;
        break;
      }
      case "FRONT END OFFICE": {
        this.menu = frontEndOfficeMenu;
        break;
      }
      case "RECEPTIONIST": {
        this.menu = labUserMenu;
        break;
      }
      case "QUALITY MANAGER": {
        this.menu = labTechMenu;
        break;
      }
      case "TECHNICAL MANAGER": {
        this.menu = labTechMenu;
        break;
      }
      case "TECHNICAL ANALYST": {
        this.menu = labTechMenu;
        break;
      }
      case "WEIGH BRIDGE": {
        this.menu = weightBridgeMenu;
        break;
      }
      case "DATA ENTRY": {
        this.menu = stockDetailsMenu;
        break;
      }
      case "NEWSFEED ADMIN": {
        this.menu = newsFeedMenu;
        break;
      }
      case "ADMIN": {
        this.menu = MENU;
        break;
      }
      case "CHAMBER ADMIN": {
        this.menu = chamberAdminMenu;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

}

export const gateKeeperMenu = [
  {
    name: "Toll Gate",
    iconClasses: "fas fa-road",
    children: [
      {
        name: "Toll Gate Entry",
        iconClasses: "fas fa-road",
        path: ["/main/tollgate"]
      },
      {
        name: "Toll Gate View",
        iconClasses: "fas fa-building",
        path: ["/main/tollGateView"]
      },
      // {
      //   name: 'Toll Gate Cash',
      //   iconClasses: "fas fa-money-bill",
      //   path: ["/main/tollGateCash"]
      // },
    ]
  }
];

export const accountantMenu = [
  {
    name: 'Toll Gate Cash',
    iconClasses: "fas fa-money-bill",
    path: ["/main/tollGateCash"]
  },
  {
    name: 'Weigh Bridge Cash',
    iconClasses: "fas fa-money-bill",
    path: ["/main/weigh-bridge-cash"]
  },
];

export const frontEndOfficeMenu = [
  {
    name: "Front Office",
    iconClasses: "fas fa-building",
    path: ["/main/frontOffice"]
  },
  {
    name: 'Toll Gate Cash',
    iconClasses: "fas fa-money-bill",
    path: ["/main/tollGateCash"]
  },
  {
    name: 'Weigh Bridge Cash',
    iconClasses: "fas fa-money-bill",
    path: ["/main/weigh-bridge-cash"]
  }
];

export const labUserMenu = [
  {
    name: "Lab Testing",
    iconClasses: "fas fa-flask",
    children: [
      {
        name: "Report Entry",
        iconClasses: "fas fa-vials",
        path: ["/main/aftcl-Report-Screen"]
      },
      {
        name: "Report View",
        iconClasses: "fas fa-folder",
        path: ["/main/aftcl-Edit-Screen"]
      },
      {
        name: "All Products View",
        iconClasses: "fas fa-tags",
        path: ["/main/aftcl-Product-Screen"]
      },
      // {
      //   name: "Pending Test",
      //   iconClasses: "fas fa-file",
      //   path: ["/main/pending-test"]
      // }
    ]
  }
];

export const labTechMenu = [
  {
    name: "Lab Testing",
    iconClasses: "fas fa-flask",
    children: [
      {
        name: "Report View",
        iconClasses: "fas fa-folder",
        path: ["/main/aftcl-Edit-Screen"]
      }
    ]
  }
];

export const weightBridgeMenu = [
  {
    name: "Tollgate Entries",
    iconClasses: "fas fa-road",
    path: ["/main/weigh-bridge-view"]
  },
  {
    name: "Weigh Bridge View",
    iconClasses: "fas fa-weight",
    path: ["/main/weigh-bridge-detail-view"]
  }
];

export const stockDetailsMenu = [
  {
    name: "Impact Study",
    iconClasses: "fas fa-weight",
    path: ["/main/stockDetails"]
  },
  {
    name: "Vehicle Module Data Entry",
    iconClasses: "fas fa-car",
    path: ["/main/depotDetails"]
  },
  {
    name: "Vehicle In/Out Details",
    iconClasses: 'fas fa-car',
    path: ["/main/vehiclePunchDetails"]
  },
];

export const newsFeedMenu = [
  {
    name: "Newsfeed Upload",
    iconClasses: "fas fa-upload",
    path: ["/main/newsFeedAdmin"]
  },
  {
    name: "Newsfeed View",
    iconClasses: "fas fa-newspaper",
    path: ["/main/newsFeedView"]
  }
];

export const chamberAdminMenu = [
  {
    name: "Membership View",
    iconClasses: "fas fa-weight",
    path: ["/main/membershipView"]
  }
];

export const MENU = [
  {
    name: "Dashboard",
    iconClasses: "fas fa-tachometer-alt",
    path: ["/main/dash"]
  },
  {
    name: "Digitalization",
    iconClasses: "fas fa-desktop",
    children: [

      {
        name: "Toll Gate",
        iconClasses: "fas fa-road",
        path: ["/main/tollgate"]
      },
      {
        name: "Front Office",
        iconClasses: "fas fa-building",
        path: ["/main/frontOffice"]
      },
      {
        name: "Weigh Bridge",
        iconClasses: "fas fa-weight",
        path: ["/main/weigh-bridge-view"]
      },
      {
        name: "Warehouse",
        iconClasses: "fas fa-warehouse",
        path: ["/main/stackHolders"]
      },
      {
        name: "Loading Dock",
        iconClasses: "fas fa-boxes",
        path: ["/main/loadingDock"]
      },
      {
        name: "Packaging",
        iconClasses: "fas fa-box",
        path: ["/main/packaging"]
      },
      {
        name: "Administrative Office",
        iconClasses: "fas fa-landmark",
        path: ["/main/adminOffice"]
      },
      {
        name: "Reports",
        iconClasses: "fas fa-file",
        path: ["/main/reports"]
      },
    ]
  },
  {
    name: "Lab Testing",
    iconClasses: "fas fa-flask",
    children: [
      {
        name: "Report Entry",
        iconClasses: "fas fa-vials",
        path: ["/main/aftcl-Report-Screen"]
      },
      {
        name: "Report View",
        iconClasses: "fas fa-folder",
        path: ["/main/aftcl-Edit-Screen"]
      },
      {
        name: "All Products View",
        iconClasses: "fas fa-tags",
        path: ["/main/aftcl-Product-Screen"]
      },
      // {
      //   name: "Pending Test",
      //   iconClasses: "fas fa-file",
      //   path: ["/main/pending-test"]
      // }
    ]
  },
  {
    name: "Data Entry",
    iconClasses: "fas fa-book",
    children: [
      {
        name: "Impact Study",
        iconClasses: "fas fa-weight",
        path: ["/main/stockDetails"]
      },
      {
        name: "Vehicle Module Data Entry",
        iconClasses: "fas fa-car",
        path: ["/main/depotDetails"]
      },
      {
        name: "Vehicle In/Out Details",
        iconClasses: 'fas fa-car',
        path: ["/main/vehiclePunchDetails"]
      },
    ]
  },
  {
    name: "Membership View",
    iconClasses: "fas fa-users",
    path: ["/main/membershipView"]
  }
]


