import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;

    constructor(private appService: AppService,private spinner : NgxSpinnerService) {}

    ngOnInit(): void {
        this.user = this.appService.user;
        if (localStorage.getItem('role') == 'USER') {
          this.user = localStorage.getItem('department')
        } else if (localStorage.getItem('role') == 'ADMIN') {
          this.user = localStorage.getItem('role')
        }
    }

    logout() {
        this.appService.logout();
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
    reload(){
        this.spinner.show();
        setTimeout(() => {
          window.location.reload();
        }, 200);
    }
}
