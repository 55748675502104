<div class="">
  <!--<div class="row">
    <div class="col-lg-4 col-4">
      <img src="./assets/img/aftc-icon.png">
    </div>
    <div class="col-lg-8 col-8">
      <img src="./assets/img/aftc-tag.png">
    </div>
  </div>
  <table class="table table-borderless">
    <tbody>
    <tr>
      <th class="col-lg-3 col-5"><img src="./assets/img/aftc-icon.png"></th>
      <td class="col-lg-6 col-5"> <img src="./assets/img/aftc-tag.png"></td>
    </tr>
    </tbody>
  </table>
  <hr style="width:100%; text-align:left;  border-width:3px; border-color: black;">

  <div class="row mt-3">
    <div class="col-6">
      <div class="d-flex justify-content-start">
        <label class="content1">No: 2023-24/V/</label>
      </div>
    </div>
    <div class="col-5">
      <div class="d-flex justify-content-end">
        <label class="content1">Date: 24/10/2023</label>
      </div>
    </div>
  </div>
  <table class="table-responsive table table-borderless">
    <tbody class="col-lg-12 col-12">
    <tr class="mt-3">
      <th class="col-lg-6 col-6 h6 text-bold">Member Name</th>
      <td class="col-lg-6 col-6">Raja Raja Raja Raja Cholan</td>
    </tr>
    </tbody>
  </table>-->

  <div class="container-fluid pt-2">

    <!-- Invoice heading -->

    <table class="table table-borderless">
      <tbody>
      <tr>
        <td class="border-0">
          <div class="row">
            <div class="col-lg-2 col-xs-12 col-12 col-md-3 text-center text-md-left">
              <img class="logo img-fluid" src="../../../../assets/img/icon-512.png" style="max-height: 100px;" />
              <br>
            </div>

            <div class="col-lg-9 col-xs-12 col-12 col-md-9 text-center">
              <span class="d-none d-block">
                            <p class="text">AGRO FOOD TRADE CENTRE</p>
                <!--<p class="text2">(Formerly: Tamilnadu Foodgrains Marketing Yard)</p>
        <p class="text2">[Special Purpose Vehicle (SPV) of Cereals, Pulses & Staples (CPS) Cluster]</p>-->
        <p class="text4">வேளாண்உணவு வர்த்தக மையம்</p>
        <p class="text3">சிக்கந்தர் சாவடி, அலங்காநல்லூர் ரோடு, மதுரை - 625018.</p>
        <p class="text5">www.grainscluster.com &nbsp;&nbsp; 0452-2660669 <br>
          E-mail: office@agrofoodtradecenter.com</p>
                <!--<p class="text7">GSTIN:33AABCT1101F1Z7 &nbsp;&nbsp; CIN:U74999TN1993NPL025771 &nbsp;&nbsp; FSSAI
                  No:12419012001801</p>-->
                        </span>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <table class="table">
      <thead>
      <tr>
        <th>No: 2023-24/{{billNo}}/</th>
        <th class="text-right">Date - {{billDate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <h5 class="mb-1">Member Name</h5>
        </td>
        <td class="font-weight-bold align-middle text-right text-nowrap">{{memberName}}</td>
      </tr>
      <tr>
        <td>
          <h5 class="mb-1">Vehicle No</h5>
        </td>
        <td
          class="font-weight-bold align-middle text-right text-nowrap">{{vehicleNo}}</td>
      </tr>
      <tr>
        <td>
          <h5 class="mb-1">Vehicle Type</h5>
        </td>
        <td
          class="font-weight-bold align-middle text-right text-nowrap">{{vehicleType}} {{wheelCount}}</td>
      </tr>
      <tr>
        <td>
          <h5 class="mb-1">Driver Name</h5>
        </td>
        <td
          class="font-weight-bold align-middle text-right text-nowrap">{{driverName}}</td>
      </tr>
      <tr>
        <td>
          <h5 class="mb-1">Mobile Number</h5>
        </td>
        <td
          class="font-weight-bold align-middle text-right text-nowrap">{{mobileNumber}}</td>
      </tr>
      <!--<tr>
        <td>
          <h5 class="mb-1">Signature</h5>
        </td>
        <td class="font-weight-bold align-middle text-right text-nowrap"></td>
      </tr>-->
      <tr>
        <td>
          <h5 class="mb-1">Time In</h5>
        </td>
        <td class="font-weight-bold align-middle text-right text-nowrap">{{entryTime}}</td>
      </tr>
      <tr>
        <td>
          <h5 class="mb-1">Time Out</h5>
        </td>
        <td class="font-weight-bold align-middle text-right text-nowrap">{{exitTime}}</td>
      </tr>
    </table>
    <!--<h5 class="text-center pt-2">
      Thank you for visiting AFTC
    </h5>-->
  </div>
</div>
