import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { depotDetails } from "@/model/depotDetails/depotDetails";
import { DepotClassDetails } from "@/model/depotDetails/DepotClassDetails";
import { BikeDetails } from "@/model/vehicleDetails/BikeDetails";
import { carList } from "@/model/vehicleDetails/BikeDetails";
import { truckList } from "@/model/vehicleDetails/BikeDetails";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-depot-entry-form",
  templateUrl: "./depot-entry-form.component.html",
  styleUrls: ["./depot-entry-form.component.scss"]
})
export class DepotEntryForm implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  options: string[] = [];
  depotVehicle: boolean = false;
  depotDetails: boolean = false;
  getDepotDetails: depotDetails[] = [];
  administrativeVehicle: boolean = false;
  selectedOption: string = "";
  getDepotOccupantName: string[] = [];
  depotOccupantName: string = "";
  getDepotNo: string[] = [];
  dropdownList = [];
  selectedItems = this.dropdownList;
  dropdownSettings = {};
  selectedArrayType: any[] = [];
  selectedLabel: any[] = [];
  saveDepotList: DepotClassDetails[] = [];
  brandName = BikeDetails;
  carName = carList;
  truckName = truckList;
  brands: string[] = [];
  models: string[] = [];
  vehicleType: string;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "label",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  allVehicleDetails = new UntypedFormGroup({
    vehicleRegPK: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    registrationNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    typeOfvehicle: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    usage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    uniqueQrData: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    adminId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    adminName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    ownerName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    memberName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    memberId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    shNameWithDN: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    shNameWithAtti: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    isPermanent: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  gatewayForm = new UntypedFormGroup({
    depotDetailsId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotOccupantName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    aftcMembershipType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  ngOnInit() {
    for (let i = 1; i <= 256; i++) {
      this.options.push(`D:${i}`);
    }
    this.dropdownList = [
      { id: 1, label: "Depots" },
      { id: 2, label: "Common Warehouse" },
      { id: 3, label: "Cold Storage Unit" },
      { id: 4, label: "Packaging Unit" },
      { id: 5, label: "Cleaning & Grading" },
      { id: 6, label: "R & D" },
      { id: 7, label: "Solar Tunnel Dryer" }
    ];
  }

  depositFormErrors = {
    aftcMembershipType: "",
    serviceType: ""
  };

  vehicleDetailsErrors = {
    registrationNumber: "",
    typeOfvehicle: "",
    adminName: "",
    depotNumber: ""
  };

  onItemDeSelect(item: any) {
    const index = this.selectedArrayType.findIndex((selectedItem: any) => selectedItem.id === item.id);
    if (index !== -1) {
      this.selectedArrayType.splice(index, 1);
    }
  }

  onItemSelect(items: any) {
    this.selectedArrayType.push(items);
  }

  onSelectAll(items: any) {
  }

  selectedServiceType(event: any) {
    const value = event.target.value;
    this.selectedArrayType = value;
  }

  // Selected Brand based vehicle details show function
  selectVehicleTypeBrands(event: any) {
    this.brands = [];
    const value = event.target.value;
    this.vehicleType = value;
    if (value == "Bike") {
      var brandName: string[] = [];
      for (let brand of this.brandName) {
        brandName.push(brand.bikeBrand);
      }
      this.brands = brandName.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    } else if (value == "Car") {
      var carName: string[] = [];
      for (let car of this.carName) {
        carName.push(car.carBrand);
      }
      this.brands = carName.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    } else if (value == "Truck") {
      var truckName: string[] = [];
      for (let truck of this.truckName) {
        truckName.push(truck.truckBrand);
      }
      this.brands = truckName.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    } else {
      this.brands = ["Others"];
    }
  }

  // Entry form show function based on selected vehicle
  visibleForm(option: string) {
    this.selectedOption = option;
    if (this.selectedOption === "depot") {
      this.depotDetails = !this.depotDetails;
      this.administrativeVehicle = false;
      this.getStockDetails();
      this.depotVehicle = false;
    } else if (this.selectedOption === "administrative") {
      this.depotDetails = false;
      this.administrativeVehicle = !this.administrativeVehicle;
      this.allVehicleDetails.controls["usage"].setValue("AD - நிர்வாக வாகனங்கள்");
      this.depotVehicle = false;
    } else if (this.selectedOption === "depotVehicle") {
      this.getStockDetails();
      this.allVehicleDetails.controls["usage"].setValue("DV - டிப்போ வாகனங்கள்");
      this.depotDetails = false;
      this.administrativeVehicle = false;
      this.depotVehicle = !this.depotVehicle;
    }
  }

  // Required field validation conditions
  formValidationDepot() {
    this.depositFormErrors.aftcMembershipType = "";
    this.depositFormErrors.serviceType = "";
    let hasError = false;

    if (this.gatewayForm.get("aftcMembershipType").invalid) {
      this.depositFormErrors.aftcMembershipType = "* Membership Type is required";
      hasError = true;
    }
    if (this.selectedArrayType.length == 0) {
      this.depositFormErrors.serviceType = "* Service Type is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveDepotDetails();
    }
  }

  // Save new depot details
  saveDepotDetails() {
    for (let label of this.selectedArrayType) {
      this.selectedLabel.push(label.label);
    }
    for (let select of this.selectedLabel) {
      const depotClassDetails: DepotClassDetails = new DepotClassDetails();
      depotClassDetails.aftcMembershipType = this.gatewayForm.get("aftcMembershipType").value;
      depotClassDetails.depotNo = this.gatewayForm.get("depotNo").value;
      depotClassDetails.depotOccupantName = this.gatewayForm.get("depotOccupantName").value;
      depotClassDetails.serviceType = select;
      this.saveDepotList.push(depotClassDetails);
    }
    this.spinner.show();
    this.apiServices.saveDepotDetails(this.saveDepotList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });

  }

  // Required field validation for vehicle details
  formValidationVehicleEntry() {
    this.vehicleDetailsErrors.registrationNumber = "";
    this.vehicleDetailsErrors.typeOfvehicle = "";
    this.vehicleDetailsErrors.adminName = "";
    this.vehicleDetailsErrors.depotNumber = "";
    let hasError = false;

    if (this.allVehicleDetails.get("registrationNumber").invalid) {
      this.vehicleDetailsErrors.registrationNumber = "* Registration Number is required";
      hasError = true;
    }
    if (this.allVehicleDetails.get("typeOfvehicle").invalid) {
      this.vehicleDetailsErrors.typeOfvehicle = "* Vehicle Type is required";
      hasError = true;
    }
    if (this.allVehicleDetails.get("usage").value == "AD - நிர்வாக வாகனங்கள்") {
      if (this.allVehicleDetails.get("adminName").invalid) {
        this.vehicleDetailsErrors.adminName = "* Admin Name is required";
        hasError = true;
      }
    } else {
      if (this.allVehicleDetails.get("depotNumber").invalid) {
        this.vehicleDetailsErrors.depotNumber = "* Depot Number is required";
        hasError = true;
      }
    }

    if (!hasError) {
      this.saveAdminVehicleDetails();
    }
  }

  // Save admin vehicle details
  saveAdminVehicleDetails() {
    this.allVehicleDetails.get("isPermanent").setValue(true);
    this.spinner.show();
    this.apiServices.saveAllVehicleDetails(this.allVehicleDetails.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Depot name get based on depot number
  depotNo(event: any) {
    const value = event.target.value;
    for (let depo of this.getDepotDetails) {
      if (value == depo.depotNo) {
        this.depotOccupantName = depo.depotOccupantName;
        this.allVehicleDetails.controls["depotName"].setValue(this.depotOccupantName);
      }
    }
  }


  saveAlert() {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      window.location.reload();
    });
  }

  // Get all stock details
  getStockDetails() {
    this.spinner.show();
    this.apiServices.getDepotDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.getDepotDetails = data.filter(item => item.records == "NEW");
        const depotName = new Set(this.getDepotDetails.map(item => item.depotOccupantName).sort((a, b) => a.localeCompare(b)));
        this.getDepotOccupantName = Array.from(depotName);
        const depotNo = new Set(this.getDepotDetails.filter(item => item.depotNo != "").map(item => item.depotNo));
        this.getDepotNo = Array.from(depotNo).map(num => parseInt(num.split(":")[1]))
          .sort((a, b) => a - b)
          .map(num => `D:${num}`);
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Vehicle number field number format function
  formatVehicleNumber(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\W/g, "").toUpperCase();

    let positions;
    let hyphenPositions;
    const split = value.split("");

    if (!isNaN(Number(split[5]))) {
      positions = [
        { start: 0, end: 1, regex: /[^A-Z]/g },
        { start: 2, end: 3, regex: /[^\d]/g },
        { start: 4, end: 4, regex: /[^A-Z]/g },
        { start: 5, end: 8, regex: /[^\d]/g }
      ];
      hyphenPositions = [2, 5, 7];
      if (value.length == 10) {
        this.vehicleDetailsErrors.registrationNumber = "*Enter Valid Register Number";
      } else {
        this.vehicleDetailsErrors.registrationNumber = "";
      }
    } else if (!isNaN(Number(split[6]))) {
      positions = [
        { start: 0, end: 1, regex: /[^A-Z]/g },
        { start: 2, end: 3, regex: /[^\d]/g },
        { start: 4, end: 5, regex: /[^A-Z]/g },
        { start: 6, end: 9, regex: /[^\d]/g }
      ];
      hyphenPositions = [2, 5, 8];
      if (value.length == 11) {
        this.vehicleDetailsErrors.registrationNumber = "*Enter Valid Register Number";
      } else {
        this.vehicleDetailsErrors.registrationNumber = "";
      }
    } else {
      positions = [
        { start: 0, end: 1, regex: /[^A-Z]/g },
        { start: 2, end: 3, regex: /[^\d]/g },
        { start: 4, end: 6, regex: /[^A-Z]/g }
        /* { start: 7, end: 10, regex: /[ ^\d]/g}*/
      ];
      hyphenPositions = [2, 5, 9];
    }
    positions.forEach(pos => {
      value = value.replace(pos.regex, (match, offset) =>
        (offset >= pos.start && offset < pos.end) ? "" : match);
    });
    value = value.slice(0, 11);
    hyphenPositions.forEach(pos => {
      if (value.length > pos) {
        value = value.slice(0, pos) + "-" + value.slice(pos);
      }
    });
    this.allVehicleDetails.controls["registrationNumber"].setValue(value);
  }

}
