<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarNav" type="button">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/warehouse-unit-view" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Form Details</p>
        </li>
        <!--<li class="nav-item">
          <p class="nav-link" routerLink="/main/warehouse-unit-vie" (click)="navigate('Delivery')" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Delivery Details</p>
        </li>-->
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/stackHolders" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Warehouse Details</p>
        </li>
      </ul>
    </div>
  </div>
</nav>
