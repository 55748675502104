<app-toll-gate-navbar *ngIf="userType == 'ADMIN'"></app-toll-gate-navbar>
<div class="container-fluid">
  <form [formGroup]="gateForm">
    <div *ngIf="userType == 'ADMIN'" class="row"></div>
    <div class="row mt-4">
      <div class="col-md-12 mt-5 text-center">
        <div>
          <label>Service Type :</label>
          <input class="ml-3" type="radio" role="button" value="Warehouse" formControlName="serviceType"
                 (change)="selectServiceType($event)">
          <label class="ml-1">Warehouse</label>
          <input class="ml-3" type="radio" role="button" value="Depot" formControlName="serviceType"
                 (change)="selectServiceType($event)">
          <label class="ml-1">Depot</label>
        </div>
        <span *ngIf="formErrorValidation.serviceType"
              class="text-danger">{{ formErrorValidation.serviceType }}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-3 mt-2">
        <!--<div class="form-floating ">
          <select *ngIf="currentMemberNames.length == 0" class="form-select" formControlName="memberName">
            <option value="" disabled selected hidden>
              Select Member
            </option>
            <option *ngFor="let mem of currentMemberDetails"
                    value="{{mem.depotOccupantName}} {{mem.depotNo}}">{{mem.depotOccupantName}} {{mem.depotNo}}</option>
          </select>
          &lt;!&ndash;<select *ngIf="currentMemberDetails.length == 0 && serviceType" class="form-select"
                  formControlName="memberName">
            <option value="" disabled selected hidden>
              Select Member
            </option>
            <option *ngFor="let mem of currentMemberNames" value="{{mem}}">{{mem}} - UM</option>
          </select>&ndash;&gt;
          <label class="form-label">Member Name<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.memberName"
                class="text-danger">{{ formErrorValidation.memberName }}</span>
        </div>-->
        <ng-multiselect-dropdown class=""
          [placeholder]="'Select an option'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItem"
          formControlName="memberName">
        </ng-multiselect-dropdown>
        <span *ngIf="formErrorValidation.memberName"
              class="text-danger">{{ formErrorValidation.memberName }}</span>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating ">
          <select class="form-select" formControlName="storageType">
            <option value="" disabled selected hidden>
              Select Storage Type
            </option>
            <option value="Common Warehouse">Common Warehouse</option>
            <option value="SLCM">SLCM</option>
            <option value="Cold Storage">Cold Storage</option>
            <option value="Pre Processing">Pre Processing</option>
            <option value="Packing">Packing</option>
          </select>
          <label class="form-label">Storage Type</label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating ">
          <select class="form-select" id="vehType" formControlName="vehType" (change)="selectVehicleType($event)">
            <option value="" disabled selected hidden>
              Select Vehicle Type
            </option>
            <option *ngFor="let veh of vehicleOption" value="{{veh}}">{{veh}}</option>
          </select>
          <label class="form-label">Vehicle Type<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.vehType"
                class="text-danger">{{ formErrorValidation.vehType }}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-select" id="wheelCount" formControlName="wheelCount"
                  (change)="wheelCountFunction($event)">
            <option value="" disabled selected hidden>
              Select Wheel Count
            </option>
            <option *ngFor="let wheel of wheelOptions" value="{{wheel}}">{{wheel}}</option>
          </select>
          <label class="form-label">Wheel Count<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.wheelCount"
                class="text-danger">{{ formErrorValidation.wheelCount }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="heavy" class="row mt-2">
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-select" id="purpose" formControlName="purpose" (change)="chargeCal($event)">
            <option value="" disabled selected hidden>
              Select Type
            </option>
            <option value="Loading">Empty</option>
            <option value="Unloading">With Goods</option>
          </select>
          <label class="form-label">Select Type<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.purpose"
                class="text-danger">{{ formErrorValidation.purpose }}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-select" id="vehState" formControlName="vehState" (change)="changeState($event)">
            <option value="" disabled selected hidden>
              Vehicle State
            </option>
            <!--<option *ngFor="let state of allStates" value="{{state}}">{{state}}</option>-->
            <option value="Tamil Nadu">Tamil Nadu</option>
            <option value="Other State">Other State</option>
          </select>
          <label class="form-label">Vehicle State</label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select"
                  formControlName="commodityName">
            <option value="" disabled selected hidden>
              Select Commodity Name
            </option>
            <option *ngFor="let com of commodityNames" value="{{com}}">{{com}}</option>
          </select>
          <label class="form-label">Commodity Name<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.commodityName"
                class="text-danger">{{ formErrorValidation.commodityName }}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Weight MT"
                 formControlName="weightMt" (input)="calculateCharges($event)">
          <label class="form-label">Weight MT<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.weightMt"
                class="text-danger">{{ formErrorValidation.weightMt }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="heavy" class="row mt-2">
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Bags Unit"
                 formControlName="bagsUnits" (keypress)="OnlyNumbers($event)">
          <label class="form-label">Bags Unit</label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Consignor Supplier Name"
                 formControlName="consignorSupplierName">
          <label class="form-label">Consignor Supplier Name</label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select"
                  formControlName="placeDispatchOrigin">
            <option value="" disabled selected hidden>
              Select Dispatch Origin
            </option>
            <option value="Tamil Nadu">Tamil Nadu</option>
            <option value="Other State">Other State</option>
          </select>
          <label class="form-label">Place Dispatch Origin</label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Delivery Way Bill"
                 formControlName="deliveryWayBill">
          <label class="form-label">Delivery Way Bill</label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div *ngIf="heavy" class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control" placeholder="Declaration Date"
                 formControlName="declarationDate">
          <label class="form-label">Declaration Date</label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="eg: TN-00-AA-0000"
                 formControlName="vehNumberPlate">
          <label class="form-label">Vehicle Number<span class="text-danger">*</span></label>
          <span *ngIf="formErrorValidation.vehNumberPlate"
                class="text-danger">{{ formErrorValidation.vehNumberPlate }}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Driver Name"
                 formControlName="driverName">
          <label class="form-label">Driver Name</label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control text-capitalize" placeholder="Driver Phone No"
                 formControlName="driverPhNo" (keypress)="OnlyNumbers($event)" maxlength="10">
          <label class="form-label">Driver Phone No<span *ngIf="heavy" style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.driverPhNo" class="text-danger">{{formErrorValidation.driverPhNo}}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Vehicle Entry Date Time"
                 formControlName="vehEntryDateTime">
          <label class="form-label">Vehicle Entry Date Time<span style="color:red;">*</span></label>
          <span *ngIf="formErrorValidation.vehEntryTime" class="text-danger">{{formErrorValidation.vehEntryTime}}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" disabled class="form-control" placeholder="Charges"
                 formControlName="charges">
          <label class="form-label">Charges</label>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-4 mb-5">
      <button type="submit" class="btn btn-success col-md-1" (click)="formValidation(gateForm)">Save</button>
    </div>
  </form>
</div>

<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>
