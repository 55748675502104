<div class="py-4"></div>
<div class="container-fluid">
  <!-- Card stats -->
  <div class="row g-6 mb-6">
    <div class="col-xl-1 col-sm-6 col-12"></div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card shadow border-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="h3 font-bold mb-0">CG I</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                <i class="bi bi-credit-card"></i>
              </div>
            </div>
          </div>
          <div class="mt-2 mb-0 text-sm">
                        <span class="badge badge-pill bg-soft-success text-success me-2">
                        </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card shadow border-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="h3 font-bold mb-0">CG II</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-primary text-white text-lg rounded-circle">
                <i class="bi bi-people"></i>
              </div>
            </div>
          </div>
          <div class="mt-2 mb-0 text-sm">
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card shadow border-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="h3 font-bold mb-0">CG III</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-info text-white text-lg rounded-circle">
                <i class="bi bi-clock-history"></i>
              </div>
            </div>
          </div>
          <div class="mt-2 mb-0 text-sm">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row g-6 mb-6">
    <div class="col-xl-1 col-sm-6 col-12"></div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card shadow border-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="h3 font-bold mb-0">G I</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                <i class="bi bi-credit-card"></i>
              </div>
            </div>
          </div>
          <div class="mt-2 mb-0 text-sm">
            <span class="badge badge-pill bg-soft-success text-success me-2"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card shadow border-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="h3 font-bold mb-0">G II</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-primary text-white text-lg rounded-circle">
                <i class="bi bi-people"></i>
              </div>
            </div>
          </div>
          <div class="mt-2 mb-0 text-sm"></div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card shadow border-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="h3 font-bold mb-0">G III</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-info text-white text-lg rounded-circle">
                <i class="bi bi-clock-history"></i>
              </div>
            </div>
          </div>
          <div class="mt-2 mb-0 text-sm"></div>
        </div>
      </div>
    </div>
  </div>
</div>
