<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Employee Registration</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Blank Page</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <form [formGroup]="employeeForm">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <div><label for="emName"> Employee Name</label></div>
            <input
              formControlName="emName"
              class="form-control"
              id="emName"
              placeholder="Employee Name"
              type="text"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div><label for="emPhone"> Employee Phone</label></div>
            <input
              appOnlyNumber
              formControlName="emPhone"
              class="form-control"
              id="emPhone"
              placeholder="Enter phone number only"
              type="text"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div><label for="emStreetOne"> Employee street</label></div>
            <input
              formControlName="emStreetOne"
              class="form-control"
              id="emStreetOne"
              placeholder="Employee Street"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <div><label for="emStreetTwo"> Employee Area</label></div>
            <input
              formControlName="emStreetTwo"
              class="form-control"
              id="emStreetTwo"
              placeholder="Employee Area"
              type="text"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <div><label for="emCity"> Employee City</label></div>
            <input
              formControlName="emCity"
              class="form-control"
              id="emCity"
              placeholder="Employee City"
              type="text"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <div><label for="emVillage"> Employee village</label></div>
            <input
              formControlName="emVillage"
              class="form-control"
              id="emVillage"
              placeholder="Employee village"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row" formGroupName="empPositionDetails">
        <div class="col-md-4">
          <div class="form-group">
            <div><label for="empPositionDetails"> Position</label></div>
            <div ngbDropdown class="d-inline-block">
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic2"
                ngbDropdownToggle
              >
                {{ selectedPos }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button
                  *ngFor="let pos of positions"
                  ngbDropdownItem
                  (click)="setPos(pos)"
                >
                  {{ pos.emPositionName }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div><label for="departments"> Department</label></div>
            <div ngbDropdown class="d-inline-block">
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                {{ selectedDept }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button
                  *ngFor="let item of departments"
                  ngbDropdownItem
                  (click)="setDept(item)"
                >
                  {{ item.departmentName }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <div class="Submit-button">
              <button type="submit" class="button" (click)="onSubmit()">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      [ngStyle]="{ display: displayStyle }"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Your Credentials</h4>
          </div>
          <div class="modal-body">
            <p>Your Login ID : {{ loginID }}</p>
            <p>Password : {{ password }}</p>
            <p>Note: {{ notes }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="closePopup()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
