import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import * as QRCode from "qrcode";
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from "file-saver";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { data } from "jquery";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import {
  WarehouseStockDeposit
} from "@components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";
import { DatePipe } from "@angular/common";
import {
  warehouseStockDelivery
} from "@components/aftc-digitalization/aftc-digitalization-models/warehouseStockDelivery";


@Component({
  selector: "app-unit-view",
  templateUrl: "./unit-view.component.html",
  styleUrls: ["./unit-view.component.scss"]
})
export class UnitViewComponent implements OnInit {

  searchText;
  getAllDepositDetailsList: WarehouseStockDeposit[] = [];
  getFilteredDepositDetails: WarehouseStockDeposit[] = [];
  getAllDeliveryDetailsList: warehouseStockDelivery[] = [];
  getFilteredDeliveryDetails: warehouseStockDelivery[] = [];
  qrCodeData: string = "https://aftc-digi.varnik.cloud/#/qrEntry?code=";
  qrCodeUrl: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  serviceType = false;
  tollgateSeq: number = 0
  getDateValue: string[] = [];
  getMemberName: string[] = [];
  nameFilter: string | null = null;
  DateFilter: string | null = null;
  formType: string;
  userType: string;

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService, private spinner: NgxSpinnerService,
    private datePipe: DatePipe) {

  }

  gateForm = new UntypedFormGroup({
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  })

  ngOnInit(): void {
    this.userType = localStorage.getItem('role');
  }

  selectForm(event: any) {
    const value = event.target.value

    if (value == 'Deposit') {
      this.formType = value;
      this.getAllDepositDetails();
    } else if (value == "Delivery") {
      this.formType = value;
      this.getAllDeliveryDetails();
    }
  }

  getAllDepositDetails() {
    this.apiServices.getAllWarehouseDepositDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("get All", data);
        this.getAllDepositDetailsList = data.sort((a,b) => b.stockDepositId - a.stockDepositId);
        this.getFilteredDepositDetails = data.sort((a,b) => b.stockDepositId - a.stockDepositId);
        const memName = new Set(this.getAllDepositDetailsList.map(item => item.depositorName));
        this.getMemberName = Array.from(memName);
        const dateList = this.getAllDepositDetailsList.map(item => this.datePipe.transform(item.depositDate * 1000, 'dd/MM/yyyy'));
        const date = new Set(dateList);
        console.log(date);
        this.getDateValue = Array.from(date);
      }, (err: any) => {
        console.log("something went worng");
      });
  }

  getAllDeliveryDetails() {
    this.apiServices.getAllWarehouseDeliveryDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("get All", data);
        this.getAllDeliveryDetailsList = data.sort((a,b) => b.stockDeliveryId - a.stockDeliveryId);
        this.getFilteredDeliveryDetails = data.sort((a,b) => b.stockDeliveryId - a.stockDeliveryId);
        const memName = new Set(this.getAllDeliveryDetailsList.map(item => item.depositorName));
        this.getMemberName = Array.from(memName);
        const dateList = this.getAllDeliveryDetailsList.map(item => this.datePipe.transform(item.deliveryDate * 1000, 'dd/MM/yyyy'));
        const date = new Set(dateList);
        console.log(date);
        this.getDateValue = Array.from(date);
      }, (err: any) => {
        console.log("something went worng");
      });
  }

  /*getAllPassDetails() {
    this.apiServices.getAllTollGate()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("getallpassDetails::", data);
        this.getLocalPassDetails = data;
        for (var aftc of this.getLocalPassDetails) {
          if (aftc.vehEntryDateTime === 0) {
            aftc.entryDate = "0";
          } else {
            const entryDate = new Date(aftc.vehEntryDateTime * 1000);
            const dateOnly = entryDate.toLocaleString().split(',')[0];
            aftc.entryDate = dateOnly;
          }

          if (aftc.vehExitDateTime === 0) {
            aftc.exitDate = "0";
          } else {
            const exitDate = new Date(aftc.vehExitDateTime * 1000);
            aftc.exitDate = exitDate.toLocaleString();
          }
        }
        const vehicleType = new Set(this.getLocalPassDetails.map(item => item.tollgateVehicleDetails.vehType));
        this.getVehicleType = Array.from(vehicleType)
        const getDate = new Set(this.getLocalPassDetails.map(item => item.entryDate))
        this.getDateValue = Array.from(getDate)

        this.getPassDetails = this.getLocalPassDetails.sort((a, b) => {
          const idA = a.tollgateSeq;
          const idB = b.tollgateSeq;
          return idB - idA;
        });
        this.cashDepositValues = this.getPassDetails.filter(item => item.cashDeposit == false)
        const vanValues = this.cashDepositValues.map(item => item.charges)
        this.totalAmount = vanValues.reduce((total, charge) => total + charge, 0);
        console.log("getallpassDetails::", this.getPassDetails);
      }, (err: any) => {
        console.log("something went worng");
      });
  }*/


  filter(event: any, filterType: string) {
    const value = event.target.value;

    switch (filterType) {
      case 'name':
        this.nameFilter = value !== 'Select Member Name' ? value : null;
        break;
      case 'date':
        this.DateFilter = value !== 'Select Date' ? value : null;
        break;
      default:
        break;
    }
    this.applyFilters();
  }

  applyFilters() {
    if (this.getAllDepositDetailsList.length > 0) {
      const nameFiltered = this.nameFilter ? this.getAllDepositDetailsList.filter(item => item.depositorName === this.nameFilter) : this.getAllDepositDetailsList;
      const dateFiltered = this.DateFilter ? nameFiltered.filter(item => this.datePipe.transform(item.depositDate * 1000, 'dd/MM/yyyy') === this.DateFilter) : nameFiltered;
      this.getFilteredDepositDetails = dateFiltered.sort((a,b) => b.stockDepositId - a.stockDepositId);
    } else if (this.getAllDeliveryDetailsList.length > 0) {
      const nameFiltered = this.nameFilter ? this.getAllDeliveryDetailsList.filter(item => item.depositorName === this.nameFilter) : this.getAllDeliveryDetailsList;
      const dateFiltered = this.DateFilter ? nameFiltered.filter(item => this.datePipe.transform(item.deliveryDate * 1000, 'dd/MM/yyyy') === this.DateFilter) : nameFiltered;
      this.getFilteredDeliveryDetails = dateFiltered.sort((a,b) => b.stockDeliveryId - a.stockDeliveryId);
    }
  }

  ngOnDestroy(): void {
  }

  updateDetails(id: number, type: string) {
    if (type == 'Deposit') {
      sessionStorage.setItem('depositId', String(id));
      this.router.navigate(['/main/warehouse-deposit-unit-entry']);
    } else if (type == 'Delivery') {
      sessionStorage.setItem('deliveryId', String(id));
      this.router.navigate(['/main/warehouse-delivery-unit-entry']);
    }
  }

}
