<app-warehouse-unit-navbar></app-warehouse-unit-navbar>
<div class="container -fulid">
  <div class="row"></div>
  <div class="row mt-5">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
<!--        <h3>Stack Holder Details</h3>-->
      </div>
    </div>
    <div class="col-md-4 justify-content-end">
      <div class="div mb-2 mt-3">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>Depositor Name</th>
            <th>Commodity Type</th>
            <th>Service Type</th>
            <th>Details View</th>
          </tr>
        </thead>
        <tbody *ngFor="let aftc of services | filter: searchText;let i = index">
        <tr>
          <td>{{i+1}}</td>
          <td>{{aftc.depositorName}}</td>
          <td>{{aftc.commodity}}</td>
          <td>{{aftc.service_type}}</td>
          <td><button class="btn btn-success" style="background-color: #22c158" (click)="detailsShoew(aftc.vendorId)">Details</button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

