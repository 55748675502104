import { Component, OnInit } from "@angular/core";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { TestDetails } from "@/model/labModal/testDetails";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { Subject, takeUntil } from "rxjs";
import { NormalTestDetails } from "@/model/labModal/normalTestDetails";
import { InternalTestDetails } from "@/model/labModal/internalTestDetails";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";

@Component({
  selector: "app-aftc-view",
  templateUrl: "./aftc-view.component.html",
  styleUrls: ["./aftc-view.component.scss"],
})
export class AFTCViewComponent implements OnInit {
  currentTestDetails: TestDetails | undefined;
  currentNormalTestDetails: NormalTestDetails | undefined;
  currentInternalTestDetails: InternalTestDetails | undefined;
  currentResult: ProcedureResults | undefined;
  currentPrint: AllTestDetails | undefined;
  editDetails: AllTestDetails | undefined;
  entryEdit: AllTestDetails | undefined;
  [x: string]: any;
  testType: string = "";
  isVisible = false;
  eSign: string = "";
  signShow = false;

  productList: String[] = [];

  logoShow = false;
  public TestResult: ProcedureResults[] = [];
  public ulrNo: String;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private appService: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const ulrParam = params["ulr"];
      this.ulrNo = ulrParam;
    });
    this.getTestDetailulr();
    this.getNormalTestDetailulr();
    this.getInternalTestDetailulr();
  }

  // Function to fetch test details based on ulrNo
  getTestDetailulr() {
    this.appService
      .getTestDetails(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
        this.getProcedureResultsulr();
      });
  }

  // Function to fetch normal test details based on ulrNo
  getNormalTestDetailulr() {
    this.appService
      .getNormalTestDetails(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
        this.getProcedureResultsulr();
      });
  }

  // Function to fetch internal test details based on ulrNo
  getInternalTestDetailulr() {
    this.appService
      .getInternalTestDetails(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
        this.getProcedureResultsulr();
      });
  }

  // Function to fetch procedure results based on ulrNo
  getProcedureResultsulr() {
    this.appService
      .getProcedureResults(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        var localdatass: ProcedureResults[] = []
        localdatass = data;
        const uniqueProcedureIds = new Set(localdatass.map(item => item.aftcl_procedure_id));
        const uniqueProcedureResults = Array.from(uniqueProcedureIds).map(procedureId => {
          return localdatass.find(item => item.aftcl_procedure_id === procedureId);
        });
        this.TestResult = uniqueProcedureResults
        var product: String[] = [];
        for (var pro of this.TestResult) {
          product.push(pro.aftcl_test_sample);
        }
        this.productList = product.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
      });
  }
}
