import { ApiService } from "@services/api.service";
import { Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { NewsFeed } from "@/model/newsFeed/newsFeed";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-news-feed-admin",
  templateUrl: "./news-feed-admin.component.html",
  styleUrls: ["./news-feed-admin.component.css"]
})
export class NewsFeedAdminComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  title: string;
  description: string;
  selectedFile: File;
  newsFeedData: NewsFeed | undefined;

  constructor(private apiService: ApiService, private toaster: ToastrService,
              private router: Router, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  news = new UntypedFormGroup({
    title: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    description: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    image: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    date: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    time: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    city: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  public newsErrors = {
    title: "", description: "", image: "", date: "", city: "", time: ""
  };

  onSubmit() {
    this.formValidation();
  }

  formValidation() {
    this.newsErrors.title = "";
    this.newsErrors.description = "";
    this.newsErrors.image = "";
    this.newsErrors.date = "";
    this.newsErrors.time = "";
    this.newsErrors.city = "";
    let hasError = false;

    if (this.news.get("title")?.invalid) {
      this.newsErrors.title = " Title is Required*";
      hasError = true;
    }
    if (this.news.get("description")?.invalid) {
      this.newsErrors.description = "Description is required*";
      hasError = true;
    }
    // if (this.news.get("image")?.invalid) {
    //   this.newsErrors.image = "Select an Image*";
    //   hasError = true
    // }
    if (this.news.get("date")?.invalid) {
      this.newsErrors.date = "Date is required*";
      hasError = true;
    }
    if (this.news.get("time")?.invalid) {
      this.newsErrors.time = "Time is required*";
      hasError = true;
    }
    if (this.news.get("city")?.invalid) {
      this.newsErrors.city = "City   is required*";
      hasError = true;
    }

    if (!hasError) {
      this.sendFile();
      console.log("add", this.news.value);
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }


  sendFile() {
    this.spinner.show();
    this.apiService.addNewsFeed(this.news.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("saved data::", data);
        this.newsFeedData = data;
        this.news.reset();
        this.spinner.hide();
        this.toaster.success("Saved Successfully");
        // this.readURL();
      }, (err: any) => {
        console.log("Something went wrong::", err);
        this.toaster.error("Something went wrong, Please try again later");
        this.spinner.hide();
      });
  }

  /*readURL() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);

    this.apiService.uploadNewsFeedFile(formData, this.newsFeedData.newsFeedId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("File Upload successfully");
      }, (err: any) => {
        console.log("Something went wrong::", err);
      })
  }*/

}



