import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import * as QRCode from "qrcode";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";


@Component({
  selector: "app-stack-details",
  templateUrl: "./stack-details.component.html",
  styleUrls: ["./stack-details.component.scss"]
})
export class StackDetailsComponent implements OnInit {

  searchText;
  vendorId : string = ""
  services : any [] = [];

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService) {

  }

  ngOnInit(): void {
     this.vendorId = localStorage.getItem("seqId");
    this.serviceFunction();

  }

  serviceFunction(){
    if(this.vendorId == '1'){
      this.services = [
        {"vendorId" : "1","depositorName": "Ram", "commodity": "Paddy", "service_type": 'Warehouse',"date": '12-04-2024',"bags": '100',
        "tons": '120',"godownNo": '12',"stockNumber" : "C/II"},
        {"vendorId" : "2","depositorName": "Ram", "commodity": "Paddy", "service_type": 'Warehouse',"date": '02-03-2024',"bags": '50',
        "tons": '60',"godownNo": '12',"stockNumber" : "C/III"},
        {"vendorId" : "3","depositorName": "Ram", "commodity": "Paddy", "service_type": "Warehouse","date": '24-02-2024',"bags": '200',
        "tons": '230',"godownNo": '11',"stockNumber" : "G/I"},
        {"vendorId" : "4","depositorName": "Ram", "commodity": "Paddy", "service_type": "Warehouse","date": '29-01-2024',"bags": '50',
        "tons": '70',"godownNo": '9',"stockNumber" : "G/IV"},
    ]
    }else if(this.vendorId == '2'){
      this.services = [
        {"vendorId" : "1","depositorName": "Raja", "commodity": "Paddy", "service_type": 'Warehouse',"date": '02-05-2024',"bags": '500',
        "tons": '250',"godownNo": '10',"stockNumber" : "CG2/A"},
        {"vendorId" : "2","depositorName": "Raja", "commodity": "Paddy", "service_type": 'Warehouse',"date": '22-04-2024',"bags": '100',
        "tons": '160',"godownNo": '12',"stockNumber" : "CG2/B"},
        {"vendorId" : "3","depositorName": "Raja", "commodity": "Paddy", "service_type": "Warehouse","date": '12-03-2024',"bags": '250',
        "tons": '280',"godownNo": '11',"stockNumber" : "G I/32"},
        {"vendorId" : "4","depositorName": "Raja", "commodity": "Paddy", "service_type": "Warehouse","date": '29-12-2023',"bags": '150',
        "tons": '270',"godownNo": '10',"stockNumber" : "G I/35"},
    ]
    }else if(this.vendorId == '3'){
      this.services = [
        {"vendorId" : "1","depositorName": "Ravi", "commodity": "Paddy", "service_type": 'Warehouse',"date": '03-04-2024',"bags": '150',
        "tons": '250',"godownNo": '15',"stockNumber" : "G II/38"},
        {"vendorId" : "2","depositorName": "Ravi", "commodity": "Paddy", "service_type": 'Warehouse',"date": '30-03-2024',"bags": '40',
        "tons": '100',"godownNo": '15',"stockNumber" : "G I/43"},
        {"vendorId" : "3","depositorName": "Ravi", "commodity": "Paddy", "service_type": "Warehouse","date": '13-01-2024',"bags": '90',
        "tons": '180',"godownNo": '17',"stockNumber" : "G I/44"},
        {"vendorId" : "4","depositorName": "Ravi", "commodity": "Paddy", "service_type": "Warehouse","date": '15-11-2023',"bags": '150',
        "tons": '170',"godownNo": '17',"stockNumber" : "G II/21"},
    ]
    }else if(this.vendorId == '4'){
      this.services = [
        {"vendorId" : "1","depositorName": "Hari", "commodity": "Paddy", "service_type": 'Warehouse',"date": '04-05-2024',"bags": '50',
        "tons": '50',"godownNo": '18',"stockNumber" : "PPU/13"},
        {"vendorId" : "2","depositorName": "Hari", "commodity": "Paddy", "service_type": 'Warehouse',"date": '31-03-2024',"bags": '250',
        "tons": '300',"godownNo": '18',"stockNumber" : "PPU/14"},
        {"vendorId" : "3","depositorName": "Hari", "commodity": "Paddy", "service_type": "Warehouse","date": '17-02-2024',"bags": '150',
        "tons": '200',"godownNo": '20',"stockNumber" : "PPU/17"},
        {"vendorId" : "4","depositorName": "Hari", "commodity": "Paddy", "service_type": "Warehouse","date": '15-12-2023',"bags": '50',
        "tons": '80',"godownNo": '120',"stockNumber" : "PPU/18"},
    ]
    }
  }
}
