import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { vehiclePunchDetails } from "@/model/vehiclePunchDetails/vehiclPunchDetails";
import { DatePipe } from "@angular/common";


@Component({
  selector: "app-vehicle-punch-data-view",
  templateUrl: "./vehicle-punch-data-view.component.html",
  styleUrls: ["./vehicle-punch-data-view.component.scss"]
})
export class VehiclePunchDataView implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText;
  getVehicle: vehiclePunchDetails[] = [];
  currentDate: any;
  getDate: string = "";

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService, private toaster: ToastrService,
              private router: Router, private datePipe: DatePipe) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "dd-MM-yyyy") ?? "";
  }

  ngOnInit() {
    this.getDateBasedPunchDetails(this.currentDate);
  }

  ngOnDestroy() {
  }

  // selected date based punch details get
  dateFilter(event: any) {
    const inputValue = event.target.value;
    const parsedDate = new Date(inputValue);
    if (!isNaN(parsedDate.getTime())) {
      const values = this.datePipe.transform(parsedDate, "dd-MM-yyyy") ?? "";
      this.getDate = values;
      this.getDateBasedPunchDetails(this.getDate);
    } else {
      this.toaster.error("Invalid date format");
    }
  }

  // get the punch data based on current date
  getDateBasedPunchDetails(date: any) {
    this.apiServices.getDateBasedVehiclePunchDetails(date)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getVehicle = data.sort((a, b) => b.createdAt - a.createdAt).filter(item => item.vehicleRegDetails != null);
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }
}

