<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarNav" type="button">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/stack-details-view" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Stack-Details</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/stock-details-view" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Stock-Details</p>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container -fulid">
  <div class="row">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
        <h3>Stack Card Details</h3>
      </div>
    </div>
    <div class="col-md-4 justify-content-end">
      <div class="div mb-2 mt-3">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>Depositor Name</th>
            <th>Date</th>
            <th>Godown No</th>
            <th>Stack No</th>
            <th>Bags</th>
            <th>M.Tons</th>
            <th>Deposit / Delivery</th>
            <th>Total Bags</th>
          </tr>
        </thead>
        <tbody *ngFor="let aftc of services | filter: searchText;let i = index">
        <tr>
          <td>{{i+1}}</td>
          <td>{{aftc.depositorName}}</td>
          <td>{{aftc.date}}</td>
          <td>{{aftc.stockNumber}}</td>
          <td>{{aftc.godownNo}}</td>
          <td>{{aftc.bags}}</td>
          <td>{{aftc.tons}}</td>
          <td>{{aftc.service_type}}</td>
          <td>{{aftc.totalBags}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

