<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Register a new membership</p>

    <form [formGroup]="registerForm" (ngSubmit)="registerByAuth()">
      <div class="row"><div class="col-md"><div><label for="loginId"> Login Id</label></div><input id="loginId" class="form-control" type="text" placeholder="Login Id" formControlName="loginId" ></div></div>
      <div class="row"><div class="col-md"><div><label for="grantType"> Grant Type</label></div><input id="grantType" class="form-control" type="text" placeholder="Grant Type" formControlName="grantType" ></div></div>
      <div class="row"><div class="col-md"><div><label for="password"> Password</label></div><input id="password" class="form-control" type="password" placeholder="password" formControlName="password" ></div></div>
      <div class="row"><div class="col-md"><div><label for="loginPhone">Phone</label></div><input id="loginPhone" class="form-control" type="text" placeholder="login Phone" formControlName="loginPhone" ></div></div>
  <div class="input-group-append">
        <div class="input-group-text">
          <span class="fas fa-lock"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="icheck-primary">
            <input type="checkbox" id="agreeTerms" name="terms" value="agree" />
            <label for="agreeTerms">
              I agree to the <a href="#">terms</a>
            </label>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-5">
          <pf-button
            [type]="'submit'"
            [block]="true"
            [loading]="isAuthLoading"
            [disabled]="isFacebookLoading || isGoogleLoading"
          >
            Register
          </pf-button>
        </div>
        <!-- /.col -->
      </div>
    </form>

    <div class="social-auth-links text-center">
      <pf-button
        class="mb-2"
        [block]="true"
        [loading]="isFacebookLoading"
        [disabled]="isAuthLoading || isGoogleLoading"
        (click)="registerByFacebook()"
      >
        <i class="fab fa-facebook mr-2"></i>
        Sign up using Facebook
      </pf-button>
      <pf-button
        [block]="true"
        [loading]="isGoogleLoading"
        [disabled]="isAuthLoading || isFacebookLoading"
        (click)="registerByGoogle()"
        theme="danger"
      >
        <i class="fab fa-google mr-2"></i>
        Sign up using Google+
      </pf-button>
    </div>

    <a [routerLink]="['/login']" class="text-center">
      I already have a membership</a
    >
  </div>
</div>
