import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  currentUserDept:string
title: any;
optionLinkOne: any;
optionLinkOneUrl: any;


  ngOnInit(): void {
  }


}
