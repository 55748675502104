<div class="container-fluid">
  <div class="card ms-3 border border-dark">
    <div class="row">
      <div class="row">
        <h5 class="mt-2"><b>GSTIN: 33AABCT1101F1Z7</b></h5>
      </div>

      <div class="row">
        <div class="col-2">
          <img src="../../../../assets/img/AFTC%20logo.png" class="alt" style="width: 130px;height: 130px;">
        </div>
        <div class="col-10 mt-5">
          <div class="row">
            <div class="col">
              <h3 class="text-center" style="font-family: 'Guest Check', cursive;"><b>AGROFOOD TRADE CENTRE</b></h3>
              <h5 class="text-center">Sikkandarchavadi, Alanganallur Road, Madurai-625 018. 0452-2660669</h5>
              <h5 class="text-center"><b>Delivery Order - Pre-Processing Centre</b></h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <p class="text-left">Delivery Order No :</p>
        </div>
      </div>

      <div class="container-fluid col-lg-11">
        <table class="table">
          <thead>
          <tr>
            <th class="border-bottom-hide">Date</th>
            <th colspan="2">Commodity</th>
            <th>Quantity</th>
            <th>Processing Charges Rs.</th>
            <th colspan="2">Preservation</th>
            <th>Transport & Handling Charges Rs.</th>
            <th>Total Amount Rs.</th>
            <th>Bill No / Date</th>
            <th colspan="4">Signature</th>
            <th>Remarks</th>
          </tr>
          <tr>
            <th></th>
            <th>Bags</th>
            <th>T.Kgs</th>
            <th></th>
            <th></th>
            <th>Preservation Charges Rs.</th>
            <th>One Month Storage Charges Rs.</th>
            <th></th>
            <th></th>
            <th></th>
            <th>Depositor / Authorized Agent</th>
            <th>Accountant</th>
            <th>Operator</th>
            <th>Manager</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>

        <div class="row mt-3 mb-3">
          <div class="col-12 text-center">
            <h5 class="text-bold">Delivery Details</h5>
          </div>

          <div class="row">
            <div class="col-8 text-start">
              <h6 class="text-bold ml-3">Date of Processing:</h6>
            </div>
            <div class="col-4">
              <h6 class="text-bold">Delivery Due Date:</h6>
            </div>
          </div>
        </div>

        <table class="table">
          <thead>
          <tr>
            <th class="border-bottom-hide">Date</th>
            <th colspan="2">Vehicle No.</th>
            <th>Quantity</th>
            <th>No.of Days Delay</th>
            <th>Month</th>
            <th>Storage Charge / Bag Rs.</th>
            <th>Storage Charges Rs.</th>
            <th>Bill No / Date</th>
            <th colspan="4">Signature</th>
            <th>Remarks</th>
          </tr>
          <tr>
            <th></th>
            <th>Bags</th>
            <th>T.Kgs</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Depositor / Authorized Agent</th>
            <th>Accountant</th>
            <th>Operator</th>
            <th>Manager</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
