import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { Subject, takeUntil, Subscription, interval } from "rxjs";
import { CameraRecords } from "@/model/cameraRecords/CameraRecords";
import { DateTime } from "luxon";


@Component({
  selector: "app-camera-records",
  templateUrl: "./camera-records.component.html",
  styleUrls: ["./camera-records.component.scss"]
})
export class CameraRecordsComponent implements OnInit {
  searchText;
  public currentYear: string = DateTime.now().toFormat("y");
  destroy$: Subject<boolean> = new Subject<boolean>();
  cameraRecords: CameraRecords[] = [];
  private intervalSubscription: Subscription;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router) {

  }

  ngOnInit() {
    this.getRecords();
    this.intervalSubscription = interval(5000).subscribe(() => {
      this.getRecords();
    });
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe();
  }

  getRecords() {
    this.apiServices.getCameraRecords()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("records::", data);
        this.cameraRecords = this.convertEpochToDateFormat(data);
      }, (err: any) => {
        console.log("something went wrong::", err);
      });
  }

  convertEpochToDateFormat(records: any[]): any[] {
    // Sort records by license plate number and then by timestamp
    records.sort((a, b) => {
      /*if (a.labels < b.labels) return -1;
      if (a.labels > b.labels) return 1;
      return a.cameraCurrentTime - b.cameraCurrentTime;*/
      return b.cameraCurrentTime - a.cameraCurrentTime;
    });

    return records.map(record => {
      const timestamp = new Date(record.cameraCurrentTime * 1000);
      const formattedDate = this.formatDate(timestamp);

      return { ...record, formattedDate };
    });
  }

  formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${hours}:${minutes}/${day}-${month}-${year}`;
    return formattedDate;
  }


}
