import { DeptDetailsModel } from '@/model/department/dept-details-model';
import { EmpPosDetailsModel } from '@/model/employee/emp-pos-details-model';
import { RegResponseModel } from '@/model/reg-response/reg-response-model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css'],
})
export class EmployeeDetailsComponent implements OnInit {
  // departments: string[] = [
  //   'Gate Keepers',
  //   'Weigh Bridge',
  //   'Frontend Office',
  //   'Common Warehouse',
  //   'Preprocessing Unit',
  //   'Cold Storage',
  //   'Packing Unit',
  // ];

  departments: any;
  positions: EmpPosDetailsModel;

  selectedPos: string = 'select Position';
  selectPosJson: any;
  selectedPosId: number;

  selectedDept: string = 'Select department';

  employeeForm: any;
  empPositionDetailsForm: any;
  selectDeptJson: any;

  selectedDeptName: string='';
  selectedDeptID: number=0;

  displayStyle = 'none';
  loginID: any;
  password: any;
  notes: any;

  openPopup() {
    this.displayStyle = 'block';
  }
  closePopup() {
    this.displayStyle = 'none';
  }

  async onSubmit() {
    let field_check = this.checkObj();
    if (!field_check) {
      return;
    } else {
      this.employeeForm
        .get('deptDetails')
        .get('deptId')
        .get('departmentId')
        .setValue(this.selectedDeptID);
      this.employeeForm
        .get('deptDetails')
        .get('deptId')
        .get('departmentName')
        .setValue(this.selectedDeptName);
      this.employeeForm
        .get('empPositionDetails')
        .get('empSeq')
        .setValue(this.selectedPosId);
      console.log(this.employeeForm.value);

      let data = await this.apiService.addEmployee(this.employeeForm.value);
      if (data == '') {
        this.openPopup();
        this.notes = 'Error..please Try again later';
      } else {
        this.openPopup();
        let regResponseModel: RegResponseModel = data;
        this.loginID = regResponseModel.userId;
        this.password = regResponseModel.password;
        this.notes =
          'Please note your credentials you can login with this credentials after administrator permits you';
        this.employeeForm.reset();
        this.initial_data();
      }
    }
  }

  checkObj() {
    return true;
  }
  setDept(dept: any) {
    this.selectedDeptName = dept.departmentName;
    this.selectedDeptID = dept.departmentId;
    this.selectedDept = dept.departmentName;
  }

  setPos(pos: any) {
    console.log(pos)
    this.selectedPos=pos.emPositionName
    this.selectedPosId = pos.empSeq;
  }

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {}
  ngOnInit() {
    this.apiService.getDepartmentData().subscribe((res) => {
      this.departments = res as DeptDetailsModel;

    });

    this.apiService.getEmpPosition().subscribe((res) => {
      this.positions = res as EmpPosDetailsModel;
      console.log(this.positions)
    });

    this.initial_data();
  }

  initial_data() {
    this.employeeForm = this.formBuilder.group({
      emPhone: '',
      emName: '',
      emStreetOne: '',
      emStreetTwo: '',
      emCity: '',
      emVillage: '',
      empPositionDetails: this.formBuilder.group({
        empSeq: this.selectedPosId,
      }),
      deptDetails: this.formBuilder.group({
        deptId: this.formBuilder.group({
          departmentId: this.selectedDeptID,
          departmentName: this.selectedDeptName,
        }),
      }),
    });
  }
}
