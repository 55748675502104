import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import * as QRCode from 'qrcode';
import { Router } from "@angular/router";
import { WeighBridgeDetails } from "@components/aftc-digitalization/aftc-digitalization-models/weighBridgeDetails";

@Component({
  selector: "app-weigh-bridge-print",
  templateUrl: "./weigh-bridge-print.component.html",
  styleUrls: ["./weigh-bridge-print.component.css"]
})
export class WeighBridgePrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentWeighBridge: WeighBridgeDetails | undefined;
  currentDate: string;
  weighBridgeId: number;
  basicValue: number;
  private intervalSubscription: Subscription;

  constructor(private apiService: ApiService, private datePipe: DatePipe,
              private router: Router) {
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
  }

  ngOnInit(): void {
    this.weighBridgeId = Number(sessionStorage.getItem('printId'));
    this.getWeighBridgeDetails();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('printId');
    sessionStorage.removeItem('backTo');
  }

  getWeighBridgeDetails() {
    this.apiService.getWeighBridgeDetailsById(this.weighBridgeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("All Gate Pass :", data);
        this.currentWeighBridge = data;
        this.intervalSubscription = interval(1000).subscribe(() => {
          this.print();
        });
      }, (err: any) => {
        console.log("Something went wrong :", err);
      })
  }

  print() {
    const printStyle = `
      @media print {
        @page {
          size: A5; /* Set your desired page size here */
        }
      }
    `;
    const originalTitle = document.title;
    document.title = this.currentWeighBridge.irmNo.split('/')[2] + this.currentWeighBridge.irmNo.split('/')[3];
    const style = document.createElement('style');
    style.innerHTML = printStyle;
    document.head.appendChild(style);
    window.print();
    document.head.removeChild(style);
    document.title = originalTitle;
    this.intervalSubscription.unsubscribe();

    setTimeout(()=> {
      sessionStorage.removeItem('printId');
      if (sessionStorage.getItem('backTo') == 'tollgateEntries') {
        this.router.navigate(['/main/weigh-bridge-view']);
      } else {
        this.router.navigate(['/main/weigh-bridge-detail-view']);
      }
    })
  }

  gstValue(): number {
    const gstAmount = Number(this.basicValue * 18 / 100);
    return gstAmount
  }

  basicAmount(): number {
    this.basicValue = Number(Number(this.currentWeighBridge.weighmentChargesRs)*100/118);
    return this.basicValue
  }

}



