import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Router } from "@angular/router";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-toll-gate-cash-overall-print",
  templateUrl: "./toll-gate-cash-overall-print.component.html",
  styleUrls: ["./toll-gate-cash-overall-print.component.css"]
})
export class TollGateOverallPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  reportQr: string;
  currentTollGateValue: TollGate [] = [];
  currentDate: string;
  venDetails: TollGate [] = [];
  autoDetails: TollGate [] = [];
  truckDetails: TollGate [] = [];
  vanTotalAmount: number = 0;
  autoTotalAmount: number = 0;
  truckTotalAmount: number = 0;
  totalAmount: number = 0;
  totalAmountInWords: string = "";
  irmNumber: string = "";
  basicAmountValue: number = 0;
  gstValue: number = 0;
  truckBillNo: string[] = [];
  vanBillNo: string[] = [];
  autoBillNo: string[] = [];
  billDate: number;
  cirmNo: string;
  private intervalSubscription: Subscription;

  constructor(private apiService: ApiService, private datePipe: DatePipe, private toaster: ToastrService,
              private router: Router, private dataService: PrintDataService) {
    this.currentDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
  }

  ngOnInit(): void {
    // After status updated, that data only get for invoice
    if (this.dataService.tollGateDetails.length > 0) {
      this.currentTollGateValue = this.dataService.tollGateDetails;
      this.cirmNo = this.currentTollGateValue[0].cirmNo;
      this.filterCashValues();
      const printStyle = `
      @media print {
        @page {
          size: A5; /* Set your desired page size here */
        }
      }
    `;
      this.intervalSubscription = interval(2000).subscribe(() => {
        this.print(printStyle);
      });
    }
    // Based on selected invoice number print the invoice details
    else {
      this.cirmNo = sessionStorage.getItem("cirmNo");
      this.getAllPassDetails(this.cirmNo);
    }
  }

 // Get All tollgate details based on cirm invoice number
  getAllPassDetails(cirmNo) {
    this.apiService.getTollgateDetailsByCirmNo(cirmNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTollGateValue = data;
        this.filterCashValues();
        const printStyle = `
      @media print {
        @page {
          size: A5; /* Set your desired page size here */
        }
      }
    `;
        this.intervalSubscription = interval(2000).subscribe(() => {
          this.print(printStyle);
        });
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Filter the amount & count values based on vehicle
  filterCashValues() {
    this.venDetails = this.currentTollGateValue.filter(item => item.tollgateVehicleDetails?.vehType === "Van");
    this.autoDetails = this.currentTollGateValue.filter(item => item.tollgateVehicleDetails?.vehType === "Auto");
    this.truckDetails = this.currentTollGateValue.filter(item => item.tollgateVehicleDetails?.vehType === "Truck");
    this.truckBillNo = this.truckDetails.map(item => item.irmNo.split("/")[2]);
    this.vanBillNo = this.venDetails.map(item => item.irmNo.split("/")[2]);
    this.autoBillNo = this.autoDetails.map(item => item.irmNo.split("/")[2]);
    const vanValues = this.venDetails.map(item => item.charges);
    this.vanTotalAmount = vanValues.reduce((total, charge) => total + charge, 0);
    const autoValues = this.autoDetails.map(item => item.charges);
    this.autoTotalAmount = autoValues.reduce((total, charge) => total + charge, 0);
    const truckValues = this.truckDetails.map(item => item.charges);
    this.truckTotalAmount = truckValues.reduce((total, charge) => total + charge, 0);
    this.totalAmount = Number((this.vanTotalAmount + this.autoTotalAmount + this.truckTotalAmount).toFixed(2));
    this.totalAmountInWords = this.numberToWords(Math.floor(this.totalAmount));
    const irm = this.currentTollGateValue.map(item => item.irmNo);
    this.irmNumber = irm[0];
    this.billDate = this.currentTollGateValue[this.currentTollGateValue.length - 1].createdAt * 1000;
  }

  // For amount letter format
  numberToWords(number: number): string {
    const units: string[] = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens: string[] = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens: string[] = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    function convertThreeDigits(num: number): string {
      let result = "";
      if (num >= 100) {
        result += units[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num >= 20) {
        result += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }
      if (num > 0) {
        if (num < 10) {
          result += units[num] + " ";
        } else {
          result += teens[num - 10] + " ";
        }
      }
      return result.trim();
    }

    const crores = Math.floor(number / 10000000);
    const millions = Math.floor(number % 10000000 / 100000);
    const lakhs = Math.floor(number % 100000 / 1000);
    const thousands = Math.floor(number % 1000);
    const hundreds = Math.floor(number % 100);

    let result = "";

    if (crores > 0) {
      result += convertThreeDigits(crores) + " Crore ";
    }

    if (millions > 0) {
      result += convertThreeDigits(millions) + " Lakh ";
    }

    if (lakhs > 0) {
      result += convertThreeDigits(lakhs) + " Thousand ";
    }

    if (thousands > 0) {
      result += convertThreeDigits(thousands) + " ";
    }

    if (result === "") {
      return "zero Only";
    }

    return result;
  }


  // Currency format function
  formatCharges(charges: number): string {
    let formattedCharges = charges.toFixed(2);
    const [integerPart, decimalPart] = formattedCharges.split(".");
    formattedCharges = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedCharges += "." + decimalPart.padEnd(2, "0");
    return formattedCharges;
  }

  // Get basic amount calculation
  basicAmount(): string {
    if (this.currentTollGateValue.length > 0) {
      const basicAmount = Number(this.totalAmount * 100 / 118);
      this.basicAmountValue = Number(basicAmount.toFixed(2));
      const [integerPart, decimalPart] = String(this.basicAmountValue).split(".");
      let formattedCharges = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      formattedCharges += "." + decimalPart.padEnd(2, "0");
      return formattedCharges;
    }
  }

  // Get GST amount calculation
  gst(): string {
    if (this.currentTollGateValue.length > 0) {
      const gstAmount = Number(Number(this.basicAmountValue) * 9 / 100);
      this.gstValue = Number(gstAmount.toFixed(2));
      const [integerPart, decimalPart] = String(this.gstValue).split(".");
      let formattedCharges = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      formattedCharges += "." + decimalPart.padEnd(2, "0");
      return formattedCharges;
    }
  }

  // Get round off amount calculation
  roundedOff(): number {
    const roundSum = Number(Number(this.basicAmountValue) + (2 * Number(this.gstValue)));
    const roundedOff = Number(this.totalAmount - roundSum).toFixed(2);
    return Number(roundedOff);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('cirmNo');
  }

  // Print the receipt & navigate after
  print(printStyle: string) {
    const originalTitle = document.title;
    document.title = this.cirmNo.split("/")[1] + " " + this.cirmNo.split("/")[2];
    const style = document.createElement("style");
    style.innerHTML = printStyle;
    document.head.appendChild(style);
    window.print();
    document.head.removeChild(style);
    document.title = originalTitle;
    this.intervalSubscription.unsubscribe();

    setTimeout(() => {
      if (!!sessionStorage.getItem("cirmNo")) {
        this.router.navigate(["/main/tollGateCash"]);
      } else {
        this.router.navigate(["/main/tollGateView"]);
      }
    });
  }
}


