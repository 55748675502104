<div class="col-md-12">
  <h3 class="ml-2">Delivery Details Update</h3>
</div>
<div class="container-fluid">
  <form [formGroup]="deliveryForm">
    <div class="row mt-2">
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Delivery Order No" disabled formControlName="deliverOrderNo">
          <label>Delivery Order No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.deliverOrderNo" class="text-danger">{{deliveryFormErrors.deliverOrderNo}}</span>
      </div>
      <!--<div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Date Time" formControlName="deliveryDate">
          <label class="form-label">Date Time<span style="color:red;">*</span></label>
        </div>
      </div>-->
      <div class="col-md-3 mt-2">
        <!--<div class="form-floating">
          <input type="text" class="form-control" placeholder="Commodity Name" formControlName="commodityName">
          <label class="form-label">Commodity<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.commodity" class="text-danger">{{deliveryFormErrors.commodity}}</span>-->
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Commodity Name" formControlName="commodityName">
          <label class="form-label">Commodity Name</label>
          <span *ngIf="deliveryFormErrors.commodity" class="text-danger">{{deliveryFormErrors.commodity}}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Bags of" formControlName="bagsOf">
          <label class="form-label">Bags of<span style="color:red;">*</span></label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Weighing" formControlName="weighing">
          <label class="form-label">Weighing<span style="color:red;">*</span></label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Warehouse Receipt No" formControlName="warehouseReceiptNo">
          <label>Warehouse Receipt No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.warehouseReceiptNo" class="text-danger">{{deliveryFormErrors.warehouseReceiptNo}}</span>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Date Of Deposit" formControlName="date">
          <label class="form-label">Date Of Deposit<span style="color:red;">*</span></label>
          <span *ngIf="deliveryFormErrors.date" class="text-danger">{{deliveryFormErrors.date}}</span>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Deposited by" formControlName="depositorName">
          <label class="form-label">Deposited by<span style="color:red;">*</span></label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="IRM Receipt No" formControlName="irmReceiptNo">
          <label>IRM Receipt No<span style="color:red;">*</span></label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Date Of Deposit" formControlName="deliveryDate">
          <label class="form-label">Date Of Delivery<span style="color:red;">*</span></label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Vehicle No" formControlName="vehicleNo">
          <label class="form-label">Vehicle No<span style="color:red;">*</span></label>
        </div>
      </div>
      <!--<div class="col-md-3 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select"
                  formControlName="billPeriodType">
            <option disabled selected hidden value="">Select bill period</option>
            <option value="Day wise">Day wise</option>
            <option value="Month wise">Month wise</option>
          </select>
          <label>Bill Period Type<span style="color:red;">*</span></label>
          <span *ngIf="deliveryFormErrors.billPeriodType" class="text-danger">{{deliveryFormErrors.billPeriodType}}</span>
        </div>
      </div>-->
    </div>
    <hr style="width:100%; text-align:left;  border-width:3px; border-color: black;">
    <div class="row mt-2">
      <!--<div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Storage Charges Invoice No" formControlName="storageChargesInvoiceNo">
          <label>Storage Charges Invoice No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.storageChargesInvoiceNo" class="text-danger">{{deliveryFormErrors.storageChargesInvoiceNo}}</span>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Date" formArrayName="atOfficeDate">
          <label class="form-label">Date<span style="color:red;">*</span></label>
        </div>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Amount" formControlName="amount">
          <label class="form-label">Amount<span style="color:red;">*</span></label>
        </div>
      </div>-->
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter godown number" formControlName="godownNo">
          <label>Godown No<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.godownNo" class="text-danger">{{deliveryFormErrors.godownNo}}</span>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter stack no" formControlName="stackNo">
          <label>Stack No<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.stackNo" class="text-danger">{{deliveryFormErrors.stackNo}}</span>
      </div>
    </div>
    <div class="row justify-content-center mt-4 mb-5">
      <button type="submit" class="btn btn-success col-md-1" (click)="formValidation()">Update</button>
    </div>
  </form>
</div>
