import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { ConfigService } from "@/config.service";
import { AftcMembershipDetails } from "@/model/membershipDetails/aftc-membershipDetails";
import { PaymentInitModal } from "pg-test-project";
import * as React from "react";
import { createRoot } from "react-dom/client";
import * as uuid from 'uuid';
import { DatePipe } from "@angular/common";


@Component({
  selector: "app-basic-aftc-membership-form",
  templateUrl: "./basic-aftc-membership-form.component.html",
  styleUrls: ["./basic-aftc-membership-form.component.scss"]
})
export class BasicAftcMembershipForm implements OnInit {
  @ViewChild("sabPaisaSdk", { static: true }) containerRef!: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  fileList: File[] = [];
  formData = new FormData();
  memberOfAftc: string;
  burron: false;
  emailIdOTP = false;
  email: string;
  memeberId: number;
  firstValue: string = '';
  secondValue: string = '';
  thirdValue: string = '';
  fourthValue: string = '';
  onFarmer: boolean = true;
  memberId: number = 0;
  getMemberDetails: AftcMembershipDetails;
  allMembershipDetails: AftcMembershipDetails[] = [];
  onShow: boolean = true;
  readOnly: boolean = false;
  cureetDtate = new Date();
  reactRoot: any = React.createRef();
  paymentMethods : string = '';
  // Global key for canMakepayment cache.
  canMakePaymentCache = 'canMakePaymentCache';
  savedMembershipDetails: AftcMembershipDetails ;
  isLoading = false;  
  preventBackNavigation() {
    history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      if (this.isLoading) {
        history.pushState(null, '', window.location.href);
        alert('Back navigation is disabled while loading.');
      }
    };

    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (this.isLoading) {
        event.preventDefault();
        event.returnValue = 'The page is still loading. Are you sure you want to leave?';
      }
    };
  }

  enableBackAndReload() {
    window.onpopstate = null;
    window.onbeforeunload = null;
  }
  

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private toastr: ToastrService, private datePipe: DatePipe,
    private configService: ConfigService, private route: ActivatedRoute,) {

  }
  termscondition: boolean = false;


  membership = new UntypedFormGroup({
    applicantName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    applicantRole: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    organizationName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    orgType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    postalAddress: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phoneNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    contactPerson: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    whatsappNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    udyamRegistrationNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    udyamCertificatePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gstNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gstCertificatePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    panNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    panImagePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    aadhaarNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    aadhaarImagePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    businessType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    businessName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    memberOfAftc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    annualTurnoverGroup: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    forum: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    acknowledgement: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    status: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    city: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    memberPeriod: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    countryCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    paymentStatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    admissionFee: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    updateAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    pincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  });

  public membershipError = {
    applicantName: '',
    postalAddress: ' ',
    memberPeriod: ' ',
    whatsappNo: ' ',
    emailId: '',
    orgType: '',
    contactPerson: '',
    pincode: '',
  };

  onPhoneNumber(event: any) {
    const value = event.target.value
    this.membership.get("whatsappNo").setValue(value)
  }


  membershipValidation(type: string) {
    const value = type;
    this.paymentMethods = type
    console.log(value)
    this.membershipError.applicantName = "";
    this.membershipError.memberPeriod = "";
    this.membershipError.whatsappNo = "";
    this.membershipError.postalAddress = "";
    this.membershipError.orgType = "";
    this.membershipError.contactPerson = "";
    this.membershipError.pincode = "";


    let hasError = false

    if (this.membership.get("applicantName")?.invalid) {
      this.membershipError.applicantName = "* Applicant's Name";
      hasError = true
    }
    if (this.membership.get("memberPeriod")?.invalid) {
      this.membershipError.memberPeriod = "* Select a Member Period Type";
      hasError = true
    }
    if (this.membership.get("postalAddress")?.invalid) {
      this.membershipError.postalAddress = "* Enter a Postal Address";
      hasError = true
    }
    if (this.membership.get("whatsappNo")?.invalid) {
      if (this.membership.get('whatsappNo').errors.required) {
        this.membershipError.whatsappNo = "* Enter a Whatsapp Number";
      }
      if (this.membership.get('whatsappNo').errors.pattern) {
        this.membershipError.whatsappNo = "* Please, Enter 10 digit Valid Whatsapp Number.";
      }
      hasError = true;
    }
    if (this.membership.get("orgType")?.invalid) {
      this.membershipError.orgType = "* Select the Type of Organization";
      hasError = true
    }
    if (this.membership.get("contactPerson")?.invalid) {
      this.membershipError.contactPerson = "* Enter a Authorised Representative Name";
      hasError = true
    }
    if (this.membership.get("pincode")?.invalid) {
      this.membershipError.pincode = "* Enter a Pincode"
    }
    if (!hasError) {
      this.saveMembershipDetails()
    }
  }

  ngOnInit() {
    this.isLoading = true;
    this.spinner.show();
    this.preventBackNavigation();
    setTimeout(() => {
      this.isLoading = false;
      this.spinner.hide();
      this.enableBackAndReload();
    }, 15000); 
    this.isLoading = true;
    this.route.queryParams.subscribe((params) => {
      const ulrParam = params["id"];

      const encResponse = params["encResponse"];

      if (ulrParam != null && encResponse == undefined) {
        this.memberId = ulrParam;
        this.getAllMembership();
        this.readOnly = true;
      } else if (ulrParam != null && encResponse != null) {
        let data1 = {
          encrytedData: encResponse,
          membershipDetailsId: Number(localStorage.getItem("userId")),
          isSendLink: true,
        };
        this.spinner.show();
        console.log("firstpayemnt",data1);
        this.apiServices.updatePaymentStatus(data1)
          .subscribe((data) => {
            this.spinner.hide()
            if (data.paymentStatus == "SUCCESS") {
              this.alertShow();
            } else {
              this.errorAlertShow()
            }
            localStorage.removeItem("userId")
          }, (error: any) => {
            this.spinner.hide()
            console.log("Something Went wrong");
            this.toastr.error("Something went wrong , Please try again later....")
          })
      } else {
        const date = this.datePipe.transform(this.cureetDtate, 'yyyy-MM-dd');
        const dateFItler = "2025-04-01";
        if (dateFItler <= date) {
          this.membership.controls['admissionFee'].setValue('Admission Fee')
        } else {
          this.membership.get('admissionFee').disable()
        }

        this.membership.controls['countryCode'].setValue("+91 (India)")
        if (encResponse != null) {
          let data1 = {
            encrytedData: encResponse,
            membershipDetailsId: Number(localStorage.getItem("userId")),
            isSendLink: true,
          };
          console.log("SecondPaymentpayemnt",data1);
          this.spinner.show();
          this.apiServices.updatePaymentStatus(data1)
            .subscribe((data) => {
              this.spinner.hide()
              if (data.paymentStatus == "SUCCESS") {
                this.alertShow();
              } else {
                this.errorAlertShow()
              }
              localStorage.removeItem("userId")
            }, (error: any) => {
              this.spinner.hide();
              console.log("Something Went wrong");
              this.toastr.error("Something went wrong , Please try again later....")
            })
        }
      }
    });

  }

  enableFunction(event: any) {
    const value = event.target.value;
      if (value == "Long Term Membership") {
        this.membership.controls['amount'].setValue(2000)
      } else if (value == "Short Term Membership") {
        this.membership.controls['amount'].setValue(500)
      }
      else {
        this.membership.controls['amount'].setValue(1)
      }
    }



  getMemberDetailsId() {
    this.apiServices.getMemberDetailsid(this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getMemberDetails = data;
        this.membership.controls['applicantName'].setValue(this.getMemberDetails.applicantName)
        this.membership.controls['memberPeriod'].setValue(this.getMemberDetails.memberPeriod)
        this.membership.controls['phoneNo'].setValue(this.getMemberDetails.phoneNo)
        this.membership.controls['whatsappNo'].setValue(this.getMemberDetails.whatsappNo)
        this.membership.controls['contactPerson'].setValue(this.getMemberDetails.contactPerson)
        this.membership.controls['postalAddress'].setValue(this.getMemberDetails.postalAddress)
        this.membership.controls['emailId'].setValue(this.getMemberDetails.emailId)
        this.membership.controls['orgType'].setValue(this.getMemberDetails.orgType)
        this.membership.controls['admissionFee'].setValue(this.getMemberDetails.admissionFee)
        this.membership.controls['pincode'].setValue(this.getMemberDetails.pincode)
        this.membership.controls['countryCode'].setValue("+91 (India)");
        if (this.getMemberDetails.emailId == 'contact@agrofoodchamber.com') {
          this.onFarmer = false;
        }
        for (let test of this.allMembershipDetails) {
          if (test.whatsappNo == this.getMemberDetails.whatsappNo && test.orgType == this.getMemberDetails.orgType) {
            if (test.paymentStatus == 'SUCCESS') {
              this.onShow = false;
            } else if (test.paymentStatus == 'FAILED') {
              this.onShow = true;
            }
          }
        }
      }, (error: any) => {
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }

  getAllMembership() {
    this.apiServices.getAllMembershipDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.allMembershipDetails = data;
        this.getMemberDetailsId();
      }, (error: any) => {
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }

  // onSubmit() {
  //   this.membershipValidation("Gate Ways");
  // }

  onShowFarmers(event: any) {
    const value = event.target.value;
    if (value == 'Farmer' || value == 'Self Help Group') {
      this.onFarmer = false;
    } else {
      this.onFarmer = true;
    }
  }

  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  onlyAllowedCharacters(event: KeyboardEvent): void {
    const disallowedChars: RegExp = /^[1-9]$/;
    const specialKeys: Set<string> = new Set([
      'Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft', 'Delete', 'Enter'
    ]);
    if (specialKeys.has(event.key)) {
      return;
    }

    if (disallowedChars.test(event.key)) {
      event.preventDefault();
      return;
    }
  }

  saveMembershipDetails() {
    this.spinner.show()
    if (this.membership.get('orgType').value == 'Farmer' || this.membership.get('orgType').value == 'Self Help Group' || this.membership.get('emailId').value == '') {
      this.membership.controls['emailId'].setValue("contact@agrofoodchamber.com")
    }
    const date = this.datePipe.transform(this.cureetDtate, 'dd-MM-yyy');
    this.membership.controls['createdAt'].setValue(date)
    this.membership.controls['countryCode'].setValue("91")
    this.membership.controls['status'].setValue('Approved');
    const formData = new FormData();
    const jsonData = this.membership.value;
    formData.append('membershipDetails', new Blob([JSON.stringify(jsonData)], { type: 'application/json' }));
    this.apiServices.saveMembership(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.savedMembershipDetails = data;
        this.memeberId = data.membershipDetailsId;
        localStorage.setItem('userId', this.memeberId.toString());
        this.spinner.hide();
        if(this.paymentMethods === 'GPay'){
          this.onBuyClicked();
        }else{
          this.showPayment(data)
        }
      }, (error: any) => {
        this.spinner.hide();
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }

  showPayment(data: AftcMembershipDetails) {
    this.memeberId = data.membershipDetailsId;
    if (data.memberPeriod == 'Short Term Membership') {
      if (this.membership.get('admissionFee').value == 'Admission Fee') {
        var amount = '1500'
      } else {
        var amount = '500'
      }
      let config: any = {
        env: "prod",
        clientTxnId: uuid.v4(),
        payerName: data.applicantName,
        payerEmail: data.emailId,
        payerMobile: data.whatsappNo,
        payerAddress: data.postalAddress,
        amount: amount,
        isOpen: false,
        clientCode: this.configService.getConfig().CLIENT_ID,
        transUserName: this.configService.getConfig().PG_USERNAME,
        transUserPassword: this.configService.getConfig().PG_PASSWORD,
        authkey: this.configService.getConfig().PG_AUTH_KEY,
        authiv: this.configService.getConfig().PG_AUTH_VALUE,
        sabPaisaUrl: this.configService.getConfig().PG_BASE_URL,
        callbackUrl: 'https://aftc.varnik.cloud/#/basic-chamber-registration',
        udf20: data.membershipDetailsId,
      }
      const isOpen = true
      this.render(isOpen, config)
    } else if (data.memberPeriod == "Long Term Membership") {
      if (this.membership.get('admissionFee').value == 'Admission Fee') {
        var amount = '3000'
      } else {
        var amount = '2000'
      }
      let config: any = {
        env: "prod",
        clientTxnId: uuid.v4(),
        payerName: data.applicantName,
        payerEmail: data.emailId,
        payerMobile: data.whatsappNo,
        payerAddress: data.postalAddress,
        amount: amount,
        isOpen: false,
        clientCode: this.configService.getConfig().CLIENT_ID,
        transUserName: this.configService.getConfig().PG_USERNAME,
        transUserPassword: this.configService.getConfig().PG_PASSWORD,
        authkey: this.configService.getConfig().PG_AUTH_KEY,
        authiv: this.configService.getConfig().PG_AUTH_VALUE,
        sabPaisaUrl: this.configService.getConfig().PG_BASE_URL,
        callbackUrl: 'https://aftc.varnik.cloud/#/basic-chamber-registration',
        udf20: data.membershipDetailsId,
      }
      const isOpen = true
      this.render(isOpen, config)
    }
    else {
      let config: any = {
        env: "prod",
        clientTxnId: uuid.v4(),
        payerName: data.applicantName,
        payerEmail: data.emailId,
        payerMobile: data.whatsappNo,
        payerAddress: data.postalAddress,
        amount: '1',
        isOpen: false,
        clientCode: this.configService.getConfig().CLIENT_ID,
        transUserName: this.configService.getConfig().PG_USERNAME,
        transUserPassword: this.configService.getConfig().PG_PASSWORD,
        authkey: this.configService.getConfig().PG_AUTH_KEY,
        authiv: this.configService.getConfig().PG_AUTH_VALUE,
        sabPaisaUrl: this.configService.getConfig().PG_BASE_URL,
        callbackUrl: 'https://aftc.varnik.cloud/#/basic-chamber-registration',
        udf20: data.membershipDetailsId,
      }
      const isOpen = true
      this.render(isOpen, config)
    }
  }

  updateMembersGPayDetails(jsonData: any) {
    this.spinner.show();
    this.apiServices.updateMembersGPay(jsonData)
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.spinner.hide()
      if (data.paymentStatus == "SUCCESS") {
        this.alertShow();
      } else {
        this.errorAlertShow()
      }
      localStorage.removeItem("userId")
    }, (error: any) => {
      this.spinner.hide();
      this.toastr.error("Something went wrong , Please try again later....")
    })
  }

  private render(isOpen: boolean, config: any) {
    this.reactRoot.render(
      React.createElement(PaymentInitModal, { ...config, isOpen }))
  }

  ngAfterViewInit() {
    this.reactRoot = createRoot(this.containerRef.nativeElement);
    this.render(false, '');
  }


  alertShow() {
    Swal.fire({
      title: "Details Saved Successfully, Kindly check your Whatsapp",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        location.replace("https://agrofoodchamber.com/")
      }
    });
  }


  moveFocus(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');
    if (length == maxlength) {
      if (n != "") {
        n.focus();
      }
    }
    if (e.key == "Backspace") {
      if (p != "") {
        p.focus();
      }
    }
  }


  errorAlertShow() {
    Swal.fire({
      title: "Payment Failed, Kindly check with Chamber Administrative",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        location.replace("https://agrofoodchamber.com/")
      }
    });
  }

  //Google pay Integration
  /**
   * Read data for supported instruments from input from.
   */
   readSupportedInstruments() {
    let formValue = {};
    formValue['pa'] = 'boim-835115000015@boi'
    formValue['pn'] = 'AGRO FOOD CHAMBER OF COMMERCE AND INDUSTRY'
    formValue['tn'] = 'Full membership code'
    formValue['mc'] = '1234'
    formValue['tr'] = 'fullpay-gpay'
    formValue['tid'] = 'wertyui2345678sdfgh'
    formValue['url'] = 'https://dev2.aftc.in/#/basic-chamber-registration'
    return formValue;
  }

  /**
   * Launches payment request.
   */
   onBuyClicked() {
    if (!window.PaymentRequest) {
      console.log('Web payments are not supported in this browser.');
      return;
    }

    let formValue = this.readSupportedInstruments();

    const supportedInstruments = [
      {
        supportedMethods: 'https://tez.google.com/pay',
        data: {
          pa: 'boim-835115000015@boi',
          pn: 'AGRO FOOD CHAMBER OF COMMERCE AND INDUSTRY',
          // pa: 'reminjosej7@okicici',
          // pn: 'Remin Jose Test 2',
          tr: 'fullpay-gpay',  // your custom transaction reference ID
          url: 'https://dev2.aftc.in/#/basic-chamber-registration',
          mc: '1234', // your merchant category code
          tn: 'Full membership code',
          gstBrkUp: 'GST:16.90|CGST:08.45|SGST:08.45', // GST value break up
          invoiceNo: 'BillRef123', // your invoice number
          invoiceDate: '2019-06-11T13:21:50+05:30', // your invoice date and time
          gstIn: '29ABCDE1234F2Z5', // your GSTIN
        },
      }
    ];
    const details = {
      total: {
        label: 'Total',
        amount: {
          currency: 'INR',
          value: this.savedMembershipDetails.amount,
        },
      },
      displayItems: [
        {
          label: 'Original amount',
          amount: {
            currency: 'INR',
            value: this.savedMembershipDetails.amount,
          },
        },
      ],
    };

    let request = null;
    try {
      request = new PaymentRequest(supportedInstruments, details);
    } catch (e) {
      console.log('Payment Request Error: ' + e.message);
      return;
    }
    if (!request) {
      console.log('Web payments are not supported in this browser.');
      return;
    }

    var canMakePaymentPromise = this.checkCanMakePayment(request);
    canMakePaymentPromise
      .then((result) => {
        this.showPaymentUI(request, result);
      })
      .catch((err) => {
        console.log('Error calling checkCanMakePayment: ' + err);
      });
  }

  /**
   * Checks whether can make a payment with Tez on this device. It checks the
   * session storage cache first and uses the cached information if it exists.
   * Otherwise, it calls canMakePayment method from the Payment Request object and
   * returns the result. The result is also stored in the session storage cache
   * for future use.
   *
   * @private
   * @param {PaymentRequest} request The payment request object.
   * @return {Promise} a promise containing the result of whether can make payment.
   */
  checkCanMakePayment(request) {
    // Checks canMakePayment cache, and use the cache result if it exists.
    if (sessionStorage.hasOwnProperty(this.canMakePaymentCache)) {
      return Promise.resolve(JSON.parse(sessionStorage[this.canMakePaymentCache]));
    }

    // If canMakePayment() isn't available, default to assuming that the method is
    // supported.
    var canMakePaymentPromise = Promise.resolve(true);

    // Feature detect canMakePayment().
    if (request.canMakePayment) {
      canMakePaymentPromise = request.canMakePayment();
    }

    return canMakePaymentPromise
      .then((result) => {
        // Store the result in cache for future usage.
        sessionStorage[this.canMakePaymentCache] = result;
        return result;
      })
      .catch((err) => {
        console.log('Error calling canMakePayment: ' + err);
      });
  }


  /**
   * Show the payment request UI.
   *
   * @private
   * @param {PaymentRequest} request The payment request object.
   * @param {Promise} canMakePayment The promise for whether can make payment.
   */
  showPaymentUI(request, canMakePayment) {
    // Redirect to play store if can't make payment.
    if (!canMakePayment) {
      this.redirectToPlayStore();
      return;
    }

    // Set payment timeout.
    let paymentTimeout = window.setTimeout(function() {
      window.clearTimeout(paymentTimeout);
      request.abort()
        .then(function() {
          console.log('Payment timed out after 20 minutes.');
        })
        .catch(function() {
          console.log('Unable to abort, user is in the process of paying.');
        });
    }, 20 * 60 * 1000); /* 20 minutes */

    request.show()
      .then(function(instrument) {
        window.clearTimeout(paymentTimeout);
        console.log("success")
          console.log(instrument);  // Handle response from browser.
          const transactionDto = {
            status: instrument.Status,
            txtnId: instrument.txnId,
            requestId: instrument.requestId,
            amount: this.savedMembershipDetails.amount,
            payerName: this.savedMembershipDetails.applicantName,
            payerEmail: this.savedMembershipDetail.emailId,
            payerMobile: this.savedMembershipDetail.whatsappNo,
            membershipDetailsId : this.savedMembershipDetails.membershipDetailsId,
            isSendLink: true,
          }
          this.updateMembersGPayDetails(transactionDto);
      })
      .catch(function(err) {
        console.log(err);
      });
  }


  /** Redirect to PlayStore. */
  redirectToPlayStore() {
    if (confirm('Google pay not installed, go to play store and install?')) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user'
    }
  }



}

