<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Renter Form</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Blank Page</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <form (ngSubmit)="onSubmit()" [formGroup]="renterSheet">

      <div class="row">


      <div class="col-md-4">
        <div class="form-group">
          <div><label for="renterFirstName">FirstName</label></div>
          <input
            id="renterFirstName"
            type="text"
            class="form-control"
            placeholder="FirstName"
            formControlName="renterFirstName"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <div><label for="renterLastName">LastName</label></div>
          <input
            id="renterLastName"
            type="text"
            class="form-control"
            placeholder="LastName"
            formControlName="renterLastName"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">Legal Company Name</label></div>
          <input
            id="renterLegalCompanyName"
            type="text"
            class="form-control"
            placeholder="Legal Company Name"
            formControlName="renterLegalCompanyName"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">PAN</label></div>
          <input
            id="renterPAN"
            type="text"
            class="form-control"
            placeholder="PAN"
            formControlName="renterPAN"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">CIN</label></div>
          <input
            id="renterCIN"
            type="text"
            class="form-control"
            placeholder="CIN"
            formControlName="renterCIN"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">GSTN</label></div>
          <input
            id="renterGSTN"
            type="text"
            class="form-control"
            placeholder="GSTN"
            formControlName="renterGSTN"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">PhoneNumber</label></div>
          <input
            id="renterPhoneNumber"
            type="text"
            class="form-control"
            placeholder="PhoneNumber"
            maxlength="10"
            appOnlyNumber
            formControlName="renterPhoneNumber"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">Email</label></div>
          <input
            id="renterEmail"
            type="text"
            class="form-control"
            placeholder="Email"
            formControlName="renterEmail"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">Whatsapp</label></div>
          <input
            id="renterWhatsapp"
            type="text"
            class="form-control"
            placeholder="Whatsapp"
            formControlName="renterWhatsapp"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">Street1</label></div>
          <input
            id="renterStreet1"
            type="text"
            class="form-control"
            placeholder="Street1"
            formControlName="renterStreet1"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">Street2</label></div>
          <input
            id="renterStreet2"
            type="text"
            class="form-control"
            placeholder="Street2"
            formControlName="renterStreet2"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">Village</label></div>
          <input
            id="renterVillage"
            type="text"
            class="form-control"
            placeholder="Village"
            formControlName="renterVillage"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">City</label></div>
          <input
            id="renterCity"
            type="text"
            class="form-control"
            placeholder="City"
            formControlName="renterCity"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">State</label></div>
          <input
            id="renterState"
            type="text"
            class="form-control"
            placeholder="State"
            formControlName="renterState"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <div><label for="">Pincode</label></div>
          <input
            id="renterPincode"
            type="text"
            class="form-control"
            placeholder="Pincode"
            appOnlyNumber
            formControlName="renterPincode"
          />
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button class="button" type="submit">Save</button>
        </div>
      </div>
    </form>
  </div>
</section>
