<nav class="navbar navbar-expand-lg bg-light fixed-top">
  <div class="container-fluid">
    <h3 class="ml-3">AFTC Toll Gate</h3>
  </div>
</nav>
<div class="mt-3 row"></div>
<div class="container-fluid">
  <div class="col-md-12 mt-5">
  <h3 class="text-center">Camera Records</h3>
  </div>
  <div class="row">
  <div class="col-md-7"></div>
  <div class="col-md-3">
    <div class="div mb-2 mt-3">
      <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
    </div>
  </div>
  </div>
  <div class="container">
    <div class="table-responsive" style="height: 380px">
      <table class="table">
        <thead class="rounded sticky-top text-center" style="background: #7defb5">
        <tr class="sticky-top">
          <th>No</th>
          <th>Vehicle Number</th>
          <th>Time In</th>
          <th>Time Out</th>
          <th>Camera</th>
        </tr>
        </thead>
        <tbody *ngFor="let aftc of this.cameraRecords | filter: searchText;let i = index">
        <tr class="text-center">
          <td>{{i + 1}}</td>
          <td>{{aftc.labels.toUpperCase()}}</td>
          <td>
            <p *ngIf="aftc.monitorId == 3">{{aftc.formattedDate}}</p>
            <p *ngIf="aftc.monitorId != 3">-</p>
          </td>
          <td>
            <p *ngIf="aftc.monitorId == 4">{{aftc.formattedDate}}</p>
            <p *ngIf="aftc.monitorId != 4">-</p>
          </td>
          <td>
            <p *ngIf="aftc.monitorId == 3">Camera 1</p>
            <p *ngIf="aftc.monitorId == 4">Camera 2</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<footer class="text-center" style="position: fixed; bottom: 0; width: 100%; background-color: #b3c9ff">
  <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
    <span>Copyright &copy; {{ currentYear }}  </span>
    <a href="https://varnikcloud.com/"
       style="margin: 0; text-decoration: none"
       target="_blank"
    ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
    <span>. </span>
    <span>  All rights reserved.</span>
  </div>
</footer>
