import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import {
  WarehouseStockDeposit
} from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";


@Component({
  selector: "app-warehouse-deposit-print",
  templateUrl: "./warehouse-deposit-print.component.html",
  styleUrls: ["./warehouse-deposit-print.component.scss"]
})
export class WarehouseDepositPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  depositorDetails: WarehouseStockDeposit | undefined;
  currentDate: string;
  private intervalSubscription: Subscription;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private toaster: ToastrService, private datePipe: DatePipe, private router: Router) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit() {
    const depositorId = sessionStorage.getItem("depositorId");
    this.getDepositorDetailsById(depositorId);
  }

  ngOnDestroy() {
    sessionStorage.removeItem("depositorId");
  }

  getDepositorDetailsById(depositorId) {
    this.apiServices.getDepositDetailsById(depositorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositorDetails = data;
        this.intervalSubscription = interval(2000).subscribe(() => {
          this.print();
        });
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  print() {
    const originalTitle = document.title;
    document.title = this.depositorDetails.depositApplicationNo;
    window.print();
    document.title = originalTitle;
    this.intervalSubscription.unsubscribe();
    setTimeout(() => {
      this.router.navigate(["/main/frontOffice"]);
    });
  }
}
