<div class="col-md-12">
  <h3 class="ml-2">Deposit Details Update</h3>
</div>
<div class="container-fluid">
  <form [formGroup]="depositForm">
    <div class="row mt-2">
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Deposit Application No" disabled
                 formControlName="depositApplicationNo">
          <label>Deposit Application No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.depositApplicationNo"
              class="text-danger">{{depositFormErrors.depositApplicationNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Depositor Name" formControlName="depositorName">
          <label class="">Depositor Name<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.depositorName" class="text-danger">{{depositFormErrors.depositorName}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Commodity" formControlName="commodity">
          <label>Commodity<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.commodity" class="text-danger">{{depositFormErrors.commodity}}</span>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Godown No" formControlName="godownNo">
          <label>Godown No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.godownNo" class="text-danger">{{depositFormErrors.godownNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Stack No" formControlName="stackNo">
          <label>Stack No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.stackNo" class="text-danger">{{depositFormErrors.stackNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Quality Report Value" formControlName="qualityReportValue">
          <label>Quality Report Value<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.qualityReportValue" class="text-danger">{{depositFormErrors.qualityReportValue}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-select" formControlName="qualityReportStatus">
            <option selected value="">Select Status</option>
            <option value="Accepted">Accepted</option>
            <option value="Not accepted">Not accepted</option>
          </select>
          <label>Select Status</label>
        </div>
        <span *ngIf="depositFormErrors.qualityReportStatus" class="text-danger">{{depositFormErrors.qualityReportStatus}}</span>
      </div>
      <div class="col-lg-6  col-md-3 mt-2">
        <div class="form-floating">
          <textarea type="text" class="form-control textCap" placeholder="Quality Report Comments" formControlName="qualityReportComments"></textarea>
          <label>Quality Report Comments<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.qualityReportComments" class="text-danger">{{depositFormErrors.qualityReportComments}}</span>
      </div>
    </div>

    <div class="row mt-2">

    </div>

    <div class="row mt-2">

    </div>

    <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2">
      <button type="submit" class="btn btn-success col-md-1" (click)="formValidation(depositForm)">Update</button>
    </div>
  </form>
</div>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <div class="loader"></div>
</ngx-spinner>
