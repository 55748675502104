import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { DepotVehicleDetails } from "@/model/depotVehicleDetails/depotVehicleDetails";
import { ToastrService } from "ngx-toastr";
import { AllVehicleRegDetails } from "@/model/all-vehicle-details/VehicleRegDetails";


@Component({
  selector: "app-depot-vehicle-data-view",
  templateUrl: "./depot-vehicle-data-view.component.html",
  styleUrls: ["./depot-vehicle-data-view.component.scss"]
})
export class depotVehicleDataView implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  depotVehicleDetails: DepotVehicleDetails[] = [];
  getDepotVehicleDetails: AllVehicleRegDetails[] = [];
  vehicleDetails: AllVehicleRegDetails[] = [];
  searchText;
  qrData: string;
  vehicleNo: string;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService, private toaster: ToastrService,
              private router: Router) {

  }

  ngOnInit() {
    this.getStockDetails();
  }

  ngOnDestroy() {
  }

  // Get all stock details
  getStockDetails() {
    this.spinner.show();
    this.apiServices.getAllVehicleDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.getDepotVehicleDetails = data.filter(item => item.usage == "DV - டிப்போ வாகனங்கள்" && item.depotNumber != null);
        this.vehicleDetails = this.getDepotVehicleDetails.sort((a, b) => {
          const depotNoA = parseInt(a.depotNumber.split(":")[1]);
          const depotNoB = parseInt(b.depotNumber.split(":")[1]);
          return depotNoA - depotNoB;
        });
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Print the vehicle qr through API
  printQR(id: number) {
    for (let qr of this.getDepotVehicleDetails) {
      if (id == qr.vehicleRegPK) {
        this.qrData = qr.uniqueQrData;
        this.vehicleNo = qr.registrationNumber;
      }
    }
    const vehicleQR = {
      qrCode: this.qrData,
      vehicleNumber: this.vehicleNo,
      printerName: "TE210_USB"
    };
    this.spinner.show();
    this.apiServices.printQR(vehicleQR)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
        if (error && error.error && error.error.msg) {
          this.spinner.hide();
          this.toaster.error(error.error.msg);
        } else {
          this.spinner.hide();
          this.toaster.error("Something went wrong, please try again");
        }
      });
  }
}

