<div #sabPaisaSdk></div>
<div class="section">

  <header class="sticky-top" style="background: #37517e">
    <div class="container" style="position: sticky;">
      <div class="row">
        <div class="col-md-2 col-12"></div>
        <div class="col-md-1 col-12">
          <a href="https://dev1.aftc.in"> <img src="../../../../assets/img/final-chamber.png" alt=""> </a>
        </div>
        <div class="col-md-6 hidden text-center mt-3 ms-2">
          <h5 class="text-bold" style="color: white; margin-bottom: 3px;">AGRO FOOD CHAMBER OF COMMERCE & INDUSTRY</h5>
          <h6 class="text-bold" style="color: white; margin-bottom: 3px;">(Agro Food Chamber)</h6>
          <h6 class="" style="color: white; margin-bottom: -3px;">Agro Food Trade Centre Campus</h6>
          <h6 class="mt-1" style="color: white; margin-bottom: -3px;">Sikkanthar Chavadi, Alanganallur Road, MADURAI –
            625
            018.</h6>
          <h6 class="" style="color: white;"><img src="../../../../assets/img/icons/icons8-outgoing-call-100.png"
              style="height: 25px;width: 25px;"> +91 93639 95494
            <span class="ml-2" style="white-space: nowrap;"><img src="../../../../assets/img/icons/icons8-email-64.png"
                style="height: 30px;width: 30px;"> contact@agrofoodchamber.com</span>
          </h6>
        </div>
      </div>
    </div>
  </header>

  <div class="py-2"></div>
  <div class="container-fluid">

    <form [formGroup]="membership" (ngSubmit)="onSubmit()">
      <div class="px-4">
        <div class="row">
          <div class="col-md-2"></div>

          <div class=" col-md-8">
            <div class="card" style="border-top:8px solid #673AB7;padding: 5px 2px;">
              <h3 class="text-center mt-2 textSmall" style="text-shadow: 1px 1px 1px black;"><span>APPLICATION FOR
                  MEMBERSHIP</span>
              </h3>
            </div>
            <div class="card second">
              <div class="row">
                <div class="col-md-2"></div>

                <div class="col-md-3 mt-2">
                  <div style="padding: 5px 10px">
                    <label for="">Select a Membership <span class="text-danger">*</span><br>
                      <p style="font-size:10px">(Select Any One)</p>
                    </label>
                  </div>
                </div>

                <div class="col-md-3 mt-2 ms-1">
                    <input type="radio" value="Long Term Membership" formControlName="memberPeriod"
                      (click)="enableFunction($event)" /><span class="ml-2">Long Term Membership <br><span class="ml-2"
                        style="font-size: 13px;">(Rs.2,000 – for the period of 5 years ending 31.03.2029)</span></span>
                </div>

                <div class="col-md-3 mt-2 ms-1">

                    <input type="radio" value="Short Term Membership" formControlName="memberPeriod"
                      (click)="enableFunction($event)" /><span class="ml-2">Annual Membership <br><span class="ml-2"
                        style="font-size: 13px;">(Rs.500 - for the period ending 31.03.2025)</span></span>
                </div>

              </div>

              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-3">
                  <div style="padding: 5px 10px"></div>
                </div>
                <!-- <div class="col-md-3">
                  <input type="radio" value="Test Membership" formControlName="memberPeriod"
                    (click)="enableFunction($event)" /><span class="ml-2">Test Membership <br><span class=""
                      style="font-size: 13px;">( Rs.1)</span></span><br>
                </div> -->

                <div class="col-md-3">
                </div>
              </div>
            </div>

            <div class="card third">
              <div class="row mt-3">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                  <div class="row">
                    <div class="row">
                      <div class="col-md-1"></div>

                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Name">Applicant's Name <span class="text-danger">*</span></label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="Name" formControlName="applicantName">
                        </div>
                        <span *ngIf="membershipError.applicantName"
                          class="text-danger ms-2">{{membershipError.applicantName}}</span>
                      </div>

                      <div class="col-md-1"></div>
                    </div>

                    <div class="row" *ngIf="onshowGst">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Organization">Organization Name <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="organizationName"
                            formControlName="organizationName">
                          <span *ngIf="membershipError.organizationName"
                            class="text-danger">{{membershipError.organizationName}}</span>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="orgType">Type of Organization <i class="bi bi-caret-down-fill"></i> <span
                              class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <select type="text" formControlName="orgType" class="form-control" id="orgType"
                            (change)="onShowFarmers($event)" placeholder="">
                            <option value="" disabled selected hidden>Select a Type of Organization
                            </option>
                            <option value="Proprietor">Proprietor</option>
                            <option value="PartnershipFirm">Partnership Firm</option>
                            <option value="LLP">LLP</option>
                            <option value="Limited Company">Limited Company</option>
                            <option value="Trade Association/Chamber">Trade Association/Chamber</option>
                            <option value="NGO">NGO</option>
                            <option value="Institute">Institute</option>
                            <option value="Farmer">Farmer</option>
                            <option value="FPO">FPO</option>
                            <option value="Self Help Group">Self Help Group</option>
                          </select>
                          <span *ngIf="membershipError.orgType" class="text-danger">{{membershipError.orgType}}</span>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>


                    <div class="row" *ngIf="onshowGst">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="businessType">Type of Business <i class="bi bi-caret-down-fill"></i> <span
                              class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <select type="text" formControlName="businessType" class="form-control" id="businessType">
                            <option value="" disabled selected hidden>Select a Type of Business
                            </option>
                            <option value="Trade">Trade</option>
                            <option value="Industry">Industry</option>
                            <option value="Farming">Farming</option>
                            <option value="Service">Service</option>
                            <option value="others">others</option>
                          </select>
                        </div>
                        <span *ngIf="membershipError.businessType"
                          class="text-danger ms-2">{{membershipError.businessType}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>


                    <div class="row" *ngIf="onshowGst">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="BusinessName">Nature of Business <span class="text-danger">*</span></label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="BusinessName" formControlName="businessName">
                        </div>
                        <span *ngIf="membershipError.businessName"
                          class="text-danger ms-2">{{membershipError.businessName}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Address">Postal Address <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="Address" formControlName="postalAddress">
                        </div>
                        <span *ngIf="membershipError.postalAddress"
                          class="text-danger ms-2">{{membershipError.postalAddress}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Pincode">Pincode <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="tel" class="form-control" id="Pincode" formControlName="pincode"
                            (keypress)="OnlyNumbers($event)" maxlength="6">
                        </div>
                        <span *ngIf="membershipError.pincode"
                          class="text-danger ms-2">{{membershipError.pincode}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="WhatsApp">City <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <select type="text" formControlName="city" class="form-control" id="businessType">
                            <option value="" disabled selected hidden>Select a City
                            </option>
                            <option value="Ariyalur">Ariyalur</option>
                            <option value="Chennai">Chennai</option>
                            <option value="Coimbatore">Coimbatore</option>
                            <option value="Cuddalore">Cuddalore</option>
                            <option value="Dharmapuri">Dharmapuri</option>
                            <option value="Dindigul">Dindigul</option>
                            <option value="Erode">Erode</option>
                            <option value="Kancheepuram">Kancheepuram</option>
                            <option value="Kanniyakumari">Kanniyakumari</option>
                            <option value="Karur">Karur</option>
                            <option value="Krishnagiri">Krishnagiri</option>
                            <option value="Madurai">Madurai</option>
                            <option value="Nagapattinam">Nagapattinam</option>
                            <option value="Namakkal">Namakkal</option>
                            <option value="Perambalur">Perambalur</option>
                            <option value="Pudukkottai">Pudukkottai</option>
                            <option value="Ramanathapuram">Ramanathapuram</option>
                            <option value="Salem">Salem</option>
                            <option value="Sivaganga">Sivaganga</option>
                            <option value="Thanjavur">Thanjavur</option>
                            <option value="The Nilgiris">The Nilgiris</option>
                            <option value="Theni">Theni</option>
                            <option value="Thiruvallur">Thiruvallur</option>
                            <option value="Thiruvarur">Thiruvarur</option>
                            <option value="Thoothukkudi">Thoothukkudi</option>
                            <option value="Tiruchirappalli">Tiruchirappalli</option>
                            <option value="Tirunelveli">Tirunelveli</option>
                            <option value="Tiruppur">Tiruppur</option>
                            <option value="Tiruvannamalai">Tiruvannamalai</option>
                            <option value="Vellore">Vellore</option>
                            <option value="Viluppuram">Viluppuram</option>
                            <option value="Virudhunagar">Virudhunagar</option>
                          </select>
                        </div>
                        <span *ngIf="membershipError.city" class="text-danger ms-2">{{membershipError.city}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="ContactPerson">Authorised Representative and
                            his / her Designation <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="ContactPerson" formControlName="contactPerson"
                          (keydown)="onlyAllowedCharacters($event)">
                        </div>
                        <span *ngIf="membershipError.contactPerson"
                          class="text-danger ms-2">{{membershipError.contactPerson}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row" *ngIf="onFarmer">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="email">His / Her e-mail ID <br><span class="ml-2" style="font-size: 13px;">(for
                              communication)</span> </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="email" formControlName="emailId">
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>


                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Phone">Mobile Number <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="tel" class="form-control" id="Phone" (input)="onPhoneNumber($event)"
                            [readonly]="readOnly" (keypress)="OnlyNumbers($event)" formControlName="phoneNo"
                            maxlength="10">
                          <span *ngIf="membershipError.phoneNo" class="text-danger">{{membershipError.phoneNo}}</span>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>


                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="WhatsApp">His / Her WhatsApp No <span class="text-danger">*</span> <br>
                            <span class="" style="font-size: 13px;">(for communication)</span></label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="WhatsApp" (keypress)="OnlyNumbers($event)"
                            formControlName="countryCode" readonly>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div style="padding: 5px 10px">
                          <input type="tel" class="form-control" id="WhatsApp" (keypress)="OnlyNumbers($event)"
                            formControlName="whatsappNo" maxlength="10" [readonly]="readOnly">
                        </div>
                        <span *ngIf="membershipError.whatsappNo"
                          class="text-danger ms-2">{{membershipError.whatsappNo}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row" *ngIf="onshowGst">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="UDYAM">UDYAM Reg / GST Number </label>

                        </div>
                      </div>

                      <div class="col-md-6 mt-3">
                        <div style="padding: 5px 10px">
                          <!-- <label for="UDYAMfile" style="font-size: 14px;">UDYAM Registration / GST File</label>
                          <input type="file" (change)="onFileChange($event, 'udyam')" class="form-control"
                            id="UDYAMfile" formControlName="udyamCertificatePath"> -->
                            <input type="text" class="form-control" id="UDYAM" formControlName="udyamRegistrationNo">
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="aadhar">Aadhaar Number <span *ngIf="onshowAadhaar"
                              class="text-danger">*</span></label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="tel" class="form-control" id="aadhar" formControlName="aadhaarNo"
                            (keypress)="OnlyNumbers($event)" maxlength="12">
                          <span *ngIf="membershipError.aadhaarNo"
                            class="text-danger ms-2">{{membershipError.aadhaarNo}}</span>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row" *ngIf="onshowGst">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="pan">PAN Number</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="pan" formControlName="panNo">
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-5 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="member">Are you a member of AFTC<br>
                            <p style="font-size:10px">(Strike-out whichever is not
                              applicable)</p>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-5 mt-3">
                        <div style="padding: 5px 10px">
                          <div>
                            <input type="radio" value="Utility Member" formControlName="memberOfAftc" /><span
                              class="ml-2">Utility
                              Member</span>
                          </div>
                          <div>
                            <input type="radio" class="" value="Stakeholder Member"
                              formControlName="memberOfAftc" /><span class="ml-2">Stakeholder Member</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>

                      <div class="col-md-5 mt-3">
                        <div style="padding: 10px">
                          <label for="Turnover">Annual Turnover Group<br>
                            <p style="font-size:10px">(Select Any One)</p>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-5 mt-3">
                        <div style="padding: 10px">
                          <div>
                            <input type="radio" value="Upto Rs.5 Crore" formControlName="annualTurnoverGroup" /><span
                              class="ml-2">Upto Rs.5 Crore</span>
                          </div>
                          <div>
                            <input type="radio" value="Above Rs.5Cr upto Rs.10Cr"
                              formControlName="annualTurnoverGroup" /><span class="ml-2">
                              Above Rs.5 Cr. upto Rs.10 Cr</span>
                          </div>
                          <div>
                            <input type="radio" value="Above Rs.10Cr upto Rs.50Cr"
                              formControlName="annualTurnoverGroup" /><span class="ml-2">Above Rs.10 Cr. upto Rs.50
                              Cr.</span>
                          </div>
                          <div>
                            <input type="radio" value="Above Rs.50Cr" formControlName="annualTurnoverGroup" /><span
                              class="ml-2">Above Rs.50 Cr.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>


                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-5 mt-4">
                        <div style="padding: 5px 10px">
                          <label for="">Interested to take part in the Forum <br>
                            <p style="font-size:10px">(Select Any One)</p>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6 mt-4">
                        <div style="padding: 5px 10px">
                          <div>
                            <input type="checkbox" value="LAUNCH" (change)="forumValue($event)" /><span
                              class="ml-2">LAUNCH <span class="ml-2" style="font-size:13px">(Arimukam)</span></span>
                          </div>
                          <div>
                            <input type="checkbox" value="AIM" (change)="forumValue($event)" /><span
                              class="ml-2">AIM<span style="font-size:13px"> (Agri International Market)</span></span>
                          </div>
                          <div>
                            <input type="checkbox" value="Water Shed to Market Shed"
                              (change)="forumValue($event)" /><span class="ml-2">Water Shed to
                              Market Shed</span>
                          </div>
                          <div>
                            <input type="checkbox" class="mr-2" value="BLESS" (change)="forumValue($event)" />BLESS<span
                              style="font-size:10px">
                              (Business Leadership Excellence thru’ Sustained Spirituality)</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mt-2" style="padding: 5px 10px">
                      <div class="col-md-1"></div>
                      <div class="col-md-10">
                        <p><input type="checkbox" id="terms" class="" (click)="termcheckbox()" name="terms" value="ok"
                            formControlName="acknowledgement" />
                          I agree to these <span class="text-primary" style="cursor: pointer" (click)="terms()">terms &
                            conditions</span></p>
                        <span *ngIf="membershipError.acknowledgement"
                          class="text-danger ms-2">{{membershipError.acknowledgement}}</span>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12 card">
                        <div class="mt-3 mb-3" style="padding: 5px 10px">
                          <h4 style="text-align: center"><u>Declaration</u></h4>
                          <ul>
                            <h6>
                              We are interested to join as a Member of Agro
                              Food Chamber of Commerce & Industry.
                              We shall abide by the Memorandum and Articles of
                              Association and all
                              other Rules and Regulations of Agro Food
                              Chamber, passed then and there.
                            </h6>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 mb-3"></div>
                      <div class="col-md-6 mb-3 text-center">
                        <button type="submit" class="btn btn-primary" id="submit" *ngIf="onShow">{{this.isUpdate == true
                          ?
                          "Update" : "Proceed For Payment"}}
                        </button>
                      </div>
                      <div class="col-md-3 mb-5 mt-2"></div>
                    </div>

                  </div>
                </div>
                <div class="col-md-1"></div>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </form>
  </div>
</div>

<div [style.display]="isVisible ? 'block' : 'none'" class="modal custom-modal">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background-color: #e6eba9;">
      <h4 class="modal-title mt-3" style="text-align: center">
      </h4>
      <div class="modal-header note">
        <h6 class="modal-title">
          <h5 class=""><span class="text-danger">*</span>
            Please Select a Membership to Proceed for the Application Form
          </h5>
        </h6>
      </div>
    </div>
  </div>
</div>

<div class="modal" class="modal" id="exampleModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true" [style.display]="showCommand ? 'block' : 'none'">
  <form [formGroup]="membership">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Enter Payment Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 mt-3">
              <div style="padding: 5px 10px">
                <label for="Name">Received Amount <span class="text-danger">*</span></label>
              </div>
            </div>

            <div class="col-md-6">
              <div style="padding: 5px 10px">
                <input type="text" class="form-control" id="amount" formControlName="amount">
              </div>
              <span *ngIf="membershipError.amount" class="text-danger ms-2">{{membershipError.amount}}</span>
            </div>

            <div class="col-md-1"></div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-3">
              <div style="padding: 5px 10px">
                <label for="Name">Payment Date <span class="text-danger">*</span></label>
              </div>
            </div>

            <div class="col-md-6">
              <div style="padding: 5px 10px">
                <input type="date" class="form-control" id="registeredDate" formControlName="registeredDate">
              </div>
              <span *ngIf="membershipError.registeredDate"
                class="text-danger ms-2">{{membershipError.registeredDate}}</span>
            </div>

            <div class="col-md-1"></div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-3">
              <div style="padding: 5px 10px">
                <label for="Name">Manual Receipt Number <span class="text-danger">*</span></label>
              </div>
            </div>

            <div class="col-md-6">
              <div style="padding: 5px 10px">
                <input type="text" class="form-control" id="Name" formControlName="manualReceiptNumber">
              </div>
              <span *ngIf="membershipError.manualReceiptNumber"
                class="text-danger ms-2">{{membershipError.manualReceiptNumber}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-3">
              <div style="padding: 5px 10px">
                <label for="Name">Collected By <span class="text-danger">*</span></label>
              </div>
            </div>

            <div class="col-md-6">
              <div style="padding: 5px 10px">
                <input type="text" class="form-control" id="collectedBy" formControlName="collectedBy">
              </div>
              <span *ngIf="membershipError.collectedBy" class="text-danger ms-2">{{membershipError.collectedBy}}</span>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6 mt-3">
              <div style="padding: 5px 10px">
                <label for="Image">Manual Receipt Image <span class="text-danger">*</span></label>
              </div>
            </div>

            <div class="col-md-6">
              <div style="padding: 5px 10px">
                <input type="file" (change)="onFileChange($event, 'manualForm')" class="form-control" id="manualForm"
                  formControlName="manualForm">
              </div>
              <span *ngIf="membershipError.manualForm" class="text-danger ms-2">{{membershipError.manualForm}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-bs-dismiss="modal" (click)="saveFuction()">Ok</button>
        </div>
      </div>
    </div>
  </form>
</div>

<ngx-spinner>
  <div class="loaders">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<div class="footer">
  <footer class="text-center text-white"
    style="background: linear-gradient(90deg, rgba(245,245,220,1) 0%, rgba(208,255,148,1) 100%) !important;">
    <div class="text-center text-white" style="background: #37517e">
      <span>Designed & Developed by <a class="text-primary fw-bolder" href="https://varnikcloud.com/"
          target="_blank">VarNik Systems</a>, Trichy @Copyrights - 2024. All Rights Reserved</span>
    </div>
  </footer>
</div>
