import { Component, HostBinding, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { Observable, Subject, takeUntil } from "rxjs";
import { DatePipe } from "@angular/common";
import { UiState } from "@/store/ui/state";
import { AppService } from "@services/app.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModel } from "@angular/forms";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "@services/api.service";

@Component({
    selector: "app-aftc-edit",
    templateUrl: "./aftc-edit.component.html",
    styleUrls: ["./aftc-edit.component.scss"],
})
export class AFTCEditComponent implements OnInit {
    searchText;
    @HostBinding("class") class = "wrapper";
    public ui: Observable<UiState>;
    datePipe: DatePipe = new DatePipe("en-IN");
    destroy$: Subject<boolean> = new Subject<boolean>();
    nablTestList: AllTestDetails[] = [];
    sampleTestList: AllTestDetails[] = [];
    nablSampleNo: String[] = []
    entryEdit: AllTestDetails | undefined;
    viewDetails: AllTestDetails | undefined;
    printDetails: AllTestDetails | undefined;
    nablList: String[] = [];
    testType: string = "";
    isModalShow = false;
    modalAllList: AllTestDetails[] = [];
    public TestResult: ProcedureResults[] = [];
    productList: String[] = [];
    public ulrNo: String;
    reportNo: String = ""
    analystTableDisable = false;
    normalTableDisable = false;
    techAnalystName: string[] = [];

    page = 1;
    pageSize = 5;
    premiumData: any[] = [];
    paginateData: any[] = [];

    constructor(
        private appService: ApiService,
        private router: Router,
        private dataService: PrintDataService, private spinner: NgxSpinnerService, private toaster: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.toaster.error("Select test type to view details")
        this.isModalShow = false;
        if (localStorage.getItem("department") == "TECHNICAL ANALYST") {
            this.analystTableDisable = true;
        } else {
            this.normalTableDisable = true;
        }
    }

    // Cleanup function called when the component is destroyed
    ngOnDestroy(): void {
        this.dataService.entryEdit = this.entryEdit;
        this.dataService.entryView = this.viewDetails;
    }

    // Get paginated premium data
    getPremiumData() {
        this.paginateData = this.premiumData
            .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }

    // Fetch procedure results based on ULR number and filter them based on user roles
    getProcedureResultsulr() {
        this.appService
            .getProcedureResults(this.ulrNo)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                //@ts-ignore
                var localdata: ProcedureResults[] = []
                //@ts-ignore
                localdata = data;
                var localdatass: ProcedureResults[] = []
                for (let test of localdata) {
                    if (test.technicalAnalystName == localStorage.getItem('loginId') || localStorage.getItem('userName') == 'MURUGESWARI-TA'
                        && test.technicalAnalystName == 'MURUGESWARI-TA') {
                        localdatass.push(test)
                    }
                    else if (localStorage.getItem('loginId') == 'vsspl' || localStorage.getItem('loginId') == 'RECEPTIONIST') {
                        localdatass.push(test)
                    } else if (localStorage.getItem('loginId') == 'DRKS-QM' || localStorage.getItem('loginId') == 'MURUGESWARI-TM') {
                        localdatass.push(test)
                    } else {
                        localdatass.push(test)
                    }
                }

                this.TestResult = localdatass.sort((a, b) => {
                    const idA = a.aftcl_procedure_id;
                    const idB = b.aftcl_procedure_id;
                    return idA - idB;
                });
                var product: String[] = [];
                for (var pro of this.TestResult) {
                    product.push(pro.aftcl_test_sample);
                }
                this.productList = product.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
                console.log("product list::", this.productList);
            });
    }

    // Open modal with test details based on ULR number
    openModal(ulrNotest: string) {
        var modalTable: AllTestDetails | undefined;
        for (let test of this.nablTestList) {
            if (test.aftcl_ulr_no == ulrNotest) {
                modalTable = test
                this.ulrNo = test.aftcl_ulr_no;
                this.getProcedureResultsulr();
            }
        }
        this.modalAllList.push(modalTable)
        this.isModalShow = true;
    }

    // Navigate to the print screen with the given ULR number
    NavPrintScreen(ulrNo: string) {
        for (var data of this.nablTestList) {
            const allDetails = data;
            if (data.aftcl_ulr_no == ulrNo) {
                this.entryEdit = allDetails;
            }
            this.router.navigate(["aftcl-Print-ScreenWeb", ulrNo]);
        }
    }

    // Navigate to the technical print screen with the given ULR number
    TestPrintScreen(ulrNo: string) {
        for (var data of this.sampleTestList) {
            const allDetails = data;
            if (data.aftcl_ulr_no == ulrNo) {
                this.entryEdit = allDetails;
            }
            this.router.navigate(["aftcl-tech-print", ulrNo]);
        }
    }

    // Navigate to the Product Screen
    NavProductscreen() {
        this.router.navigate(["aftcl-Product-Screen"]);
    }

    // Fetch NABL test details and filter them based on user roles
    getNABLTest() {
        var nablList: String[] = [];
        var nablsampleno: String[] = [];
        this.spinner.show();
        this.appService
            .getNABLTest()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                const uniqueTechAnalystNames = new Set<string>();
                var localdata: AllTestDetails[] = []
                this.nablTestList = data
                this.spinner.hide();
                for (let nablTest of this.nablTestList) {
                    nablList.push(nablTest.aftcl_ulr_no);
                    const proceduresArray = nablTest.procedures as any[];

                    if (proceduresArray.some(procedure => procedure.technicalAnalystName.includes(localStorage.getItem('loginId')))) {
                        if (nablTest.qualityManagerStatus !== 'Approved') {
                            localdata.push(nablTest);
                            nablsampleno.push(nablTest.aftcl_sample_no)
                        }
                    } else if (localStorage.getItem('loginId') == 'DRKS-QM' || localStorage.getItem('loginId') == 'MURUGESWARI-TM') {
                        localdata.push(nablTest);
                        nablsampleno.push(nablTest.aftcl_sample_no)
                    } else if (localStorage.getItem('loginId') == 'vsspl' || localStorage.getItem('loginId') == 'RECEPTIONIST') {
                        localdata.push(nablTest);
                        nablsampleno.push(nablTest.aftcl_sample_no)
                    } else {
                        localdata.push(nablTest);
                        nablsampleno.push(nablTest.aftcl_sample_no)
                    }
                    proceduresArray.forEach(procedure => {
                        uniqueTechAnalystNames.add(procedure.technicalAnalystName);
                    });
                    this.techAnalystName = Array.from(uniqueTechAnalystNames);
                }
                this.sampleTestList = localdata
                this.nablSampleNo = nablsampleno
                nablList.sort();
                this.nablList = nablList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            }, (error: any) => {
                this.spinner.hide();
                console.log("error::", error);
                this.toaster.error("Something went wrong, please try again later...")
            });
    }

    // Fetch Normal test details and filter them based on user roles
    getNormalTest() {
        var nablList: String[] = [];
        var normalsample: String[] = [];
        this.spinner.show();
        this.appService
            .getNormalTest()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                var localdata: AllTestDetails[] = []
                this.nablTestList = data;
                this.spinner.hide();
                for (let nablTest of this.nablTestList) {
                    nablList.push(nablTest.aftcl_ulr_no);
                    const proceduresArray = nablTest.procedures as any[];

                    if (proceduresArray.some(procedure => procedure.technicalAnalystName.includes(localStorage.getItem('loginId')))) {
                        if (nablTest.qualityManagerStatus !== 'Approved') {
                            localdata.push(nablTest);
                            normalsample.push(nablTest.aftcl_sample_no)
                        }
                    } else if (localStorage.getItem('loginId') == 'DRKS-QM' || localStorage.getItem('loginId') == 'MURUGESWARI-TM') {
                        localdata.push(nablTest);
                        normalsample.push(nablTest.aftcl_sample_no)
                    } else if (localStorage.getItem('loginId') == 'vsspl' || localStorage.getItem('loginId') == 'RECEPTIONIST') {
                        localdata.push(nablTest);
                        normalsample.push(nablTest.aftcl_sample_no)
                    } else {
                        localdata.push(nablTest);
                        normalsample.push(nablTest.aftcl_sample_no)
                    }
                }
                this.sampleTestList = localdata
                this.nablSampleNo = normalsample
                nablList.sort();
                this.nablList = nablList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            }, (error: any) => {
                this.spinner.hide();
                console.log("error::", error);
                this.toaster.error("Something went wrong, please try again later...")
            });
    }

    // Fetch Internam test details and filter them based on user roles
    getInternalTest() {
        var nablList: String[] = [];
        var internalsample: String[] = [];
        this.spinner.show();
        this.appService
            .getInternalTest()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                var localdata: AllTestDetails[] = []
                this.nablTestList = data;
                this.spinner.hide();
                this.sampleTestList = this.nablTestList
                for (let nablTest of this.nablTestList) {
                    const proceduresArray = nablTest.procedures as any[];
                    if (proceduresArray.some(procedure => procedure.technicalAnalystName.includes(localStorage.getItem('loginId')))) {
                        if (nablTest.qualityManagerStatus !== 'Approved') {
                            localdata.push(nablTest);
                            internalsample.push(nablTest.aftcl_sample_no)
                        }
                    } else if (localStorage.getItem('loginId') == 'DRKS-QM' || localStorage.getItem('loginId') == 'MURUGESWARI-TM') {
                        localdata.push(nablTest);
                        internalsample.push(nablTest.aftcl_sample_no)
                    } else if (localStorage.getItem('loginId') == 'vsspl' || localStorage.getItem('loginId') == 'RECEPTIONIST') {
                        localdata.push(nablTest);
                        internalsample.push(nablTest.aftcl_sample_no)
                    } else {
                        localdata.push(nablTest);
                        internalsample.push(nablTest.aftcl_sample_no)
                    }
                }
                this.sampleTestList = localdata
                this.nablSampleNo = internalsample
                nablList.sort();
                this.nablList = nablList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            }, (error: any) => {
                this.spinner.hide();
                console.log("error::", error);
                this.toaster.error("Something went wrong, please try again later...")
            });
    }

    // Filters the list of NABL test details based on the sample number provided in the event
    sampleno(event) {
        var samplenabl: AllTestDetails[] = [];
        const value = event.target.value;
        for (let ep of this.nablTestList) {
            if (value == ep.aftcl_sample_no) {
                //@ts-ignore
                samplenabl.push(ep);
            } else if (value == "") {
                samplenabl.push(ep);
            }
            //@ts-ignore
            this.sampleTestList = samplenabl;
        }
    }

    // Selects the appropriate test type based on the event value
    tableTestSelect(event) {
        const value = event.target.value;
        if (value == "NABL Test") {
            this.getNABLTest();
        } else if (value == "Non-NABL Test") {
            this.getNormalTest();
        } else if (value == "Internal Test") {
            this.getInternalTest();
        }
    }

    // Reloads the current page
    NavEditScreen() {
        window.location.reload();
    }

    // Reloads the current page
    reload() {
        window.location.reload();
    }

    // Navigates to the report screen
    report() {
        this.router.navigate(["/main/aftcl-Report-Screen"]);
    }

    // Selects a ULR number and navigates to the report screen
    selectUlr(ulrNo: string) {
        for (var data of this.nablTestList) {
            const allDetails = data;
            if (data.aftcl_ulr_no == ulrNo) {
                this.entryEdit = allDetails;
            }
            this.router.navigate(["/main/aftcl-Report-Screen"]);
        }
    }

    // Returns a unique list of technical analyst names from the procedures array
    getUniqueTechnicalAnalysts(procedures: any[]): string[] {
        const uniqueNames = new Set<string>();
        procedures.forEach(procedure => {
            if (procedure.technicalAnalystName) {
                uniqueNames.add(procedure.technicalAnalystName);
            }
        });
        return Array.from(uniqueNames);
    }

    // Selects a ULR number and navigates to the show screen
    selectUlr1(ulrNo: string) {
        for (var data of this.nablTestList) {
            const allDetails = data;
            if (data.aftcl_ulr_no == ulrNo) {
                this.viewDetails = allDetails;
            }
            this.router.navigate(["aftc-show-Screen", ulrNo]);
        }
    }

    // Navigates to a given URL if it's not empty
    onNavigation(url: string) {
        if (url && url !== "") {
            this.router.navigate([url]);
        }
    }
}
