import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { WeighBridgeDetails } from "@components/aftc-digitalization/aftc-digitalization-models/weighBridgeDetails";
import { Router } from "@angular/router";

@Component({
  selector: "app-weight-bridge-entry",
  templateUrl: "./weight-bridge-entry.component.html",
  styleUrls: ["./weight-bridge-entry.component.scss"]
})
export class WeightBridgeEntryComponent implements OnInit {
  getPassDetails: TollGate | undefined;
  getWeighBridgeDetails: WeighBridgeDetails | undefined;
  isUpdate: boolean = false;
  transactionNo: string;
  commodityNames: string[] = [];
  commodityShow: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private apiService: ApiService, private toaster: ToastrService,
    private datePipe: DatePipe, private router: Router
  ) {
  }

  weightBridgeForm = new UntypedFormGroup({
    weightBridgeSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    memberCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weighmentChargesRs: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    bags: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    loadType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    invDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    total: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    dateIn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    timeIn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    dateOut: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    timeOut: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    grossWeight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tareWeight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    netWeight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    transactionNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    memberName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    shift: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    printDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  weighBridgeFormErrors = {
    loadType: "",
    shift: "",
    invDate: "",
    grossWeight: "",
    tareWeight: "",
    commodityCode: ""
  };

  ngOnInit() {
    const reportQr = sessionStorage.getItem('reportQr');
    const weighBridgeId = sessionStorage.getItem('weighBridgeId');
    if (!!reportQr && weighBridgeId == null) {
      this.initial_data();
      this.getTollgateDetails(reportQr);
    } else if (!!weighBridgeId && reportQr == null) {
      this.getTransactionNo(weighBridgeId);
      this.getCommodityDetails();
    }
  }

  initial_data() {
    const now = new Date();
    this.weightBridgeForm.controls["printDate"].setValue(this.datePipe.transform(now, "yyyy-MM-ddTHH:mm"));
    this.weightBridgeForm.controls["dateIn"].setValue(this.datePipe.transform(now, "yyyy-MM-ddTHH:mm"));
    this.weightBridgeForm.controls["invDate"].setValue(this.datePipe.transform(now, "yyyy-MM-dd"));
  }

  getTollgateDetails(reportQr) {
    this.spinner.show();
    this.apiService.getTollGate(reportQr)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        console.log("getAllPassDetails::", data);
        this.getPassDetails = data;
        console.log(this.getPassDetails);
        this.weightBridgeForm.controls["memberName"].setValue(this.getPassDetails.memberName);
        this.weightBridgeForm.controls["commodityCode"].setValue(this.getPassDetails.commodityName);
        this.weightBridgeForm.controls["vehicleNumber"].setValue(this.getPassDetails.tollgateVehicleDetails.vehNumberPlate);
        this.weightBridgeForm.controls["irmNo"].setValue(this.getPassDetails.irmNo);
        this.weightBridgeForm.controls["storageType"].setValue(this.getPassDetails.storageType);
        this.weightBridgeForm.controls["serviceType"].setValue(this.getPassDetails.serviceType);
        if (this.getPassDetails.tollgateVehicleDetails.wheelCount <= 6) {
          this.weightBridgeForm.controls['weighmentChargesRs'].setValue('150')
        } else if (this.getPassDetails.tollgateVehicleDetails.wheelCount > 6) {
          this.weightBridgeForm.controls['weighmentChargesRs'].setValue('200')
        }
        console.log("getAllPassDetails::", this.getPassDetails);
      }, (err: any) => {
        this.spinner.hide();
        console.log("something went wrong");
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  getTransactionNo(weighBridgeId) {
    this.spinner.show();
    this.apiService.getTransactionNo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.transactionNo = data.transactionNo;
        this.getAllWeighBridgeDetails(weighBridgeId);
      }, (err: any) => {
        this.spinner.hide();
        console.log("something went wrong");
        this.toaster.error("Something went wrong, Please try again later")
      })
  }

  getCommodityDetails() {
    this.apiService.getCommodityDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("Commodity Details :", data);
        const set = new Set(data.map(item => item.commodityName))
        this.commodityNames = Array.from(set).sort((a,b) => a.localeCompare(b));
      }, (err: any) => {
        console.log("Something went wrong :", err);
      });
  }

  getAllWeighBridgeDetails(weighBridgeId) {
    this.spinner.show();
    this.apiService.getWeighBridgeDetailsById(weighBridgeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        console.log("Get all weigh bridge details :", data);
        this.isUpdate = true;
        this.getWeighBridgeDetails = data;
        if (this.getWeighBridgeDetails.loadType == 'Empty') {
          if (this.getWeighBridgeDetails.commodityCode != "") {
            this.weightBridgeForm.controls["commodityCode"].setValue(this.getWeighBridgeDetails.commodityCode);
          }
          this.commodityShow = true
        } else {
          this.commodityShow = false;
          this.weightBridgeForm.controls["commodityCode"].setValue(this.getWeighBridgeDetails.commodityCode);
        }
        this.weightBridgeForm.controls["weightBridgeSeq"].setValue(this.getWeighBridgeDetails.weightBridgeSeq);
        this.weightBridgeForm.controls["createdAt"].setValue(this.getWeighBridgeDetails.createdAt);
        this.weightBridgeForm.controls["createdBy"].setValue(this.getWeighBridgeDetails.createdBy);
        this.weightBridgeForm.controls["dateIn"].setValue(this.getWeighBridgeDetails.dateIn);
        this.weightBridgeForm.controls["loadType"].setValue(this.getWeighBridgeDetails.loadType);
        this.weightBridgeForm.controls["grossWeight"].setValue(this.getWeighBridgeDetails.grossWeight);
        this.weightBridgeForm.controls["invDate"].setValue(this.getWeighBridgeDetails.invDate);
        this.weightBridgeForm.controls["irmNo"].setValue(this.getWeighBridgeDetails.irmNo);
        this.weightBridgeForm.controls["memberName"].setValue(this.getWeighBridgeDetails.memberName);
        this.weightBridgeForm.controls["netWeight"].setValue(this.getWeighBridgeDetails.netWeight);
        this.weightBridgeForm.controls["printDate"].setValue(this.getWeighBridgeDetails.printDate);
        this.weightBridgeForm.controls["serviceType"].setValue(this.getWeighBridgeDetails.serviceType);
        this.weightBridgeForm.controls["storageType"].setValue(this.getWeighBridgeDetails.storageType);
        this.weightBridgeForm.controls["shift"].setValue(this.getWeighBridgeDetails.shift)
        this.weightBridgeForm.controls["tareWeight"].setValue(this.getWeighBridgeDetails.tareWeight);
        this.weightBridgeForm.controls["updatedAt"].setValue(this.getWeighBridgeDetails.updatedAt);
        this.weightBridgeForm.controls["updatedBy"].setValue(this.getWeighBridgeDetails.updatedBy);
        this.weightBridgeForm.controls["vehicleNumber"].setValue(this.getWeighBridgeDetails.vehicleNumber);
        this.weightBridgeForm.controls["weighmentChargesRs"].setValue(this.getWeighBridgeDetails.weighmentChargesRs);
        this.weightBridgeForm.controls["total"].setValue(this.getWeighBridgeDetails.total);
        if (this.getWeighBridgeDetails.transactionNumber == "") {
          this.weightBridgeForm.controls["transactionNumber"].setValue(this.transactionNo);
        } else {
          this.weightBridgeForm.controls["transactionNumber"].setValue(this.getWeighBridgeDetails.transactionNumber);
        }
        const now = new Date();
        if (this.getWeighBridgeDetails.dateOut == "") {
          this.weightBridgeForm.controls["dateOut"].setValue(this.datePipe.transform(now, "yyyy-MM-ddTHH:mm"));
        } else {
          this.weightBridgeForm.controls["dateOut"].setValue(this.getWeighBridgeDetails.dateOut);
        }
      }, (err: any) => {
        this.spinner.hide();
        console.log("Something went wrong");
        this.toaster.error("Something went wrong, Please try again later");
      })
  }

  ngOnDestroy() {
    sessionStorage.removeItem('reportQr');
    sessionStorage.removeItem('weighBridgeId');
    sessionStorage.removeItem('through')
  }

  formValidation() {
    this.weighBridgeFormErrors.loadType = "";
    this.weighBridgeFormErrors.shift = "";
    this.weighBridgeFormErrors.invDate = "";
    this.weighBridgeFormErrors.grossWeight = "";
    this.weighBridgeFormErrors.tareWeight = "";
    this.weighBridgeFormErrors.commodityCode = "";
    let hasError = false;

    if (this.weightBridgeForm.get("loadType").invalid) {
      this.weighBridgeFormErrors.loadType = "Load Type is Required";
      hasError = true;
    }

    if (this.weightBridgeForm.get("shift").invalid) {
      this.weighBridgeFormErrors.shift = "Shift is Required";
      hasError = true;
    }

    if (this.weightBridgeForm.get("invDate").invalid) {
      this.weighBridgeFormErrors.invDate = "Inv Date is Required";
      hasError = true;
    }

    if (!this.isUpdate && this.weightBridgeForm.get("loadType").value == 'Load') {
      if (this.weightBridgeForm.get("grossWeight").invalid) {
        this.weighBridgeFormErrors.grossWeight = "Gross Weight is Required";
        hasError = true;
      }
    } else if (!this.isUpdate && this.weightBridgeForm.get("loadType").value == 'Empty') {
      if (this.weightBridgeForm.get("tareWeight").invalid) {
        this.weighBridgeFormErrors.tareWeight = "Tare Weight is Required";
        hasError = true;
      }
    }

    if (this.isUpdate) {
      if (this.weightBridgeForm.get("grossWeight").invalid || this.weightBridgeForm.get("grossWeight").value == 0) {
        this.weighBridgeFormErrors.grossWeight = "Gross Weight is Required";
        hasError = true;
      }
      if (this.weightBridgeForm.get("tareWeight").invalid || this.weightBridgeForm.get("tareWeight").value == 0) {
        this.weighBridgeFormErrors.tareWeight = "Tare Weight is Required";
        hasError = true;
      }
    }

    if (this.isUpdate && this.weightBridgeForm.get("loadType").value == 'Empty') {
      if (this.weightBridgeForm.get("commodityCode").invalid) {
        this.weighBridgeFormErrors.commodityCode = "Commodity is Required";
        hasError = true;
      }
    }

    if (!hasError) {
      console.log(this.weightBridgeForm.value);
      if (!this.isUpdate) {
        this.saveWeighBridge();
      } else if (this.isUpdate) {
        this.updateWeighBridge();
      }
    }
  }

  saveWeighBridge() {
    this.spinner.show();
    this.weightBridgeForm.controls['grossWeight'].enable();
    this.weightBridgeForm.controls['tareWeight'].enable();
    this.apiService.saveWeighBridge(this.weightBridgeForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        console.log("Saved data :", data);
        this.successPopup('Saved', data.weightBridgeSeq);
      }, (err: any) => {
        this.spinner.hide();
        console.log("Something went wrong");
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  updateWeighBridge() {
    this.spinner.show();
    this.weightBridgeForm.controls['loadType'].enable();
    this.apiService.updateWeighBridge(this.weightBridgeForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        console.log("Updated data :", data.weightBridgeSeq);
        this.successPopup('Updated', data.weightBridgeSeq);
      }, (err: any) => {
        this.spinner.hide();
        console.log("Something went wrong");
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  successPopup(type, weighBridgeId) {
    Swal.fire({
      title: type,
      text: "Do you want to print?",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#30d66a",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem('printId', weighBridgeId);
        sessionStorage.setItem('backTo', sessionStorage.getItem('through'))
        this.router.navigate(['/weigh-receipt']);
      } else {
        if (sessionStorage.getItem('through') == 'tollgateEntries') {
          this.router.navigate(['/main/weigh-bridge-view'])
        } else {
          this.router.navigate(['/main/weigh-bridge-detail-view'])
        }
      }
    });
  }

  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowRight", "ArrowLeft"];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  chargeValue(event) {
    const value = event.target.value;
    if (value == 'Load' && !this.isUpdate) {
      this.weightBridgeForm.controls['tareWeight'].disable();
      this.weightBridgeForm.controls['grossWeight'].enable();
      /*this.weightBridgeForm.controls['weighmentChargesRs'].setValue('70')
      this.weightBridgeForm.controls['total'].setValue(String(70 + 70*18/100))*/
    } else if (value == 'Empty' && !this.isUpdate) {
      this.weightBridgeForm.controls['grossWeight'].disable();
      this.weightBridgeForm.controls['tareWeight'].enable();
      /*this.weightBridgeForm.controls['weighmentChargesRs'].setValue('30')
      this.weightBridgeForm.controls['total'].setValue(String(30 + 30*18/100))*/
    }
  }

  calculateNetWeight(event, type: string) {
    const value = event.target.value;
    if (this.isUpdate) {
      if (type == 'gross') {
        const tareWeight = this.weightBridgeForm.get('tareWeight').value;
        this.weightBridgeForm.controls['netWeight'].setValue(value - tareWeight);
      } else if (type == 'tare') {
        const grossWeight = this.weightBridgeForm.get('grossWeight').value;
        this.weightBridgeForm.controls['netWeight'].setValue(grossWeight - value);
      }
    }
  }

}
