<div class="container-fluid">

   <div class="row">
      <div class="col-2">
         <img src="assets/img/AFTC%20logo.png" class="logo" style="height: 150px;  width: 150px;">
      </div>
      <div class="col-8">
         <p class="text">FOOD PRODUCT ANALYTICAL LABORATORY</p>
         <p class="text1">AGRO FOOD TRADE CENTRE</p>
         <p class="text2">(Formerly: Tamilnadu Foodgrains Marketing Yard)</p>
         <p class="text3">ISO/IEC 17025 Accredited Testing Laboratory by NABL </p>
         <p class="text4">SIKKANDARCHAVADI,MADURAI 625018</p>
         <p class="text5">foodanalysis@grainscluster.com , office@agrofoodtradecenter.com</p>
         <p class="text7">GSTIN:33AABCT1101F1Z7 &nbsp;&nbsp; www.grainscluster.com &nbsp;&nbsp;&nbsp; PH No: 93637 96637
         </p>
      </div>
      <div class="col-2">
         <img src="assets/img/NABL%20logo.png" style="height: 150px; width: 150px;">
         <p class="text15">TC-10157</p>
      </div>
      <hr style="width:100%; text-align:left;  border-width:3px; border-color: black;">
   </div>

   <p class="text8">TEST REPORT</p>

   <table>
      <tbody>
         <tr>
            <th class="col-2">ULR No:</th>
            <td class="col-4">{{currentTestDetails.aftcl_ulr_no}}</td>
            <th class="col-2">
               <p>Report No</p>
               <p>Report Date</p>
            </th>
            <td class="col-4">
               <p>{{currentTestDetails.aftcl_report_no}}</p>
               <p>{{currentTestDetails.aftcl_sample_received_date}}</p>
            </td>
         </tr>
         <tr>
            <th class="col-2">Customer Name</th>
            <td class="col-3">{{currentTestDetails.aftcl_customer_name}}</td>
            <th class="col-3">Address</th>
            <td class="col-4">{{currentTestDetails.aftcl_customer_address}}</td>
         </tr>
         <tr>
            <th class="col-2">Contact No</th>
            <td class="col-4">{{currentTestDetails.aftcl_phone_number}}</td>
            <th class="col-2">Email Id</th>
            <td class="col-4">
               <p style="text-align: center;"
                  *ngIf='currentTestDetails.aftcl_email_id== null  || currentTestDetails.aftcl_email_id == "" '>--</p>
               <p *ngIf="currentTestDetails.aftcl_email_id!= null">{{currentTestDetails.aftcl_email_id}}</p>
            </td>
         </tr>
         <tr>
            <th class="col-2">Customer Reference</th>
            <td class="col-4">
               <p style="text-align: center;"
                  *ngIf='currentTestDetails.aftcl_customer_reference== null  || currentTestDetails.aftcl_customer_reference == "" '>
                  --</p>
               <p *ngIf="currentTestDetails.aftcl_customer_reference!= null">
                  {{currentTestDetails.aftcl_customer_reference}}</p>
            </td>
            <th class="col-2">Sample No</th>
            <td class="col-4">{{currentTestDetails.aftcl_sample_no}}</td>
         </tr>
         <tr>
            <th class="col-2">Sample Drawn By</th>
            <td class="col-4">{{currentTestDetails.aftcl_sample_drawn_by}}</td>
            <th class="col-2">Sample Received Date</th>
            <td class="col-4">{{currentTestDetails.aftcl_date | date: "dd-MM-yyyy"}}</td>
         </tr>
         <tr>
            <th class="col-2">Test Starting Date</th>
            <td class="col-4">{{currentTestDetails.aftcl_test_starting_date | date: "dd-MM-yyyy"}}</td>
            <th class="col-2">Due Date</th>
            <td class="col-4">{{currentTestDetails.aftcl_test_complete_date | date: "dd-MM-yyyy"}}</td>
         </tr>
         <tr>
            <th class="col-2">Sample_Qty_Received</th>
            <td class="col-4">{{currentTestDetails.aftcl_sample_qty_received}}</td>
            <th class="col-4">Packing Condition </th>
            <td class="col-4">{{currentTestDetails.aftcl_packing_condition}}</td>
         </tr>
         <tr>
            <th class="col-2">Discipline</th>
            <td class="col-4">{{currentTestDetails.aftcl_discipline}}</td>
            <th class="col-2">Group</th>
            <td class="col-4">{{currentTestDetails.aftcl_group}}</td>
         </tr>
         <tr>
            <th class="col-2">Product</th>
            <td class="col-4">{{productList}}</td>
            <th class="col-2">Product Description</th>
            <td class="col-4">{{currentTestDetails.aftcl_description}}</td>
         </tr>
      </tbody>
   </table>

   <br>

   <table>
      <tbody>
         <tr class="text-center">
            <th class="col-1" style="width: 30px;">S.NO</th>
            <th class="col-2">Parameter</th>
            <th class="col-4">Method</th>
            <th class="col-1">Unit</th>
            <th class="col-2">Result</th>
            <th class="col-2">Norms as per FSSAI</th>
         </tr>
         <tr class="col-md-12" *ngFor="let result of TestResult; let i = index">
            <td class="col-1 text-center">{{i+1}}</td>
            <td class="col-2">{{result.aftcl_test_parameter}}</td>
            <td class="col-4">{{result.aftcl_test_method}}</td>
            <td class="col-1 text-center">{{result.aftcl_test_unit}}</td>
            <td class="col-2 text-center">{{result.aftcl_test_result}}</td>
            <td class="col-2">{{result.aftcl_fssai_standard}}</td>
         </tr>
      </tbody>
   </table>

   <div class="row">
      <div class="col-1"></div>
      <div class="col-3">
         <p>Verified By</p>
      </div>
      <div class="col-4"></div>
      <div class="col-3">
         <p>Authorized Signatory</p>
      </div>
      <div class="col-1"></div>
   </div>

   <div class="row">
      <div class="col-1"></div>
      <div class="col-3">
      </div>
      <div class="col-4"></div>
      <div class="col-3">
         <img src="assets/img/sign singaravadivel.png" class="sign">
      </div>
      <div class="col-1"></div>
   </div>

   <div class="row">
      <div class="col-1"></div>
      <div class="col-3">
         <p class="mb-0"><b>S.Murugeswari</b></p>
         <p>Technical Manager</p>
      </div>
      <div class="col-4"></div>
      <div class="col-3">
         <p class="mb-0"><b>Dr.K.Singaravadivel</b></p>
         <p>Quality Manager</p>
      </div>
      <div class="col-1"></div>
   </div>

   <div class="row">
      <div class="col-1"></div>
      <div class="col-8" style="border: 2px solid rgb(0, 0, 0);">
         <b>
            <h4>Note :</h4>
            <ul>
               <li>The test results relate only to the items tested.</li>
               <li>The test report shall not be reproduced anywhere in full or in partial format without the approval of
                  the laboratory</li>
            </ul>
         </b>
      </div>
      <div class="col-2" style="border: 2px solid rgb(0, 0, 0);">
         <img src="http://136.233.82.215:8080/aftc-ws/qr/getPrintScreenQR?ulrNo={{currentTestDetails.aftcl_ulr_no}}"
            class="rounded" style="margin-top: 10px;margin-left: 25px;">
      </div>
      <div class="col-1"></div>
   </div>

</div>