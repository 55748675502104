import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { Subject, takeUntil } from "rxjs";
import { ProcedureDetails } from "@/model/labModal/procedureDetails";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "@services/api.service";

@Component({
    selector: "app-aftc-product",
    templateUrl: "./aftc-product.component.html",
    styleUrls: ["./aftc-product.component.scss"],
})
export class AFTCProductComponent implements OnInit {
    searchText;
    destroy$: Subject<boolean> = new Subject<boolean>();
    productEdit: ProcedureDetails | undefined;
    procedureList: ProcedureDetails[] = [];
    showProduct: ProcedureDetails[] = [];
    productId: number;

    page: 1;
    pageSize: 5;
    premiumData: any[] = [];
    paginateData: any[] = [];

    constructor(
        private appService: ApiService,
        private router: Router,
        private dataService: PrintDataService,
        private spinner: NgxSpinnerService, private toaster: ToastrService,
    ) {
    }

    ngOnInit(): void {
        this.getProduct();
    }

    ngOnDestroy(): void {
        this.dataService.productEdit = this.productEdit;
    }

    // Slice the premiumData array to get the paginated data based on page and pageSize
    getPremiumData() {

        this.paginateData = this.premiumData
            .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

    }

    // Fetch product data from the server
    getProduct() {
        this.spinner.show();
        this.appService
            .getProduct()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.procedureList = data;
                this.spinner.hide();
                var ShowList: ProcedureDetails[] = [];
                for (var pro of this.procedureList) {
                    if (pro.active_yn == "Yes" && pro.delete_yn == "No") {
                        ShowList.push(pro);
                    }
                }
                this.showProduct = ShowList.sort((a, b) => a.aftcl_test_sample.localeCompare(b.aftcl_test_sample));
            }, (error: any) => {
                this.spinner.hide();
                console.log("error::", error);
                this.toaster.error("Something went wrong, please try again later...")
            });
    }

    // Display confirmation dialog before deleting a product
    deleteShow(productId: number) {
        Swal.fire({
            title: "Are you sure to Delete?",
            text: "If you do not want Delete please select cancel.",
            html: "Note :Deleted entries cannot be undo",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                this.appService
                    .UpdateDelete(productId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                    });
                Swal.fire("Deleted!", "Your Product has been deleted.", "success").then(
                    function () {
                        window.location.reload();
                    }
                );
            }
        });
    }

    // Fetch product details for editing and navigate to the product edit screen
    editProduct(productId: number) {
        for (var data of this.procedureList) {
            const allProductDetails = data;
            if (data.aftcl_product_list_id == productId) {
                this.productEdit = allProductDetails;
            }
            this.router.navigate(["/main/aftcl-add-Product-Screen"]);
        }
    }

    // Navigate to the edit screen
    NavEditScreen() {
        this.router.navigate(["aftcl-Edit-Screen"]);
    }

    // Navigate to the report screen
    NavReportScreen() {
        this.router.navigate(["aftcl-Report-Screen"]);
    }

    // Reload the product screen
    NavProductscreen() {
        window.location.reload();
    }

    // Navigate to the add product screen
    addProduct() {
        this.router.navigate(["aftcl-add-Product-Screen"]);
    }

    // Navigate based on the provided URL
    onNavigation(url: string) {
        if (url && url !== "") {
            this.router.navigate([url]);
        }
    }

    // Reload the product screen
    reload() {
        window.location.reload()
    }
}
