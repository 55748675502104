<div class="container">
  <div class="table-responsive">
    <table class="table mt-4">
      <thead class="rounded sticky-top" style="background-color: #79efad">
      <tr class="text-center">
        <th>Select</th>
        <th>Sr.No</th>
        <th>Date</th>
        <th>Title</th>
        <th>Description</th>
        <th>City</th>
        <th>Status</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let news of newsData; let i = index">
        <td>
          <input type="checkbox" [checked]="presentId === news.newsFeedId" (click)="selectNews(news, news.newsFeedId)">
        </td>
        <td>{{i + 1}}</td>
        <td class="col-2">{{news.date}}/{{news.time}}</td>
        <td>{{news.title}}</td>
        <td>{{news.description}}</td>
        <td>{{news.city}}</td>
        <td>{{news.status}}</td>
        <td>
          <button [disabled]="!news.isUpdate" type="button" class="btn btn-primary" (click)="updateStatus()">Update
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>
