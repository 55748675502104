<div class="container-fluid">
  <div class="py-4"></div>

  <div class="header">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-2 border border-5">
        <img src="assets/img/AFTC%20logo.png" style="height: 100px; width: 100px" class="ms-2">
        <br>
        &nbsp;
      </div>
      <div class="col-8 border border-5 text-center">
        <h4 class="mt-3">AFTC FOOD ANALYTICAL LAB</h4>
        <h5>Accredited by NABL ISO/IEC 17025:2017</h5>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div class="py-5"></div>
  <h4 class="text-center ms-5">SAMPLE ANALYSIS REQUEST FORM-LAB</h4>
  <div class="py-1"></div>

  <div class="table-container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="emptySpace">

          <table class="table">
            <thead class="firsthead">
              <tr>
                <th scope="col" class="col-2">Sample Code No</th>
                <th scope="col" class="col-2">{{currentPrint?.aftcl_samplefa_no}}</th>
                <th scope="col" class="col-3">Sample Name</th>
                <th scope="col" class="col-4">{{currentPrint?.aftcl_description}}</th>
              </tr>
              <tr>
                <th scope="col" class="col-2">Received Date</th>
                <th scope="col" class="col-2">{{currentPrint?.aftcl_test_starting_date | date: "dd-MM-yyyy"}}</th>
                <th scope="col" class="col-3">Due Date</th>
                <th scope="col" class="col-4">{{currentPrint?.aftcl_test_complete_date | date: "dd-MM-yyyy"}}</th>
              </tr>
            </thead>
          </table>
          <table class="table testingTable">
            <thead>
              <tr>
                <th scope="col" class="col-1">S.No</th>
                <th scope="col" class="col-2">Parameter</th>
                <th scope="col" class="col-2">Method</th>
                <th scope="col" class="col-2">Sample given to</th>
                <th scope="col" class="col-2">Test Starting date</th>
                <th scope="col" class="col-2">Test Completed date</th>
                <th scope="col" class="col-1">Unit</th>
                <th scope="col" class="col-1">Mean value</th>
                <th scope="col" class="col-1">Result</th>
                <th scope="col" class="col-1">Analyzed By</th>
              </tr>
            </thead>
            <tbody *ngFor="let result of TestResult; let i = index;">
              <tr>
                <td rowspan="3">{{i+1}}</td>
                <td rowspan="3">{{ result.aftcl_test_parameter }}</td>
                <td rowspan="3">{{ result.aftcl_test_method }}</td>
                <td>{{ result.technicalAnalystName[0] }}</td>
                <td></td>
                <td></td>
                <td>{{ result.aftcl_test_unit}}</td>
                <td></td>
                <td rowspan="3">{{ result.aftcl_test_result }}</td>
                <td rowspan="3"></td>
              </tr>
              <tr>
                <td>{{ result.technicalAnalystName[1] }}</td>
                <td></td>
                <td></td>
                <td>{{ result.aftcl_test_unit}}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ result.technicalAnalystName[2] }}</td>
                <td></td>
                <td></td>
                <td>{{ result.aftcl_test_unit}}</td>
                <td></td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="footer-space">&nbsp;</div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>

  <div class="py-4"></div>


  <div class="footer py-5">
    <div class="row py-4">
      <div class="col-1"></div>
      <div class="col-3">
        <p>Sample Distribution by</p>
      </div>
      <div class="col-4"></div>
      <div class="col-3">
        <p>Test Result verified by</p>
      </div>
      <div class="col-1"></div>
    </div>
  </div>

</div>