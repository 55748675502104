import { ApiService } from "@services/api.service";
import { Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { NewsFeed } from "@/model/newsFeed/newsFeed";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-news-feed-view",
  templateUrl: "./news-feed-view.component.html",
  styleUrls: ["./news-feed-view.component.css"]
})
export class NewsFeedViewComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  newsData: NewsFeed[] = [];
  selectNewsFeed: NewsFeed | undefined;
  presentId: number | null = null;

  constructor(private apiService: ApiService, private toaster: ToastrService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.getAllNewsfeed();
  }

  ngOnDestroy() {
  }

  getAllNewsfeed() {
    this.spinner.show();
    this.apiService.getAllNewsfeed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("saved data::", data);
        this.spinner.hide();
        this.newsData = data.sort((a, b) => b.newsFeedId - a.newsFeedId);
      }, (err: any) => {
        this.spinner.hide();
        console.log("Something went wrong::", err);
      });
  }

  selectNews(News: NewsFeed, id: number) {
    if (this.presentId == id) {
      this.presentId = null;
      News.isUpdate = false;
    } else {
      this.presentId = null;
      this.presentId = id;
      for (let news of this.newsData) {
        news.isUpdate = false;
        if (news.newsFeedId == id) {
          this.selectNewsFeed = news;
        }
      }
      News.isUpdate = true;
    }
  }

  updateStatus() {
    this.spinner.show();
    this.selectNewsFeed.status = "Showing";
    this.apiService.updateNewsFeedStatus(this.selectNewsFeed)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("updated data ::", data);
        this.presentId = null;
        this.getAllNewsfeed();
        this.toaster.success("Updated successfully");
      }, (err: any) => {
        console.log("Something went wrong ::", err);
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

}



