import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { WeighBridgeDetails } from "@components/aftc-digitalization/aftc-digitalization-models/weighBridgeDetails";
import { DatePipe } from "@angular/common";
import * as XLSX from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";
import { PrintDataService } from "@/model/labModal/PrintDataService";


@Component({
  selector: "app-weigh-bridge-detail-view",
  templateUrl: "./weigh-bridge-detail-view.component.html",
  styleUrls: ["./weigh-bridge-detail-view.component.scss"]
})
export class WeighBridgeDetailViewComponent implements OnInit {

  searchText;
  getWeighBridgeData: WeighBridgeDetails[] = [];
  getTableDetails: WeighBridgeDetails[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  userType: string;
  cashFilter: string | null = null;
  DateFilter: string | null = null;
  getDateValue: string[] = [];
  isModalShow: boolean = false;
  totalAmount: number = 0;
  cashDepositValues: WeighBridgeDetails[] = [];
  updateWeighBridgePaymentDetails: WeighBridgeDetails[] = [];
  seqList: number[] = [];

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService, private spinner: NgxSpinnerService,
              private datePipe: DatePipe, private dataServices: PrintDataService) {

  }

  ngOnInit(): void {
    this.userType = localStorage.getItem("role");
    this.getWeighBridgeDetails();
  }

  getWeighBridgeDetails() {
    this.spinner.show();
    this.apiServices.getAllWeighBridgeDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        console.log("get all pass Details::", data);
        this.getWeighBridgeData = data;
        this.getTableDetails = this.getWeighBridgeData.sort((a, b) => {
          const idA = a.weightBridgeSeq;
          const idB = b.weightBridgeSeq;
          return idB - idA;
        });
        const getDate = new Set(this.getWeighBridgeData.map(item => item.dateIn.split('T')[0]));
        const filterData = Array.from(getDate).sort((a, b) => {
          const dateA = this.parseDate(a);
          const dateB = this.parseDate(b);
          return dateB.getTime() - dateA.getTime();
        });
        this.getDateValue = filterData.map(it => this.datePipe.transform(it, 'dd-MM-yyyy'));
        console.log("get all weigh bridge Details::", this.getTableDetails);
      }, (err: any) => {
        this.spinner.hide();
        console.log("something went worng");
      });
  }

  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(day, month - 1, year);
  }

  checkRecord(item: WeighBridgeDetails) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to update weight entry for this " + item.irmNo,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#30d66a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.setItem("weighBridgeId", String(item.weightBridgeSeq));
          sessionStorage.setItem("through", 'weighBridgeView');
          this.router.navigate(["/main/weigh-bridge-entry"]);
        }
      });
  }

  report(weighBridgeId) {
    sessionStorage.setItem('printId', weighBridgeId);
    sessionStorage.setItem('backTo', 'weighBridgeView');
    this.router.navigate(['/weigh-receipt']);
  }

  // Filter function based on selection
  weighBridgeFilter(event: any, filterType: string) {
    const value = event.target.value;

    switch (filterType) {
      case "date":
        this.DateFilter = value !== "Select Date" ? value : null;
        break;
      case "cash":
        this.cashFilter = value !== "Select Cash Deposit" ? value : null;
        break;
      default:
        break;
    }
    this.applyFilters();
  }

  // Apply filter based data
  applyFilters() {
    const dateFiltered = this.DateFilter ? this.getWeighBridgeData.filter(item => this.datePipe.transform(item.dateIn.split('T')[0], 'dd-MM-yyyy') === this.DateFilter) : this.getWeighBridgeData;
    const cashFiltered = this.cashFilter ? dateFiltered.filter(item => {
      if (this.cashFilter === "Not Deposit") {
        return item.cashDeposit === false;
      } else if (this.cashFilter === "Deposit") {
        return item.cashDeposit === true;
      }
      return true;
    }) : dateFiltered;
    this.getTableDetails = cashFiltered.sort((a, b) => {
      const idA = a.weightBridgeSeq;
      const idB = b.weightBridgeSeq;
      return idB - idA;
    });
  }

  // Excel generate for table data report
  generateWeighBridgeDetailsXLSX() {
    const desiredColumns = ["irmNo", "vehicleNumber", "dateIn", "dateOut", "weighmentChargesRs", "cashDeposit"];

    const header = desiredColumns.map(column => {
      const parts = column.split(".");
      return parts[parts.length - 1];
    });

    const headerStyle = {
      fill: {
        fgColor: { rgb: "black" }
      },
      font: {
        color: { rgb: "FFFFFF" },
        bold: true
      }
    };

    const filteredData = this.getTableDetails.map(item =>
      desiredColumns.reduce((obj, key) => {
        const keys = key.split(".");
        let value: any = item;
        keys.forEach(k => {
          if (value && typeof value === "object" && k in value) {
            value = value[k];
          } else {
            value = undefined;
          }
        });

        obj[key] = value;
        return obj;
      }, {})
    );

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);

    const workbook: XLSX.WorkBook = { Sheets: { "data": worksheet }, SheetNames: ["data"] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const data: Blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

    saveAs(data, "Weigh Bridge Details" + " Report.xlsx");
  }

  // Modal popup open
  modelPopup() {
    if (this.DateFilter != null) {
      this.isModalShow = true;
      this.getTableDetails = this.getWeighBridgeData.filter(item => this.datePipe.transform(item.dateIn.split('T')[0], 'dd-MM-yyyy') == this.DateFilter);
      this.cashDepositValues = this.getTableDetails.filter(item => item.cashDeposit == false);
      const vanValues = this.cashDepositValues.map(item => Number(item.weighmentChargesRs));
      this.totalAmount = vanValues.reduce((total, charge) => total + charge, 0);
    } else {
      this.toaster.error("Please select date");
    }
  }

  // Currency format condition
  formatCharges(charges: number): string {
    let formattedCharges = charges.toFixed(2);
    const [integerPart, decimalPart] = formattedCharges.split(".");
    formattedCharges = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedCharges += "." + decimalPart.padEnd(2, "0");
    return formattedCharges;
  }

  cancel() {
    this.isModalShow = false;
    location.reload();
  }

  // Update status not deposit to deposit & cirm No
  saveCash() {
    this.seqList = this.cashDepositValues.map(item => item.weightBridgeSeq);
      this.spinner.show();
      this.apiServices.updateCashDepositStatus(this.seqList)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide();
          this.updateWeighBridgePaymentDetails = data;
          this.saveAlert();
        }, (error: any) => {
          this.spinner.hide();
          this.toaster.error("Something went wrong, Please try again later");
        });
  }

  ngOnDestroy(): void {
    this.dataServices.weighBridgeDetails = this.updateWeighBridgePaymentDetails;
  }

  saveAlert() {
    Swal.fire({
      title: "Details Updated Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      this.router.navigate(["weigh-bridge-cash-receipt"]);
    });
  }
}
