import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-toll-gate-navbar",
  templateUrl: "./toll-gate-navbar.component.html",
  styleUrls: ["./toll-gate-navbar.component.scss"]
})
export class TollGateNavbarComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
