import { Component, OnInit } from "@angular/core";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { TestDetails } from "@/model/labModal/testDetails";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { Subject, takeUntil } from "rxjs";
import { NormalTestDetails } from "@/model/labModal/normalTestDetails";
import { InternalTestDetails } from "@/model/labModal/internalTestDetails";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";

@Component({
  selector: "app-aftc-show",
  templateUrl: "./aftc-show.component.html",
  styleUrls: ["./aftc-show.component.scss"],
})
export class AFTCShowComponent implements OnInit {
  currentTestDetails: TestDetails | undefined;
  currentNormalTestDetails: NormalTestDetails | undefined;
  currentInternalTestDetails: InternalTestDetails | undefined;
  currentResult: ProcedureResults | undefined;
  currentPrint: AllTestDetails | undefined;
  editDetails: AllTestDetails | undefined;
  entryEdit: AllTestDetails | undefined;
  [x: string]: any;
  isFooterShow = false;
  testType: string = "";
  isVisible = false;
  eSign: string = "";
  signShow = false;

  productList: String[] = [];
  containerSpace = false;
  logoShow = false;
  public TestResult: ProcedureResults[] = [];
  public ulrNo: String;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dataService: PrintDataService,
    private appService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.dataService.entryView != undefined) {
      this.currentPrint = this.dataService.entryView;
      this.ulrNo = this.route.snapshot.paramMap.get("ulrNo");
      this.getTestDetailulr();
      this.getNormalTestDetailulr();
      this.getInternalTestDetailulr();
      this.getProcedureResultsulr();
    }
    else {
      this.ulrNo = this.route.snapshot.paramMap.get("ulrNo");
      this.getTestDetailulr();
      this.getNormalTestDetailulr();
      this.getInternalTestDetailulr();
      this.getProcedureResultsulr();
      this.image();
    }
  }

  // Function to fetch test details based on ulrNo
  getTestDetailulr() {
    this.appService
      .getTestDetails(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
      });
  }

  // Function to fetch normal test details based on ulrNo
  getNormalTestDetailulr() {
    this.appService
      .getNormalTestDetails(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
      });
  }

  // Function to fetch internal test details based on ulrNo
  getInternalTestDetailulr() {
    this.appService
      .getInternalTestDetails(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentTestDetails = data;
      });
  }

  // Function to fetch procedure results based on ulrNo
  getProcedureResultsulr() {
    this.appService
      .getProcedureResults(this.ulrNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        //@ts-ignore
        this.TestResult = data;
      });
  }

}
