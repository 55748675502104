<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <p class="navbar-brand" href="#">Impact Study</p>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/stockDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Impact Data Entry</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/impactDataView" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Impact Data View</p>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid py-5">
  <form [formGroup]="StcokForm">
    <div class="row py-3">
      <div class="col-lg-2"></div>
      <div class="col-lg-4 col-md-6 col-12 mt-2">
        <div class="form-group">
          <div class="form-floating">
            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                    placeholder="Name Of Depositor"
                    formControlName="nameOfDepositor">
              <option selected disabled>Select Packing Type</option>
              <option *ngFor="let name of this.depositerName" value="{{name}}">{{name}}</option>
            </select>
            <label for="floatingSelect">Name Of Depositor</label>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" id="floatingSelect1" aria-label="Floating label select example"
                  placeholder="Stack Number" formControlName="stackNumber"
          >
            <option *ngFor="let no of this.stackNumbers" value="{{no}}">{{no}}</option>
          </select>
          <label>Stack Number</label>
        </div>
      </div>
    </div>
    <div class="py-2"></div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-4">
        <label>Stock IN</label>
      </div>
      <div class="col-lg-4">
        <label>Stock OUT</label>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-4 card">
        <div class="form-floating mt-3">
          <input type="date" class="form-control" placeholder="Stock IN Date" formControlName="stockInDate"
          >
          <label>Stock IN Date</label>
        </div>
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Stock IN Weight (Machine)"
                 formControlName="stockInWeightMachine">
          <label>Stock IN Weight (Machine)</label>
        </div>
        <div class="col-lg-1"></div>
      </div>
      <div class="col-lg-4 card ms-2">
        <div class="form-floating mt-3">
          <input type="date" class="form-control" placeholder="Stock OUT Date" formControlName="stockOutDate"
          >
          <label>Stock OUT Date</label>
        </div>
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Stock OUT Weight (Party)"
                 formControlName="stockOutWeightParty">
          <label>Stock OUT Weight (Party)</label>
        </div>
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Stock OUT Weight (Machine)"
                 formControlName="stockOutWeightMachine" (input)="Weight($event)">
          <label>Stock OUT Weight (Machine)</label>
        </div>
        <div class="py-2"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-4">
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Difference" formControlName="difference" readonly>
          <label>Difference</label>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Average" formControlName="average" readonly>
          <label>Average (%)</label>
        </div>
      </div>
    </div>
    <div class="py-2"></div>
    <div class="row">
      <div class="text-center">
        <button type="button" class="btn btn-success" (click)="saveStockDetails()">Save</button>
      </div>
    </div>
    <div class="py-3"></div>

  </form>
</div>
<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
