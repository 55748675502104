<!-- START RECEIPT -->
<div class="receipt">
  <div class="">
    <div class="row text-center">
      <div class="col-2">
        <img src="../../../../../assets/img/aftc-icon.png" alt="Logo"
             style="text-align: center;height: 150px;width: 150px;">
      </div>
      <div class="col-10 mt-3">
        <h4 class="text-uppercase text-bold mt-2 headerTitle">Agro Food Trade Centre</h4>
        <div class="text-center headerSubTitle">
          Sikkandar Savadi, Alanganallur Main Road, Madurai
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div id="location">
    <div class="text-bold text-uppercase text-center location"><u>Tollgate Receipt</u></div>
  </div>

  <div class="row mt-3">
    <table class="table table-borderless">
      <thead>
      <tr>
        <th>No - {{currentGatePass.irmNo}}</th>
        <th>Dated : {{currentDate}}</th>
      </tr>
      <tr>
        <td>Member Name :</td>
        <td>{{currentGatePass.memberName}}</td>
      </tr>
      <tr>
        <td>Vehicle Type :</td>
        <td>{{currentGatePass.tollgateVehicleDetails.vehType}}</td>
      </tr>
      <tr>
        <td>Vehicle No :</td>
        <td>{{currentGatePass.tollgateVehicleDetails.vehNumberPlate}}</td>
      </tr>
      <tr>
        <td>Commodity :</td>
        <td>{{currentGatePass.commodityName}}</td>
      </tr>
      <tr class="amount">
        <td>Amount Received:</td>
        <td>
          <h5 class="price"> ₹ {{currentGatePass.charges }} /-</h5>
          <!-- <h5 class="price"> ₹ 100 /-</h5> -->
        </td>
      </tr>
      </thead>
    </table>
  </div>
  <div class="keepIt">
    <div class="row mt-4 mb-5">
      <div class="col-12 text-center keepIt">
        <h6 class="keepIt">Valid for single trip only</h6>
        <h5 class="keepIt font-italic">***** www.aftc.in *****</h5>
      </div>
    </div>
  </div>
</div>
