import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-warehouse-unit-navbar",
  templateUrl: "./warehouse-unit-navbar.component.html",
  styleUrls: ["./warehouse-unit-navbar.component.scss"]
})
export class WarehouseUnitNavbarComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  navigate(selectedType: string) {
    this.router.navigate(['/main/warehouse-unit-view', {type: selectedType}])
  }

}
