<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Entry Form</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Blank Page</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <form (ngSubmit)="onSubmit()" [formGroup]="entrySheetForm">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="entryUM">Entry UM</label>
            <input
              type="text"
              class="form-control"
              id="entryUM"
              formControlName="entryUM"
              placeholder="Entry UM"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="entryQty">Entry Quantity</label>
            <input
              type="text"
              class="form-control"
              id="entryQty"
              formControlName="entryQty"
              placeholder="Entry Qty"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="entryTotalWeight">Entry Total Weight</label>
            <input
              type="text"
              class="form-control"
              id="entryTotalWeight"
              formControlName="entryTotalWeight"
              placeholder="Entry Weight Total"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="entryIndWeight">Entry Ind Weight</label>
            <input
              type="text"
              class="form-control"
              id="entryIndWeight"
              formControlName="entryIndWeight"
              placeholder="Entry Ind Weight"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="entryInfo">Entry Info</label>
            <input
              type="text"
              class="form-control"
              id="entryInfo"
              formControlName="entryInfo"
              placeholder="Entry Info"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button class="button" type="submit">Save</button>
        </div>
      </div>
    </form>
  </div>
</section>
