<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-3">
      <a class="nav-link" routerLink="/main/stockDetails">
        <div class="small-box bg-success">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Data Entry</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3">
      <a class="nav-link" routerLink="/main/depotDetails">
        <div class="small-box bg-primary">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Depot Entry</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3">
      <a class="nav-link" routerLink="/main/depotView">
        <div class="small-box bg-dark">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Depot View</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
  </div>
</div>
