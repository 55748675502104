<!--<div class="container">
  <div class="row it">
    <div class="col-sm-2"></div>
    <div class="col-sm-offset-2 col-sm-8" id="one">
      <form (ngSubmit)="onSubmit()" [formGroup]="news"  enctype="multipart/form-data" autocomplete="off">
        <br>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-4">
            <div class="form-floating">
              <input type="date" class="form-control" formControlName="date"  name="date" placeholder="Date" id="date">
              <label class="required" for="date">Date</label>
              <span style="color:red" *ngIf="newsErrors.date">{{newsErrors.date}}</span>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="city"  name="city" placeholder="City" id="city">
              <label class="required" for="city">Time</label>
              <span style="color:red" *ngIf="newsErrors.city">{{newsErrors.city}}</span>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="title" name="title" placeholder="Title" id="title">
              <label class="required" for="title">Title</label>
              <span style="color:red" *ngIf="newsErrors.title">{{newsErrors.title}}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <div class="form-floating">
              <textarea type="text" class="form-control" formControlName="description"  name="description" placeholder="Description" id="description">
              </textarea>
              <label class="required" for="description">Description</label>
              <span style="color:red" *ngIf="newsErrors.description">{{newsErrors.description}}</span>

            </div>
          </div>
        </div>
        <div class="row mt-5">
        </div>
&lt;!&ndash;        <div class="row uploadDoc">&ndash;&gt;
&lt;!&ndash;          <div class="col-sm-4"></div>&ndash;&gt;
&lt;!&ndash;          <div class="col-sm-4">&ndash;&gt;
&lt;!&ndash;            <div class="fileUpload btn btn-orange">&ndash;&gt;
&lt;!&ndash;              <input type="file" formControlName="image" (change)="onFileSelected($event)"  accept="image/*">&ndash;&gt;
&lt;!&ndash;              <span style="color:red" *ngIf="newsErrors.image">{{newsErrors.image}}</span>&ndash;&gt;

&lt;!&ndash;            </div>&ndash;&gt;
&lt;!&ndash;          </div>&ndash;&gt;
&lt;!&ndash;        </div>&ndash;&gt;
        <div class="text-center">
          <button class="btn btn-next" type="submit"><i class="fa fa-cloud-upload"></i> Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>-->
<section>
  <div class="container it">
    <form (ngSubmit)="onSubmit()" [formGroup]="news">
      <div class="justify-content-around row">
        <div class="col-md-4 mt-4">
          <div class="form-floating">
            <input type="date" class="form-control" formControlName="date" name="date" placeholder="Date" id="date">
            <label class="required">Date</label>
            <span style="color:red" *ngIf="newsErrors.date">{{newsErrors.date}}</span>
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <div class="form-floating">
            <input type="time" class="form-control" formControlName="time" name="time" placeholder="time" id="time">
            <label class="required">Time</label>
            <span style="color:red" *ngIf="newsErrors.time">{{newsErrors.time}}</span>
          </div>
        </div>
      </div>

      <div class="justify-content-around row">
        <div class="col-md-10 mt-4">
          <div class="form-floating">
            <input type="text" class="form-control" formControlName="title" name="title" placeholder="title" id="title">
            <label class="required">Title</label>
            <span style="color:red" *ngIf="newsErrors.title">{{newsErrors.title}}</span>
          </div>
        </div>
      </div>

      <div class="justify-content-around row">
        <div class="col-md-10 mt-4">
          <div class="form-floating">
            <textarea type="text" class="form-control" style="height: 100px" formControlName="description"
                      name="description" placeholder="description" id="description"></textarea>
            <label class="required">Description</label>
            <span style="color:red" *ngIf="newsErrors.description">{{newsErrors.description}}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-5 mt-4">
          <div class="form-floating">
            <input type="text" class="form-control" formControlName="city" name="city" placeholder="city" id="city">
            <label class="required">Venue</label>
            <span style="color:red" *ngIf="newsErrors.city">{{newsErrors.city}}</span>
          </div>
        </div>
      </div>

      <div class="justify-content-center">
        <div class="text-center">
          <button class="btn btn-next" type="submit"><i class="fa fa-cloud-upload"></i> Submit</button>
        </div>
      </div>

    </form>
  </div>
</section>

<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>
