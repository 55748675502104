<app-weigh-bridge-navbar *ngIf="userType == 'ADMIN'"></app-weigh-bridge-navbar>
<div class="container -fulid">
  <div *ngIf="userType == 'ADMIN'" class="row"></div>
  <div class="row ms-2 mt-5">
    <div class="col-md-6 justify-content-start">
      <div class="div mb-2 mt-3">
        <div class="row">
          <div class="col-lg-5 mt-2">
            <div class="form-floating">
              <select class="form-select" (change)="weighBridgeFilter($event, 'date')">
                <option selected>Select Date</option>
                <option *ngFor="let date of this.getDateValue">{{date}}</option>
              </select>
              <label>Date</label>
            </div>
          </div>
          <div class="col-lg-5 mt-2">
            <div class="form-floating">
              <select class="form-select" (change)="weighBridgeFilter($event, 'cash')">
                <option selected>Select Cash Deposit</option>
                <option value="Deposit">Deposit</option>
                <option value="Not Deposit">Not Deposit</option>
              </select>
              <label>Cash Deposit</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 mt-2">
      <div class="div mb-1 mt-4">
        <button type="button" class="btn btn-success" (click)="generateWeighBridgeDetailsXLSX()">Download Excel</button>
      </div>
    </div>
    <div class="col-lg-4 justify-content-end mt-2">
      <div class="div mb-1 mt-4">
        <button type="button" class="btn btn-clr btn-primary col-lg-7" (click)="modelPopup()">Generate IRM Invoice</button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>IRM No</th>
            <th>Vehicle Number</th>
            <th>Date In</th>
            <th>Date Out</th>
            <th>Amount(₹)</th>
            <th>Cash Deposit</th>
            <th>Print</th>
          </tr>
        </thead>
        <tbody *ngFor="let aftc of this.getTableDetails | filter: searchText;let i = index">
        <tr>
          <td (click)="checkRecord(aftc)">{{i + 1}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.irmNo}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.vehicleNumber}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.dateIn | date: 'dd-MM-yyyy / HH:mm'}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.dateOut | date: 'dd-MM-yyyy / HH:mm'}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.weighmentChargesRs | currency: 'INR'}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.cashDeposit ? 'Deposit' : 'Not Deposit' }}</td>
          <td (click)="report(aftc.weightBridgeSeq)"><i class="fas fa-print"></i></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" [style.display]="isModalShow ? 'block' : 'none'" role="dialog">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><b>No Cash Deposit Details</b></h5>
        <input type="text" class="col-4 ms-2 mt-1 font-weight-bolder" [value]="'₹' + formatCharges(this.totalAmount)"
               disabled>
        <button type="button" class="close" data-dismiss="modal" (click)="cancel()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive p-0 text-center" style="height: 350px;overflow-y: scroll;">
          <table class="table table-striped table-bordered table-head-fixed">
            <thead class="popuphead">
            <tr>
              <th>S.No</th>
              <th>IRM No</th>
              <th>Vehicle Number</th>
              <th>Amount(₹)</th>
            </tr>
            </thead>
            <tbody *ngIf="this.cashDepositValues.length > 0; else noDataFound">
            <tr *ngFor="let same of this.cashDepositValues; let i =index;">
              <td>{{i + 1}}</td>
              <td>{{ same.irmNo}}</td>
              <td>{{ same.vehicleNumber}}</td>
              <td>{{ same.weighmentChargesRs | currency: 'INR'}}</td>
            </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="6">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="saveCash()">Ok</button>
        <button type="button" class="btn btn-danger" (click)="cancel()" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>
