
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {ApiService} from "@services/api.service";

@Component({
  selector: 'app-entry-list',
  templateUrl: './entry-list.component.html',
  styleUrls: ['./entry-list.component.css']
})
export class EntryListComponent implements OnInit {
  entrySheetForm: any;

  onSubmit() {
    let field_check = this.checkObj();
    if (!field_check) {
         return ;
    } else {


        console.log(this.entrySheetForm.value);
        this.apiService.addEntrySheet(this.entrySheetForm.value)

        this.entrySheetForm.reset();
        this.initial_data();
    }
}

checkObj() {
return true
}



constructor(private formBuilder: FormBuilder, private apiService:ApiService) {



}
ngOnInit() {

  this.initial_data()
}




initial_data() {


    this.entrySheetForm = this.formBuilder.group({

      entryUM:"",
      entryQty:0,
      entryTotalWeight:0.00,
      entryIndWeight:0.00,
      entryInfo:"",
      })
}


}
