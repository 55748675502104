import { Component, OnInit } from "@angular/core";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { TestDetails } from "@/model/labModal/testDetails";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { Subject, takeUntil } from "rxjs";
import { NormalTestDetails } from "@/model/labModal/normalTestDetails";
import { InternalTestDetails } from "@/model/labModal/internalTestDetails";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";
import QRCode from "qrcode-generator";

@Component({
  selector: "app-aftc-print",
  templateUrl: "./aftc-print.component.html",
  styleUrls: ["./aftc-print.component.scss"],
})
export class AFTCPrintComponent implements OnInit {
  currentTestDetails: TestDetails | undefined;
  currentNormalTestDetails: NormalTestDetails | undefined;
  currentInternalTestDetails: InternalTestDetails | undefined;
  currentResult: ProcedureResults | undefined;
  currentPrint: AllTestDetails | undefined;
  editDetails: AllTestDetails | undefined;
  entryEdit: AllTestDetails | undefined;
  isFooterShow = false;
  testType: string = "";
  isVisible = false;
  eSign: string = "";
  signShow = false;
  isHeader = false;
  printHeader: string = "";
  headerContant = false;
  originalTitle: String = ""
  productList: String[] = [];
  containerSpace = false;
  logoShow = false;
  public TestResult: ProcedureResults[] = [];
  public ulrNo: String;
  avargeResults: { average_aftcl_test_result: string; }[] = [];
  filterprocedureResults: ProcedureResults[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dataService: PrintDataService,
    private appService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
     // If entryEdit is defined in dataService, it means the user is printing an edited entry
    if (this.dataService.entryEdit != undefined) {
      this.isHeader = true;
      this.currentPrint = this.dataService.entryEdit;
      this.ulrNo = this.route.snapshot.paramMap.get("ulrNo");
      this.getTestDetailulr();
      this.getNormalTestDetailulr();
      this.getInternalTestDetailulr();
      this.getProcedureResultsulr();
      const desiredFileName = String(this.currentPrint.aftcl_sample_no);
      document.title = desiredFileName;
      this.originalTitle = document.title;
      if (!this.isHeader) {
        setTimeout(function () {
          window.print();
          setTimeout(() => {
            document.title = this.originalTitle
          }, 0);
        }, 3000);
        setTimeout(() => {
          this.router.navigate(["/main/aftcl-Edit-Screen"]);
        }, 5000);
      }
    }
    // If entryView is defined in dataService, it means the user is viewing an entry
    else if (this.dataService.entryView != undefined) {
      this.currentPrint = this.dataService.entryView;
      this.ulrNo = this.route.snapshot.paramMap.get("ulrNo");
      this.getTestDetailulr();
      this.getNormalTestDetailulr();
      this.getInternalTestDetailulr();
      this.getProcedureResultsulr();
      this.isFooterShow = true;
    }
    // If allTest is defined in dataService, it means the user is printing all test details
    else if (this.dataService.allTest != undefined) {
      this.isHeader = true;
      this.currentTestDetails = this.dataService.allTest;
      this.TestResult = JSON.parse(localStorage.getItem("procedureResults"));
      this.showSpace();
      var product: String[] = [];
      for (var pro of this.TestResult) {
        product.push(pro.aftcl_test_sample);
      }
      this.productList = product.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      const desiredFileName = String(this.currentTestDetails.aftcl_sample_no);
      document.title = desiredFileName;
      this.originalTitle = document.title;
      // If there's no header, trigger print after delay and navigate to edit screen
      if (!this.isHeader) {
        setTimeout(function () {
          window.print();
          setTimeout(() => {
            document.title = this.originalTitle
          }, 0);
        }, 3000);
        setTimeout(() => {
          this.router.navigate(["/main/aftcl-Edit-Screen"]);
        }, 5000);
      }
    }
     // If none of the above conditions met, it means normal page load
    else {
      this.ulrNo = this.route.snapshot.paramMap.get("ulrNo");
      this.getTestDetailulr();
      this.getNormalTestDetailulr();
      this.getInternalTestDetailulr();
      this.getProcedureResultsulr();
      this.isHeader = true;
    }
    
 
  // Check if the table has more than or equal to 5 rows and add page break if true
  const table = document.getElementById("my-table");
  const tbody = document.getElementById("my-table-body");
  const rows = tbody.getElementsByClassName("tr");
  if (rows.length >= 5) {
    table.classList.add("page-break-after");
  }

  // Trigger image function after delay
  setTimeout(() => {
    this.image()
  }, 500);
}

// Function to fetch test details based on ulrNo
getTestDetailulr() {
  this.appService
    .getTestDetails(this.ulrNo)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      this.currentTestDetails = data;
    });
}

// Function to fetch normal test details based on ulrNo
getNormalTestDetailulr() {
  this.appService
    .getNormalTestDetails(this.ulrNo)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      this.currentTestDetails = data;
    });
}

// Function to fetch internal test details based on ulrNo
getInternalTestDetailulr() {
  this.appService
    .getInternalTestDetails(this.ulrNo)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      this.currentTestDetails = data;
    });
}

// Function to fetch procedure results based on ulrNo
getProcedureResultsulr() {
  this.appService
    .getProcedureResults(this.ulrNo)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      // Filter procedure results where 'aftcApprove' is 'Yes'
      var localdata: ProcedureResults[] = data.filter(item => item.aftcApprove == 'Yes');

      // Check if entryEdit is not undefined, and if so, call showSpace() function
      if (this.dataService.entryEdit != undefined) {
        this.showSpace();
      }

      // Calculating averages of test results grouped by procedure ID and test parameter
      const typedData: Array<{ aftcl_procedure_id: number, aftcl_test_result: string, aftcl_test_parameter: string }> = localdata;
      const groupSums: { [key: string]: number } = {};
      const groupCounts: { [key: string]: number } = {};

      for (const item of typedData) {
        const key = `${item.aftcl_procedure_id}_${item.aftcl_test_parameter}`;
        const numericValue = parseFloat(item.aftcl_test_result);

        if (!isNaN(numericValue)) {
          groupSums[key] = (groupSums[key] || 0) + numericValue;
          groupCounts[key] = (groupCounts[key] || 0) + 1;
        }
      }

      // Calculate averages
      const averages = Object.keys(groupSums).map(key => {
        const average = groupSums[key] / groupCounts[key];

        return {
          aftcl_procedure_id: parseInt(key.split('_')[0], 10),
          aftcl_test_parameter: key.split('_')[1],
          average_aftcl_test_result: average.toFixed(1),
        };
      });

      // Update test results with averages
      for (const result of averages) {
        const matchingTestResult = localdata.find(item =>
          item.aftcl_procedure_id === result.aftcl_procedure_id &&
          item.aftcl_test_parameter === result.aftcl_test_parameter
        );

        if (matchingTestResult) {
          matchingTestResult.aftcl_test_result = result.average_aftcl_test_result;
        }
      }

      // Get unique procedure IDs
      const uniqueProcedureIds = new Set(localdata.map(item => item.aftcl_procedure_id));
      // Get unique procedure results based on unique procedure IDs
      const uniqueProcedureResults = Array.from(uniqueProcedureIds).map(procedureId => {
        return localdata.find(item => item.aftcl_procedure_id === procedureId);
      });
      // Update TestResult with unique procedure results
      this.TestResult = uniqueProcedureResults;
      this.showSpace();
      // Extract product list from TestResult
      var product: String[] = [];
      for (var pro of this.TestResult) {
        product.push(pro.aftcl_test_sample);
      }
      // Update productList with unique product list
      this.productList = product.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
    });
}


 
   // Check if the test type is "NABL Test" for either currentTestDetails or currentPrint
  image() {
    if (this.currentTestDetails.aftcl_test_type == "NABL Test" || this.currentPrint.aftcl_test_type == "NABL Test") {
      this.logoShow = true;
    } else {
      this.logoShow = false;
    }
  }

  // The function to handle selection in the test dropdown  
  testSelect(event) {
    this.image();
    const value = event.target.value;
    if (value == "With E-sign") {
      this.signShow = true;
      this.eSign = "Yes";
    } else if (value == "Without E-sign") {
      this.signShow = false;
      this.eSign = "No";
    }
  }

  // The function to handle selection in the header/footer dropdown
  headerSelect(event) {
    this.image();
    const value = event.target.value;
    if (value == "With Header/Footer") {
      this.printHeader = "Yes";
      this.headerContant = true;
    } else if (value == "Without Header/Footer") {
      this.printHeader = "No";
      this.headerContant = false;
    }
  }

  // Function to handle saving the selected type (with or without E-sign) and initiating print
  saveType() {
    if (this.eSign != "") {
      this.isVisible = false;
      this.isHeader = false;
      const desiredFileName = String(this.currentTestDetails.aftcl_sample_no);
      document.title = desiredFileName;
      this.originalTitle = document.title;
      setTimeout(function () {
        window.print();
        setTimeout(() => {
          document.title = this.originalTitle
        }, 0);
      }, 3000);
      setTimeout(() => {
        this.router.navigate(["/main/aftcl-Edit-Screen"]);
      }, 5000);
    }
  }

  // Function to handle saving the selected header/footer type
  saveHeaderType() {
    if (this.printHeader != "") {
      this.isHeader = false;
      this.isVisible = true;
    }
  }

// Function to determine whether to show space below the results container based on the number of results
  showSpace() {
    if (this.TestResult.length <= 7) {
      this.containerSpace = false;
    } else if (this.TestResult.length > 7) {
      this.containerSpace = true;
    }
  }


  // Function to generate QR code for the current test details
  generateQRCode(): string {
    const qr = QRCode(0, 'M');
    qr.addData("https://aftc.varnik.cloud/#/aftcl-view-Screen?ulr=" + this.currentTestDetails.aftcl_ulr_no);
    qr.make();

    return qr.createDataURL();
  }

}
