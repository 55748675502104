import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import * as QRCode from "qrcode";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";


@Component({
  selector: "app-stack-holder-details",
  templateUrl: "./stack-holder-details.component.html",
  styleUrls: ["./stack-holder-details.component.scss"]
})
export class StackHolderDetailsComponent implements OnInit {

  searchText;
  services = [
    {"vendorId" : "1","depositorName": "Ram", "commodity": "Paddy", "service_type": 'Warehouse'},
    {"vendorId" : "2","depositorName": "Raja", "commodity": "Paddy", "service_type": 'Warehouse'},
    {"vendorId" : "3","depositorName": "Ravi", "commodity": "Paddy", "service_type": "Warehouse"},
    {"vendorId" : "4","depositorName": "Hari", "commodity": "Paddy", "service_type": "Warehouse"},
]

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService) {

  }

  ngOnInit(): void {
    localStorage.removeItem("seqId");
  }

  detailsShoew(id : number){
    const vendorId = id;
    localStorage.setItem("seqId",String(vendorId));
    this.router.navigate(['/main/stack-details-view']);
  }
}
