import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import * as QRCode from "qrcode";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";


@Component({
  selector: "app-stock-details-view",
  templateUrl: "./stock-details-view.component.html",
  styleUrls: ["./stock-details-view.component.scss"]
})
export class StockDetailsViewComponent implements OnInit {

  searchText;
  vendorId : string = ""
  services : any [] = [];

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService) {

  }

  ngOnInit(): void {
    this.vendorId = localStorage.getItem("seqId");
   this.serviceFunction();

 }

 serviceFunction(){
   if(this.vendorId == '1'){
     this.services = [
       {"vendorId" : "1","depositorName": "Ram", "commodity": "Paddy", "service_type": 'Delivery',"date": '12-04-2024',"bags": '100',
       "tons": '120',"godownNo": '12',"stockNumber" : "C/II", "totalBags": "150"},
       {"vendorId" : "2","depositorName": "Ram", "commodity": "Paddy", "service_type": 'Deposit',"date": '02-03-2024',"bags": '100',
       "tons": '60',"godownNo": '12',"stockNumber" : "C/III", "totalBags": "250"},
       {"vendorId" : "3","depositorName": "Ram", "commodity": "Paddy", "service_type": "Delivery","date": '24-02-2024',"bags": '50',
       "tons": '230',"godownNo": '11',"stockNumber" : "G/I", "totalBags": "150"},
       {"vendorId" : "4","depositorName": "Ram", "commodity": "Paddy", "service_type": "Deposit","date": '29-01-2024',"bags": '200',
       "tons": '70',"godownNo": '9',"stockNumber" : "G/IV", "totalBags": "200"},
   ]
   }else if(this.vendorId == '2'){
     this.services = [
       {"vendorId" : "1","depositorName": "Raja", "commodity": "Paddy", "service_type": 'Delivery',"date": '02-05-2024',"bags": '200',
       "tons": '250',"godownNo": '10',"stockNumber" : "CG2/A", "totalBags": "0"},
       {"vendorId" : "2","depositorName": "Raja", "commodity": "Paddy", "service_type": 'Deposit',"date": '22-04-2024',"bags": '100',
       "tons": '160',"godownNo": '12',"stockNumber" : "CG2/B", "totalBags": "200"},
       {"vendorId" : "3","depositorName": "Raja", "commodity": "Paddy", "service_type": "Delivery","date": '12-03-2024',"bags": '50',
       "tons": '100',"godownNo": '11',"stockNumber" : "G I/32", "totalBags": "100"},
       {"vendorId" : "4","depositorName": "Raja", "commodity": "Paddy", "service_type": "Deposit","date": '29-12-2023',"bags": '150',
       "tons": '270',"godownNo": '10',"stockNumber" : "G I/35", "totalBags": "150"},
   ]
   }else if(this.vendorId == '3'){
     this.services = [
       {"vendorId" : "1","depositorName": "Ravi", "commodity": "Paddy", "service_type": 'Delivery',"date": '03-04-2024',"bags": '100',
       "tons": '250',"godownNo": '15',"stockNumber" : "G II/38", "totalBags": "0"},
       {"vendorId" : "2","depositorName": "Ravi", "commodity": "Paddy", "service_type": 'Deposit',"date": '30-03-2024',"bags": '40',
       "tons": '100',"godownNo": '15',"stockNumber" : "G I/43", "totalBags": "100"},
       {"vendorId" : "3","depositorName": "Ravi", "commodity": "Paddy", "service_type": "Deposit","date": '13-01-2024',"bags": '90',
       "tons": '180',"godownNo": '17',"stockNumber" : "G I/44", "totalBags": "60"},
       {"vendorId" : "4","depositorName": "Ravi", "commodity": "Paddy", "service_type": "Deposit","date": '15-11-2023',"bags": '150',
       "tons": '170',"godownNo": '17',"stockNumber" : "G II/21", "totalBags": "150"},
   ]
   }else if(this.vendorId == '4'){
     this.services = [
       {"vendorId" : "1","depositorName": "Hari", "commodity": "Paddy", "service_type": 'Delivery',"date": '04-05-2024',"bags": '50',
       "tons": '50',"godownNo": '18',"stockNumber" : "PPU/13", "totalBags": "100"},
       {"vendorId" : "2","depositorName": "Hari", "commodity": "Paddy", "service_type": 'Delivery',"date": '31-03-2024',"bags": '150',
       "tons": '300',"godownNo": '18',"stockNumber" : "PPU/14", "totalBags": "150"},
       {"vendorId" : "3","depositorName": "Hari", "commodity": "Paddy", "service_type": "Deposit","date": '17-02-2024',"bags": '250',
       "tons": '250',"godownNo": '20',"stockNumber" : "PPU/17", "totalBags": "300"},
       {"vendorId" : "4","depositorName": "Hari", "commodity": "Paddy", "service_type": "Deposit","date": '15-12-2023',"bags": '50',
       "tons": '80',"godownNo": '120',"stockNumber" : "PPU/18", "totalBags": "50"},
   ]
   }
 }
}
