<div class="container-fluid">

  <thead>
    <div class="header-space">&nbsp;</div>
  </thead>

  <div class="header">
    <div class="row" *ngIf="headerContant">
      <div class="topleft col-1"></div>
      <div class="col-2">
        <img src="assets/img/NABL%20LOGO%20LAB.png" *ngIf="this.logoShow">
      </div>
      <div class="col-2">
        <img src="assets/img/AFTC%20logo.png" style="height: 150px; width: 150px">
      </div>
      <div class="col-7">
        <p class="text text-last">AFTC FOOD ANALYTICAL LAB</p>
        <p class="text2">Accredited by NABL ISO/IEC 17025:2017</p>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <p class="text8">TEST REPORT</p>

    <table>
      <tbody>
        <tr>
          <th class="col-3">ULR No:</th>
          <td class="col-3" *ngIf="currentTestDetails.aftcl_test_type == 'NABL Test'">
            {{currentTestDetails.aftcl_ulr_no}}</td>
          <td class="col-3" *ngIf="currentTestDetails.aftcl_test_type !== 'NABL Test'">--</td>
          <th class="col-3">
            <p>Report No</p>
            <p>Report Date</p>
          </th>
          <td class="col-3">
            <p>{{currentTestDetails.aftcl_report_no}}</p>
            <p>{{currentTestDetails.aftcl_sample_received_date | date: "dd-MM-yyyy"}}</p>
          </td>
        </tr>
        <tr>
          <th class="col-3">Customer Name</th>
          <td class="col-3">{{currentTestDetails.aftcl_customer_name}}</td>
          <th class="col-3">Address</th>
          <td class="col-3">{{currentTestDetails.aftcl_customer_address}}</td>
        </tr>
        <tr>
          <th class="col-3">Contact No</th>
          <td class="col-3">{{currentTestDetails.aftcl_phone_number}}</td>
          <th class="col-3">Mail Id</th>
          <td class="col-3">
            <ng-container
              *ngIf="currentTestDetails.aftcl_email_id == null || currentTestDetails.aftcl_email_id == ''; else showEmail">
              --
            </ng-container>
            <ng-template #showEmail>
              {{ currentTestDetails.aftcl_email_id }}
            </ng-template>
          </td>
        </tr>
        <tr>
          <th class="col-3">Customer Reference</th>
          <td class="col-3">
            <ng-container
              *ngIf="currentTestDetails.aftcl_customer_reference == null || currentTestDetails.aftcl_customer_reference == ''; else showReference">
              --
            </ng-container>
            <ng-template #showReference>
              {{ currentTestDetails.aftcl_customer_reference }}
            </ng-template>
          </td>
          <th class="col-3">Sample No</th>
          <td class="col-3">{{ currentTestDetails.aftcl_sample_no }}</td>
        </tr>
        <tr>
          <th class="col-3">Sample Drawn By</th>
          <td class="col-3">
            <ng-container
              *ngIf="currentTestDetails.aftcl_sample_drawn_by == null || currentTestDetails.aftcl_sample_drawn_by == ''; else showDrawnBy">
              --
            </ng-container>
            <ng-template #showDrawnBy>
              {{ currentTestDetails.aftcl_sample_drawn_by }}
            </ng-template>
          </td>
          <th class="col-3">Sample Received Date</th>
          <td class="col-3">{{ currentTestDetails.aftcl_date | date: "dd-MM-yyyy" }}</td>
        </tr>
        <tr>
          <th class="col-3">Test Starting Date</th>
          <td class="col-3">{{currentTestDetails.aftcl_test_starting_date | date: "dd-MM-yyyy"}}</td>
          <th class="col-3">Due Date</th>
          <td class="col-3">{{currentTestDetails.aftcl_test_complete_date | date: "dd-MM-yyyy"}}</td>
        </tr>
        <tr>
          <th class="col-3">Sample Qty Received</th>
          <td class="col-3">{{currentTestDetails.aftcl_sample_qty_received}}</td>
          <th class="col-3">Packing Condition</th>
          <td class="col-3">{{currentTestDetails.aftcl_packing_condition}}</td>
        </tr>
        <tr>
          <th class="col-3">Discipline</th>
          <td class="col-3">{{currentTestDetails.aftcl_discipline}}</td>
          <th class="col-3">Group</th>
          <td class="col-3">{{currentTestDetails.aftcl_group}}</td>
        </tr>
        <tr>
          <th class="col-3">Product</th>
          <td class="col-3">{{productList}}</td>
          <th class="col-3">Sample Name</th>
          <td class="col-3">{{currentTestDetails.sample_name}}</td>
        </tr>
      </tbody>
    </table>

    <br>

    <div class="emptySpace" *ngIf="this.containerSpace"></div>

    <table id="my-table">
      <tbody id="my-table-body">
        <tr class="text-center">
          <th class="col-1" style="width: 30px;">S.NO</th>
          <th class="col-2">Parameter</th>
          <th class="col-4">Method</th>
          <th class="col-1">Unit</th>
          <th class="col-2">Result</th>
          <th class="col-2">Norms as per FSSAI</th>
        </tr>
        <tr class="col-md-12 text-center" *ngFor="let result of TestResult; let rows = index;">
          <td class="col-1">{{rows + 1}}</td>
          <td class="col-2">
            <p *ngIf='result.aftcl_test_parameter == null  || result.aftcl_test_parameter == "" '>--</p>
            <p *ngIf="result.aftcl_test_parameter != null">{{result.aftcl_test_parameter}}</p>
          </td>
          <td class="col-4">
            <p *ngIf='result.aftcl_test_method== null  || result.aftcl_test_method == "" '>--</p>
            <p *ngIf="result.aftcl_test_method != null">{{result.aftcl_test_method}}</p>
          </td>
          <td class="col-1 ">
            <p *ngIf='result.aftcl_test_unit== null  || result.aftcl_test_unit == "" '>--</p>
            <p *ngIf="result.aftcl_test_unit != null">{{result.aftcl_test_unit}}</p>
          </td>
          <td class="col-2">
            <p *ngIf='result.aftcl_test_result == null  || result.aftcl_test_result == "" '>--</p>
            <p *ngIf="result.aftcl_test_result != null">{{result.aftcl_test_result}}</p>
          </td>
          <td class="col-2">
            <p *ngIf='result.aftcl_fssai_standard== null  || result.aftcl_fssai_standard == "" '>--</p>
            <p *ngIf="result.aftcl_fssai_standard!= null">{{result.aftcl_fssai_standard}}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="text-11">**End of the Report**</div>

  </div>

  <div class="toplast"></div>

  <tfoot>
    <div class="footer-space">&nbsp;</div>
  </tfoot>

  <div class="footer" [style.display]="isFooterShow ?'none' : 'block' ">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-3 text-center">
        <p>Verified By</p>
      </div>
      <div class="col-4"></div>
      <div class="col-3 text-center">
        <p>Authorized Signatory</p>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row">
      <div class="col-1"></div>
      <div class="col-3 text-center" *ngIf="this.signShow == true">
      </div>
      <div class="col-3 sign text-center" *ngIf="this.signShow == false">
      </div>
      <div class="col-4"></div>
      <div class="col-3 text-center" *ngIf="this.signShow == true">
        <img src="assets/img/sign singaravadivel.png" class="sign">
      </div>
      <div class="col-3 sign text-center" *ngIf="this.signShow == false">
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row">
      <div class="col-1"></div>
      <div class="col-3 text-center">
        <p class="mb-0"><b>S.Murugeswari</b></p>
        <p>Technical Manager</p>
      </div>
      <div class="col-4"></div>
      <div class="col-3 text-center">
        <p class="mb-0"><b>Dr.K.Singaravadivel</b></p>
        <p>Quality Manager</p>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row">
      <div class="col-1"></div>
      <div class="row ms-4" style="border: 2px solid rgb(0, 0, 0); border-radius: 5px;">
        <div class="col-10">
          <b>
            <h4>Note :</h4>
            <ul>
              <li>The test results relate only to the sample given for analysis.</li>
              <li>Any correction not attested shall invalidate this report</li>
              <li>The test report shall not be reproduced any where in full or in partial format without the
                approval of the laboratory
              </li>
            </ul>
          </b>
        </div>
        <div class="col-2">
          <img class="text-center" [src]="generateQRCode()" alt="QR Code" style="margin-top: 10px">
        </div>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" *ngIf="headerContant">
      <div class="fw-bold" style="display: flex; justify-content: center;">
        <h5 class="fw-bold" style="margin-top: 1rem;">AGRO FOOD TRADE CENTER</h5>
        <p class="ms-2" style="margin-top: 1rem;">(Recognized SIRO by DSIR)</p>
      </div>
      <p class="fw-bold end-foot">Sikkanthar Chavadi, Madurai</p>
    </div>

  </div>

  <div class="modal" tabindex="-1" [style.display]="isHeader ? 'block' : 'none'">

    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Header Type</h5>
        </div>

        <div class="modal-body">
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="form-floating">
                <select class="form-select" (change)="headerSelect($event)" name="testType">
                  <option selected>Select Header/Footer</option>
                  <option>With Header/Footer</option>
                  <option>Without Header/Footer</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-primary " style="margin-left:-25px" role="button" (click)="saveHeaderType()">OK
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="modal" tabindex="-1" [style.display]="isVisible ? 'block' : 'none'">

    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Signature Type</h5>
        </div>

        <div class="modal-body">
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="form-floating">
                <select class="form-select" id="aftcl_packing_type" (change)="testSelect($event)" name="testType">
                  <option selected>Select Signature</option>
                  <option>With E-sign</option>
                  <option>Without E-sign</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-primary " style="margin-left:-25px" role="button" (click)="saveType()">OK
          </button>
        </div>

      </div>
    </div>
  </div>

</div>