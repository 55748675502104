import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import * as QRCode from "qrcode";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: "app-weigh-bridge-view",
  templateUrl: "./weigh-bridge-view.component.html",
  styleUrls: ["./weigh-bridge-view.component.scss"]
})
export class WeighBridgeViewComponent implements OnInit {

  searchText;
  getLocalPassDetails: TollGate[] = [];
  getPassDetails: TollGate[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  userType: string;

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.userType = localStorage.getItem("role");
    this.getAllPassDetails();
  }

  getAllPassDetails() {
    this.spinner.show();
    this.apiServices.getAllTollGate()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        console.log("get all pass Details::", data);
        this.getLocalPassDetails = data.filter(item => item.tollgateVehicleDetails.vehType == 'Truck');
        for (var aftc of this.getLocalPassDetails) {
          if (aftc.vehEntryDateTime === 0) {
            aftc.entryDate = "0";
          } else {
            const entryDate = new Date(aftc.vehEntryDateTime * 1000);
            aftc.entryDate = entryDate.toLocaleString();
          }

          if (aftc.vehExitDateTime === 0) {
            aftc.exitDate = "0";
          } else {
            const exitDate = new Date(aftc.vehExitDateTime * 1000);
            aftc.exitDate = exitDate.toLocaleString();
          }
        }
        this.getPassDetails = this.getLocalPassDetails.sort((a, b) => {
          const idA = a.tollgateSeq;
          const idB = b.tollgateSeq;
          return idB - idA;
        });
        console.log("getallpassDetails::", this.getPassDetails);
      }, (err: any) => {
        this.spinner.hide();
        console.log("something went worng");
      });
  }

  checkRecord(item: TollGate) {
    if (item.weighBridgeMapId == 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to add weight entry for this " + item.irmNo,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#30d66a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.setItem("reportQr", String(item.reportQr));
          this.router.navigate(["/main/weigh-bridge-entry"]);
        }
      });
    } else if (item.weighBridgeMapId != 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to update weight entry for this " + item.irmNo,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#30d66a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.setItem("weighBridgeId", String(item.weighBridgeMapId));
          sessionStorage.setItem("through", 'tollgateEntries');
          this.router.navigate(["/main/weigh-bridge-entry"]);
        }
      });
    }
  }

  /*report(weighBridgeId) {
    sessionStorage.setItem('printId', weighBridgeId);
    this.router.navigate(['/weigh-receipt']);
  }*/

}
