<div class="row justify-content-end">
    <div class="col-md-4 mt-3 mr-3">
        <div class="form-floating mb-3">
            <input type="text" class="form-control" placeholder="Search.." [(ngModel)]="searchText">
            <label>Search</label>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row mt-3">
        <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
            <table class="table table-bordered text-center table-hover mb-0">
                <thead class="header sticky-top">
                    <tr>
                        <th>S.No</th>
                        <th>Deposit App No</th>
                        <th>Commodity</th>
                        <th>Depositor Name</th>
                        <th>IRM Receipt No</th>
                        <th>Test</th>
                    </tr>
                </thead>
                <tbody class="editTable">
                    <tr
                        *ngFor="let test of pendingTestList | slice: (page-1) * pageSize : page * pageSize | filter: searchText;let i = index;">
                        <td>{{i+1}}</td>
                        <td>{{test.depositApplicationNo}}</td>
                        <td>{{test.commodity}}</td>
                        <td>{{test.depositorName}}</td>
                        <td>{{test.irmReceiptNo}}</td>
                        <td>
                            <button type="button" class="btn btn-outline-primary btn-sm">Test</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="d-flex justify-content-end w-100">
    <ngb-pagination [(page)]="page" [boundaryLinks]="true" [collectionSize]="pendingTestList.length" [maxSize]="5"
        [pageSize]="pageSize" (pageChange)="getPremiumData()"></ngb-pagination>
</div>

<div class="row mt-2"></div>