import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { AdminVehicleDetails } from "@/model/adminVehicleDetails/adminVehicleDetails";
import { ToastrService } from "ngx-toastr";
import { AllVehicleRegDetails } from "@/model/all-vehicle-details/VehicleRegDetails";


@Component({
  selector: "app-admin-vehicle-data-view",
  templateUrl: "./admin-vehicle-data-view.component.html",
  styleUrls: ["./admin-vehicle-data-view.component.scss"]
})
export class AdminVehicleDataView implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  depotDetails: AdminVehicleDetails[] = [];
  adminVehicleDetails: AllVehicleRegDetails[] = [];
  getAdminVehicle: AllVehicleRegDetails[] = [];
  searchText;
  qrData: string;
  vehicleNo: string;


  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService, private toastr: ToastrService,
              private router: Router) {

  }

  ngOnInit() {
    this.getStockDetails();
  }

  ngOnDestroy() {
  }

  // Get all stock details
  getStockDetails() {
    this.spinner.show();
    this.apiServices.getAllVehicleDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.adminVehicleDetails = data.filter(item => item.usage == "AD - நிர்வாக வாகனங்கள்");
        this.getAdminVehicle = this.adminVehicleDetails.sort((a, b) => b.vehicleRegPK - a.vehicleRegPK);
      }, (err: any) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, Please try again later");
      });
  }

  // Print the vehicle QR code through API
  printQRData(id: number) {
    for (let qr of this.adminVehicleDetails) {
      if (id == qr.vehicleRegPK) {
        this.qrData = qr.uniqueQrData;
        this.vehicleNo = qr.registrationNumber;
      }
    }
    const vehicleQR = {
      qrCode: this.qrData,
      vehicleNumber: this.vehicleNo,
      printerName: "TE210_USB"
    };
    this.spinner.show();
    this.apiServices.printQR(vehicleQR)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
        if (error && error.error && error.error.msg) {
          this.spinner.hide();
          this.toastr.error(error.error.msg);
        } else {
          this.spinner.hide();
          this.toastr.error("Something went wrong, please try again");
        }
      });
  }
}

