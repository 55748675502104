import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import {
  warehouseStockDelivery
} from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDelivery";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-preprocessing-delivery-print",
  templateUrl: "./preprocessing-delivery-print.component.html",
  styleUrls: ["./preprocessing-delivery-print.component.scss"]
})
export class PreprocessingDeliveryPrintComponent implements OnInit {
  getDetails: warehouseStockDelivery;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: string;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private datePipe: DatePipe, private toaster: ToastrService) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit() {
    const deliveryId = Number(sessionStorage.getItem("deliveryId"));
    this.getDeliveryDetails(deliveryId);
  }

  ngOnDestroy() {
    sessionStorage.removeItem("deliveryId");
  }


  getDeliveryDetails(id: number) {
    this.apiServices.getDeliveryDetails(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getDetails = data;
        /* setTimeout(() => {
           this.print();
         }, 3000)*/
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  print() {
    window.print();
    setTimeout(() => {
      this.router.navigate(["/main/frontOffice"]);
    });
  }

}
