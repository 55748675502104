<div class="container-fluid" *ngIf="normalReportDisable">
  <div class="row">
    <div class="col-lg-12 col-12 col-md-12">
      <h6><img src="./assets/img/icons/microscope%20.png" class="img-fluid beaker"><span class="text-bold ml-1 mt-2">Lab
          Report Entry</span></h6>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-12">
      <h6 class="mt-3"><span class="ml-2 text-bold">Test Analysis Report</span></h6>
    </div>
  </div>

  <form (ngSubmit)="onSubmit()" [formGroup]="aftclAllDetails" id="aftclCustomer">

    <div class="row text-center">
      <div class="col-lg-2 col-md-4 col-12" *ngIf="testingDisable">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="nablTest" value="NABL Test"
            (change)="testSelect($event)">
          <label class="form-check-label">NABL Test</label>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-12" *ngIf="testingDisable">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="normalTest" value="Normal Test"
            (change)="testSelect($event)">
          <label class="form-check-label">Non-NABL Test</label>
        </div>
      </div>

      <div class="col-lg-2  col-md-4 col-12" *ngIf="testingDisable">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="internalTest" value="Internal Test"
            (change)="testSelect($event)">
          <label class="form-check-label">Internal Test</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" id="floatingInput" placeholder="Sample Number"
            formControlName="aftcl_sample_no" (change)="sample($event)" readonly>
          <label for="floatingInput">Sample Number</label>
        </div>
        <span *ngIf="aftcLabErrors.aftcl_sample_no" class="text-danger">{{aftcLabErrors.aftcl_sample_no}}</span>
      </div>

      <div class="col-lg-3 col-md-6 col-12 mt-2" *ngIf="aftculrDisable">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="ULR Number" formControlName="aftcl_ulr_no"
            value="{{ulrNoShow}}" readonly>
          <label for="floatingInput">ULR Number</label>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Report" formControlName="aftcl_report_no" readonly
            value="{{reportNo}}">
          <label for="floatingInput">Report Number</label>
        </div>
      </div>

      <div class="col-lg-3  col-md-6 col-12 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control" formControlName="aftcl_date" id="aftcl_date" placeholder="Date"
            required pattern="\d{2}-\d{2}-\d{4}">
          <label for="floatingInput">Sample Received Date</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-12">
        <h6 class="mt-3"><span class="ml-2 text-bold">Customer Details</span></h6>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Name" formControlName="aftcl_customer_name"
            list="customerName">
          <datalist id="customerName">
            <option *ngFor="let name of uniqueCustomerNames">{{name}}</option>
          </datalist>
          <label for="floatingInput">Customer Name<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_customer_name"
            class="text-danger">{{aftcLabErrors.aftcl_customer_name}}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Name" formControlName="aftcl_phone_number"
            list="phoneNo" (keypress)="OnlyNumbers($event)" maxlength="10">
          <datalist id="phoneNo">
            <option *ngFor="let phone of uniquePhoneNumbers">{{phone}}</option>
          </datalist>
          <label for="floatingInput">Mobile Number</label>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Email ID" formControlName="aftcl_email_id" list="email">
          <datalist id="email">
            <option *ngFor="let name of nablTestList">{{name.aftcl_email_id}}</option>
          </datalist>
          <label for="floatingInput">Email ID</label>
          <span *ngIf="aftcLabErrors.aftcl_email_id" class="text-danger">{{aftcLabErrors.aftcl_email_id}}</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Address"
            formControlName="aftcl_customer_address" list="customerAddress">
          <datalist id="customerAddress">
            <option *ngFor="let address of uniqueAddresses">{{address}}</option>
          </datalist>
          <label for="floatingInput">Customer Address<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_customer_address"
            class="text-danger">{{aftcLabErrors.aftcl_customer_address}}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Reference"
            formControlName="aftcl_customer_reference" list="refer">
          <datalist id="refer">
            <option *ngFor="let refer of uniqueReferences">{{refer}}</option>
          </datalist>
          <label for="floatingInput">Customer Reference</label>
        </div>
      </div>
    </div>
  </form>


  <form (ngSubmit)="onSubmit()" [formGroup]="aftclAllDetails" id="aftclTestResult">

    <div class="row">
      <div class="col-lg-12 col-12">
        <h6 class="mt-3"><span class="ml-2 text-bold">Test Details</span></h6>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Sample Code Number" formControlName="aftcl_samplefa_no"
            readonly value="{{sampleFaNo}}">
          <label>Sample Code Number</label>
        </div>
      </div>
      <div class="col-lg-3 col-12 col-md-4 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" id="aftcl_sample_drawn_by" placeholder="Enter Sample Drawn by"
            formControlName="aftcl_sample_drawn_by" list="sample">
          <label>Sample Drawn By</label>
        </div>
        <datalist id="sample">
          <option *ngFor="let sample of uniqueSampleDrawn">{{sample}}</option>
        </datalist>
      </div>

      <div class="col-lg-3 col-12 col-md-4 mt-2">
        <div class="form-group" style="border: 1px solid rgb(203, 200, 200); border-radius: 8px;">
          <label class="ms-2">Sample Quality<span class="text-danger">*</span></label>
          <div class="row ms-1">
            <div class="col-md-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="aftcl_sample_quality" value="Acceptable">
                <label class="form-check-label">Acceptable</label>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="aftcl_sample_quality"
                  value="Non Acceptable">
                <label class="form-check-label">Non Acceptable</label>
              </div>
            </div>
          </div>
        </div>
        <span *ngIf="aftcLabErrors.aftcl_sample_quality"
          class="text-danger">{{aftcLabErrors.aftcl_sample_quality}}</span>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-group" style="border: 1px solid rgb(203, 200, 200); border-radius: 8px;">
          <label class="ms-2">Packing Condition</label>
          <div class="row">
            <div class="col-md-4 ms-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="aftcl_packing_condition" value="Good">
                <label class="form-check-label">Good</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="aftcl_packing_condition" value="Bad">
                <label class="form-check-label">Bad</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
            (change)="onShowOthers($event)" formControlName="aftcl_packing_type">
            <option selected>Select Packing Type</option>
            <option>Polythene Bags</option>
            <option>Aluminium Foil Covers</option>
            <option>Carton Box</option>
            <option>Plastic Containers</option>
            <option>Tins</option>
            <option>Polyethelene Containers</option>
            <option>Glass Bottles</option>
            <option value="Others">Others</option>
          </select>
          <label for="floatingSelect">Packing Type<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_packing_type" class="text-danger">{{aftcLabErrors.aftcl_packing_type}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Other Packing Type"
            formControlName="aftcl_other_packing_type" list="others" [readOnly]="isReadOnly">
          <label>Other Packing Type</label>
          <datalist id="others">
            <option *ngFor="let other of uniqueOtherPaking">{{other}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Discipline" formControlName="aftcl_discipline"
            list="discipline" readonly>
          <label>Discipline</label>
          <datalist id="discipline">
            <option *ngFor="let discipline of uniqueDiscipline">{{discipline}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" formControlName="aftcl_group"
            placeholder="Group">
            <option value="" selected>Select a Group</option>
            <option *ngFor="let param of testGroup" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Group<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_group" class="text-danger">{{aftcLabErrors.aftcl_group}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" formControlName="aftcl_sub_group"
            (click)="subGroupDetails($event)" placeholder="Sub Group">
            <option value="" selected>Select a Sub Group</option>
            <option *ngFor="let param of testSubGroup" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Sub Group<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_sub_group" class="text-danger">{{aftcLabErrors.aftcl_sub_group}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Sample Name" formControlName="sample_name"
            list="sampleName">
          <label>Sample Name</label>
          <datalist id="sampleName">
            <option *ngFor="let sample of uniqueSampleName">{{sample}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Product Description" formControlName="aftcl_description"
            list="description">
          <label>Product Description</label>
          <datalist id="description">
            <option *ngFor="let description of uniqueDescription">{{description}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Sample Qty Received"
            formControlName="aftcl_sample_qty_received" list="sampleReceived" >
          <label>Sample Qty Received</label>
          <datalist id="sampleReceived">
            <option *ngFor="let qty of uniqueQty">{{qty}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" formControlName="aftcl_test_sample"
            (change)="onSelectProduct($event)">
            <option value="" disabled selected hidden>Select a Product</option>
            <option *ngFor="let param of paramList" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Product<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_test_sample" class="text-danger">{{aftcLabErrors.aftcl_test_sample}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" [style.display]="isVisibleStandard ? 'block' : 'none'">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" (change)="onSelectStandard($event)">
            <option value="" selected hidden>Select a Standard</option>
            <option *ngFor="let param of testStandard" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Standard<span class="text-danger">*</span></label>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" [style.display]="isVisibleAnalysis ? 'block' : 'none'">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" (change)="onSelectAnalysis($event)">
            <option value="" selected hidden>Select a Analysis</option>
            <option *ngFor="let param of testPerformed" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Analysis<span class="text-danger">*</span></label>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" [style.display]="isVisibleParameter ? 'block' : 'none'">
        <div class="form-floating card" style="height: 100%;">
          <div style="max-height: 150px; overflow-y: auto;">
            <div *ngFor="let param of testAnalysis">
              <label class="ms-3">
                <input type="checkbox" [value]="param" (change)="onSelectParameter($event, param)">
                {{ param }}
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="container-fluid">

      <div class="row mt-4">
        <div class="col-md-12 text-center table-responsive-md">

          <table class="table table-striped">
            <thead class="">
              <tr>
                <th class="col-md-1">S.No</th>
                <th class="col-md-1">Product</th>
                <th class="col-md-2">Analysis</th>
                <th class="col-md-2">Parameter</th>
                <th class="col-md-1">Unit</th>
                <!-- <th class="col-md-2">Result</th> -->
                <!-- <th class="col-md-2">Norms</th> -->
                <th class="col-md-1"></th>
              </tr>
            </thead>
            <tbody *ngIf="!isUpdate">
              <tr class="text-center" *ngFor="let test of procedureResults;let i = index;">
                <td class="col-1">{{i + 1}}</td>
                <td class="col-1">{{test.aftcl_test_sample}}</td>
                <td class="col-1">{{test.aftcl_test_analysis}}</td>
                <td class="col-1">{{test.aftcl_test_parameter}}</td>
                <td class="col-1">
                  <p *ngIf="test.aftcl_test_unit == null">-</p>
                  <p *ngIf="test.aftcl_test_unit != null">{{test.aftcl_test_unit}}</p>
                </td>
                <td *ngIf="!isUpdate || newAdd">
                  <i class='fas fa-trash' style='font-size:24px' data-toggle="tooltip" data-placement="top"
                    title="Click to Delete Product" (click)="removeProduct(i)"></i>
                </td>
                <td *ngIf="isUpdate && !newAdd">

                  <i class='fas fa-trash' style='font-size:24px' data-toggle="tooltip" data-placement="top"
                    title="Click to Delete Product" (click)="removeProduct(test.aftcl_procedure_result_id)"></i>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="isUpdate">
              <tr class="text-center" *ngFor="let test of filterprocedureResults;let i = index;">
                <td class="col-1">{{i + 1}}</td>
                <td class="col-1">{{test.aftcl_test_sample}}</td>
                <td class="col-1">{{test.aftcl_test_analysis}}</td>
                <td class="col-1">{{test.aftcl_test_parameter}}</td>
                <td class="col-1">
                  <p *ngIf="test.aftcl_test_unit == null">-</p>
                  <p *ngIf="test.aftcl_test_unit != null">{{test.aftcl_test_unit}}</p>
                </td>
                <td *ngIf="!isUpdate || newAdd">
                  <i class='fas fa-trash' style='font-size:24px' data-toggle="tooltip" data-placement="top"
                    title="Click to Delete Product" (click)="removeProduct(i)"></i>
                </td>
                <td *ngIf="isUpdate && !newAdd">

                  <i class='fas fa-trash' style='font-size:24px' data-toggle="tooltip" data-placement="top"
                    title="Click to Delete Product" (click)="removeProduct(test.aftcl_procedure_result_id)"></i>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="row mt-3"></div>

      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-2 mb-5" *ngIf="buttonSave">
          <button type="submit" class="btn btn-success">{{this.isUpdate == true ? "Update" : "Save"}}</button>
        </div>
        <div class="col-md-2">
          <button (click)="NavPrintScreen()" [style.display]="isShowPrint ? 'block' : 'none'"
            class="btn btn-primary text-center" style="margin-left:10px" type="button">Print</button>
        </div>
        <div class="col-md-3"></div>
      </div>

    </div>
  </form>


  <ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
    <div id="page">
      <div id="container">
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
      </div>
    </div>
  </ngx-spinner>

</div>

<!-- Technical & Quality Manager Login Report Entry Screen-->

<div class="container-fluid" *ngIf="managerReportDisable">

  <div class="row">
    <div class="col-lg-12 col-12 col-md-12">
      <h6><img src="./assets/img/icons/microscope%20.png" class="img-fluid beaker"><span class="text-bold ml-1 mt-2">Lab
          Report Entry</span></h6>
    </div>
  </div>

  <div class="row" *ngIf="techAnalaystDisable">
    <div class="col-lg-12 col-12">
      <h6 class="mt-3"><span class="ml-2 text-bold">Test Analysis Report</span></h6>
    </div>
  </div>

  <form (ngSubmit)="onSubmit()" [formGroup]="aftclAllDetails" id="aftclCustomer">

    <div class="row text-center" *ngIf="techAnalaystDisable">
      <div class="col-lg-2 col-md-4 col-12" *ngIf="testingDisable">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="nablTest" value="NABL Test"
            (change)="testSelect($event)">
          <label class="form-check-label">NABL Test</label>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-12" *ngIf="testingDisable">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="normalTest" value="Normal Test"
            (change)="testSelect($event)">
          <label class="form-check-label">Normal Test</label>
        </div>
      </div>
      <div class="col-lg-2  col-md-4 col-12" *ngIf="testingDisable">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="internalTest" value="Internal Test"
            (change)="testSelect($event)">
          <label class="form-check-label">Internal Test</label>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="techAnalaystDisable">

      <div class="col-lg-3 col-md-6 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" id="floatingInput" placeholder="Sample Number"
            formControlName="aftcl_sample_no" (change)="sample($event)" readonly>
          <label for="floatingInput">Sample Number</label>
        </div>
        <span *ngIf="aftcLabErrors.aftcl_sample_no" class="text-danger">{{aftcLabErrors.aftcl_sample_no}}</span>
      </div>

      <div class="col-lg-3 col-md-6 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="ULR Number" formControlName="aftcl_ulr_no"
            value="{{ulrNoShow}}" readonly>
          <label for="floatingInput">ULR Number</label>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Report" formControlName="aftcl_report_no" readonly
            value="{{reportNo}}">
          <label for="floatingInput">Report Number</label>
        </div>
      </div>

      <div class="col-lg-3  col-md-6 col-12 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control" formControlName="aftcl_date" id="aftcl_date" placeholder="Date"
            [readOnly]="isReadOnly">
          <label for="floatingInput">Sample Received Date</label>
        </div>
      </div>

    </div>

    <div class="row" *ngIf="techAnalaystDisable">
      <div class="col-lg-12 col-12">
        <h6 class="mt-3"><span class="ml-2 text-bold">Customer Details</span></h6>
      </div>
    </div>

    <div class="row" *ngIf="techAnalaystDisable">

      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Name" formControlName="aftcl_customer_name"
            list="customerName" [readOnly]="isReadOnly">
          <datalist id="customerName">
            <option *ngFor="let name of uniqueCustomerNames">{{name}}</option>
          </datalist>
          <label for="floatingInput">Customer Name<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_customer_name"
            class="text-danger">{{aftcLabErrors.aftcl_customer_name}}</span>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Name" formControlName="aftcl_phone_number"
            list="phoneNo" (keypress)="OnlyNumbers($event)" maxlength="10" [readOnly]="isReadOnly">
          <datalist id="phoneNo">
            <option *ngFor="let phone of uniquePhoneNumbers">{{phone}}</option>
          </datalist>
          <label for="floatingInput">Mobile Number<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_phone_number" class="text-danger">{{aftcLabErrors.aftcl_phone_number}}</span>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Email ID" formControlName="aftcl_email_id" list="email"
            [readOnly]="isReadOnly">
          <datalist id="email">
            <option *ngFor="let name of nablTestList">{{name.aftcl_email_id}}</option>
          </datalist>
          <label for="floatingInput">Email ID</label>
          <span *ngIf="aftcLabErrors.aftcl_email_id" class="text-danger">{{aftcLabErrors.aftcl_email_id}}</span>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Address"
            formControlName="aftcl_customer_address" list="customerAddress" [readOnly]="isReadOnly">
          <datalist id="customerAddress">
            <option *ngFor="let address of uniqueAddresses">{{address}}</option>
          </datalist>
          <label for="floatingInput">Customer Address<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_customer_address"
            class="text-danger">{{aftcLabErrors.aftcl_customer_address}}</span>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Customer Reference"
            formControlName="aftcl_customer_reference" list="refer" [readOnly]="isReadOnly">
          <datalist id="refer">
            <option *ngFor="let refer of uniqueReferences">{{refer}}</option>
          </datalist>
          <label for="floatingInput">Customer Reference</label>
        </div>
      </div>

    </div>
  </form>

  <form (ngSubmit)="onSubmit()" [formGroup]="aftclAllDetails" id="aftclTestResult">

    <div class="row">
      <div class="col-lg-12 col-12">
        <h6 class="mt-3"><span class="ml-2 text-bold">Test Details</span></h6>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Sample Code Number" formControlName="aftcl_samplefa_no"
            readonly value="{{sampleFaNo}}">
          <label>Sample Code Number</label>
        </div>
      </div>

      <div class="col-lg-3 col-12 col-md-4 mt-2" *ngIf="techAnalaystDisable">
        <div class="form-floating">
          <input type="text" class="form-control" id="aftcl_sample_drawn_by" placeholder="Enter Sample Drawn by"
            formControlName="aftcl_sample_drawn_by" list="sample" [readOnly]="isReadOnly">
          <label>Sample Drawn By</label>
        </div>
        <datalist id="sample">
          <option *ngFor="let sample of uniqueSampleDrawn">{{sample}}</option>
        </datalist>
      </div>

      <div class="col-lg-3 col-12 col-md-4 mt-2" style="border: 1px solid rgb(203, 200, 200); border-radius: 8px;"
        *ngIf="techAnalaystDisable">
        <label class="">Sample Quality<span class="text-danger">*</span></label>
        <div class="row">
          <div class="col-md-6">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="aftcl_sample_quality" value="Acceptable"
                disabled>
              <label class="form-check-label">Acceptable</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="aftcl_sample_quality" value="Non Acceptable"
                disabled>
              <label class="form-check-label">Non Acceptable</label>
            </div>
          </div>
          <span *ngIf="aftcLabErrors.aftcl_sample_quality"
            class="text-danger">{{aftcLabErrors.aftcl_sample_quality}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control" formControlName="aftcl_test_starting_date">
          <label>Testing Start Date<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_test_starting_date"
            class="text-danger">{{aftcLabErrors.aftcl_test_starting_date}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control" formControlName="aftcl_test_complete_date">
          <label>Due Date<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_test_complete_date"
            class="text-danger">{{aftcLabErrors.aftcl_test_complete_date}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control" formControlName="aftcl_sample_received_date">
          <label>Report Date<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_sample_received_date"
            class="text-danger">{{aftcLabErrors.aftcl_sample_received_date}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" style="border: 1px solid rgb(203, 200, 200); border-radius: 8px;"
        *ngIf="techAnalaystDisable">
        <label>Packing Condition</label>
        <div class="row">
          <div class="col-md-4">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="aftcl_packing_condition" value="Good"
                disabled>
              <label class="form-check-label">Good</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="aftcl_packing_condition" value="Bad"
                disabled>
              <label class="form-check-label">Bad</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" *ngIf="techAnalaystDisable">
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
            (change)="onShowOthers($event)" formControlName="aftcl_packing_type" disabled>
            <option selected>Select Packing Type</option>
            <option>Polythene Bags</option>
            <option>Aluminium Foil Covers</option>
            <option>Carton Box</option>
            <option>Plastic Containers</option>
            <option>Tins</option>
            <option>Polyethelene Containers</option>
            <option>Glass Bottles</option>
            <option value="Others">Others</option>
          </select>
          <label for="floatingSelect">Packing Type<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_packing_type" class="text-danger">{{aftcLabErrors.aftcl_packing_type}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" *ngIf="techAnalaystDisable">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Other Packing Type"
            formControlName="aftcl_other_packing_type" list="others" [readOnly]="isReadOnly">
          <label>Other Packing Type</label>
          <datalist id="others">
            <option *ngFor="let other of uniqueOtherPaking">{{other}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" *ngIf="techAnalaystDisable">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Discipline" formControlName="aftcl_discipline"
            list="discipline" readonly>
          <label>Discipline</label>
          <datalist id="discipline">
            <option *ngFor="let discipline of uniqueDiscipline">{{discipline}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" *ngIf="techAnalaystDisable">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Group" formControlName="aftcl_group" list="group"
            readonly>
          <label>Group</label>
          <datalist id="group">
            <option *ngFor="let name of nablTestList">{{name.aftcl_group}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" *ngIf="techAnalaystDisable">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Group" formControlName="aftcl_sub_group" list="subGroup"
            readonly>
          <label>Sub Group</label>
          <datalist id="subGroup">
            <option *ngFor="let name of nablTestList">{{name.aftcl_sub_group}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Sample Name" formControlName="sample_name"
            list="sampleName" readonly>
          <label>Sample Name</label>
          <datalist id="sampleName">
            <option *ngFor="let sample of uniqueSampleName">{{sample}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Product Description" formControlName="aftcl_description"
            list="description" readonly>
          <label>Product Description</label>
          <datalist id="description">
            <option *ngFor="let description of uniqueDescription">{{description}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Sample Qty Received"
            formControlName="aftcl_sample_qty_received" list="sampleReceived" [readOnly]="isReadOnly">
          <label>Sample Qty Received</label>
          <datalist id="sampleReceived">
            <option *ngFor="let qty of uniqueQty">{{qty}}</option>
          </datalist>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" *ngIf="techAnalaystDisable">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" formControlName="aftcl_test_sample"
            (change)="onSelectProduct($event)" disabled>
            <option value="" disabled selected hidden>Select a Product</option>
            <option *ngFor="let param of paramList" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Product<span class="text-danger">*</span></label>
          <span *ngIf="aftcLabErrors.aftcl_test_sample" class="text-danger">{{aftcLabErrors.aftcl_test_sample}}</span>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" [style.display]="isVisibleStandard ? 'block' : 'none'">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" (change)="onSelectStandard($event)"
            disabled>
            <option value="" disabled hidden selected>Select a Standard</option>
            <option *ngFor="let param of testStandard" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Standard<span class="text-danger">*</span></label>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" [style.display]="isVisibleAnalysis ? 'block' : 'none'">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" formControlName="aftcl_test_sample"
            (change)="onSelectAnalysis($event)" disabled>
            <option value="" disabled selected hidden>Select a Analysis</option>
            <option *ngFor="let param of testPerformed" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Analysis<span class="text-danger">*</span></label>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2" [style.display]="isVisibleParameter ? 'block' : 'none'">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example" disabled>
            <option value=""></option>
            <option *ngFor="let param of testAnalysis" value="{{param}}">{{param}}</option>
          </select>
          <label for="floatingSelect">Select a Parameter<span class="text-danger">*</span></label>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-12 mt-2 text-center" [style.display]="isVisiblePlusIcon ? 'block' : 'none'">
        <button type="button" class="btn btn-success mt-2" (click)="onSelectPlusIcon()">ADD</button>
      </div>

    </div>

    <div class="container-fluid">

      <div class="row mt-3">
        <div class="col-md-12 text-center table-responsive-md">
          <table class="table table-striped" *ngIf="assigenTable">
            <thead class="">
              <tr>
                <th class="col-md-1">S.No</th>
                <th class="col-md-1">Product</th>
                <th class="col-md-2">Analysis</th>
                <th class="col-md-2">Parameter</th>
                <th class="col-md-1">Unit</th>
                <th class="col-md-2">Result</th>
                <th class="col-md-2" *ngIf="techAnalaystDisable">Assign</th>
              </tr>
            </thead>
            <tbody *ngFor="let test of filterprocedureResults;let i = index;">
              <tr class="text-center">
                <td class="col-1">{{i + 1}}</td>
                <td class="col-1">{{test.aftcl_test_sample}}</td>
                <td class="col-1">{{test.aftcl_test_analysis}}</td>
                <td class="col-1">{{test.aftcl_test_parameter}}</td>
                <td class="col-1">
                  <p *ngIf="test.aftcl_test_unit == null">-</p>
                  <p *ngIf="test.aftcl_test_unit != null">{{test.aftcl_test_unit}}</p>
                </td>
                <td class="col-1">
                  <p *ngIf="this.userName == 'TECHNICAL ANALYST'">
                    <input type="text" class="tdbox" value="{{test.aftcl_test_result}}"
                      (change)="onResultChange(test,$event)">
                  </p>
                  <p *ngIf="this.userName == 'TECHNICAL MANAGER' || this.userName == 'QUALITY MANAGER' ">
                    <input type="text" class="tdbox" value="{{test.aftcl_test_result}}"
                      (change)="onResultChange(test,$event)">
                  </p>
                </td>
                <td class="col-1" *ngIf="techAnalaystDisable">
                  <div *ngIf="test.aftcl_updated_at == ''; else inputField" class="checkbox-container">
                    <div *ngFor="let assigen of techAnalystName" class="form-check">
                      <input class="form-check-input d-flex" type="checkbox" id="{{assigen}}" [value]="assigen"
                        (change)="onAssigenChange(test, $event)">
                      <label class="form-check-label d-flex" for="{{assigen}}">{{assigen}}</label>
                    </div>
                  </div>
                  <ng-template #inputField>
                    <div class="tooltip-container" (mouseover)="onMouseOver(test.technicalAnalystName)">
                      <input type="text" class="form-control" [value]="test.technicalAnalystName"
                        [readonly]="techAnalaystDisable">
                      <div class="tooltip">{{test.technicalAnalystName}}</div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-striped" *ngIf="approvedtable">
            <thead class="">
              <tr>
                <th class="col-md-1">S.No</th>
                <th class="col-md-1">Product</th>
                <th class="col-md-2">Analysis</th>
                <th class="col-md-2">Parameter</th>
                <th class="col-md-1">Unit</th>
                <th class="col-md-2">Result</th>
                <th class="col-md-2" *ngIf="techAnalaystDisable">Assign</th>
                <th class="col-md-1"
                  *ngIf="this.userName == 'TECHNICAL MANAGER' || this.userName == 'QUALITY MANAGER' ">
                  Final Result Values</th>
              </tr>
            </thead>
            <tbody *ngFor="let test of procedureResults;let i = index;">
              <tr class="text-center">
                <td class="col-1">{{i + 1}}</td>
                <td class="col-1">{{test.aftcl_test_sample}}</td>
                <td class="col-1">{{test.aftcl_test_analysis}}</td>
                <td class="col-1">{{test.aftcl_test_parameter}}</td>
                <td class="col-1">
                  <p *ngIf="test.aftcl_test_unit == null">-</p>
                  <p *ngIf="test.aftcl_test_unit != null">{{test.aftcl_test_unit}}</p>
                </td>
                <td class="col-1">
                  <p *ngIf="this.userName == 'TECHNICAL ANALYST'">
                    <input type="text" class="tdbox" value="{{test.aftcl_test_result}}"
                      (change)="onResultChange(test,$event)">
                  </p>
                  <p *ngIf="this.userName == 'TECHNICAL MANAGER' || this.userName == 'QUALITY MANAGER' ">
                    <input type="text" class="tdbox" value="{{test.aftcl_test_result}}"
                      (change)="onResultChange(test,$event)">
                  </p>
                </td>
                <td class="col-1" *ngIf="techAnalaystDisable">
                  <div *ngIf="test.aftcl_updated_at == ''; else inputField" class="checkbox-container">
                    <div *ngFor="let assigen of techAnalystName" class="form-check ">
                      <input class="form-check-input d-flex justify-content-center" type="checkbox" id="{{assigen}}"
                        [value]="assigen" (change)="onAssigenChange(test, $event)">
                      <label class="form-check-label d-flex justify-content-center"
                        for="{{assigen}}">{{assigen}}</label>
                    </div>
                  </div>
                  <ng-template #inputField>
                    <div class="tooltip-container" (mouseover)="onMouseOver(test.technicalAnalystName)">
                      <input type="text" class="form-control" [value]="test.technicalAnalystName"
                        [readonly]="techAnalaystDisable">
                      <div class="tooltip">{{test.technicalAnalystName}}</div>
                    </div>
                  </ng-template>
                </td>
                <td *ngIf="this.userName == 'TECHNICAL MANAGER' || this.userName == 'QUALITY MANAGER' ">
                  <input type="checkbox" value="{{test.aftcApprove}}" [checked]="test.aftcApprove === 'Yes'"
                    (change)="onFinal(test, $event)">
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="row mt-3"></div>

      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-2 mb-5" *ngIf="buttonSave">
          <button type="submit" class="btn btn-success">{{this.isUpdate == true ? "Click to Assign" : "Save"}}</button>
        </div>
        <div class="col-md-2 mb-5" *ngIf="resultSave">
          <button type="submit" class="btn btn-success">{{this.isUpdate == true ? "Submit" : "Save"}}</button>
        </div>
        <div class="col-md-2 mb-5" *ngIf="isManagerStatus">
          <button type="submit" class="btn btn-success" [disabled]="ohShow">{{this.isUpdate == true ? "Approve" :
            "Save"}}</button>
        </div>
        <div class="col-md-2">
          <button (click)="NavPrintScreen()" [style.display]="isShowPrint ? 'block' : 'none'"
            class="btn btn-primary text-center" style="margin-left:10px" type="button">Print</button>
        </div>
        <div class="col-md-3"></div>
      </div>

    </div>
  </form>

  <ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
    <div id="page">
      <div id="container">
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
      </div>
    </div>
  </ngx-spinner>

</div>