<div class="container" style="border: 2px solid grey; background-color: pink">
  <div class="row">
    <span>GSTIN: <b>33AABCT1101F1Z7</b></span>
  </div>
  <div class="row mt-2">
    <div class="col-2 ml-4">
      <img src="../../../../../assets/img/AFTC%20logo.png" style="height: 150px; width: 150px">
    </div>
    <div class="col-9">
      <div class="mt-5"></div>
      <p class="text1">AGRO FOOD TRADE CENTRE</p>
      <p class="text5">Sikkandarchavadi, Alanganallur Road, Madurai - 625 018. <i class="fas fa-phone-alt ml-2"></i>
        0452-2660669</p>
      <h3 class="text-center"><b class="underline">DEPOSIT APPLICATION - WAREHOUSE</b></h3>
    </div>
  </div>

  <div class="row">
    <div class="col-7 mt-2">
      <div class="d-flex justify-content-start">
        <span class="content1">Deposit Application No: <b>{{depositorDetails?.depositApplicationNo}}</b></span>
      </div>
    </div>
    <div class="col-5">
      <div class="d-flex justify-content-end">
        <span class="content1">Date: <b>{{currentDate}}</b></span>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="row">
      <span class="content3 col-12"> Please accept the following commodity of our concern namely</span>
    </div>
    <div class="row">
      <div class="col-1">
        <span class="label-content">M/s</span>
      </div>
      <div class="col-4 underline text-center">
        <label class="label-content">{{depositorDetails?.depositorName}}</label>
      </div>
      <div class="col-5">
        <span class="label-content">vide Invoice No/Self declaration</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.invoiceNo}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <span class="label-content">dt&nbsp;</span>
        <label class="label-content underline">{{depositorDetails?.billDate | date: 'dd/MM/yyyy'}}</label>
      </div>
      <div class="col-1">
        <span class="label-content">from</span>
      </div>
      <div class="col-6 underline text-center">
        <label class="label-content">{{depositorDetails?.depositorName}}</label>
      </div>
      <div class="col-3">
        <span class="label-content">for storage and issue</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="label-content">of warehouse Receipt.</span>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <span class="label-content">Commodity</span>
      </div>
      <div class="col-3 underline text-center">
        <label class="label-content">{{depositorDetails?.commodity}}</label>
      </div>
      <div class="col-2">
        <span class="label-content">No. of Bags</span>
      </div>
      <div class="col-1 underline text-center">
        <label class="label-content">{{depositorDetails?.noOfBags}}</label>
      </div>
      <div class="col-1">
        <span class="label-content">Weight</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.weight}}</label>
      </div>
      <div class="col-1">
        <span class="label-content">(M.Tons)</span>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <span class="label-content">Type of Vehicle</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.typeOfVehicle}}</label>
      </div>
      <div class="col-1">
        <span class="label-content">Wheels</span>
      </div>
      <div class="col-1 underline text-center">
        <label class="label-content">{{depositorDetails?.wheels}}</label>
      </div>
      <div class="col-2">
        <span class="label-content">Vehicle No</span>
      </div>
      <div class="col-3 underline text-center">
        <label class="label-content">{{depositorDetails?.vehicleNo}}</label>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <span class="label-content">Toll Gate Receipt No</span>
      </div>
      <div class="col-4 underline text-center">
        <label class="label-content">{{depositorDetails?.irmReceiptNo}}</label>
      </div>
      <div class="col-3">
        <span class="label-content">and Amount Rs.</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.amount | currency: 'INR'}}</label>
      </div>
    </div>
  </div>
  <div class="row" style="border-bottom: 2px solid grey">
    <div class="col-6">
      <img *ngIf="depositorDetails?.billPeriodType == 'Day wise'" src="../../../../../assets/img/day%20wise.png"
           style="height: 135px">
      <img *ngIf="depositorDetails?.billPeriodType == 'Month wise'" src="../../../../../assets/img/month%20wise.png"
           style="height: 135px">
    </div>
    <div class="col-6 text-end" style="margin-top: 70px !important;">
      <label class="label-content">Signature of the Depositor/<br>Authorised Representative</label>
    </div>
  </div>

  <div class="row mt-2 justify-content-center">
    <button class="col-2">For Yard Use</button>
  </div>

  <div class="row mt-3">
    <div class="col-5 ml-3">
      <div class="d-flex justify-content-start">
        <label class="content1">1. Check the quality & accept</label>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-end">
        <label class="content1">2. Quality Report: Accepted / not accepted</label>
      </div>
    </div>
  </div>

  <div class="row mt-3" style="margin-top: 70px !important;">
    <div class="col-5 ml-3">
      <div class="d-flex justify-content-start">
        <label class="content1">Manager</label>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-end">
        <label class="content1">Quality Control Manager</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div>
      <label class="content1">3. Storage details :</label>
    </div>
  </div>

  <div class="row">
    <div class="col-2">
      <span class="label-content">Received</span>
    </div>
    <div class="col-1 underline text-center">
      <label class="label-content">{{depositorDetails?.noOfBags}}</label>
    </div>
    <div class="col-2" style="margin-right: -60px">
      <span class="label-content">Bags of</span>
    </div>
    <div class="col-3 underline text-center">
      <label class="label-content">{{depositorDetails?.commodity}}</label>
    </div>
    <div class="col-1">
      <span class="label-content">Weighing</span>
    </div>
    <div class="col-2 underline text-center">
      <label class="label-content">{{depositorDetails?.weight}}</label>
    </div>
    <div class="col-1">
      <span class="label-content">[M.tons]</span>
    </div>
  </div>

  <div class="row">
    <div class="col-4" style="margin-right: -50px">
      <span class="label-content">and stored in Godown No</span>
    </div>
    <div class="col-2 underline text-center">
      <label class="label-content">&nbsp;</label>
    </div>
    <div class="col-2" style="margin-right: -60px">
      <span class="label-content">Stack No</span>
    </div>
    <div class="col-2 underline text-center">
      <label class="label-content">&nbsp;</label>
    </div>
    <div class="col-3">
      <span class="label-content" style="word-spacing: 25px">and entered in</span>
    </div>
  </div>

  <div class="row">
    <div class="col-4" style="margin-right: -50px">
      <span class="label-content">Stock Register Page No</span>
    </div>
    <div class="col-2 underline text-center">
      <label class="label-content">&nbsp;</label>
    </div>
    <div class="col-5" style="margin-right: -40px">
      <span class="label-content">and Depositor's Register Page No</span>
    </div>
    <div class="col-2 underline text-center">
      <label class="label-content">&nbsp;</label>
    </div>
  </div>

  <div class="row mt-4" style="margin-top: 50px !important; border-bottom: 2px solid grey">
    <label class="content1 text-end">Godown Assistant/Godown Supervisor</label>
  </div>

  <div class="row mt-2">
    <div class="col-5">
      <label class="content1">4. Acknowledgement by Depositor :</label>
    </div>
  </div>

  <div class="row">
    <span style="font-size: 22px; margin-bottom: 1px">Deposit செய்ததில் கருக்காய், பச்சை நெல், கருப்பு நெல், பொக்கு உடைசல், கல், கரம்பைமண், தூசி</span>
    <span
      style="font-size: 22px; margin-bottom: 1px">உள்ளது. இதனால் ஏற்படும் சேதாரத்திற்கு நாங்களே பொறுப்பேற்கிறோம்.</span>
  </div>

  <div class="row ml-3">
    <div class="col-6">
      <span style="font-size: 22px; margin-bottom: 1px">Received the Warehouse Receipt bearing No</span>
    </div>
    <div class="col-2 underline text-center">
      <label style="font-size: 22px; margin-bottom: 1px">&nbsp;</label>
    </div>
    <div class="col-1">
      <span style="font-size: 22px; margin-bottom: 1px">dated</span>
    </div>
    <div class="col-2 underline text-center">
      <label style="font-size: 22px; margin-bottom: 1px">&nbsp;</label>
    </div>
    <div class="col-1">
      <span style="font-size: 22px; margin-bottom: 1px">We</span>
    </div>
  </div>

  <div class="row ml-3">
    <span style="font-size: 22px; margin-bottom: 1px">Shall abide by all term and conditions. We undertake to pay Storage Charges on or before taking delivery of goods.</span>
  </div>

  <div class="row mt-4" style="margin-top: 50px !important">
    <label class="content1 text-end">Signature of the Depositor/Authorised Representative</label>
  </div>
</div>
