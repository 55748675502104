<div class="container-fulid py-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="div">
          <label>Date Filter</label>
          <input type="date" class="form-control" (change)="dateFilter($event)">
        </div>
      </div>
      <div class="col-lg-6"></div>
      <div class="col-lg-3">
        <div class="div mt-3">
          <label></label>
          <input type="search" class="form-control" placeholder="Search" [(ngModel)]="searchText">
        </div>
      </div>
    </div>
  </div>
  <div class="py-2"></div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
        <tr class="sticky-top">
          <th>No</th>
          <th>Vehicle Registration No</th>
          <th>Vehicle Type</th>
          <th>Usage</th>
          <th>Check In</th>
          <th>Check Out</th>
          <th>Date</th>
        </tr>
        </thead>
        <tbody *ngIf="this.getVehicle.length > 0; else noDataFound">
        <tr *ngFor="let aftc of this.getVehicle | filter: searchText;let i = index">
          <td>{{i + 1}}</td>
          <td>{{aftc.vehicleRegDetails.registrationNumber}}</td>
          <td>{{aftc.vehicleRegDetails.typeOfvehicle}}</td>
          <td>{{aftc.vehicleRegDetails.usage}}</td>
          <td>{{aftc.inTime === 0 ? '--' : (aftc.inTime | date:'dd/MM/yyyy, HH:mm:ss')}}</td>
          <td>{{aftc.outTime === 0 ? '--' : (aftc.outTime | date:'dd/MM/yyyy, HH:mm:ss') }}</td>
          <td>{{aftc.createdAt | date:'dd/MM/yyyy'}}</td>
        </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr>
            <td colspan="11" class="notfound">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
