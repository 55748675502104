<app-weigh-bridge-navbar *ngIf="userType == 'ADMIN'"></app-weigh-bridge-navbar>
<div class="container -fulid">
  <div *ngIf="userType == 'ADMIN'" class="row"></div>
  <div class="row mt-4">
    <div class="col-md-8 justify-content-start">
      <div class="div mt-5">
        <h3>Weigh Bridge View</h3>
      </div>
    </div>
    <div class="col-md-4 justify-content-end">
      <div class="div mt-5">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>Entry Date/Time</th>
            <th>IRM No</th>
            <th>Service Type</th>
            <th>Driver Name</th>
            <th>Vehicle Number</th>
            <th>Wheel Count</th>
          </tr>
        </thead>
        <tbody *ngFor="let aftc of this.getPassDetails | filter: searchText;let i = index">
        <tr>
          <td (click)="checkRecord(aftc)">{{i + 1}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.entryDate}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.irmNo}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.serviceType}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.tollgateVehicleDetails.driverName}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.tollgateVehicleDetails.vehNumberPlate}}</td>
          <td (click)="checkRecord(aftc)">{{aftc.tollgateVehicleDetails.wheelCount}}</td>
          <!--<td>
            <div *ngIf="aftc.weighBridgeMapId == 0"> - </div>
            <div *ngIf="aftc.weighBridgeMapId != 0" (click)="report(aftc.weighBridgeMapId)"><i class="fas fa-print"></i></div>
          </td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>
