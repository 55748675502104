import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import * as XLSX from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";
import { StockDetails } from "@/model/stockDetails/stockDetails";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-Impact-data-view",
  templateUrl: "./Impact-data-view.component.html",
  styleUrls: ["./Impact-data-view.component.scss"]
})
export class ImpactDataView implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  stockDetails: StockDetails [] = [];
  dataEntry: StockDetails [] = [];
  depositerName: String  [] = [];
  stackNumber: String [] = [];
  nameFilter: string | null = null;
  numberFilter: string | null = null;
  under3Months: string | null = null;
  under6Months: string | null = null;
  under1Years: string | null = null;
  above1Years: string | null = null;
  searchText;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {

  }

  ngOnInit() {
    this.getStockDetails();
  }

  ngOnDestroy() {
  }

  // Get all stock details
  getStockDetails() {
    this.spinner.show();
    this.apiServices.getInpactDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.stockDetails = data;
        const depositer = new Set(this.stockDetails.map(item => item.nameOfDepositor));
        this.depositerName = Array.from(depositer).filter(vendor => vendor !== null && vendor !== "").sort((a, b) => a.localeCompare(b));
        const stackNumber = new Set(this.stockDetails.map(item => item.stackNumber));
        this.stackNumber = Array.from(stackNumber);
        this.stockDetails.forEach(item => {
          const stockInDate = new Date(item.stockInDate);
          const stockOutDate = new Date(item.stockOutDate);
          const timeDifference = stockOutDate.getTime() - stockInDate.getTime();
          const agingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          item.agingDays = agingDays;
        });
        this.dataEntry = this.stockDetails;
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  updateStock(event: any, filterType: string) {
    const value = event.target.value;
    if (filterType === "under3Month") {
      this.under3Months = this.under3Months === "under3Month" ? null : "under3Month";
    } else if (filterType === "under6Month") {
      this.under6Months = this.under6Months === "under6Month" ? null : "under6Month";
    } else if (filterType === "under1Year") {
      this.under1Years = this.under1Years === "under1Year" ? null : "under1Year";
    } else if (filterType === "above1Year") {
      this.above1Years = this.above1Years === "above1Year" ? null : "above1Year";
    }
    switch (filterType) {
      case "name":
        this.nameFilter = value !== "Select Depositor Name" ? value : null;
        break;
      case "number":
        this.numberFilter = value !== "Select Stack Number" ? value : null;
        break;
      default:
        break;
    }
    this.applyFilters();
  }

  // Filter based on selection
  applyFilters() {
    const nameFiltered = this.nameFilter ? this.stockDetails.filter(item => item.nameOfDepositor === this.nameFilter) : this.stockDetails;
    const numberFiltered = this.numberFilter ? nameFiltered.filter(item => item.stackNumber === this.numberFilter) : nameFiltered;
    const under3months = this.under3Months ? numberFiltered.filter(item => item.agingDays <= 90) : numberFiltered;
    const under6months = this.under6Months ? under3months.filter(item => item.agingDays <= 180) : under3months;
    const under1years = this.under1Years ? under6months.filter(item => item.agingDays <= 365) : under6months;
    const above1years = this.above1Years ? under1years.filter(item => item.agingDays > 365) : under1years;
    this.dataEntry = above1years;
  }


  // Generate excel sheet
  generateVistorsXLSX() {
    const desiredColumns = ["nameOfDepositor", "stackNumber", "stockInDate", "stockInWeightMachine", "stockOutDate", "stockOutWeightParty", "stockOutWeightMachine", "difference", "average"];

    const header = desiredColumns.map(column => {
      const parts = column.split(".");
      return parts[parts.length - 1];
    });

    const headerStyle = {
      fill: {
        fgColor: { rgb: "black" }
      },
      font: {
        color: { rgb: "FFFFFF" },
        bold: true
      }
    };

    const filteredData = this.dataEntry.map(item =>
      desiredColumns.reduce((obj, key) => {
        const keys = key.split(".");
        let value: any = item;
        keys.forEach(k => {
          if (value && typeof value === "object" && k in value) {
            value = value[k];
          } else {
            value = undefined;
          }
        });


        obj[key] = value;
        return obj;
      }, {})
    );

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);

    const workbook: XLSX.WorkBook = { Sheets: { "data": worksheet }, SheetNames: ["data"] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const data: Blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

    saveAs(data, "Impact-Study Details" + " Report.xlsx");
  }


}

