import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-tollgate-receipt",
  templateUrl: "./tollgate-receipt-print-component.html",
  styleUrls: ["./tollgate-receipt-print-component.css"]
})
export class TollgateReceiptPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  reportQr: string;
  currentGatePass: TollGate | undefined;
  currentDate: string;
  entryTime: string;
  entryDate: string;
  exitTime: string;
  private intervalSubscription: Subscription;
  tollgateView: string;

  constructor(private apiService: ApiService, private datePipe: DatePipe,
              private router: Router, private toaster: ToastrService) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit(): void {
    this.reportQr = sessionStorage.getItem("reportQr");
    this.tollgateView = sessionStorage.getItem("typeOfprint");
    this.getGatePass();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("reportQr");
  }

  // Get tollgate detail by id for fetch data in receipt
  getGatePass() {
    this.apiService.getTollGate(this.reportQr)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.currentGatePass = data;
        const entryDate = new Date(data.vehEntryDateTime * 1000);
        const entryHours = entryDate.getHours().toString().padStart(2, "0");
        const entryMinutes = entryDate.getMinutes().toString().padStart(2, "0");
        const formattedEntryTime = `${entryHours}:${entryMinutes}`;
        this.entryTime = formattedEntryTime;

        const formattedDate = `${entryDate.getDate().toString().padStart(2, "0")}/${
          (entryDate.getMonth() + 1).toString().padStart(2, "0")}/${entryDate.getFullYear()}`;
        this.entryDate = formattedDate;

        if (data.vehExitDateTime != 0) {
          const exitDate = new Date(data.vehExitDateTime * 1000);
          const exitHours = exitDate.getHours().toString().padStart(2, "0");
          const exitMinutes = exitDate.getMinutes().toString().padStart(2, "0");
          const formattedExitTime = `${exitHours}:${exitMinutes}`;

          this.exitTime = formattedExitTime;
        }
        this.intervalSubscription = interval(1000).subscribe(() => {
          this.print();
        });
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Receipt print function
  print() {
    const printStyle = "";
    `
      @media print {
        @page {
          size: A5; /* Set your desired page size here */
        }
      }
    `;
    const originalTitle = document.title;
    document.title = this.currentGatePass.tollgateVehicleDetails.vehNumberPlate;
    const style = document.createElement("style");
    style.innerHTML = printStyle;
    document.head.appendChild(style);
    window.print();
    document.head.removeChild(style);
    document.title = originalTitle;
    this.intervalSubscription.unsubscribe();

    setTimeout(() => {
      if (localStorage.getItem("frontOffice") == "true") {
        localStorage.removeItem("reportQr");
        localStorage.removeItem("frontOffice");
        this.router.navigate(["/main/frontOffice"]);
      } else if (this.tollgateView == "tollgateView") {
        localStorage.removeItem("reportQr");
        localStorage.removeItem("typeOfprint");
        this.router.navigate(["/main/tollGateView"]);
      } else {
        sessionStorage.removeItem("reportQr");
        this.router.navigate(["/main/tollgate"]);
      }
    });
  }

}



