import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'register-box';

  public registerForm: any;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private apiService:ApiService

  ) {}

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'register-page');
    this.registerForm =  this.formBuilder.group({
      password: new FormControl(null, [Validators.required]),
      retypePassword: new FormControl(null, [Validators.required]),
      loginId: new FormControl(null, [Validators.required]),
      grantType: "",
      enabled: "",
      loginPhone: "",
      lastLoginDate: "",
      lastLoginTime: "",
      lastLoginIpAddr: "",
      lastLoginLat: "",
      lastLoginLong: ""
    });
  }

  async registerByAuth() {
    if (this.registerForm.valid) {
      this.isAuthLoading = true;
      await this.appService.registerByAuth(this.registerForm.value);
      this.isAuthLoading = false;
    } else {
      this.toastr.error('Form is not valid!');
    }
  }

  async registerByGoogle() {
    this.isGoogleLoading = true;
    await this.appService.registerByGoogle();
    this.isGoogleLoading = false;
  }

  async registerByFacebook() {
    this.isFacebookLoading = true;
    await this.appService.registerByFacebook();
    this.isFacebookLoading = false;
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
  }
}
