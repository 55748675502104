import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-renter-details',
  templateUrl: './renter-details.component.html',
  styleUrls: ['./renter-details.component.scss'],
})
export class RenterDetailsComponent {
  renterSheet: any;

  onSubmit() {
    let field_check = this.checkObj();
    if (!field_check) {
      return;
    } else {
      console.log(this.renterSheet.value);
      this.apiService.addRenter(this.renterSheet.value);

      this.renterSheet.reset();
      this.initial_data();
    }
  }

  checkObj() {
    return true;
  }

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {}
  ngOnInit() {
    this.initial_data();
  }

  initial_data() {
    this.renterSheet = this.formBuilder.group({
      renterFirstName: '',
      renterLastName: '',
      renterLegalCompanyName: '',
      renterPAN: '',
      renterCIN: '',
      renterGSTN: '',
      renterPhoneNumber: '',
      renterEmail: '',
      renterWhatsapp: '',
      renterStreet1: '',
      renterStreet2: '',
      renterVillage: '',
      renterCity: '',
      renterState: '',
      renterPincode: 0,
    });
  }
}
