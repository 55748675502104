<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Product Form</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Blank Page</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fl ">
    <form (ngSubmit)="onSubmit()" [formGroup]="productForm">

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="productName">Product Name</label>
            <input formControlName="productName" class="form-control" id="productName" placeholder="Product Name" type="text">
          </div>
        </div>
      </div>



      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="productBioName"> Product Bio Name</label>
            <input formControlName="productBioName" class="form-control" id="productBioName" placeholder="Product Bio Name" type="text">
          </div>
        </div>
      </div>



      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="productAltName"> Product Alt Name</label>
            <input formControlName="productAltName" class="form-control" id="productAltName" placeholder="Product Alt Name" type="text">
          </div>
        </div>

      </div>


      <div class="row">
        <div class="col-md-12">
          <button class="button" type="submit">Save</button>
        </div>
      </div>
    </form>


  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
