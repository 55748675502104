import { Injectable } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Gatekeeper } from "gatekeeper-client-sdk";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoginResponseModel } from "@/model/login-response/login-response-model";
import { LoginRequest } from "@/model/login-users/login-request";
import { ConfigService } from "@/config.service";

@Injectable({
  providedIn: "root"
})
export class AppService {
  public user: any;
  userDetails: LoginResponseModel;
  baseURL = "https://tomcat3.varnik.cloud:8443/aftc-ws";
  //baseURL = 'http://127.0.0.1:8084';
  // baseURL = 'http://136.233.82.212:8080/aftc-ws';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  async loginByAuth(loginform: any) {
    const username = loginform.get("username").value;
    const password = loginform.get("password").value;

    let loginRequest = new LoginRequest();
    loginRequest.username = username;
    loginRequest.password = password;

    try {
      const add_url = this.configService.getConfig().END_POINT + "/auth/api";
      const body = JSON.stringify(loginRequest);
      const headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      let status = 502;
      this.user = await this.http
        .post<LoginResponseModel>(add_url, body, headers)
        .subscribe(
          (data) => {
            if (data != null) {
              this.userDetails = data;
              localStorage.setItem(
                "department",
                String(
                  this.userDetails.loginDto.employeeSeq.deptDetails.deptId
                    .departmentName
                )
              );
              localStorage.setItem("isLoggedIn", "true");
              localStorage.setItem(
                "authHeader",
                String(this.userDetails.authHeader)
              );
              localStorage.setItem(
                "role",
                String(this.userDetails.loginDto.role)
              );
              localStorage.setItem(
                "grantType",
                String(this.userDetails.loginDto.grantType)
              );
              localStorage.setItem(
                "userName",
                String(this.userDetails.loginDto.employeeSeq.emName)
              );
              localStorage.setItem(
                "loginId",
                String(this.userDetails.loginDto.loginId)
              );

              this.router.navigate(["/main/dash"]);

              this.toastr.success("Login Successfully");
            }
          },
          (error) => {
            this.toastr.error("User Name or Password is Wrong");
          }
        );
    } catch (error) {
      this.toastr.error("Network Error.Try again Later");
    }
  }

  async registerByAuth(registerForm: any) {
    try {
      let addUrl = "http://127.0.0.1:8084/auth/aftc-users/add";
      const body = JSON.stringify(registerForm);
      const headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      const a = await this.http
        .post<LoginResponseModel>(addUrl, body, headers)
        .subscribe((data) => {
          localStorage.setItem("Authorization", data.authHeader);
        });

      await this.getProfile();
      this.router.navigate(["/"]);
      this.toastr.success("Register success");
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByGoogle() {
    try {
      const token = await Gatekeeper.loginByGoogle();
      localStorage.setItem("token", token);
      await this.getProfile();
      this.router.navigate(["/"]);
      this.toastr.success("Login success");
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByGoogle() {
    try {
      const token = await Gatekeeper.registerByGoogle();
      localStorage.setItem("token", token);
      await this.getProfile();
      this.router.navigate(["/"]);
      this.toastr.success("Register success");
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByFacebook() {
    try {
      const token = await Gatekeeper.loginByFacebook();
      localStorage.setItem("token", token);
      await this.getProfile();
      this.router.navigate(["/"]);
      this.toastr.success("Login success");
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByFacebook() {
    try {
      const token = await Gatekeeper.registerByFacebook();
      localStorage.setItem("token", token);
      await this.getProfile();
      this.router.navigate(["/"]);
      this.toastr.success("Register success");
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async getProfile() {
    try {
      const token = localStorage.getItem("authHeader");

      if (token == null) {
        this.user = null;
        this.router.navigate(["/login"]);
      } else {
        this.user = token;
      }
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.user = null;
    this.router.navigate(['/']);
  }
}
