import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import {
  PreprocessingDepositDetails
} from "@/components/aftc-digitalization/aftc-digitalization-models/preprocessingDepositDetails";


@Component({
  selector: "app-preprocessing-deposit-print",
  templateUrl: "./preprocessing-deposit-print.component.html",
  styleUrls: ["./preprocessing-deposit-print.component.scss"]
})
export class PreprocessingDepositPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  depositorDetails: PreprocessingDepositDetails | undefined;
  currentDate: string;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private toaster: ToastrService, private datePipe: DatePipe, private router: Router) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit() {
    const depositorId = sessionStorage.getItem("depositorId");
    this.getDepositorDetailsById(depositorId);
  }

  ngOnDestroy() {
    sessionStorage.removeItem("depositorId");
  }

  getDepositorDetailsById(depositorId) {
    this.apiServices.getPreprocessingDepositDetailsById(depositorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositorDetails = data;
        setTimeout(() => {
          this.print();
        }, 3000);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  print() {
    window.print();
    setTimeout(() => {
      this.router.navigate(["/main/frontOffice"]);
    });
  }
}
