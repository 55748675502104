export const BikeDetails = [
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Activa"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "CB Shine"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "CB Unicorn"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Aviator"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Dio"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Dream Yuga"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "CB 300R"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "CB Shine"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Unicorn"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "CB Twister"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Activa-I"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "CB 350 RS"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "CRF1000L"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Hness"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "Hornet 2.0"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "SP"
    },
    {
      "bikeBrand": "HONDA",
      "bikeModel": "X-Blade"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar 150 Dtsi"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar 150"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Avenger"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Platina"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar 180 Dtsi"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar NS"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Blade"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Classic"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "CT 100"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "CT 125"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover M"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar N"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar 125"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover 125"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover 150"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "V15"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Aspire"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Boxer 150"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Caliber"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Chetak"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover 112"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover F"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover S"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover ST"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Discover T"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Dominar 250"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Dominar 400"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Eliminator"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Kawasaki Ninja"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "KB 100"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "KB 125 RTZ"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Kristal"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Legend"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "M 80"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar 180"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar 200 Dtsi"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar 220 Dtsi"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar AS"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Pulsar RS"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Sunny"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Super"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "SX Enduro"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "V12"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Wave"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "Wind 125"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "XCD"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "XCD 125"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "XCD 135"
    },
    {
      "bikeBrand": "BAJAJ",
      "bikeModel": "XCD 135 Dtsi"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Apache"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Jupiter"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Scooty"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Wego"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Star City"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Sport"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "XL Super"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Zest"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Iqube"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Ntorq"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Ronin"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Star"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Victor"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Phoenix"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Centra"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Champ"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Flame"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Jive"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "MAX"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "MAX 4R"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Radeon"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Raider"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Scooty (Ev)"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "Suzuki Fiero"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "TVS 50"
    },
    {
      "bikeBrand": "TVS",
      "bikeModel": "XL"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "FZS"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "FZ 16"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "Fazer"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "FZ"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "YZF R15"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "Ray"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "Alpha"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "Aerox"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "SZ R"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "Gladiator"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "SS"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "XJR 1300"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "XTZ 125"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "FZ X"
    },
    {
      "bikeBrand": "YAMAHA",
      "bikeModel": "MT 15"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Maestro"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Passion Pro"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Splendor Pro"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Passion Xpro"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "HF Deluxe"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Splendor Plus"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Glamour"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Pleasure"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "CD Dawn"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "CD Deluxe"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Glamour FI"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Passion"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Passion Plus"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Super Splendor"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Xoom"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Splendor Ismart"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Ignitor"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Achiever"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "CBZ X-Treme"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Destini"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Duet"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Glamour 2017"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "HF 100"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "HF Dawn"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "HF Deluxe I3S"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Hunk"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Impulse"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Karizma"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Maestro Edge"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Passion Pro I3S"
    },
    {
      "bikeBrand": "HERO",
      "bikeModel": "Xtreme"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Classic"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Thunderbird"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Electra"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Bullet"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Himalayan"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Meteor"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Hunter"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Scram"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Shotgun"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Super Meteor"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Continental GT"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Machismo"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Eledro"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Interceptor"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Classic 500"
    },
    {
      "bikeBrand": "ROYAL ENFIELD",
      "bikeModel": "Taurus"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Access"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Gixxer"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Swish"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "GS150R"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Hayate"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Lets"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Burgman"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Intruder"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Katana"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "UZ"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Slingshot"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Zeus"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Heat"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Avenis"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Bandit"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Gixxer Sf Fi"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "GSX"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "GT 125"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Hayabusa"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Hayabusa GSX"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Inazuma"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "IND"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "MAX"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "RM"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Samurai"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "Shaolin"
    },
    {
      "bikeBrand": "SUZUKI",
      "bikeModel": "V-Strom"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Centuro"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Duro"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Gusto"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Rodeo"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Sym Flyte"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Kine"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Mojo"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Pantero"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Stallio"
    },
    {
      "bikeBrand": "MAHINDRA",
      "bikeModel": "Zesto"
    },
    {
      "bikeBrand": "KTM",
      "bikeModel": "Duke"
    },
    {
      "bikeBrand": "KTM",
      "bikeModel": "RC"
    },
    {
      "bikeBrand": "KTM",
      "bikeModel": "Super Duke"
    },
    {
      "bikeBrand": "KTM",
      "bikeModel": "250 Adventure"
    },
    {
      "bikeBrand": "KTM",
      "bikeModel": "390 Adventure"
    },
    {
      "bikeBrand": "KTM",
      "bikeModel": "690"
    },
    {
      "bikeBrand": "OLA",
      "bikeModel": "S1"
    },
  ]

export  const truckList = [
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Jeeto"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Jayo"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Furio 14"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Furio 12"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Blazo X 35"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Furio 16"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Furio 14 HD"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Blazo X 49"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Blazo X 28"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Furio 11"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Furio 17"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Blazo X 42"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Blazo X 42 PUSHER AXLE"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Blazo X 35 LIFT AXLE"
  },
  {
    "truckBrand": "MAHINDRA",
    "truckModel": "Mahindra Bolero Camper"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata 1412 LPT"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata 407 Gold SFC 29 WB"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata T.10 Ultra"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata LPT 4225 Cowl"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata 709g LPT"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata Ultra 1918.T"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata 1512 LPT"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata LPT 3518 Cowl"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata Signa 4825.TK"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata T.7 ultra"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata LPT 1918 Cowl"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata LPT 4825"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata Signa 4825.T"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata 1212 LPT"
  },
  {
    "truckBrand": "TATA",
    "truckModel": "Tata 1109g LPT"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland 1920 4X2"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Dost+"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland BADA DOST"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Dost Strong"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Partner 6 Tyre"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland 1920 4x2"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Ecomet 1615 HE"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Partner 4 Tyre"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland 4220 10x2 MAV"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland 4825 10x2 DTLA MAV"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland 2820 6x2 MAV"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland 3520 Twin Steering"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Ecomet 1015 HE"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Ecomet 1215"
  },
  {
    "truckBrand": "ASHOK LEYLAND",
    "truckModel": "Ashok Leyland Ecomet 1015"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 1923C"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 2823C"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 1617R"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 1217C"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 5528TT"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 1015R"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 3528C"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 3523R"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 4228R"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 1415R"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 2828C"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 2823R"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 3528CM"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 1215R"
  },
  {
    "truckBrand": "BHARAT BENZ",
    "truckModel": "BharatBenz 4023TT"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2049"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 3015"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 3019"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2095XP"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2059"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2049 CNG"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2110"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2055"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2114XP"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2059XP"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2075"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 6048"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 6019"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 3014"
  },
  {
    "truckBrand": "EICHER",
    "truckModel": "Eicher Pro 2114XP CNG"
  },
]

export const  carList = [
  {
    "carBrand": "TATA",
    "carModel": "ALTROZ"
  },
  {
    "carBrand": "TATA",
    "carModel": "Harrier"
  },
  {
    "carBrand": "TATA",
    "carModel": "NEXON"
  },
  {
    "carBrand": "TATA",
    "carModel": "Punch"
  },
  {
    "carBrand": "TATA",
    "carModel": "SAFARI"
  },
  {
    "carBrand": "TATA",
    "carModel": "TIAGO"
  },
  {
    "carBrand": "TATA",
    "carModel": "TIAGO EV"
  },
  {
    "carBrand": "TATA",
    "carModel": "TIGOR"
  },
  {
    "carBrand": "TATA",
    "carModel": "INDICA V2"
  },
  {
    "carBrand": "TATA",
    "carModel": "INDICA VISTA"
  },
  {
    "carBrand": "TATA",
    "carModel": "NANO"
  },
  {
    "carBrand": "TATA",
    "carModel": "Nexon EV Max"
  },
  {
    "carBrand": "TATA",
    "carModel": "Nexon EV Prime"
  },
  {
    "carBrand": "TATA",
    "carModel": "SAFARI DICOR 2.2 VTT"
  },
  {
    "carBrand": "TATA",
    "carModel": "TIAGO NRG"
  },
  {
    "carBrand": "TATA",
    "carModel": "ZEST"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "BALENO"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "Brezza"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "ERTIGA"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "Fronx"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "GRAND VITARA"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "SWIFT"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "Vitara Brezza"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "WAGON R"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "ALTO"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "ALTO K10"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "CELERIO"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "DZIRE"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "IGNIS"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "JIMNY"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "SWIFT DZIRE"
  },
  {
    "carBrand": "MARUTHI",
    "carModel": "XL6"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "AURA"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "CRETA"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "Exter"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "GRAND i10"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "Grand i10 Nios"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "i 20"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "Venue"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "VERNA"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "Alcazar"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "i 10 1.1"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "i 10 1.2 KAPPA"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "i20 N Line"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "Ioniq 5"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "SANTRO"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "TUCSON"
  },
  {
    "carBrand": "HYUNDAI",
    "carModel": "Venue N Line"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "BOLERO"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "SCORPIO"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "SCORPIO M HAWK"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "Scorpio N"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "THAR"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "XUV 400"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "XUV300"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "XUV700"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "BOLERO POWER PLUS"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "KUV100"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "Marazzo"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "SCORPIO M2DICR"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "SUPRO"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "TUV300"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "XUV500"
  },
  {
    "carBrand": "MAHINDRA",
    "carModel": "XYLO"
  },
  {
    "carBrand": "KIA",
    "carModel": "Carens"
  },
  {
    "carBrand": "KIA",
    "carModel": "CARNIVAL"
  },
  {
    "carBrand": "KIA",
    "carModel": "EV6"
  },
  {
    "carBrand": "KIA",
    "carModel": "SELTOS"
  },
  {
    "carBrand": "KIA",
    "carModel": "Sonet"
  },
  {
    "carBrand": "HONDA",
    "carModel": "AMAZE"
  },
  {
    "carBrand": "HONDA",
    "carModel": "CITY"
  },
  {
    "carBrand": "HONDA",
    "carModel": "CITY ZX"
  },
  {
    "carBrand": "HONDA",
    "carModel": "CIVIC"
  },
  {
    "carBrand": "HONDA",
    "carModel": "Elevate"
  },
  {
    "carBrand": "HONDA",
    "carModel": "JAZZ"
  },
  {
    "carBrand": "HONDA",
    "carModel": "NEW CITY"
  },
  {
    "carBrand": "HONDA",
    "carModel": "WR-V"
  },
  {
    "carBrand": "HONDA",
    "carModel": "ACCORD"
  },
  {
    "carBrand": "HONDA",
    "carModel": "BR-V"
  },
  {
    "carBrand": "HONDA",
    "carModel": "BRIO"
  },
  {
    "carBrand": "HONDA",
    "carModel": "CIVIC HYBRID"
  },
  {
    "carBrand": "HONDA",
    "carModel": "CRV"
  },
  {
    "carBrand": "HONDA",
    "carModel": "MOBILIO"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "FORTUNER"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "GLANZA"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "INNOVA"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "Innova Crysta"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "Innova Hycross"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "Rumion"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "Urban Cruiser"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "Urban Cruiser Hyryder"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "CAMRY"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "COROLLA"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "COROLLA ALTIS"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "ETIOS"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "ETIOS LIVA"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "Hilux"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "LAND CRUISER"
  },
  {
    "carBrand": "TOYOTA",
    "carModel": "YARIS"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "CAPTUR"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "DUSTER"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "FLUENCE"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "KIGER"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "Kwid"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "LODGY"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "SCALA"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "Triber"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "KOLEOS"
  },
  {
    "carBrand": "RENAULT",
    "carModel": "PULSE"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "GTR"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "Kicks"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "Magnite"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "MICRA"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "MICRA ACTIVE"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "SUNNY"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "TEANA"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "X-TRAIL"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "EVALIA"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "MICRA NEW MC"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "TERRANO"
  },
  {
    "carBrand": "NISSAN",
    "carModel": "Y62"
  },
  {
    "carBrand": "SKODA",
    "carModel": "FABIA"
  },
  {
    "carBrand": "SKODA",
    "carModel": "Karoq"
  },
  {
    "carBrand": "SKODA",
    "carModel": "KODIAQ"
  },
  {
    "carBrand": "SKODA",
    "carModel": "Kushaq"
  },
  {
    "carBrand": "SKODA",
    "carModel": "OCTAVIA"
  },
  {
    "carBrand": "SKODA",
    "carModel": "RAPID"
  },
  {
    "carBrand": "SKODA",
    "carModel": "SLAVIA"
  },
  {
    "carBrand": "SKODA",
    "carModel": "SUPERB"
  },
  {
    "carBrand": "SKODA",
    "carModel": "LAURA"
  },
  {
    "carBrand": "SKODA",
    "carModel": "OCTAVIA COMBI"
  },
  {
    "carBrand": "SKODA",
    "carModel": "YETI"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "Ameo"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "JETTA"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "POLO"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "POLO GT"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "Taigun"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "TIGUAN"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "VENTO"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "Virtus"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "NEW BEETLE"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "PASSAT"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "T-ROC"
  },
  {
    "carBrand": "VOLKSWAGEN",
    "carModel": "VENTO GT"
  }
]

