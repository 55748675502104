<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <p class="navbar-brand" href="#">Impact Study</p>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/stockDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Impact Data Entry</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/impactDataView" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Impact Data View</p>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fulid py-3">
  <div class="row py-4 mt-2">
  </div>
  <div class="row ms-3">
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'under3Month')">
        <label class="ms-1">Under Three Months</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'under6Month')">
        <label class="ms-1">Under Six Months</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'under1Year')">
        <label class="ms-1">Under One Year</label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="form-group">
        <input type="checkbox" (click)="updateStock($event, 'above1Year')">
        <label class="ms-1">Above One Year</label>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="form-group">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-3">
      <div class="form-floating">
        <select class="form-select" placeholder="Name Of Depositor" (change)="updateStock($event, 'name')">
          <option selected>Select Depositor Name</option>
          <option *ngFor="let name of this.depositerName" value="{{name}}">{{name}}</option>
        </select>
        <label>Name Of Depositor</label>
      </div>
    </div>
    <div class="col-lg-1"></div>
    <div class="col-lg-3">
      <div class="form-floating">
        <select class="form-select" placeholder="Stack Number" (change)="updateStock($event, 'number')">
          <option selected>Select Stack Number</option>
          <option *ngFor="let number of this.stackNumber" value="{{number}}">{{number}}</option>
        </select>
        <label>Stack Number</label>
      </div>
    </div>
    <div class="col-lg-1"></div>
    <div class="col-lg-2 mt-2">
      <button type="button" class="btn btn-sucess" (click)="generateVistorsXLSX()">Generate File</button>
    </div>
  </div>
  <div class="py-2"></div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
        <tr class="sticky-top">
          <th>No</th>
          <th>Depositor Name</th>
          <th>Stock Number</th>
          <th>Stock IN Date</th>
          <th>Stock IN Weight (Machine)</th>
          <th>Stock Out Date</th>
          <th>Stock OUT Weight (Party)</th>
          <th>Stock OUT Weight (Machine)</th>
          <th>Difference</th>
          <th>Average (%)</th>
        </tr>
        </thead>
        <tbody *ngFor="let aftc of this.dataEntry | filter: searchText;let i = index">
        <tr>
          <td>{{i + 1}}</td>
          <td>{{aftc.nameOfDepositor}}</td>
          <td>{{aftc.stackNumber}}</td>
          <td>{{aftc.stockInDate | date: "dd-MM-yyyy" }}</td>
          <td>{{aftc.stockInWeightMachine}}</td>
          <td>{{aftc.stockOutDate | date: "dd-MM-yyyy"}}</td>
          <td>{{aftc.stockOutWeightParty}}</td>
          <td>{{aftc.stockOutWeightMachine}}</td>
          <td>{{aftc.difference}}</td>
          <td>{{aftc.average}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
