<!--<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>-->
<strong>
    <span>Copyright &copy; {{ currentYear }} </span>
    <a
        href="https://varnik.cloud"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0" class="text-decoration-none"
    >VarNik.Cloud</a>
    <span>.</span>
</strong>
<span> All rights reserved.</span>
