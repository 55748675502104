import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Router } from "@angular/router";

@Component({
  selector: "app-toll-gate-print-screen",
  templateUrl: "./toll-gate-print-screen.component.html",
  styleUrls: ["./toll-gate-print-screen.component.css"]
})
export class TollGatePrintScreenComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  reportQr: string;
  currentGatePass: TollGate | undefined;
  currentDate: string;
  declarationDate: string;
  tollgateView: string;
  private intervalSubscription: Subscription;

  constructor(private apiService: ApiService, private datePipe: DatePipe,
              private router: Router) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit(): void {
    this.reportQr = sessionStorage.getItem("reportQr");
    this.tollgateView = sessionStorage.getItem("typeOfprint");
    this.getGatePass();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("reportQr");
    sessionStorage.removeItem("typeOfprint");
  }

  // Get tollgate detail by id for fetch in receipt
  getGatePass() {
    this.apiService.getTollGate(this.reportQr)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data.vehEntryDateTime.toString().length == 10) {
          data.vehEntryDateTime = data.vehEntryDateTime * 1000;
        }
        if (data.createdAt.toString().length == 10) {
          data.createdAt = data.createdAt * 1000;
        }
        this.currentGatePass = data;
        this.intervalSubscription = interval(1000).subscribe(() => {
          this.print();
        });
      }, (err: any) => {
      });
  }

  // Print the receipt
  print() {
    const printStyle = `
      @media print {
        @page {
          size: A5; /* Set your desired page size here */
        }
      }
    `;
    const originalTitle = document.title;
    document.title = this.currentGatePass.tollgateVehicleDetails.vehNumberPlate;
    const style = document.createElement("style");
    style.innerHTML = printStyle;
    document.head.appendChild(style);
    window.print();
    document.head.removeChild(style);
    document.title = originalTitle;
    this.intervalSubscription.unsubscribe();

    setTimeout(() => {
      if (localStorage.getItem("frontOffice") == "true") {
        localStorage.removeItem("reportQr");
        localStorage.removeItem("frontOffice");
        this.router.navigate(["/main/frontOffice"]);
      } else if (this.tollgateView == "tollgateView") {
        localStorage.removeItem("reportQr");
        localStorage.removeItem("typeOfprint");
        this.router.navigate(["/main/tollGateView"]);
      } else {
        sessionStorage.removeItem("reportQr");
        this.router.navigate(["/main/tollgate"]);
      }
    });
  }

}



