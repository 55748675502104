import { AppState } from "@/store/state";
import { UiState } from "@/store/ui/state";
import { Component, HostBinding, OnInit, Renderer2 } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";
import { DatePipe } from "@angular/common";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ProcedureDetails } from "@/model/labModal/procedureDetails";
import { Router } from "@angular/router";
import { TestDetails } from "@/model/labModal/testDetails";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { NormalTestDetails } from "@/model/labModal/normalTestDetails";
import { NormalCustomerDetails } from "@/model/labModal/normalCustomerDetails";
import { InternalTestDetails } from "@/model/labModal/internalTestDetails";
import { InternalCustomerDetails } from "@/model/labModal/internalCustomerDetails";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "@services/api.service";

@Component({
    selector: "app-main",
    templateUrl: "./aftc-report-entry.html",
    styleUrls: ["./aftc-report-entry.scss"],
})
export class AftcReportEntry implements OnInit {
    @HostBinding("class") class = "wrapper";
    public ui: Observable<UiState>;
    datePipe: DatePipe = new DatePipe("en-IN");
    destroy$: Subject<boolean> = new Subject<boolean>();
    isUpdate = false;
    procedureList: ProcedureDetails[] = [];
    paramList: String[] = [];
    paramId: Number[] = [];
    isVisible = false;
    isVisible1 = false;
    isVisiblePlusIcon = false;
    isVisibleAnalysis = false;
    isVisibleProduct = false;
    isVisibleParameter = false;
    isSaveButtonShow = false;
    isVisibleStandard = false;
    isShowOthers = false;
    isShowPrint = false;
    testPerformed: String[] = [];
    testAnalysis: String[] = [];
    testStandard: String[] = [];
    procedureResults: ProcedureResults[] = [];
    filterprocedureResults: ProcedureResults[] = [];
    ulrNo: TestDetails[] = [];
    ulrNoShow: string = "";
    ulrNoshowing: string = "";
    normalUlrNoShow: string[] = [];
    internalUlrNoShow: String[] = [];
    reportNoData: TestDetails[] = [];
    reportNo: String[] = [];
    normalReportNo: String[] = [];
    internalReportNo: String[] = [];
    sampleNoData: TestDetails[] = [];
    sampleNo: String[] = [];
    normalSampleNo: String[] = [];
    internalSampleNo: String[] = [];
    currentTestDetails: TestDetails | undefined;
    currentNormalTestDetails: NormalTestDetails | undefined;
    currentInternalTestDetails: InternalTestDetails | undefined;
    currentTestResult: ProcedureResults | undefined;
    editEntry: AllTestDetails | undefined;
    testType: string = "";
    selectProduct: string = "";
    selectAnalysis: string = "";
    selectParameter: string = "";
    getResult: string = "";
    getUnit: string = "";
    methods: string = "";
    normalUlrNo: NormalTestDetails[] = [];
    normalReportNoData: NormalCustomerDetails[] = [];
    normalSampleNoData: NormalTestDetails[] = [];
    internalUlrNo: InternalTestDetails[] = [];
    internalReportNoData: InternalCustomerDetails[] = [];
    internalSampleNoData: InternalTestDetails[] = [];
    isReadOnly = false;
    isDisabled: boolean = true;
    product: string;
    analysis: string = "";
    parameter: ProcedureResults[] = [];
    standard: string = "";
    sampleData: ProcedureDetails[] = [];
    standardData: ProcedureDetails[] = [];
    analysisData: ProcedureDetails[] = [];
    typeSelected: string;
    procedureResult: ProcedureResults | undefined;
    newAdd = false;
    nablTestList: any[] = [];
    savedCustomerAddress: String[] = [];
    savedUlrNo: String[] = [];
    savedSampleNo: String[] = [];
  maxSampleNo: number;
    ulrSampleNo: String[] = []
    sampleFaNo: String[] = []
    normaltestSampleNo: String[] = []
    internaltestSampleNo: String[] = []
    sampleCurrentNo: string = ""
    isDisable = false
    testingDisable = false
    buttonSave = false
    normalReportDisable = false;
    managerReportDisable = false;
    test: boolean;
    disabled = true;
    resultSave = false;
    isManagerStatus = false;
    techAnalystName: string[] = [];
    aftculrDisable = true;
    userName: string = "";
    techAnalaystDisable = false;
    tooltipValue: string;
    AllTestUrlNo: string = ''
    techName: string;
    finalApprove: string;
    approvedtable = false;
    assigenTable = false;
    id: number;
    name: string;
    currentApproveStatus: String = ''
    selectedParameters: string[] = [];
    testGroup: string[] = [];
    testSubGroup: string[] = [];
    updatedSampleNo: string = '';
    standardfassi: string = '';
    sampleId: number = 0;
    procedureId: number = 0;
    uniqueCustomerNames: string[] = [];
    uniquePhoneNumbers: string[] = [];
    uniqueEmails: string[] = [];
    uniqueAddresses: string[] = [];
    uniqueReferences: string[] = [];
    uniqueSampleDrawn: string[] = [];
    uniqueOtherPaking: string[] = [];
    uniqueDiscipline: string[] = [];
    uniqueGroup: string[] = [];
    uniqueSubGroup: string[] = [];
    uniqueSampleName: string[] = [];
    uniqueDescription: string[] = [];
    uniqueQty: string[] = [];
    ohShow: boolean = true;


    constructor(
        private renderer: Renderer2,
        private appService: ApiService,
        private router: Router,
        private dataService: PrintDataService,
        private spinner: NgxSpinnerService,
        private toaster: ToastrService
    ) {
        this.typeSelected = "ball-fussion";
    }

    aftclAllDetails = new UntypedFormGroup({

        nablTest: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        normalTest: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        internalTest: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_ulr_no: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_report_no: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_date: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_customer_name: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_customer_address: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_email_id: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]),
        aftcl_phone_number: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        ]),
        aftcl_customer_reference: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_sample_no: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_sample_quality: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_sample_received_date: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_packing_condition: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_product_name: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_sample_drawn_by: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_starting_date: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_discipline: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_description: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_complete_date: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_group: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_sub_group: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_packing_type: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_other_packing_type: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_parameter_id: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_created_at: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        procedures: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_type: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_sample_qty_received: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_samplefa_no: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_test_sample: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        technicalAnalystName: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        qualityManagerStatus: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        sample_name: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        aftcl_updated_at: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
    });

    public aftcLabErrors = {
        aftcl_customer_name: "",
        aftcl_sample_no: "",
        aftcl_customer_address: "",
        aftcl_email_id: "",
        aftcl_phone_number: "",
        aftcl_sample_quality: "",
        aftcl_sample_received_date: "",
        aftcl_test_starting_date: "",
        aftcl_test_complete_date: "",
        aftcl_packing_type: "",
        aftcl_group: "",
        aftcl_sub_group: "",
        aftcl_test_sample: "",
        aftcl_test_analysis: "",
        aftcl_test_parameter: "",
        aftcl_standard: "",
        aftcl_sample_qty_received: "",
        sample_name: ""
    };

    ngOnInit(): void {
        if (this.dataService.entryEdit != undefined) {
            this.isVisibleProduct = true;
            this.isUpdate = true;
            this.isReadOnly = true;
            this.editEntry = this.dataService.entryEdit;
            this.aftclAllDetails.controls["aftcl_ulr_no"].setValue(
                this.editEntry.aftcl_ulr_no
            );
            this.aftclAllDetails.controls["aftcl_report_no"].setValue(
                this.editEntry.aftcl_report_no
            );
            this.aftclAllDetails.controls["aftcl_date"].setValue(
                this.editEntry.aftcl_date
            );
            this.aftclAllDetails.controls["aftcl_customer_name"].setValue(
                this.editEntry.aftcl_customer_name
            );
            this.aftclAllDetails.controls["aftcl_customer_address"].setValue(
                this.editEntry.aftcl_customer_address
            );
            this.aftclAllDetails.controls["aftcl_email_id"].setValue(
                this.editEntry.aftcl_email_id
            );
            this.aftclAllDetails.controls["aftcl_phone_number"].setValue(
                this.editEntry.aftcl_phone_number
            );
            this.aftclAllDetails.controls["aftcl_customer_reference"].setValue(
                this.editEntry.aftcl_customer_reference
            );
            this.aftclAllDetails.controls["aftcl_sample_no"].setValue(
                this.editEntry.aftcl_sample_no
            );
            this.aftclAllDetails.controls["aftcl_samplefa_no"].setValue(
                this.editEntry.aftcl_samplefa_no
            );
            this.aftclAllDetails.controls["aftcl_sample_quality"].setValue(
                this.editEntry.aftcl_sample_quality
            );
            this.aftclAllDetails.controls["aftcl_sample_drawn_by"].setValue(
                this.editEntry.aftcl_sample_drawn_by
            );
            this.aftclAllDetails.controls["aftcl_sample_received_date"].setValue(
                this.editEntry.aftcl_sample_received_date
            );
            this.aftclAllDetails.controls["aftcl_test_starting_date"].setValue(
                this.editEntry.aftcl_test_starting_date
            );
            this.aftclAllDetails.controls["aftcl_test_complete_date"].setValue(
                this.editEntry.aftcl_test_complete_date
            );
            this.aftclAllDetails.controls["aftcl_packing_condition"].setValue(
                this.editEntry.aftcl_packing_condition
            );
            this.aftclAllDetails.controls["aftcl_product_name"].setValue(
                this.editEntry.aftcl_product_name
            );
            this.aftclAllDetails.controls["aftcl_discipline"].setValue(
                this.editEntry.aftcl_discipline
            );
            this.aftclAllDetails.controls["aftcl_sample_qty_received"].setValue(
                this.editEntry.aftcl_sample_qty_received
            );
            this.aftclAllDetails.controls["aftcl_description"].setValue(
                this.editEntry.aftcl_description
            );
            this.aftclAllDetails.controls["aftcl_group"].setValue(
                this.editEntry.aftcl_group
            );
            this.aftclAllDetails.controls["aftcl_sub_group"].setValue(
                this.editEntry.aftcl_sub_group
            );
            this.aftclAllDetails.controls["aftcl_packing_type"].setValue(
                this.editEntry.aftcl_packing_type
            );
            if (this.editEntry.aftcl_other_packing_type == "" || this.editEntry.aftcl_other_packing_type == null) {
                this.aftclAllDetails.controls["aftcl_other_packing_type"].disable();
            } else {
                this.aftclAllDetails.controls["aftcl_other_packing_type"].setValue(
                    this.editEntry.aftcl_other_packing_type
                );
            }
            this.aftclAllDetails.controls["aftcl_test_type"].setValue(
                this.editEntry.aftcl_test_type
            );
            this.aftclAllDetails.controls["sample_name"].setValue(
                this.editEntry.sample_name
            );
            this.currentApproveStatus = this.editEntry.qualityManagerStatus

            this.getProduct();
            this.getProcedureResults();
            this.getAllTest()

            if (
                this.editEntry.aftcl_other_packing_type != "" &&
                this.editEntry.aftcl_other_packing_type != null
            ) {
                this.isShowOthers = true;
            } else {
                this.isShowOthers = false;
            }

            //Table Disable Function

            if (localStorage.getItem("department") == 'TECHNICAL MANAGER' || localStorage.getItem("department") == 'QUALITY MANAGER') {
                this.userName = localStorage.getItem('department')
                this.managerReportDisable = true
                this.test = false;
                this.techAnalaystDisable = true;
                this.getTechAnalystName();
            } else if (localStorage.getItem("department") == "TECHNICAL ANALYST") {
                this.userName = localStorage.getItem('department')
                this.managerReportDisable = true;
                this.aftclAllDetails.disable()
            } else {
                this.normalReportDisable = true;
            }

        } else {
            Object.keys(this.aftclAllDetails.controls).forEach((controlName) => {
                if (controlName !== 'nablTest' && controlName !== 'normalTest' && controlName !== 'internalTest') {
                    this.aftclAllDetails.get(controlName).disable();
                }
            })
            if (localStorage.getItem("department") == 'TECHNICAL MANAGER' || localStorage.getItem("department") == 'QUALITY MANAGER'
                || localStorage.getItem("department") == "TECHNICAL ANALYST") {
                this.router.navigate(["/main/aftcl-Edit-Screen"])
            } else {
                this.toaster.error("Select NABL/Normal/Internal Test")
            }
            this.buttonSave = true
            this.testingDisable = true
            this.isVisible = true;
            this.getAllTest();
            // this.getAllTestUrlNo()
            this.aftclAllDetails.controls["aftcl_date"].setValue(
                this.getformattedDate()
            );
            this.aftclAllDetails.controls["aftcl_created_at"].setValue(
                this.getformattedDate()
            );
            this.aftclAllDetails.controls["aftcl_discipline"].setValue("Chemical Testing");
            if (localStorage.getItem("department") == 'TECHNICAL MANAGER') {
                this.managerReportDisable = true
            } else if (localStorage.getItem("department") == "TECHNICAL ANALYST") {
                this.managerReportDisable = true;
            } else {
                this.normalReportDisable = true;
            }
            this.getProduct();
            this.isReadOnly = false;
            this.disable();
        }
    }

    ngOnDestroy(): void {
        this.dataService.allTest = this.currentTestDetails;
        this.dataService.allNormalTestDetails = this.currentNormalTestDetails;
        this.dataService.allResult = this.currentTestResult;
        this.dataService.allInternalTestDetails = this.currentInternalTestDetails;
    }

    /* All Test Details URL NO Auto Generate Function */

    /*getAllTestUrlNo() {
        this.appService.getAllTestUrlNo()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.AllTestUrlNo = String(data.length + 1)
            }, (error: any) => {
                console.log("something Went Wrong")
            })
    }*/

    /* All Test Details Get Function */

    getAllTest() {
        var customerAddress: String[] = [];
        var allUlrNo: String[] = [];
        var allSampleNo: String[] = [];
        this.appService
            .getAllTestDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.nablTestList = data;
                for (let nablTest of this.nablTestList) {
                    customerAddress.push(nablTest.aftcl_customer_address);
                    this.savedCustomerAddress = customerAddress;
                    allUlrNo.push(nablTest.aftcl_ulr_no);
                    this.savedUlrNo = allUlrNo;
                    allSampleNo.push(nablTest.aftcl_sample_no)
                    this.savedSampleNo = allSampleNo;
                    for (let no of this.savedSampleNo) {
                      const splitValue = no.split('/')[0];
                    }
                    const sampleNo = this.savedSampleNo.map(it => it.split('/'));
                  // @ts-ignore
                  const sampleNoAsNumbers = sampleNo.map(it => parseInt(it, 10));
                  this.maxSampleNo = Math.max(...sampleNoAsNumbers);
                  this.AllTestUrlNo = String(this.maxSampleNo + 1);
                    this.uniqueCustomerNames = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_customer_name));
                    this.uniquePhoneNumbers = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_phone_number).filter(phone => phone != ""));
                    this.uniqueEmails = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_email_id));
                    this.uniqueAddresses = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_customer_address));
                    this.uniqueReferences = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_customer_reference));
                    this.uniqueDescription = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_description));
                    this.uniqueSampleDrawn = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_sample_drawn_by));
                    this.uniqueOtherPaking = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_other_packing_type));
                    this.uniqueGroup = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_group));
                    this.uniqueSubGroup = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_sub_group));
                    this.uniqueSampleName = this.getUniqueValues(this.nablTestList.map(item => item.sample_name));
                    this.uniqueDiscipline = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_discipline));
                    this.uniqueQty = this.getUniqueValues(this.nablTestList.map(item => item.aftcl_sample_qty_received));
                }
            });
    }


    getUniqueValues(arr: string[]): string[] {
        return arr.filter((value, index, self) => self.indexOf(value) === index);
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.aftcLabErrors.aftcl_customer_name = "";
        this.aftcLabErrors.aftcl_customer_address = "";
        this.aftcLabErrors.aftcl_email_id = "";
        this.aftcLabErrors.aftcl_sample_quality = "";
        this.aftcLabErrors.aftcl_sample_received_date = "";
        this.aftcLabErrors.aftcl_test_starting_date = "";
        this.aftcLabErrors.aftcl_test_complete_date = "";
        this.aftcLabErrors.aftcl_packing_type = "";
        this.aftcLabErrors.aftcl_group = "";
        this.aftcLabErrors.aftcl_sub_group = "";
        this.aftcLabErrors.aftcl_test_parameter = "";
        this.aftcLabErrors.aftcl_test_sample = "",
            this.aftcLabErrors.aftcl_test_analysis = "";
        this.aftcLabErrors.aftcl_test_parameter = "";
        this.aftcLabErrors.aftcl_standard = "";

        let hasError = false;

        if (this.aftclAllDetails.get("aftcl_customer_name")?.invalid) {
            this.aftcLabErrors.aftcl_customer_name = "*Customer Name is required";
            hasError = true;
        }
        if (this.aftclAllDetails.get("aftcl_customer_address")?.invalid) {
            this.aftcLabErrors.aftcl_customer_address = "*Address is required";
            hasError = true;
        }
        if (this.aftclAllDetails.get("aftcl_email_id")?.invalid) {
            if (this.aftclAllDetails.get('aftcl_email_id').errors.pattern) {
                this.aftcLabErrors.aftcl_email_id = "*Enter Valid Email Address (eg:example@gmail.com)";
            }
        }
        if (this.aftclAllDetails.get("aftcl_sample_quality")?.invalid) {
            this.aftcLabErrors.aftcl_sample_quality = "*Sample Quality is required";
            hasError = true;
        }
        if (this.aftclAllDetails.get("aftcl_packing_type")?.value == "") {
            this.aftcLabErrors.aftcl_packing_type = "* Select Packing Type";
            hasError = true;
        }
        if (this.aftclAllDetails.get("aftcl_group")?.value == "") {
            this.aftcLabErrors.aftcl_group = "* Select Group Name";
            hasError = true;
        }
        if (this.aftclAllDetails.get("aftcl_sub_group")?.value == "") {
            this.aftcLabErrors.aftcl_sub_group = "* Select Sub Group Name";
            hasError = true;
        }
        if (!this.isUpdate) {
            if (this.aftclAllDetails.get("aftcl_test_sample")?.invalid) {
                this.aftcLabErrors.aftcl_test_sample = "* Select Product is required";
                hasError = true;
            }
            if (this.aftclAllDetails.get("aftcl_standard")?.invalid) {
                this.aftcLabErrors.aftcl_standard = "* Select Standard is required";
                hasError = true;
            }
            if (this.aftclAllDetails.get("aftcl_select_analysis")?.invalid) {
                this.aftcLabErrors.aftcl_test_analysis = "* Select Analysis is required";
                hasError = true;
            }
            if (this.aftclAllDetails.get("aftcl_test_parameter")?.invalid) {
                this.aftcLabErrors.aftcl_test_parameter = "*Select Parameter is required";
                hasError = true;
            }
        }
        if (!hasError) {
            this.saveDetails();
        }
    }

    /*Only Number Values Get Function */

    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    /*All Test Details Save And Updated Function */

    saveDetails() {
        if (this.isUpdate) {
            this.spinner.show();
            if (this.editEntry.aftcl_test_type == "NABL Test") {
                if (this.userName == 'TECHNICAL MANAGER') {
                    this.procedureResults.forEach((procedure: any) => {
                        procedure.aftcl_updated_at = "TECHNICAL MANAGER";
                    });
                } else if (this.userName == 'QUALITY MANAGER') {
                    this.procedureResults.forEach((procedure: any) => {
                        procedure.aftcl_updated_at = "QUALITY MANAGER";
                    });
                }
                if (this.userName == 'QUALITY MANAGER' || this.userName == 'TECHNICAL MANAGER') {
                    const map = new Map<number, { nonEmpty: string, count: number }>();
                    this.procedureResults.forEach(item => {
                        const { aftcl_procedure_id, technicalAnalystName } = item;

                        if (!map.has(aftcl_procedure_id) && technicalAnalystName !== "") {
                            map.set(aftcl_procedure_id, { nonEmpty: technicalAnalystName, count: 0 });
                        }

                        if (technicalAnalystName === "") {
                            const count = map.get(aftcl_procedure_id)?.count || 0;
                            map.set(aftcl_procedure_id, { nonEmpty: map.get(aftcl_procedure_id)?.nonEmpty || "", count: count + 1 });
                        }
                    });
                    this.procedureResults.forEach(item => {
                        const { aftcl_procedure_id, technicalAnalystName } = item;

                        if (technicalAnalystName === "" && map.has(aftcl_procedure_id) && map.get(aftcl_procedure_id)?.count === 2) {
                            item.technicalAnalystName = map.get(aftcl_procedure_id)?.nonEmpty || "";
                        }
                    });
                }
                this.aftclAllDetails.controls["procedures"].setValue(
                    this.procedureResults
                );
                this.appService
                    .updateNABLTestDetails(this.aftclAllDetails.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        (data) => {
                            setTimeout(() => {
                            }, 2000);
                            // @ts-ignore
                            this.currentTestDetails = data;
                            this.alertShow();
                            this.spinner.hide();
                            localStorage.setItem(
                                "procedureResults",
                                JSON.stringify(this.procedureResults)
                            );
                            this.isVisible1 = true;
                        },
                        (err: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong , Please try again later....")
                        },
                        () => console.log("HTTP request completed.")
                    );
            } else if (this.editEntry.aftcl_test_type == "Normal Test") {
                this.spinner.show();
                if (this.userName == 'TECHNICAL MANAGER') {
                    this.procedureResults.forEach((procedure: any) => {
                        procedure.aftcl_updated_at = 'TECHNICAL MANAGER';
                    });
                } else if (this.userName == 'QUALITY MANAGER') {
                    this.procedureResults.forEach((procedure: any) => {
                        procedure.aftcl_updated_at = 'QUALITY MANAGER';
                    });
                }

                /*Single Anaylst Value Assigen Another Two Value Set Function*/

                if (this.userName == 'QUALITY MANAGER' || this.userName == 'TECHNICAL MANAGER') {
                    const map = new Map<number, { nonEmpty: string, count: number }>();
                    this.procedureResults.forEach(item => {
                        const { aftcl_procedure_id, technicalAnalystName } = item;

                        if (!map.has(aftcl_procedure_id) && technicalAnalystName !== "") {
                            map.set(aftcl_procedure_id, { nonEmpty: technicalAnalystName, count: 0 });
                        }

                        if (technicalAnalystName === "") {
                            const count = map.get(aftcl_procedure_id)?.count || 0;
                            map.set(aftcl_procedure_id, { nonEmpty: map.get(aftcl_procedure_id)?.nonEmpty || "", count: count + 1 });
                        }
                    });
                    this.procedureResults.forEach(item => {
                        const { aftcl_procedure_id, technicalAnalystName } = item;

                        if (technicalAnalystName === "" && map.has(aftcl_procedure_id) && map.get(aftcl_procedure_id)?.count === 2) {
                            item.technicalAnalystName = map.get(aftcl_procedure_id)?.nonEmpty || "";
                        }
                    });

                }
                this.aftclAllDetails.controls["procedures"].setValue(
                    this.procedureResults
                );
                this.appService
                    .updateNormalTestDetails(this.aftclAllDetails.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        (data) => {
                            setTimeout(() => {

                            }, 2000);
                            // @ts-ignore
                            this.currentTestDetails = data;
                            this.spinner.hide();
                            this.alertShow();
                            localStorage.setItem(
                                "procedureResults",
                                JSON.stringify(this.procedureResults)
                            );
                            this.isVisible1 = true;
                        },
                        (err: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong , Please try again later....")
                        },
                        () => console.log("HTTP request completed.")
                    );
            } else if (this.editEntry.aftcl_test_type == "Internal Test") {
                this.spinner.show();
                if (this.userName == 'TECHNICAL MANAGER') {
                    this.procedureResults.forEach((procedure: any) => {
                        procedure.aftcl_updated_at = 'TECHNICAL MANAGER';
                    });
                } else if (this.userName == 'QUALITY MANAGER') {
                    this.procedureResults.forEach((procedure: any) => {
                        procedure.aftcl_updated_at = 'QUALITY MANAGER';
                    });
                }
                if (this.userName == 'QUALITY MANAGER' || this.userName == 'TECHNICAL MANAGER') {
                    const map = new Map<number, { nonEmpty: string, count: number }>();
                    this.procedureResults.forEach(item => {
                        const { aftcl_procedure_id, technicalAnalystName } = item;

                        if (!map.has(aftcl_procedure_id) && technicalAnalystName !== "") {
                            map.set(aftcl_procedure_id, { nonEmpty: technicalAnalystName, count: 0 });
                        }

                        if (technicalAnalystName === "") {
                            const count = map.get(aftcl_procedure_id)?.count || 0;
                            map.set(aftcl_procedure_id, { nonEmpty: map.get(aftcl_procedure_id)?.nonEmpty || "", count: count + 1 });
                        }
                    });
                    this.procedureResults.forEach(item => {
                        const { aftcl_procedure_id, technicalAnalystName } = item;

                        if (technicalAnalystName === "" && map.has(aftcl_procedure_id) && map.get(aftcl_procedure_id)?.count === 2) {
                            item.technicalAnalystName = map.get(aftcl_procedure_id)?.nonEmpty || "";
                        }
                    });

                }
                this.aftclAllDetails.controls["procedures"].setValue(
                    this.procedureResults
                );
                this.appService
                    .updateInternalTestDetails(this.aftclAllDetails.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        (data) => {
                            setTimeout(() => {

                            }, 2000);
                            this.spinner.hide();
                            this.alertShow();
                            // @ts-ignore
                            this.currentTestDetails = data;
                            localStorage.setItem(
                                "procedureResults",
                                JSON.stringify(this.procedureResults)
                            );
                            this.isVisible1 = true;
                        },
                        (err: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong , Please try again later....")
                        },
                        () => console.log("HTTP request completed.")
                    );
            }
        } else {
            this.spinner.show();
            if (this.aftclAllDetails.get('nablTest').value == 'NABL Test') {
                this.aftclAllDetails.controls["aftcl_ulr_no"].setValue(
                    this.ulrNoShow
                );
                this.aftclAllDetails.controls["aftcl_report_no"].setValue(
                    this.reportNo
                );
                this.procedureResults.forEach((procedure: any) => {
                    procedure.aftcApprove = 'No';
                });
                this.aftclAllDetails.controls["aftcl_sample_no"].setValue(this.aftclAllDetails.get('aftcl_sample_no').value);
                this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue(this.sampleFaNo);
                this.aftclAllDetails.controls["procedures"].setValue(
                    this.procedureResults
                );
                this.aftclAllDetails.controls["aftcl_test_type"].setValue(
                    this.testType
                );
                this.appService
                    .saveNABLTestDetails(this.aftclAllDetails.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        (data) => {
                            setTimeout(() => {

                            }, 2000);
                            // @ts-ignore
                            this.currentTestDetails = data;
                            this.spinner.hide();
                            this.alertShow();
                            localStorage.setItem(
                                "procedureResults",
                                JSON.stringify(this.procedureResults)
                            );
                            this.isVisible1 = true;
                        },
                        (err: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong , Please try again later....")
                        },
                        () => console.log("HTTP request completed.")
                    );
            } else if (this.aftclAllDetails.get('normalTest').value == 'Normal Test') {
                this.spinner.show();
                this.aftclAllDetails.controls["aftcl_ulr_no"].setValue(
                    this.ulrNoShow
                );
                this.aftclAllDetails.controls["aftcl_report_no"].setValue(
                    this.reportNo
                );
                this.aftclAllDetails.controls["aftcl_sample_no"].setValue(this.aftclAllDetails.get('aftcl_sample_no').value);
                this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue(this.sampleFaNo);
                this.aftclAllDetails.controls["aftcl_parameter_id"].setValue(
                    this.paramId[0]
                );
                this.procedureResults.forEach((procedure: any) => {
                    procedure.aftcApprove = 'No';
                });
                this.aftclAllDetails.controls["procedures"].setValue(
                    this.procedureResults
                );
                this.aftclAllDetails.controls["aftcl_test_type"].setValue(
                    this.testType
                );
                this.appService
                    .saveNormalTestDetails(this.aftclAllDetails.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        (data) => {
                            setTimeout(() => {

                            }, 2000);
                            // @ts-ignore
                            this.currentTestDetails = data;
                            this.spinner.hide();
                            this.alertShow();
                            localStorage.setItem(
                                "procedureResults",
                                JSON.stringify(this.procedureResults)
                            );
                            this.isVisible1 = true;
                        },
                        (err: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong , Please try again later....")
                        },
                        () => console.log("HTTP request completed.")
                    );
            } else if (this.aftclAllDetails.get('internalTest').value == 'Internal Test') {
                this.spinner.show();
                this.aftclAllDetails.controls["aftcl_ulr_no"].setValue(
                    this.ulrNoShow
                );
                this.aftclAllDetails.controls["aftcl_report_no"].setValue(
                    this.reportNo
                );
                this.aftclAllDetails.controls["aftcl_sample_no"].setValue(this.aftclAllDetails.get('aftcl_sample_no').value);
                this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue(this.sampleFaNo);
                this.aftclAllDetails.controls["aftcl_parameter_id"].setValue(
                    this.paramId[0]
                );
                this.procedureResults.forEach((procedure: any) => {
                    procedure.aftcApprove = 'No';
                });
                this.aftclAllDetails.controls["procedures"].setValue(
                    this.procedureResults
                );
                this.aftclAllDetails.controls["aftcl_test_type"].setValue(
                    this.testType
                );
                this.appService
                    .saveInternalTestDetails(this.aftclAllDetails.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        (data) => {
                            setTimeout(() => {

                            }, 2000);
                            // @ts-ignore
                            this.currentTestDetails = data;
                            this.spinner.hide();
                            this.alertShow();
                            localStorage.setItem(
                                "procedureResults",
                                JSON.stringify(this.procedureResults)
                            );
                            this.isVisible1 = true;
                        },
                        (err: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong , Please try again later....")
                        },
                        () => console.log("HTTP request completed.")
                    );
            }
        }
    }

    // Handle test based details are auto fetch function
    sample(event) {
        const value = event.target.value;
        const sampleNos: string = value
        //@ts-ignore
        this.sampleNo = sampleNos
        const index = value.indexOf('/');
        const extractedValue = index !== -1 ? value.substring(0, index) : value;
        // @ts-ignore

        if (this.aftclAllDetails.get('nablTest').value == 'NABL Test') {
            if (this.savedSampleNo.includes(sampleNos)) {
                this.aftcLabErrors.aftcl_sample_no = "*Sample No is Already Existing"
                this.aftclAllDetails.controls['aftcl_ulr_no'].setValue("")
                this.aftclAllDetails.controls['aftcl_report_no'].setValue("")
                this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue("");
            } else {
                if (this.aftclAllDetails.get('nablTest').value == 'NABL Test') {
                    this.getUlrNo();
                    this.getReportNo();
                    this.getSampleNo();
                    this.aftcLabErrors.aftcl_sample_no = ""
                    this.aftclAllDetails.controls['aftcl_ulr_no'].setValue("")
                    this.aftclAllDetails.controls['aftcl_report_no'].setValue("")
                    this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue("");
                }
            }

        } else if (this.aftclAllDetails.get('normalTest').value == 'Normal Test') {
            if (this.savedSampleNo.includes(sampleNos)) {
                this.aftcLabErrors.aftcl_sample_no = "*Sample No is Already Existing"
                this.aftclAllDetails.controls['aftcl_ulr_no'].setValue("")
                this.aftclAllDetails.controls['aftcl_report_no'].setValue("")
                this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue("");
            } else {
                if (this.aftclAllDetails.get('normalTest').value == 'Normal Test') {
                    this.getNormalUlrNo();
                    this.getNormalReportNo();
                    this.getNormalSampleNo();
                    this.aftcLabErrors.aftcl_sample_no = ""
                    this.aftclAllDetails.controls['aftcl_ulr_no'].setValue("")
                    this.aftclAllDetails.controls['aftcl_report_no'].setValue("")
                    this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue("");
                }
            }
        } else if (this.aftclAllDetails.get('internalTest').value == 'Internal Test') {
            if (this.savedSampleNo.includes(sampleNos)) {
                this.aftcLabErrors.aftcl_sample_no = "*Sample No is Already Existing"
                this.aftclAllDetails.controls['aftcl_ulr_no'].setValue("")
                this.aftclAllDetails.controls['aftcl_report_no'].setValue("")
                this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue("");
            } else {
                if (this.aftclAllDetails.get('internalTest').value == 'Internal Test') {
                    this.getInternalUlrNo();
                    this.getInternalReportNo();
                    this.getInternalSampleNo();
                    this.aftcLabErrors.aftcl_sample_no = ""
                    this.aftclAllDetails.controls['aftcl_ulr_no'].setValue("")
                    this.aftclAllDetails.controls['aftcl_report_no'].setValue("")
                    this.aftclAllDetails.controls['aftcl_samplefa_no'].setValue("");
                }
            }
        }
    }

//fetch sample numbers from the server
    getSampleNo() {
        var tempSampleNo: String[] = [];
        this.appService.getSampleNo()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.sampleNoData = data;
                for (var sample of this.sampleNoData) {
                    tempSampleNo.push(sample.aftcl_sample_no)
                }
                this.getReportNo()
                this.getUlrNo()
                this.getSampleCodeNo()
            });
        let tempSample: string = "";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastSampleNumbers: string = this.AllTestUrlNo.padStart(3, '0');
        const baseSampleUlr: string = tempSample.slice(0, 6);
        const updatedSample: string = `${baseSampleUlr}${newLastSampleNumbers}/${currentYearLastTwoDigits}`;
        this.updatedSampleNo = updatedSample
        this.aftclAllDetails.controls['aftcl_sample_no'].setValue(updatedSample)
    }

    //fetch normal sample numbers from the server
    getNormalSampleNo() {
        var tempSampleNo: String[] = [];
        this.appService.getNormalSampleNo()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.normalSampleNoData = data;
                for (var sample of this.normalSampleNoData) {
                    tempSampleNo.push(sample.aftcl_sample_no)
                }
                this.getNormalUlrNo()
                this.getNormalReportNo()
                this.getNormalSampleCodeNo()
            });
        let tempSample: string = "";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastSampleNumbers: string = this.AllTestUrlNo.padStart(3, '0');

        const baseSampleUlr: string = tempSample.slice(0, 6);
        const updatedSample: string = `${baseSampleUlr}${newLastSampleNumbers}/${currentYearLastTwoDigits}`;
        this.updatedSampleNo = updatedSample
        this.aftclAllDetails.controls['aftcl_sample_no'].setValue(updatedSample)
    }

//fetch internal sample numbers from the server
    getInternalSampleNo() {
        var tempSampleNo: String[] = [];
        this.appService.getInternalSampleNo()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.internalSampleNoData = data;
                for (var sample of this.internalSampleNoData) {
                    tempSampleNo.push(sample.aftcl_sample_no)
                }
                this.getInternalReportNo()
                this.getInternalUlrNo()
                this.getInternalSampleCodeNo()
            });
        let tempSample: string = "";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastSampleNumbers: string = this.AllTestUrlNo.padStart(3, '0');
        const baseSampleUlr: string = tempSample.slice(0, 6);
        const updatedSample: string = `${baseSampleUlr}${newLastSampleNumbers}/${currentYearLastTwoDigits}`;
        this.updatedSampleNo = updatedSample
        this.aftclAllDetails.controls['aftcl_sample_no'].setValue(updatedSample)
    }



//Fetches technical analyst names from the server and populates an array with unique names
    getTechAnalystName() {
        this.appService.getTechAnalystName()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                const currentAging = new Set(data.map(item => item.techAnalystName));
                this.techAnalystName = Array.from(currentAging)
            }, (error: any) => {
            })
    }


    //Generates a unique ULR number based on the current year and a sample number
    getUlrNo() {
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let tempUlr: string = "TC10157" + `${currentYearLastTwoDigits}`;
        let newLast3Numbers: string = this.sampleCurrentNo.padStart(8, '0');

        const baseUlr: string = tempUlr.slice(0, 10);
        const updatedUlr: string = `${baseUlr}${newLast3Numbers}F`;
        //@ts-ignore
        this.ulrNoShow = updatedUlr
    }

    //Generates a unique report number based on the current year and a sample number
    getReportNo() {
        let tempReport: string = "AFTC/NABL/";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastNumbers: string = this.updatedSampleNo.padStart(1, '0');

        const baseReportUlr: string = tempReport.slice(0, 11);
        const updatedReportNo: string = `${baseReportUlr}${newLastNumbers}`;
        //@ts-ignore
        this.reportNo = updatedReportNo;
    }

    //Generates a unique sample number based on the current year and a sample number
    getSampleCodeNo() {
        let tempSample: string = "FA/NA/";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastSampleNumbers: string = this.sampleCurrentNo.padStart(3, '0');

        const baseSampleUlr: string = tempSample.slice(0, 6);
        const updatedSample: string = `${baseSampleUlr}${newLastSampleNumbers}/${currentYearLastTwoDigits}`;
        //@ts-ignore
        this.sampleFaNo = updatedSample
    }

    //Generates a unique normal url number based on the current year and a sample number
    getNormalUlrNo() {
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let tempUlr: string = "TC10157" + `${currentYearLastTwoDigits}`;
        let newLast3Numbers: string = this.sampleCurrentNo.padStart(8, '0');

        const baseUlr: string = tempUlr.slice(0, 10);
        const updatedUlr: string = `${baseUlr}${newLast3Numbers}P`;
        //@ts-ignore
        this.ulrNoShow = updatedUlr;
    }

    //Generates a unique normal report number based on the current year and a sample number
    getNormalReportNo() {
        let tempReport: string = "AFTC/NN/";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastNumbers: string = this.updatedSampleNo.padStart(1, '0');

        const basereportUlr: string = tempReport.slice(0, 8);
        const updatedReport: string = `${basereportUlr}${newLastNumbers}`;
        //@ts-ignore
        this.reportNo = updatedReport
    }

    //Generates a unique normal sample number based on the current year and a sample number
    getNormalSampleCodeNo() {
        let tempSample: string = "FA/NO/";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastSampleNumbers: string = this.sampleCurrentNo.padStart(3, '0');
        const baseSampleUlr: string = tempSample.slice(0, 6);
        const updatedSample: string = `${baseSampleUlr}${newLastSampleNumbers}/${currentYearLastTwoDigits}`;
        //@ts-ignore
        this.sampleFaNo = updatedSample;
    }

    //Generates a unique internal url number based on the current year and a sample number
    getInternalUlrNo() {
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let tempUlr: string = "TC10157" + `${currentYearLastTwoDigits}`;
        let newLast3Numbers: string = this.sampleCurrentNo.padStart(8, '0');

        const baseUlr: string = tempUlr.slice(0, 10);
        const updatedUlr: string = `${baseUlr}${newLast3Numbers}INT`;
        //@ts-ignore
        this.ulrNoShow = updatedUlr
    }

    //Generates a unique internal report number based on the current year and a sample number
    getInternalReportNo() {
        let tempReport: string = "AFTC/INT/";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastNumbers: string = this.updatedSampleNo.padStart(1, '0');

        const basereportUlr: string = tempReport.slice(0, 9);
        const updatedReport: string = `${basereportUlr}${newLastNumbers}`;
        //@ts-ignore
        this.reportNo = updatedReport
    }

    //Generates a unique internal sample number based on the current year and a sample number
    getInternalSampleCodeNo() {
        let tempSample: string = "FA/IN/";
        let currentYearLastTwoDigits: string = new Date().getFullYear().toString().slice(-2);
        let newLastSampleNumbers: string = this.sampleCurrentNo.padStart(3, '0');
        const baseSampleUlr: string = tempSample.slice(0, 6);
        const updatedSample: string = `${baseSampleUlr}${newLastSampleNumbers}/${currentYearLastTwoDigits}`;
        //@ts-ignore
        this.sampleFaNo = updatedSample
    }



// Get the current date in formatted string
    getformattedDate() {
        const date = new Date();
        return this.datePipe.transform(date, "dd/MM/yyy");
    }

    // Fetch product data from the server
    getProduct() {
        var paramList: String[] = [];
        var testPerformed: String[] = [];
        this.appService
            .getProduct()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.procedureList = data.filter(res => res.active_yn == 'Yes');
                for (let param of this.procedureList) {
                    paramList.push(param.aftcl_test_sample);
                }
                const group = new Set(this.procedureList.map(item => item.aftcl_test_group))
                this.testGroup = Array.from(group).filter(aging => aging !== null)
                const subGroup = new Set(this.procedureList.map(item => item.aftcl_test_sub_group))
                this.testSubGroup = Array.from(subGroup).filter(sub => sub !== null)
                paramList.sort();
                this.paramList = paramList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            });
    }

    // Determine if the date should be disabled
    disableDate() {
        return false;
    }

    // Handle change in procedure result
    onResultChange(procedureResult: ProcedureResults, event: any) {
        const index = this.procedureResults.indexOf(procedureResult);
        procedureResult.aftcl_test_result = event.target.value;
        this.procedureResults[index] = procedureResult;
        this.isSaveButtonShow = true;
    }

    // Handle change in assigned technician
    onAssigenChange(procedureResult: ProcedureResults, event: any) {
        const index = this.procedureResults.indexOf(procedureResult);

        const selectedName = event.target.value;
        const isChecked = event.target.checked;
        if (this.techName == selectedName) {
            for (let te of this.procedureResults) {
                if (this.procedureResults[index].aftcl_procedure_id == te.aftcl_procedure_id) {
                    if (te.technicalAnalystName == this.techName) {
                        te.technicalAnalystName = '';
                    }
                }
            }
        } else {
            this.techName = selectedName;
            for (let tec of this.procedureResults) {
                if (this.procedureResults[index].aftcl_procedure_id == tec.aftcl_procedure_id) {
                    if (tec.technicalAnalystName == this.techName && !isChecked) {
                        tec.technicalAnalystName = '';
                        break
                    }
                    else if (tec.technicalAnalystName == '' && isChecked) {
                        tec.technicalAnalystName = this.techName;
                        break;
                    }
                }
            }
        }

    }

    // Handle final approval for a procedure result
    onFinal(procedureResult: ProcedureResults, event: any) {
        this.ohShow = false;
        if (procedureResult && procedureResult.aftcl_procedure_id && !procedureResult.disableApproval) {
            const procedureId = procedureResult.aftcl_procedure_id;

            const index = this.procedureResults.findIndex(
                tec =>
                    tec.aftcl_procedure_id === procedureId &&
                    tec.aftcl_procedure_result_id === procedureResult.aftcl_procedure_result_id
            );

            if (event.target.checked) {
                this.procedureResults[index].aftcApprove = 'Yes';
            } else {
                this.procedureResults[index].aftcApprove = 'No';
            }
        }
    }

    // Fetch procedure results from the server based on ULR number
    getProcedureResults() {
        this.appService
            .getProcedureResults(this.editEntry.aftcl_ulr_no)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                var localdata: ProcedureResults[] = []
                //@ts-ignore
                localdata = data;
                var localdatass: ProcedureResults[] = []
                for (let test of localdata) {
                    if (test.technicalAnalystName == localStorage.getItem('loginId') || localStorage.getItem('userName') == 'MURUGESWARI-TA'
                        && test.technicalAnalystName == 'MURUGESWARI-TA') {
                        localdatass.push(test)
                        this.resultSave = true;
                        this.buttonSave = false;
                        this.isManagerStatus = false;
                        this.approvedtable = true;
                    }
                    else if (localStorage.getItem('loginId') == 'DRKS-QM' || localStorage.getItem('loginId') == 'MURUGESWARI-TM') {
                        localdatass.push(test)

                        if (test.aftcl_updated_at !== '' &&
                            test.technicalAnalystName !== localStorage.getItem('loginId')) {
                            this.isManagerStatus = true;
                            this.approvedtable = true
                            this.aftclAllDetails.controls['qualityManagerStatus'].setValue('Approved');
                        } else {
                            this.isManagerStatus = false;
                            this.aftclAllDetails.controls['qualityManagerStatus'].setValue('');
                        }
                        if (test.aftcl_updated_at == null || test.aftcl_updated_at == '') {
                            this.buttonSave = true;
                            this.assigenTable = true;
                        }
                    }
                    else if (localStorage.getItem('loginId') == 'vsspl' || localStorage.getItem('loginId') == 'RECEPTIONIST') {
                        localdatass.push(test)
                        this.buttonSave = true;
                    }

                }
                this.procedureResults = localdatass.sort((a, b) => {
                    const idA = a.aftcl_procedure_id;
                    const idB = b.aftcl_procedure_id;
                    return idA - idB;
                });
                const uniqueProcedureIds = new Set(localdatass.map(item => item.aftcl_procedure_id));
                const uniqueProcedureResults = Array.from(uniqueProcedureIds).map(procedureId => {
                    return localdatass.find(item => item.aftcl_procedure_id === procedureId);
                });
                this.filterprocedureResults = uniqueProcedureResults
            });
    }

    // Handle sub-group details selection
    subGroupDetails(event: any) {
        this.procedureResults = []
        this.isVisibleAnalysis = false;
        this.isVisibleParameter = false;
        this.isVisiblePlusIcon = false;
        this.isVisibleStandard = false;
        this.testPerformed = []
        this.sampleData = []
        this.testStandard = []
        this.testAnalysis = []
        this.standardData = []
        var paramList: String[] = [];
        const value = event.target.value;
        for (let param of this.procedureList) {
            if (value == param.aftcl_test_sub_group) {
                paramList.push(param.aftcl_test_sample)
            }
        }
        this.paramList = paramList.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        });
    }

    // Add to the product
    onSelectPlusIcon() {
        if (this.procedureResults.length === 0) {
            this.toaster.error("No Products Added, Please try again...");
            return;
        }

        for (let param of this.selectedParameters) {
            const data = this.procedureList.find(item =>
                item.aftcl_test_parameter === param
            );

            if (data) {
                const procedureRes: ProcedureResults = new ProcedureResults();
                procedureRes.aftcl_procedure_id = data.aftcl_product_list_id;
                procedureRes.aftcl_test_sample_id = data.aftcl_test_sample_id;
                procedureRes.aftcl_test_sample = data.aftcl_test_sample;
                procedureRes.aftcl_standard = data.aftcl_standard;
                procedureRes.aftcl_test_analysis = data.aftcl_test_analysis;
                procedureRes.aftcl_test_parameter = data.aftcl_test_parameter;
                procedureRes.aftcl_test_method = data.aftcl_test_method;
                procedureRes.aftcl_fssai_standard = data.aftcl_fssai_standard || null;
                procedureRes.aftcl_test_unit = data.aftcl_test_unit || null;

                this.procedureResults.push(procedureRes);
            }
        }

        this.newAdd = this.selectedParameters.length > 0;

        if (this.newAdd) {
            this.toaster.success("Products added Successfully");
        } else {
            this.toaster.error("No Products Added, Please try again...");
        }
    }

    // Handle selection of test product
    onSelectProduct(event) {
        const value = event.target.value;
        this.product = value;
        this.procedureResults = []
        this.isVisibleStandard = false;
        this.isVisibleAnalysis = false;
        this.isVisibleParameter = false;
        this.isVisiblePlusIcon = false;
        this.testPerformed = []
        this.sampleData = []
        this.testStandard = []
        this.testAnalysis = []
        this.standardData = []
        var Tests: String[] = [];
        if (this.testStandard.length == 0) {
            this.isVisibleStandard = false;
        }
        var paramId: Number[] = [];

        if (value != "") {
            for (let test of this.procedureList) {
                if (test.aftcl_test_sample === value) {
                    Tests.push(test.aftcl_standard);
                    this.sampleData.push(test);
                }
                Tests.sort();
                this.paramId = paramId;
            }
            setTimeout(fun => {
                const currentAging = new Set(this.sampleData.map(item => item.aftcl_standard));
                this.testStandard = Array.from(currentAging)
                this.isVisibleStandard = true;
            }, 300);
        }

    }

    // Handle selection of test Standard
    onSelectStandard(event) {
        const value = event.target.value;
        this.isVisibleAnalysis = false;
        this.analysis = value;
        var Tests: String[] = [];
        var paramId: Number[] = [];
        this.standardData = [];
        this.testPerformed = [];
        this.testAnalysis = []
        if (this.testPerformed.length == 0) {
            this.isVisibleAnalysis = false;
        }
        if (value != "") {
            for (let test of this.sampleData) {
                if (test.aftcl_standard == value) {
                    Tests.push(test.aftcl_test_analysis);
                    this.standardData.push(test);
                } else
                    Tests.sort();
                this.paramId = paramId;
                setTimeout(fun => {
                    const testPerformed = new Set(this.sampleData.map(item => item.aftcl_test_analysis));
                    this.testPerformed = Array.from(testPerformed)
                    this.isVisibleAnalysis = true;
                }, 300)
            }
        }
    }

    // Handle selection of test Analysis
    onSelectAnalysis(event) {
        const value = event.target.value;
        this.isVisibleParameter = false;
        this.analysis = value;
        var Tests: String[] = [];
        Tests = []
        if (this.testAnalysis.length == 0) {
            this.isVisibleParameter = false;
        }
        var paramId: Number[] = [];
        if (value != "") {
            for (let test of this.standardData) {
                if (test.aftcl_test_analysis == value) {
                    Tests.push(test.aftcl_test_parameter);
                }
                this.paramId = paramId;
                setTimeout(fun => {
                    const currentAging = new Set(this.sampleData.map(item => item.aftcl_test_parameter));
                    this.testAnalysis = Tests
                    this.isVisibleParameter = true;
                }, 300)
            }
        }
    }

    // Handle selection of test parameters
    onSelectParameter(event: any, param: string) {
        this.selectedParameters = []
        const isChecked = event.target.checked;
        if (isChecked) {
            if (!this.selectedParameters.includes(param)) {
                this.selectedParameters.push(param);
                this.isVisiblePlusIcon = true;
                this.updateProcedureResults(param, true);
            }
        } else {
            this.selectedParameters = this.selectedParameters.filter(item => item !== param);
            if (this.selectedParameters.length === 0) {
                this.isVisiblePlusIcon = false;
                this.procedureResults = [];
            } else {
                this.updateProcedureResults(param, false);
            }
        }
    }

    // Update procedure results based on parameter and action (add or remove)
    updateProcedureResults(param: string, add: boolean) {
        const data = this.procedureList.find(item =>
            item.aftcl_test_parameter === param &&
            this.sampleData.some(prodItem => prodItem.aftcl_product_list_id === item.aftcl_product_list_id
                && prodItem.aftcl_test_sample == item.aftcl_test_sample)
        );

        if (data) {
            if (add) {
                const procedureRes: ProcedureResults = new ProcedureResults();
                procedureRes.aftcl_procedure_id = data.aftcl_product_list_id;
                procedureRes.aftcl_ulr_no = this.ulrNoShow;
                procedureRes.aftcl_test_sample_id = data.aftcl_test_sample_id;
                procedureRes.aftcl_test_sample = data.aftcl_test_sample;
                procedureRes.aftcl_standard = data.aftcl_standard;
                procedureRes.aftcl_test_analysis = data.aftcl_test_analysis;
                procedureRes.aftcl_test_parameter = data.aftcl_test_parameter;
                procedureRes.aftcl_test_method = data.aftcl_test_method;
                procedureRes.aftcl_fssai_standard = data.aftcl_fssai_standard || null;
                procedureRes.aftcl_test_unit = data.aftcl_test_unit || null;

                this.procedureResults.push(procedureRes);
            } else {
                this.procedureResults = this.procedureResults.filter(item =>
                    item.aftcl_test_parameter !== param
                );
            }
        }
    }

// Delete a row from procedureResults array
    deleteRow(index: number) {
        if (!this.isUpdate || this.newAdd) {
            this.procedureResults.splice(index, 1);
            this.toaster.success("Result Removed Successfully");
        } else if (this.isUpdate && !this.newAdd) {
            this.appService
                .getProcedure(index)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.getProcedureResults();
                    this.toaster.success("Result Deleted Successfully");
                });
        }
    }

    // Enable/disable "aftcl_other_packing_type" control based on selected value
    onShowOthers(event) {
        const value = event.target.value;
        if (value == "Others") {
            this.isDisabled = false;
            this.disable();
        } else {
            this.aftclAllDetails.controls["aftcl_other_packing_type"].setValue("")
            this.isDisabled = true;
            this.disable();
        }
    }

    // Enable/disable "aftcl_other_packing_type" control
    disable() {
        if (this.isDisabled) {
            this.aftclAllDetails.controls["aftcl_other_packing_type"].disable();
        } else {
            this.aftclAllDetails.controls["aftcl_other_packing_type"].enable();
        }
    }

// Navigate to print screen
    NavPrintScreen() {
        this.router.navigate([
            "aftcl-Print-ScreenWeb",
            this.currentTestDetails.aftcl_ulr_no,
        ]);
    }

    // Handle selection of test type
    testSelect(event) {
        const value = event.target.value;
        if (value == "NABL Test") {
            this.testType = "NABL Test";
            this.aftclAllDetails.enable()
            this.aftclAllDetails.get('normalTest').disable()
            this.aftclAllDetails.get('internalTest').disable()
            this.aftclAllDetails.controls["aftcl_other_packing_type"].disable()
            this.aftculrDisable = true;
            const nablTest = this.nablTestList.filter(item => item.aftcl_test_type === 'NABL Test')
          const splitedValues = nablTest.map(it => it.aftcl_samplefa_no.split('/')[2]);
          const sampleNoAsNumbers = splitedValues.map(it => parseInt(it, 10));
          const maxValue = Math.max(...sampleNoAsNumbers)
          this.sampleCurrentNo = String(maxValue + 1);
            this.getSampleNo()
        } else if (value == "Normal Test") {
            this.testType = "Normal Test";
            this.aftclAllDetails.enable()
            this.aftclAllDetails.get('nablTest').disable()
            this.aftclAllDetails.get('internalTest').disable()
            this.aftclAllDetails.controls["aftcl_other_packing_type"].disable()
            this.aftculrDisable = false;
            const nablTest = this.nablTestList.filter(item => item.aftcl_test_type === 'Normal Test')
          const splitedValues = nablTest.map(it => it.aftcl_samplefa_no.split('/')[2]);
          const sampleNoAsNumbers = splitedValues.map(it => parseInt(it, 10));
          const maxValue = Math.max(...sampleNoAsNumbers)
          this.sampleCurrentNo = String(maxValue + 1);
            this.getNormalSampleNo()
        } else if (value == "Internal Test") {
            this.testType = "Internal Test";
            this.aftclAllDetails.enable()
            this.aftclAllDetails.get('normalTest').disable()
            this.aftclAllDetails.get('nablTest').disable()
            this.aftclAllDetails.controls["aftcl_other_packing_type"].disable()
            this.aftculrDisable = false;
            const nablTest = this.nablTestList.filter(item => item.aftcl_test_type === 'Internal Test')
          const splitedValues = nablTest.map(it => it.aftcl_samplefa_no.split('/')[2]);
          const sampleNoAsNumbers = splitedValues.map(it => parseInt(it, 10));
          const maxValue = Math.max(...sampleNoAsNumbers)
          this.sampleCurrentNo = String(maxValue + 1);
            this.getInternalSampleNo()
        }
    }

    // Check if there are duplicates of a given phone number in a list
    isDuplicate(phoneNumber: string, list: any[], currentItem: any): boolean {
        const occurrences = list.filter(item => item.aftcl_phone_number === phoneNumber);
        return occurrences.length > 1;
    }

    // Reload the current page
    reload() {
        window.location.reload()
    }

    // Log a message when mouse is over a value
    onMouseOver(value: string) {
    }

    // Show alert based on whether it's an update or not
    alertShow() {
        if (!this.isUpdate) {
            Swal.fire({
                title: "Details Saved Successfully...",
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.spinner.show();
                    window.location.reload();
                    this.spinner.hide();
                }
            });
        }
         // Show success message for updating details
         else if (this.isUpdate) {
            Swal.fire({
                title: "Details Updated Successfully",
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.router.navigate(["/main/aftcl-Edit-Screen"]);
                }
            });
        }
    }

    // Show confirmation dialog before removing a product at a given index
    removeProduct(index: number) {
        Swal.fire({
            title: 'Do you want Delete the Result?',
            showDenyButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteRow(index);
            }
        })
    }
}
