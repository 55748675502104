<div class="container">

   <div class="row">
      <img src="assets/img/NABL%20AFTC%20%20New.png" class="" alt="Responsive image">
   </div>

   <hr style="width:100%; text-align:left;  border-width:5px; margin-top: 1rem; border-color: black;">
   <p class="text-center text-blue">TEST REPORT</p>

   <div class="table-responsive">
      <table class="table table-bordered ">
         <tr>
            <th>ULR No:</th>
            <td>{{currentTestDetails.aftcl_ulr_no}}</td>
         </tr>
         <tr>
            <th>
               <p>Report No</p>
               <p>Report Date</p>
            </th>
            <td>
               <p>{{currentTestDetails.aftcl_report_no}}</p>
               <p>{{currentTestDetails.aftcl_sample_received_date}}</p>
            </td>
         </tr>
         <tr>
            <th>Customer Name</th>
            <td>{{currentTestDetails.aftcl_customer_name}}</td>
         </tr>
         <tr>
            <th>Address</th>
            <td>{{currentTestDetails.aftcl_customer_address}}</td>
         </tr>
         <tr>
            <th>Contact No</th>
            <td>{{currentTestDetails.aftcl_phone_number}}</td>
         </tr>
         <tr>
            <th>Email Id</th>
            <td>
               <p style="text-align: center;"
                  *ngIf="currentTestDetails.aftcl_email_id== null  || currentTestDetails.aftcl_email_id == ''">--</p>
               <p *ngIf="currentTestDetails.aftcl_email_id!= null">{{currentTestDetails.aftcl_email_id}}</p>
            </td>
         </tr>
         <tr>
            <th>Customer Reference</th>
            <td>
               <p
                  *ngIf="currentTestDetails.aftcl_customer_reference== null  || currentTestDetails.aftcl_customer_reference == ''">
                  --</p>
               <p *ngIf="currentTestDetails.aftcl_customer_reference!= null">
                  {{currentTestDetails.aftcl_customer_reference}}</p>
            </td>
         </tr>
         <tr>
            <th>Sample No</th>
            <td>{{currentTestDetails.aftcl_sample_no}}</td>
         </tr>
         <tr>
            <th>Sample Drawn By</th>
            <td>
               <p
                  *ngIf="currentTestDetails.aftcl_sample_drawn_by == null || currentTestDetails.aftcl_sample_drawn_by == ''">
                  -- </p>
               <p *ngIf="currentTestDetails.aftcl_sample_drawn_by != null">{{currentTestDetails.aftcl_sample_drawn_by}}
               </p>
            </td>
         </tr>
         <tr>
            <th>Sample Received Date</th>
            <td>{{currentTestDetails.aftcl_sample_received_date | date: "dd-MM-yyyy"}}</td>
         </tr>
         <tr>
            <th>Test Starting Date</th>
            <td>{{currentTestDetails.aftcl_test_starting_date | date: "dd-MM-yyyy"}}</td>
         </tr>
         <tr>
            <th>Test Completed Date</th>
            <td>{{currentTestDetails.aftcl_test_complete_date | date: "dd-MM-yyyy"}}</td>
         </tr>
         <tr>
            <th>Sample Qty Received</th>
            <td>{{currentTestDetails.aftcl_sample_qty_received}}</td>
         </tr>
         <tr>
            <th>Packing Condition </th>
            <td>{{currentTestDetails.aftcl_packing_condition}}</td>
         </tr>
         <tr>
            <th>Discipline</th>
            <td>{{currentTestDetails.aftcl_discipline}}</td>
         </tr>
         <tr>
            <th>Group</th>
            <td>{{currentTestDetails.aftcl_group}}</td>
         </tr>
         <tr>
            <th>Product</th>
            <td>{{productList}}</td>
         </tr>
         <tr>
            <th>Sample Name</th>
            <td>{{currentTestDetails.sample_name}}</td>
         </tr>
         <tr>
            <th>Product Description</th>
            <td>{{currentTestDetails.aftcl_description}}</td>
         </tr>
      </table>

      <table class="table table-bordered ">
         <tr class="text-center">
            <th>PARAMETER</th>
            <th>UNIT</th>
            <th>RESULT</th>
         </tr>
         <tr class="col-md-12" *ngFor="let result of TestResult">
            <td>{{result.aftcl_test_parameter}}</td>
            <td>{{result.aftcl_test_unit}}</td>
            <td>{{result.aftcl_test_result}}</td>
         </tr>
      </table>

   </div>

</div>