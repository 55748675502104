<div class="container-fluid">
  <thead>
  <div class="header-space">&nbsp;</div>
  </thead>
  <div class="header">
    <div class="row mt-5">
      <div class="col-2 ml-4">
        <img src="../../../../../assets/img/AFTC%20logo.png" style="height: 150px; width: 150px">
      </div>
      <div class="col-7">
        <p class="text"></p>
        <p class="text1"><i>AGRO FOOD TRADE CENTRE</i></p>
        <p class="text2">(Formerly: Tamilnadu Foodgrains Marketing Yard)</p>
        <p class="text2">[Special Purpose Vehicle (SPV) of Cereals, Pulses & Staples (CPS) Cluster]</p>
        <p class="text4">வேளாண்உணவு வர்த்தக மையம்</p>
        <p class="text3">சிக்கந்தர் சாவடி, அலங்காநல்லூர் ரோடு, மதுரை - 625018.</p>
        <p class="text5">www.grainscluster.com &nbsp;&nbsp; 0452-2660669 &nbsp;&nbsp; E-mail:
          office@agrofoodtradecenter.com</p>
        <p class="text7">GSTIN:33AABCT1101F1Z7&nbsp;&nbsp;CIN:U74999TN1993NPL025771<br>
          FSSAI No:12419012001801</p>
      </div>
      <hr style="width:100%; text-align:left;  border-width:3px; border-color: black;">
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-6">
      <div class="col-6 ml-5">
        <div class="d-flex justify-content-start">
          <span class="content1">No: <b>{{currentWeighBridge.irmNo}}</b></span>
        </div>
      </div>
      <div class="col-6 ml-5">
        <div class="d-flex justify-content-start">
          <span class="content1">Transaction No: <b>{{currentWeighBridge.transactionNumber}}</b></span>
        </div>
      </div>
    </div>
    <div class="col-4 text-end">
      <span class="content1">Date: <b>{{currentDate}}</b></span>
    </div>
  </div>
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-1" style="margin-right: -20px"></div>
        <div class="col-9 row d-flex justify-content-center">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td><span class="content1">Member Name :</span></td>
                <td colspan="3"><span class="content1"><b>{{currentWeighBridge.memberName}}</b></span></td>
              </tr>
              <tr>
                <td><span class="content1">Vehicle No :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.vehicleNumber}}</b></span></td>
                <td><span class="content1">Commodity :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.commodityCode}}</b></span></td>
              </tr>
              <tr>
                <td><span class="content1">Load/Empty :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.loadType}}</b></span></td>
                <td><span class="content1">Shift :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.shift}}</b></span></td>
              </tr>
              <tr>
                <td><span class="content1">Date/Time In :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.dateIn | date: 'dd/MM/yyyy'}} {{currentWeighBridge.dateIn | date: 'HH:mm'}}</b></span></td>
                <td><span class="content1">Date/Time Out :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.dateOut | date: 'dd/MM/yyyy'}} {{currentWeighBridge.dateOut | date: 'HH:mm'}}</b></span></td>
              </tr>
              <tr>
                <td><span class="content1">Gross Weight :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.grossWeight}}</b></span></td>
                <td><span class="content1">Tare Weight :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.tareWeight}}</b></span></td>
              </tr>
              <tr>
                <td><span class="content1">Net Weight :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.netWeight}}</b></span></td>
                <td><span class="content1">Inv Date :</span></td>
                <td><span class="content1"><b>{{currentWeighBridge.invDate | date: 'dd/MM/yyyy'}}</b></span></td>
              </tr>
              <tr>
                <td><span class="content1">Weighment Charges Rs :</span></td>
                <td><span class="content1"><b>{{basicAmount() | currency: 'INR'}}</b></span></td>
                <td><span class="content1">GST@18% :</span></td>
                <td><span class="content1"><b>{{gstValue() | currency: 'INR'}}</b></span></td>
              </tr>
              <tr>
                <td><span class="content1">Total Rs :</span></td>
                <td colspan="3"><span class="content1"><b>{{currentWeighBridge.weighmentChargesRs | currency: 'INR'}}</b></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
