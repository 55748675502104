import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { AftcMembershipDetails } from "@/model/membershipDetails/aftc-membershipDetails";
import { ConfigService } from "@/config.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { PaymentInitModal } from "pg-test-project";
import * as React from "react";
import { createRoot } from "react-dom/client";
import * as uuid from 'uuid';


@Component({
  selector: "app-aftc-testing-membership-form",
  templateUrl: "./aftc-testing-membership-form.component.html",
  styleUrls: ["./aftc-testing-membership-form.component.scss"]
})
export class AftcTestingMembershipForm implements OnInit {
  @ViewChild("sabPaisaSdk", { static: true }) containerRef!: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  fileList: File[] = [];
  formData = new FormData();
  memberOfAftc: string;
  getMemberDetails: AftcMembershipDetails;
  memeberId: number;
  burron: false;
  memberId: number = 0;
  onFarmer: boolean = true;
  onshowAadhaar: boolean = true;
  onshowGst: boolean = true;
  isUpdate: boolean = false;
  readOnly: boolean = false;
  isVisible: boolean = false;
  allMembershipDetails: AftcMembershipDetails[] = [];
  onShow: boolean = true;
  cureetDtate = new Date();
  reactRoot: any = React.createRef();

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private datePipe: DatePipe,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private toastr: ToastrService) {

  }

  termscondition: boolean = false;


  membership = new UntypedFormGroup({
    membershipDetailsId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    applicantName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    applicantRole: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    organizationName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    orgType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    postalAddress: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phoneNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    contactPerson: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    whatsappNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    udyamRegistrationNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    udyamCertificatePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gstNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gstCertificatePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    panNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    panImagePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    aadhaarNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern(/^\d{12}$/)]),
    aadhaarImagePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    businessType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    businessName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    memberOfAftc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    annualTurnoverGroup: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    forum: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    acknowledgement: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    status: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    city: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    memberPeriod: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    countryCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    paymentStatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    admissionFee: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    updateAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    pincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  });

  public membershipError = {
    applicantName: '',
    applicantRole: ' ',
    organizationName: '',
    orgType: '',
    postalAddress: ' ',
    phoneNo: ' ',
    contactPerson: ' ',
    emailId: ' ',
    whatsappNo: ' ',
    udyamRegistrationNo: ' ',
    udyamRegistrationNoCertificatePath: ' ',
    gstNo: ' ',
    gstCertificatePath: ' ',
    aadhaarNo: ' ',
    aadhaarImagePath: ' ',
    panImagePath: ' ',
    businessType: ' ',
    businessName: ' ',
    acknowledgement: ' ',
    city: ' ',
    memberPeriod: ' ',
    pincode: ' ',
  };

  membershipValidation() {
    this.membershipError.applicantName = "";
    this.membershipError.applicantRole = "";
    this.membershipError.orgType = "";
    this.membershipError.organizationName = "";
    this.membershipError.postalAddress = "";
    this.membershipError.phoneNo = "";
    this.membershipError.contactPerson = "";
    this.membershipError.whatsappNo = "";
    this.membershipError.aadhaarNo = "";
    this.membershipError.businessType = "";
    this.membershipError.businessName = "";
    this.membershipError.acknowledgement = "";
    this.membershipError.city = "";
    this.membershipError.memberPeriod = "";
    this.membershipError.pincode = "";


    let hasError = false

    if (this.membership.get("applicantName")?.invalid) {
      this.membershipError.applicantName = "* Enter a Applicant's Name";
      hasError = true
    }
    if (this.membership.get("orgType")?.invalid) {
      this.membershipError.orgType = "* Select the Type of Organization";
      hasError = true
    }
    if (this.membership.get('orgType').value !== 'Farmer' && this.membership.get('orgType').value !== 'Self Help Group') {
      if (this.membership.get("organizationName")?.invalid) {
        this.membershipError.organizationName = "* Enter a Organization Name";
        hasError = true
      }
    }
    if (this.membership.get("postalAddress")?.invalid) {
      this.membershipError.postalAddress = "* Enter a Postal Address";
      hasError = true
    }
    if (this.membership.get("phoneNo")?.invalid) {
      if (this.membership.get('phoneNo').errors.required) {
        this.membershipError.phoneNo = "* Enter a Valid Mobile Number";
      }
      if (this.membership.get('phoneNo').errors.pattern) {
        this.membershipError.phoneNo = "* Please, Enter 10 digit Valid Mobile Number.";
      }
      hasError = true;
    }
    if (this.membership.get("contactPerson")?.invalid) {
      this.membershipError.contactPerson = "* Enter a Authorised Representative Name";
      hasError = true
    }
    if (this.membership.get("whatsappNo")?.invalid) {
      if (this.membership.get('whatsappNo').errors.required) {
        this.membershipError.whatsappNo = "* Enter a Whatsapp Number";
      }
      if (this.membership.get('whatsappNo').errors.pattern) {
        this.membershipError.whatsappNo = "* Please, Enter 10 digit Valid Whatsapp Number.";
      }
      hasError = true;
    }

    if (this.membership.get('orgType').value !== 'Farmer' && this.membership.get('orgType').value !== 'Self Help Group') {
      if (this.membership.get("businessType")?.invalid) {
        this.membershipError.businessType = "* Select the Business Type";
        hasError = true
      }
    }

    if (this.membership.get('orgType').value == 'Farmer' || this.membership.get('orgType').value == 'Self Help Group') {
      if (this.membership.get("aadhaarNo")?.invalid) {
        if (this.membership.get("aadhaarNo").errors.required) {
          this.membershipError.aadhaarNo = "* Enter a Aadhaar Number";
        }
        if (this.membership.get('aadhaarNo').errors.pattern) {
          this.membershipError.aadhaarNo = "* Please, Enter 12 digit Valid Aadhaar Number";
        }
        hasError = true;
      }
    }

    if (this.membership.get('orgType').value !== 'Farmer' && this.membership.get('orgType').value !== 'Self Help Group') {
      if (this.membership.get("businessName")?.invalid) {
        this.membershipError.businessName = "* Enter a Business Name";
        hasError = true
      }
    }

    if (this.membership.get("acknowledgement")?.invalid) {
      this.membershipError.acknowledgement = "* Select Term's & Condition";
      hasError = true
    }
    if (this.membership.get("city")?.invalid) {
      this.membershipError.city = "* Enter a City";
      hasError = true
    }
    if (this.membership.get("memberPeriod")?.invalid) {
      this.membershipError.memberPeriod = "* Select a Member Period Type";
      hasError = true
    }
    if (this.membership.get("pincode")?.invalid) {
      this.membershipError.pincode = "* Enter a Pincode";
      hasError = true
    }

    if (!hasError) {
      this.saveMembershipDetails()
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const ulrParam = params["id"];

      const encResponse = params["encResponse"];

      if (ulrParam != null && encResponse == undefined) {
        this.memberId = ulrParam;
        this.getAllMembership();
        this.readOnly = true;
        this.membership.get('memberPeriod').disable();
        this.membership.get('admissionFee').disable();
      } else if (ulrParam != null && encResponse != null) {
        let data1 = {
          encrytedData: encResponse,
          membershipDetailsId: Number(localStorage.getItem("userId")),
          isSendLink: false,
        };
        this.spinner.show();
        this.apiServices.updateTestPaymentStatus(data1)
          .subscribe((data) => {
            this.spinner.hide();
            if (data.paymentStatus == "SUCCESS") {
              this.alertShow();
            } else {
              this.errorAlertShow()
            }
            localStorage.removeItem("userId")
          }, (error: any) => {
            this.spinner.hide();
            console.log("Something Went wrong");
            this.toastr.error("Something went wrong , Please try again later....")
          })
      } else {
        this.isVisible = true;
        setTimeout(() => {
          this.openModal();
        }, 2000);
        Object.keys(this.membership.controls).forEach((controlName) => {
          if (controlName !== 'memberPeriod') {
            this.membership.get(controlName).disable();
          }
        })
        this.membership.controls['countryCode'].setValue("+91 (India)")
        if (encResponse != null) {
          let data1 = {
            encrytedData: encResponse,
            membershipDetailsId: Number(localStorage.getItem("userId")),
            isSendLink: false,
          };
          this.spinner.show();
          this.apiServices.updatePaymentStatus(data1)
            .subscribe((data) => {
              this.spinner.hide()
              if (data.paymentStatus == "SUCCESS") {
                this.alertShow();
              } else {
                this.errorAlertShow()
              }
              localStorage.removeItem("userId")
            }, (error: any) => {
              this.spinner.hide();
              this.toastr.error("Something went wrong , Please try again later....")
            })
        }
      }

    });
  }

  openModal() {
    this.isVisible = false
  }

  onSubmit() {
    this.membershipValidation();
  }


  terms() {
    this.termscondition = true;
  }


  termcheckbox() {
    if (this.termscondition == true) {
      this.termscondition = false;
    }
  }

  enableFunction() {
    this.membership.enable()
    const date = this.datePipe.transform(this.cureetDtate, 'yyyy-MM-dd');
    const dateFItler = "2025-04-01";
    if (dateFItler <= date) {
      this.membership.controls['admissionFee'].setValue('Admission Fee')
    } else {
      this.membership.get('admissionFee').disable()
    }
  }

  onShowFarmers(event: any) {
    const value = event.target.value;
    if (value == 'Farmer' || value == 'Self Help Group') {
      this.onFarmer = false;
      this.onshowGst = false;
      this.onshowAadhaar = true;
    } else {
      this.onFarmer = true;
      this.onshowGst = true;
      this.onshowAadhaar = false;
    }
  }

  onPhoneNumber(event: any) {
    const value = event.target.value
    this.membership.get("whatsappNo").setValue(value)
  }


  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  saveMembershipDetails() {
    if (this.isUpdate) {
      this.membership.get('memberPeriod').enable();
      this.membership.get('admissionFee').enable();
      this.membership.controls['countryCode'].setValue("91")
      this.membership.controls['status'].setValue('Approved');
      const date = this.datePipe.transform(this.cureetDtate, 'dd-MM-yyy');
      this.membership.controls['updateAt'].setValue(date)
      this.membership.controls['membershipDetailsId'].setValue(Number(this.memberId))
      this.apiServices.updateTestMembership(this.membership.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          this.updatealertShow()
        }, (error: any) => {
          this.spinner.hide();
          console.log("Something Went wrong");
          this.toastr.error("Something went wrong , Please try again later....")
        })
    } else {
      this.spinner.show()
      if (this.membership.get('orgType').value == 'Farmer' || this.membership.get('orgType').value == 'Self Help Group' || this.membership.get('emailId').value == '') {
        this.membership.controls['emailId'].setValue("office@aftc.in")
      }
      this.membership.controls['countryCode'].setValue("91")
      const date = this.datePipe.transform(this.cureetDtate, 'dd-MM-yyy');
      this.membership.controls['createdAt'].setValue(date)
      this.membership.controls['status'].setValue('Approved');
      const formData = new FormData();
      const jsonData = this.membership.value;
      formData.append('membershipDetails', new Blob([JSON.stringify(jsonData)], { type: 'application/json' }));
      if (this.membership.get('udyamCertificatePath').value !== '') {
        formData.append('udyam', this.fileList[0], this.fileList[0].name)
      }
      this.apiServices.saveTestMembership(formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          this.showPayment(data)
          localStorage.setItem('userId', this.memeberId.toString());
        }, (error: any) => {
          this.spinner.hide();
          console.log("Something Went wrong");
          this.toastr.error("Something went wrong , Please try again later....")
        })
    }
  }

  showPayment(data: AftcMembershipDetails) {
    this.memeberId = data.membershipDetailsId;
    let config: any = {
      env: "prod",
      clientTxnId: uuid.v4(),
      payerName: data.applicantName,
      payerEmail: data.emailId,
      payerMobile: data.whatsappNo,
      payerAddress: data.postalAddress,
      amount: '1',
      isOpen: false,
      clientCode: 'LPSD1',
      transUserName: 'Abh789@sp',
      transUserPassword: 'P8c3WQ7ei',
      authkey: 'x0xzPnXsgTq0QqXx',
      authiv: 'oLA38cwT6IYNGqb3',
      sabPaisaUrl: 'https://stage-securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1',
      callbackUrl: 'https://aftc.varnik.cloud/#/testchamberregistrion',
      udf20: data.membershipDetailsId
    }
    const isOpen = true
    this.render(isOpen, config)
  }

  private render(isOpen: boolean, config: any) {
    this.reactRoot.render(
      React.createElement(PaymentInitModal, { ...config, isOpen }))
  }

  ngAfterViewInit() {
    this.reactRoot = createRoot(this.containerRef.nativeElement);
    this.render(false, '');
  }

  onFileChange(event: any, name: string) {
    this.fileList = [];
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];
      var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
      if (fileSizeInMb <= 1) {
        this.fileList.push(selectedFile);
        const formData = new FormData();
        for (let file of this.fileList) {
          formData.append(name, file, file.name)
        }
      } else {
        this.toastr.error("File size is large. Please upload below 10MP")
      }
    }
  }

  getMemberDetailsId() {
    this.apiServices.getMemberDetailsid(this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getMemberDetails = data;
        this.membership.controls['applicantName'].setValue(this.getMemberDetails.applicantName)
        this.membership.controls['city'].setValue(this.getMemberDetails.city)
        this.membership.controls['memberPeriod'].setValue(this.getMemberDetails.memberPeriod)
        this.membership.controls['phoneNo'].setValue(this.getMemberDetails.phoneNo)
        if (this.membership.get('phoneNo').value == "") {
          this.membership.controls['phoneNo'].setValue(this.getMemberDetails.whatsappNo)
        }
        this.membership.controls['whatsappNo'].setValue(this.getMemberDetails.whatsappNo)
        this.membership.controls['applicantRole'].setValue(this.getMemberDetails.applicantRole)
        this.membership.controls['postalAddress'].setValue(this.getMemberDetails.postalAddress)
        this.membership.controls['contactPerson'].setValue(this.getMemberDetails.contactPerson)
        this.membership.controls['emailId'].setValue(this.getMemberDetails.emailId)
        this.membership.controls['udyamRegistrationNo'].setValue(this.getMemberDetails.udyamRegistrationNo)
        this.membership.controls['aadhaarNo'].setValue(this.getMemberDetails.aadhaarNo)
        this.membership.controls['panNo'].setValue(this.getMemberDetails.panNo)
        this.membership.controls['businessType'].setValue(this.getMemberDetails.businessType)
        this.membership.controls['businessName'].setValue(this.getMemberDetails.businessName)
        this.membership.controls['memberOfAftc'].setValue(this.getMemberDetails.memberOfAftc)
        this.membership.controls['annualTurnoverGroup'].setValue(this.getMemberDetails.annualTurnoverGroup)
        this.membership.controls['forum'].setValue(this.getMemberDetails.forum)
        this.membership.controls['acknowledgement'].setValue(this.getMemberDetails.acknowledgement)
        this.membership.controls['organizationName'].setValue(this.getMemberDetails.organizationName)
        this.membership.controls['orgType'].setValue(this.getMemberDetails.orgType)
        this.membership.controls['admissionFee'].setValue(this.getMemberDetails.admissionFee)
        this.membership.controls['pincode'].setValue(this.getMemberDetails.pincode)
        this.membership.controls['paymentStatus'].setValue(this.getMemberDetails.paymentStatus)
        this.membership.controls['countryCode'].setValue("+91 (India)");
        if (this.membership.get('orgType').value == "Farmer" || this.membership.get('orgType').value == "Self Help Group") {
          this.onFarmer = false;
          this.onshowGst = false;
          this.onshowAadhaar = true;
        } else {
          this.onFarmer = true;
          this.onshowGst = true;
          this.onshowAadhaar = false;
        }
        if (this.getMemberDetails.paymentStatus != 'FAILED') {
          this.isUpdate = true;
        }
        for (let test of this.allMembershipDetails) {
          if (test.whatsappNo == this.getMemberDetails.whatsappNo && test.orgType == this.getMemberDetails.orgType) {
            if (test.paymentStatus == 'SUCCESS' && data.acknowledgement != "") {
              this.onShow = false;
            } else if (test.paymentStatus == 'FAILED' && data.acknowledgement != "") {
              this.onShow = true;
            }
          }
        }
        this.membership.controls['city'].setValue(this.getMemberDetails.city)
      }, (error: any) => {
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }


  getAllMembership() {
    this.apiServices.getAllMembershipDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.allMembershipDetails = data;
        this.getMemberDetailsId();
      }, (error: any) => {
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }

  alertShow() {
    Swal.fire({
      title: "Details Saved Successfully, Kindly Check a WhatsApp",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        location.replace("https://dev1.aftc.in/")
      }
    });
  }

  errorAlertShow() {
    Swal.fire({
      title: "Payment Failed, Kindly Check a WhatsApp",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        location.replace("https://dev1.aftc.in/")
      }
    });
  }


  updatealertShow() {
    Swal.fire({
      title: "Details Updated Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        location.replace("https://dev1.aftc.in/")
      }
    });
  }
}

