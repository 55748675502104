import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import {
  WeighBridgeCashDetails
} from "@components/aftc-digitalization/aftc-digitalization-models/weighBridgeCashDetails";


@Component({
  selector: "app-weigh-bridge-cash-management",
  templateUrl: "./weigh-bridge-cash-management.component.html",
  styleUrls: ["./weigh-bridge-cash-management.component.scss"]
})
export class WeighBridgeCashManagementComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText;
  getCashDetails: WeighBridgeCashDetails[] = [];
  isShowCash: boolean = false;
  getAmout: number = 0;
  currentId: number = 0;
  amount: number;
  userType: string;

  constructor(private apiServices: ApiService, private router: Router,
              private toaster: ToastrService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.userType = localStorage.getItem("role");
    this.getAllWeighBridgeCashDetails();
  }

  // Get all invoice details to show in table
  getAllWeighBridgeCashDetails() {
    this.apiServices.getAllWeighBridgeCash()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getCashDetails = data.sort((a, b) => {
          const idA = a.wbCashId;
          const idB = b.wbCashId;
          return idB - idA;
        });
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Collected amount value
  receivedAmount(aftc: any) {
    if (aftc.amount !== undefined && aftc.amount !== null && aftc.amount !== "") {
      this.currentId = aftc.wbCashId;
      this.getAmout = aftc.amount;
      console.log(this.currentId + "///" + this.getAmout);
    }
  }

  // Currency format function
  formatCharges(charges: number): string {
    let formattedCharges = charges.toFixed(2);
    const [integerPart, decimalPart] = formattedCharges.split(".");
    formattedCharges = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedCharges += "." + decimalPart.padEnd(2, "0");
    return formattedCharges;
  }

  // Update status in invoice table for amount collection
  updateStatus(id: number) {
    if (this.currentId == id) {
      const data = {
        id: id,
        amount: this.getAmout,
        status: "Approved"
      };
      this.spinner.show();
      this.apiServices.updateWeighBridgeCashReceivedStatus(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.spinner.hide();
          Swal.fire({
            title: "Cash Received Status Updated Successfully",
            // text: "Status Update Successfully",
            icon: "success"
          }).then((result) => {
            window.location.reload();
          });
        }, (err: any) => {
          this.spinner.hide();
          this.toaster.error("Something went wrong, Please try again later");
        });
    }
  }

  // Numbers only acceptable function
  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowRight", "ArrowLeft"];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Invoice print page navigate
  printInvoice(wbCashSeq: string) {
    sessionStorage.setItem("wbCashSeq", wbCashSeq);
    this.router.navigate(["weigh-bridge-cash-receipt"]);
  }
}
