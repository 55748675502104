export class ProcedureResults {
    forEach(arg0: (value: any, index: any) => void) {
        throw new Error('Method not implemented.');
    }
    splice(index: any, arg1: number) {
        throw new Error('Method not implemented.');
    }
    aftcl_procedure_id : number;
    aftcl_procedure_result_id : number;
    aftcl_test_parameter :string;
    aftcl_test_method :string;
    aftcl_test_sample :string;
    aftcl_test_sample_id :number;
    aftcl_test_analysis  :string;
    aftcl_fssai_standard: string;
    aftcl_ulr_no: string;
    aftcl_test_unit: string;
    aftcl_test_result: string;
    aftcl_standard: String ;
    active_yn :string; 
    delete_yn :string;
    aftcl_created_at: string;
    aftcl_updated_at: string;
    technicalAnalystName: string;
    aftcApprove: string;
    disableApproval: boolean;
}