<div class="container">
  <div class="col-10 mt-5 ms-2">
    <div class="mt-3 border-line">
      <div class="row">
        <div class="col-6 mt-2">
          <p style="margin-left: 10px;">GSTIN: <b>33AABCT1101F1Z7</b></p>
        </div>
        <div class="col-6 mt-2">
          <p class="text-end" style="margin-right: 10px;">Phone No: <b>0452 - 2660669</b></p>
        </div>
      </div>
      <div class="col-12 text-center">
        <h2 class="text-bold" style="margin-top: -16px">AGRO FOOD TRADE CENTRE</h2>
        <p style="font-size: 14px;margin-top: -2px">(Formerly: Tamilnadu Foodgrains Marketing Yard),
          Madurai-Allanganallur Road,Sikkantharchavadi,Madurai- 625018</p>
        <h4 class="text-bold" style="margin-top: -4px">Tax Invoice</h4>
      </div>
    </div>
    <table class="border-line">
      <div class="row">
        <div class="col-6">
          <span class="span-font mb-3 ml-2">Utility Member :</span><br>
          <h5 class="text-bold ml-2 mb-0">Internal Road Maintenance (Others)</h5>
          <span class="ml-2 span-font" style="margin-top: -22px">Madurai</span>
        </div>
        <div class="col-4" style="border-left: 1px solid black;">
          <span class="span-font">Invoice No.</span><br>
          <span class="span-font"><b>{{this.cirmNo}}</b></span>
        </div>
        <div class="col-2" style="border-left: 1px solid black;">
          <span class="span-font">Dated</span><br>
          <span class="span-font"><b>{{this.currentDate}}</b></span>
        </div>
      </div>
    </table>
    <table class="table-sm" style="max-height: 530px">
      <thead>
      <tr class="text-center" style="height: 10px">
        <th style="border-top: 1px solid transparent;width: 10px;border-right: 2px black solid">S.No</th>
        <th style="border-top: 1px solid transparent;border-right: 2px black solid">Particulars</th>
        <th style="border-top: 1px solid transparent;border-right: 2px black solid">SAC</th>
        <th class="col-2" style="border-top: 1px solid transparent">Amount (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
        </svg>)</th>
      </tr>
      </thead>
      <tbody>
      <tr class="item" style="height: 10px">
        <td style="border-bottom: 2px solid transparent" class="text-center"><span class="span-font text-bold">1</span>
        </td>
        <td style="border-bottom: 2px solid transparent"><span class="text-bold span-font">Internal Road Maintenance Charges (996426)</span>
        </td>
        <td style="border-bottom: 2px solid transparent"><span class="span-font text-bold">9971</span></td>
        <td style="border-bottom: 2px solid transparent" class="font-weight-bolder text-right h5">{{basicAmount()}}</td>
      </tr>
      <tr class="item" style="height: 10px">
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border: transparent" class="row">
          <span
            class="span-font">IRM Charges No: L: <b>{{truckBillNo[0]}}</b> to <b>{{truckBillNo[truckBillNo.length - 1]}}</b>, V: <b>{{vanBillNo[0]}}</b> to <b>{{vanBillNo[vanBillNo.length - 1]}}</b>,</span><br>
          <span class="span-font mb-3">A: <b>{{autoBillNo[0]}}</b> to <b>{{autoBillNo[autoBillNo.length - 1]}}</b>, Dt: <b>{{billDate | date: 'dd.MM.yyyy'}}</b></span>
          <span
            class="span-font">Lorry - <b>{{this.truckDetails.length}}</b> Nos. - <b>Rs.{{formatCharges(this.truckTotalAmount)}}</b></span><br>
          <span
            class="span-font">Van - <b>{{this.venDetails.length}}</b> Nos. x Rs.10 = <b>Rs.{{formatCharges(this.vanTotalAmount)}}</b></span><br>
          <span
            class="span-font">Auto - <b>{{this.autoDetails.length}}</b> Nos. x Rs.5 = <b>Rs.{{formatCharges(this.autoTotalAmount)}}</b>
            </span><br>
          <span class="span-font">Total = {{formatCharges(this.truckTotalAmount)}}
            + {{formatCharges(this.vanTotalAmount)}} + {{formatCharges(this.autoTotalAmount)}} =
              <b>Rs.{{formatCharges(this.truckTotalAmount + this.vanTotalAmount + this.autoTotalAmount)}}</b></span>
        </td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr class="item" style="height: 10px">
        <td style="border-bottom: 2px solid transparent" class="text-center"><span class="span-font text-bold">2</span>
        </td>
        <td style="border-bottom: 2px solid transparent;border-top: 2px solid transparent" class="font-weight-bolder">
          <span class="span-font text-bold">CGST - 9%</span></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent" class="text-right h5"><span
          class="span-font text-bold">{{gst()}}</span></td>
      </tr>
      <tr class="item" style="height: 10px">
        <td style="border-bottom: 2px solid transparent" class="text-center"><span class="span-font text-bold">3</span>
        </td>
        <td style="border-bottom: 2px solid transparent" class=""><span class="span-font text-bold">SGST - 9%</span>
        </td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent" class="text-right h5"><span
          class="span-font text-bold">{{gst()}}</span></td>
      </tr>
      <tr class="item" style="height: 10px">
        <td style="border-bottom: 2px solid transparent" class="text-center"><span class="span-font text-bold">4</span>
        </td>
        <td style="border-bottom: 2px solid transparent" class=""><span class="span-font text-bold">Rounded Off</span>
        </td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent" class="text-right h5"><span
          class="span-font text-bold">{{roundedOff()}}</span></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid black"></td>
        <td style="border-bottom: 2px solid black"></td>
        <td style="border-bottom: 2px solid black"></td>
        <td style="border-bottom: 2px solid black"></td>
      </tr>
      <tr>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent" class="text-end"><h5 class="text-bold">Total</h5></td>
        <td style="border-bottom: 2px solid transparent"></td>
        <td style="border-bottom: 2px solid transparent" class="text-right h5"><h5 class="text-bold">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
          </svg> {{formatCharges(this.totalAmount)}}</h5></td>
      </tr>
      </tbody>
    </table>
    <table style="border-bottom: 2px solid transparent">
      <div style="border-top: 1px solid transparent;padding: 3px">
        <h4 class="text-bold ms-2">Indian Rupees {{this.totalAmountInWords}} Only</h4>
      </div>
    </table>
    <table style="border-bottom: 2px solid transparent">
      <div class="row">
        <div class="col">
          <h5 class="ms-2">Acc.Name : Agro Food Trade Centre</h5>
        </div>
        <div class="col">
          <h5 class="text-end">Acc. No. : 835120110000006</h5>
        </div>
      </div>
      <div class="row">
        <div class="col ms-2">
          <h5 class="">Bank of India - Milakaranai Branch</h5>
        </div>
        <div class="col">
          <h5 class="text-end">IFSC : BKID0008351</h5>
        </div>
      </div>
    </table>
    <table>
      <div class="row">
        <div class="col-7">
          <div class="row" style="margin-top: 112px;">
            <div class="col-6 ms-1">
              <p class="text-bold mb-0 ml-4">Prepared By</p>
            </div>
            <div class="col-5">
              <p class="text-bold text-end mb-0">Checked By</p>
            </div>
          </div>
        </div>
        <div class="col-5" style="border-left: 1px solid black;">
          <h5 class="text-center text-bold">For AGRO FOOD TRADE CENTRE</h5>
          <div class="row">
            <div class="col-6 ms-5">
              <p class="text-bold mb-0"></p>
            </div>
            <div class="col-" style="margin-top: 80px;">
              <p class="text-bold mb-0 text-end">Authorised Signatory</p>
            </div>
          </div>
        </div>
      </div>
    </table>
  </div>
  <div class="col-2"></div>
</div>


<div class="container" id="second-container">
  <div class="col-10 mt-4 ms-2">
    <h4 class="text-bold ms-3">Auto :</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr class="text-center">
          <th>S.No</th>
          <th>Vehicle Number</th>
          <th>Member Name</th>
          <th>Charges (₹)</th>
          <th>Storage Type</th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-center" *ngFor="let auto of this.autoDetails; let i = index">
          <td>{{i + 1}}</td>
          <td>{{auto.tollgateVehicleDetails.vehNumberPlate}}</td>
          <td>{{auto.memberName}}</td>
          <td>{{formatCharges(auto.charges)}}</td>
          <td>{{auto.serviceType}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-10 mt-2">
    <h4 class="text-bold ms-3">Van :</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr class="text-center">
          <th>S.No</th>
          <th>Vehicle Number</th>
          <th>Member Name</th>
          <th>Charges (₹)</th>
          <th>Storage Type</th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-center" *ngFor="let van of this.venDetails; let i = index">
          <td>{{i + 1}}</td>
          <td>{{van.tollgateVehicleDetails.vehNumberPlate}}</td>
          <td>{{van.memberName}}</td>
          <td>{{formatCharges(van.charges)}}</td>
          <td>{{van.serviceType}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-10 mt-2">
    <h4 class="text-bold ms-3">Truck :</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr class="text-center">
          <th>S.No</th>
          <th>Vehicle Number</th>
          <th>Member Name</th>
<!--          <th class="col-1">wheel Count</th>-->
          <th>Place of Origin</th>
          <th>Weights in MT</th>
          <th>Charges (₹)</th>
          <th>Storage Type</th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-center" *ngFor="let truck of this.truckDetails; let i = index">
          <td>{{i + 1}}</td>
          <td>{{truck.tollgateVehicleDetails.vehNumberPlate}}</td>
          <td>{{truck.memberName}}</td>
<!--          <td>{{truck.tollgateVehicleDetails.wheelCount}}</td>-->
          <td>{{truck.placeDispatchOrigin}}</td>
          <td>{{truck.weightMt ? truck.weightMt : '-'}}</td>
          <td>{{formatCharges(truck.charges)}}</td>
          <td>{{truck.serviceType}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
