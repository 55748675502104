import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { WarehouseStockDeposit } from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";
import {
  warehouseStockDelivery
} from "@components/aftc-digitalization/aftc-digitalization-models/warehouseStockDelivery";


@Component({
  selector: "app-delivery-unit-entry",
  templateUrl: "./delivery-unit-entry.component.html",
  styleUrls: ["./delivery-unit-entry.component.scss"]
})
export class DeliveryUnitEntryComponent implements OnInit {
  deliveryDetails: warehouseStockDelivery | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apiServices: ApiService,private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {

  }

  deliveryForm = new UntypedFormGroup({
    stockDeliveryId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    deliverOrderNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    deliveryDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    bagsOf: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weighing: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    warehouseReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    date: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageChargesInvoiceNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    atOfficeDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    currentStage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billPeriodType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    godownNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stackNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  });

  deliveryFormErrors = {
    deliverOrderNo : "",
    commodity : "",
    warehouseReceiptNo : "",
    storageChargesInvoiceNo : "",
    deliveryDate: "",
    bagsOf: "",
    weighing: "",
    date: "",
    depositorName: "",
    iRMReceiptNo: "",
    vehicleNo: "",
    atOfficeDate: "",
    amount: "",
    billPeriodType: "",
    godownNo: "",
    stackNo: "",
  }

  formValidation(){
    this.deliveryFormErrors.deliverOrderNo = "";
    this.deliveryFormErrors.commodity = "";
    this.deliveryFormErrors.warehouseReceiptNo = "";
    this.deliveryFormErrors.billPeriodType = "";
    this.deliveryFormErrors.date = "";
    this.deliveryFormErrors.godownNo = "";
    this.deliveryFormErrors.stackNo = "";
    let hasError = false;

    if(this.deliveryForm.get('deliverOrderNo').invalid){
      this.deliveryFormErrors.deliverOrderNo = "*Delivery order no is required"
      hasError = true
    }
    if(this.deliveryForm.get('commodityName').invalid){
      this.deliveryFormErrors.commodity = "*Commodity name is required"
      hasError = true
    }
    if(this.deliveryForm.get('warehouseReceiptNo').invalid){
      this.deliveryFormErrors.warehouseReceiptNo = "*Warehouse receipt no is required"
      hasError = true
    }
    if(this.deliveryForm.get('date').invalid){
      this.deliveryFormErrors.date = "*Date is required"
      hasError = true
    }
    if(this.deliveryForm.get('godownNo').invalid){
      this.deliveryFormErrors.godownNo = "*Godown no is required"
      hasError = true
    }
    if(this.deliveryForm.get('stackNo').invalid){
      this.deliveryFormErrors.stackNo = "*Stack no is required"
      hasError = true
    }
    if(!hasError){
      console.log(this.deliveryForm.value);
      // this.addStockDelivery()
    }

  }

  ngOnInit() {
    const deliveryId = sessionStorage.getItem('deliveryId');
    this.getDeliveryDetailsById(deliveryId);
    this.initial_data();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('deliveryId')
    sessionStorage.removeItem('storageType')
    sessionStorage.removeItem('serviceType')
    sessionStorage.removeItem('tollgateSeqNo')
  }

  initial_data() {
    const now = new Date();
    console.log(now);
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    console.log("format :", formattedDateTime);
    this.deliveryForm.controls["depositDate"].setValue(formattedDateTime);
  }

  getDeliveryDetailsById(deliveryId) {
    this.apiServices.getDeliveryDetails(deliveryId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("application No::", data);
        this.deliveryDetails = data;
        this.deliveryForm.controls['deliverOrderNo'].setValue(this.deliveryDetails.deliverOrderNo);
        this.deliveryForm.controls['depositorName'].setValue(this.deliveryDetails.depositorName);
        this.deliveryForm.controls['commodityName'].setValue(this.deliveryDetails.commodityName);
        this.deliveryForm.controls['bagsOf'].setValue(this.deliveryDetails.bagsOf);
        this.deliveryForm.controls['weighing'].setValue(this.deliveryDetails.weighing);
        this.deliveryForm.controls['vehicleNo'].setValue(this.deliveryDetails.vehicleNo);
        this.deliveryForm.controls['amount'].setValue(this.deliveryDetails.amount);
        this.deliveryForm.controls['irmReceiptNo'].setValue(this.deliveryDetails.irmReceiptNo);
        this.deliveryForm.controls['storageType'].setValue(this.deliveryDetails.storageType);
        this.deliveryForm.controls['serviceType'].setValue(this.deliveryDetails.serviceType);
        this.deliveryForm.controls['billPeriodType'].setValue(this.deliveryDetails.billPeriodType);
        this.deliveryForm.controls['deliveryDate'].setValue(this.deliveryDetails.deliveryDate);
        this.deliveryForm.controls['date'].setValue(this.deliveryDetails.date);
        this.deliveryForm.controls['createdAt'].setValue(this.deliveryDetails.createdAt);
        this.deliveryForm.controls['warehouseReceiptNo'].setValue(this.deliveryDetails.warehouseReceiptNo);
        this.deliveryForm.controls['godownNo'].setValue(this.deliveryDetails.godownNo);
        this.deliveryForm.controls['stackNo'].setValue(this.deliveryDetails.stackNo);
        const milliseconds = this.deliveryDetails.date * 1000;
        const date = new Date(milliseconds);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        this.deliveryForm.controls['date'].setValue(formattedDate);
        const deliveryDate = this.deliveryDetails.deliveryDate * 1000;
        const dateFormat = new Date(deliveryDate);
        const formattedDates = `${dateFormat.getFullYear()}-${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}-${dateFormat.getDate().toString().padStart(2, '0')}T${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
        this.deliveryForm.controls['deliveryDate'].setValue(formattedDates);
      }, (err: any) => {
        console.log("something went wrong::", err);
        this.toaster.error("Something went wrong, Please try again later");
      })
  }

  addStockDelivery(){
    this.spinner.show()
    const deliveryDate = this.deliveryForm.get("deliveryDate").value;
    const epochTimestamp = new Date(deliveryDate).getTime() / 1000;
    this.deliveryForm.controls["deliveryDate"].setValue(epochTimestamp);
    const date = this.deliveryForm.get("date").value;
    const epochStartTimestamp = new Date(date).getTime() / 1000;
    this.deliveryForm.controls["date"].setValue(epochStartTimestamp);
    const atOfficeDate = this.deliveryForm.get("atOfficeDate").value;
    const epochEndTimestamp = new Date(atOfficeDate).getTime() / 1000;
    this.deliveryForm.controls["atOfficeDate"].setValue(epochEndTimestamp);
    this.deliveryForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    this.deliveryForm.controls['storageType'].setValue(sessionStorage.getItem('storageType'));
    this.deliveryForm.controls['serviceType'].setValue(sessionStorage.getItem('serviceType'));
    this.apiServices.addStockDelivery(this.deliveryForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("saveStockDeposit::",data)
        this.saveAlert(data.stockDeliveryId)
      },(err : any) =>{
        this.spinner.hide()
        console.log("something went wrong")
      })
  }


  saveAlert(depositId: number) {
        Swal.fire({
            title: "Details Saved Successfully...",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
              sessionStorage.setItem('depositorId', String(depositId));
               this.router.navigate(['/deposit-print']);
            }
        });
  }

}
