import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-weigh-bridge-navbar",
  templateUrl: "./weigh-bridge-navbar.component.html",
  styleUrls: ["./weigh-bridge-navbar.component.scss"]
})
export class WeighBridgeNavbarComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
