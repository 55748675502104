<app-weigh-bridge-navbar *ngIf="userType == 'ADMIN'"></app-weigh-bridge-navbar>
<div class="container -fulid">
  <div *ngIf="userType == 'ADMIN'" class="row"></div>
  <div class="row mt-5">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
      </div>
    </div>
    <div class="container">
      <div class="table-responsive">
        <table class="table">
          <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>WB Bill Number</th>
            <th>Total Bill Amount (₹)</th>
            <th>Bill Date</th>
            <th>Received Amount (₹)</th>
            <th>Cash Deposit</th>
            <th>Invoice Print</th>
          </tr>
          </thead>
          <tbody *ngFor="let aftc of this.getCashDetails | filter: searchText;let i = index">
          <tr class="text-center">
            <td>{{i + 1}}</td>
            <td>{{aftc.wbCashSeq}}</td>
            <td>{{formatCharges(aftc.billAmount)}}</td>
            <td>{{aftc.createdAt | date : "dd/MM/yyyy"}}</td>
            <td><input class="form-control" type="text" *ngIf="aftc.receivedAmount == ''" [(ngModel)]="aftc.amount"
                       (keypress)="OnlyNumbers($event)" (input)="receivedAmount(aftc)">
              <input class="form-control" type="text" [value]="formatCharges(aftc.receivedAmount)"
                     *ngIf="aftc.receivedAmount != ''" readonly>
            </td>
            <td><label *ngIf="aftc.status == 'Approved'">Received</label>
              <button *ngIf="aftc.status == '' || aftc.status == 'Not Approved'" class="btn btn-success" type="button"
                      [disabled]="!aftc.amount || isShowCash" (click)="updateStatus(aftc.wbCashId)">Collect
              </button>
            </td>
            <td><i title="Print" class="fas fa-print" (click)="printInvoice(aftc.wbCashSeq)"></i></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
