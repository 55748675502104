<div class="container -fulid">
  <div class="row">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
        <h3>Front Office</h3>
      </div>
    </div>
    <div class="col-md-4 justify-content-end">
      <div class="div mb-2 mt-3">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
        <tr class="sticky-top">
          <th>No</th>
          <th>Entry Date/Time</th>
          <th>Report No</th>
          <th>Service Type</th>
          <th>Member Name</th>
          <th>Driver Name</th>
          <th>Vehicle Number</th>
          <th>Sub Report</th>
          <th>Print</th>
        </tr>
        </thead>
        <tbody *ngFor="let aftc of this.getPassDetails | filter: searchText;let i = index">
        <tr>
          <td>
            <div *ngIf="!checkStorageType(aftc)" (click)="report(aftc)">{{i + 1}}</div>
            <div *ngIf="checkStorageType(aftc)">{{i + 1}}</div>
          </td>
          <td>
            <div *ngIf="!checkStorageType(aftc)" (click)="report(aftc)">{{aftc.entryDate}}</div>
            <div *ngIf="checkStorageType(aftc)">{{aftc.entryDate}}</div>
          </td>
          <td>
            <div *ngIf="aftc.reportAppNo != ''">{{aftc.reportAppNo}}</div>
            <div *ngIf="aftc.reportAppNo == ''" (click)="report(aftc)"> -</div>
          </td>
          <td>
            <div *ngIf="aftc.reportStorageType != ''">{{aftc.reportStorageType}}</div>
            <div *ngIf="aftc.reportStorageType == '' && aftc.storageType == ''" (click)="report(aftc)"> -</div>
            <div *ngIf="aftc.reportStorageType == '' && aftc.storageType != ''"
                 (click)="report(aftc)">{{aftc.storageType}}</div>
          </td>
          <td>
            <div *ngIf="!checkStorageType(aftc)" (click)="report(aftc)">{{aftc.memberName}}</div>
            <div *ngIf="checkStorageType(aftc)">{{aftc.memberName}}</div>
          </td>
          <td>
            <div *ngIf="!checkStorageType(aftc)"
                 (click)="report(aftc)">{{aftc.tollgateVehicleDetails.driverName}}</div>
            <div *ngIf="checkStorageType(aftc)">{{aftc.tollgateVehicleDetails.driverName}}</div>
          </td>
          <td>
            <div *ngIf="!checkStorageType(aftc)"
                 (click)="report(aftc)">{{aftc.tollgateVehicleDetails.vehNumberPlate}}</div>
            <div *ngIf="checkStorageType(aftc)">{{aftc.tollgateVehicleDetails.vehNumberPlate}}</div>
          </td>
          <td>
            <button *ngIf="checkWarehouse(aftc)" class="btn btn-success" style="background-color: #22c158"
                    (click)="subReportPopup(aftc)">Add
            </button>
            <div *ngIf="!checkWarehouse(aftc)">-</div>
          </td>
          <td>
            <div *ngIf="aftc.reportStorageType != ''" (click)="navigateReceipt(aftc)"><i class="fas fa-print"></i></div>
            <div *ngIf="aftc.reportStorageType == ''" (click)="report(aftc)"> -</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal " role="option" [style.display]="serviceType ? 'block' : 'none'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="model-tittle">Storage & Service Type</h4>
        <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-x-circle-fill mt-2" viewBox="0 0 16 16">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>
      </div>
      <div class="modal-body">
        <form [formGroup]="gateForm">
          <div *ngIf="storageField" class="form-floating">
            <select class="form-select" id="storageType" formControlName="storageType">
              <option value="" disabled selected hidden>
                Select Storage Type
              </option>
              <option value="Common Warehouse">Common Warehouse</option>
              <option value="SLCM">SLCM</option>
              <option value="Cold Storage">Cold Storage</option>
              <option value="Pre Processing">Pre Processing</option>
              <option value="Packing">Packing</option>
            </select>
            <label class="form-label">Select Storage Type<span style="color:red;">*</span></label>
          </div>
          <div *ngIf="serviceField" class="text-center row col-12 justify-content-center">
            <div class="col-5 justify-content-center">
              <input type="radio" role="button" value="Deposit" formControlName="serviceType">
              <label class="ml-1">Deposit</label>
            </div>
            <div class="col-5 justify-content-center">
            <input class="ml-3" type="radio" role="button" value="Delivery" formControlName="serviceType">
            <label class="ml-1">Delivery</label>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button *ngIf="storageField" type="button" class="btn btn-success" style="background-color: #22c158"
                data-dismiss="modal" (click)="storageClose()"><b>Ok</b>
        </button>
        <button *ngIf="serviceField" type="button" class="btn btn-success" style="background-color: #22c158"
                data-dismiss="modal" (click)="serviceClose()"><b>Ok</b>
        </button>
      </div>
    </div>
  </div>
</div>
