import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";


@Component({
  selector: "app-Impact-main-study",
  templateUrl: "./Impact-main-study.component.html",
  styleUrls: ["./Impact-main-study.component.scss"]
})
export class ImPactStudy implements OnInit {

  getLocalPassDetails: TollGate[] = [];
  getPassDetails: TollGate | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  saveAlert() {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      window.location.reload();
    });
  }

}
