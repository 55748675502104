<app-warehouse-unit-navbar></app-warehouse-unit-navbar>
<div class="container -fulid">
  <div class="row"></div>
  <div class="row mt-4">
    <div class="col-md-4 justify-content-start" style="align-self: center">
      <div class="div mt-5">
        <h3>Warehouse Forms Details</h3>
      </div>
    </div>
    <div class="col-md-8 mt-5 justify-content-start">
      <div class="div mb-2">
        <div class="row">
          <div class="col-lg-3 mt-2">
            <div class="form-floating">
              <select class="form-select" (change)="selectForm($event)">
                <option selected>Select Form</option>
                <option value="Deposit">Deposit</option>
                <option value="Delivery">Delivery</option>
              </select>
              <label>Select Form</label>
            </div>
          </div>
          <div class="col-lg-5 mt-2">
            <div class="form-floating">
              <select class="form-select" (change)="filter($event, 'name')">
                <option selected>Select Member Name</option>
                <option *ngFor="let name of this.getMemberName">{{name}}</option>
              </select>
              <label>Member Name</label>
            </div>
          </div>
          <div class="col-lg-3 mt-2">
            <div class="form-floating">
              <select class="form-select" (change)="filter($event, 'date')">
                <option selected>Select Date</option>
                <option *ngFor="let date of this.getDateValue">{{date}}</option>
              </select>
              <label>Date</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row ms-2">
  </div>

  <!--Deposit-->
  <div *ngIf="formType == 'Deposit'" class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>Sr.No</th>
            <th>Application No</th>
            <th>Member Name</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngFor="let aftc of this.getFilteredDepositDetails | filter: searchText;let i = index">
          <tr>
            <td>{{i + 1}}</td>
            <td>{{aftc.depositApplicationNo}}</td>
            <td>{{aftc.depositorName}}</td>
            <td>{{aftc.depositDate * 1000 | date: 'dd/MM/yyyy'}}</td>
            <td><button class="btn btn-success" (click)="updateDetails(aftc.stockDepositId, 'Deposit')">Edit</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!--Delivery-->
  <div *ngIf="formType == 'Delivery'" class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
        <tr class="sticky-top">
          <th>Sr.No</th>
          <th>Application No</th>
          <th>Member Name</th>
          <th>Date</th>
          <th></th>
        </tr>
        </thead>
        <tbody *ngFor="let aftc of this.getFilteredDeliveryDetails | filter: searchText;let i = index">
        <tr>
          <td>{{i + 1}}</td>
          <td>{{aftc.deliverOrderNo}}</td>
          <td>{{aftc.depositorName}}</td>
          <td>{{aftc.deliveryDate * 1000 | date: 'dd/MM/yyyy'}}</td>
          <td><button class="btn btn-success" (click)="updateDetails(aftc.stockDeliveryId, 'Delivery')">Edit</button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaderssm">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
  </div>
</ngx-spinner>
