<div class="container-fluid">

   <div class="row">
      <div class="col-md-3 mt-3 ml-5">
         <button type="button" class="btn btn-success" routerLink="/main/aftcl-add-Product-Screen">Add Product</button>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4 ml-5 mt-3">
         <div class="form-floating mb-3">
            <input type="text" class="form-control" placeholder="Search.." [(ngModel)]="searchText">
            <label>Search</label>
         </div>
      </div>
   </div>

   <div class="container-fluid">

      <div class="row mt-3">
         <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">

            <table class="table table-sm table-bordered text-center table-hover mb-0">
               <thead class="header sticky-top">
                  <tr>
                     <th>S.No</th>
                     <th>NAME OF THE SAMPLE</th>
                     <th>STANDARD</th>
                     <th>NAME OF THE ANALYSIS</th>
                     <th>PARAMETER</th>
                     <th>TEST METHOD</th>
                     <th>UNIT</th>
                     <th>NORMS</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let product of showProduct |filter: searchText;  let i = index;">
                     <td class="">{{i+1}}</td>
                     <td class="">{{product.aftcl_test_sample}}</td>
                     <td class="">{{product.aftcl_standard}}</td>
                     <td class="col-md-2">{{product.aftcl_test_analysis}}</td>
                     <td class="col-md-1">{{product.aftcl_test_parameter}}</td>
                     <td class="col-md-2">
                        <p *ngIf="product.aftcl_test_method == null || product.aftcl_test_method == '' ">-&#45;&#45;</p>
                        <p *ngIf="product.aftcl_test_method != null">{{product.aftcl_test_method}}</p>
                     </td>
                     <td class="col-md-1">
                        <p *ngIf="product.aftcl_test_unit == null || product.aftcl_test_unit == '' ">-&#45;&#45;</p>
                        <p *ngIf="product.aftcl_test_unit != null">{{product.aftcl_test_unit}}</p>
                     </td>
                     <td class="col-md-3">
                        <p *ngIf="product.aftcl_fssai_standard == null || product.aftcl_fssai_standard == '' ">
                           -&#45;&#45;</p>
                        <p *ngIf="product.aftcl_fssai_standard != null">{{product.aftcl_fssai_standard}}</p>
                     </td>
                     <td class="col-md-1">
                        <div class="row">
                           <div class="col-md-6">
                              <i class='fas fa-edit' type="button" style='font-size:16px' data-toggle="tooltip"
                                 data-placement="top" title="Click to Edit"
                                 (click)="editProduct(product.aftcl_product_list_id);"></i>
                           </div>
                           <div class="col-md-6">
                              <i class='fas fa-trash' type="button" style='font-size:16px' data-toggle="tooltip"
                                 data-placement="top" title="Click to Delete"
                                 (click)="deleteShow(product.aftcl_product_list_id)"></i>
                           </div>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>

         </div>
      </div>

   </div>
</div>

<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
   <div id="page">
      <div id="container">
         <div id="ring"></div>
         <div id="ring"></div>
         <div id="ring"></div>
         <div id="ring"></div>
         <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
      </div>
   </div>
</ngx-spinner>